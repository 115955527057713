import { useState } from 'react'
import {
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast
} from '@chakra-ui/react'
import { copyToClipboard } from '~/lib/copyToClipboard'

export type AcceptIntroRequestModalProps = {
  isOpen: boolean
  onClose: () => void
  introduction: Introduction
}

export const AcceptIntroRequestModal = ({ isOpen, onClose, introduction }: AcceptIntroRequestModalProps) => {
  const toast = useToast()
  const [isCopied, setIsCopied] = useState(false)

  const { requester, requesterMembership } = introduction

  const handleEmailCopy = async () => {
    try {
      await copyToClipboard(requesterMembership?.email)
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 4000)
      toast({
        title: 'Copied!',
        description: `You've copied ${requesterMembership?.email} to your clipboard.`,
        status: 'success',
        duration: 2000,
        position: 'top',
        isClosable: true
      })
    } catch (err) {
      toast({
        title: 'Failed to copy email address.',
        description: 'Please try again.',
        status: 'error',
        position: 'top',
        duration: 2000,
        isClosable: true
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader mt={6} textAlign="center">
          <Text fontWeight="semibold" fontSize="xl" lineHeight="shorter">
            Thanks for accepting the Introduction Request!
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center" mb={4}>
          <Text mb={2}>
            You can now make the introduction for {requester?.firstName}
            as you see fit (text, email, etc.). Here is {requester?.firstName}’s email to cc them on the introduction.
          </Text>

          <Text mb={2}>
            <strong>{requesterMembership?.email}</strong> (
            <Link as="span" sx={{ cursor: 'pointer' }} onClick={handleEmailCopy}>
              {isCopied ? 'copied!' : 'copy'})
            </Link>
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
