import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, CardBody, CardFooter, Card as ChakraCard, Flex, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { formatDistanceToNow } from 'date-fns'
import { fullname } from '~/lib'
import { StatusBadge } from './StatusBadge'

type IntroductionCardProps = {
  introduction: Introduction
  user: User
  anonymousSuperConnectorIds: number[]
}

const nameOrYou = (person: Person, id: number) => {
  if (person.id === id) return 'You'
  return fullname(person)
}

const connectorName = (introduction: Introduction, id: number, anons: number[]) => {
  const { connector } = introduction

  if (connector.id === id) return 'You'
  if (introduction.isWilling === true || anons.indexOf(connector.id) === -1) {
    return fullname(connector)
  }
  return 'Anonymous'
}

export const IntroductionCard = ({ introduction, user, anonymousSuperConnectorIds }: IntroductionCardProps) => {
  const navigate = useNavigate()
  const { id, status, createdAt, requester, prospect } = introduction

  const handleViewDetailsClick = useCallback(
    (id: number) => {
      return navigate(String(id))
    },
    [id]
  )

  return (
    <motion.div
      initial={{
        opacity: 0.25
      }}
      animate={{
        opacity: 1.0
      }}
    >
      <ChakraCard mb={6}>
        <CardBody>
          <Flex
            direction={{
              base: 'column',
              sm: 'row'
            }}
            justify="space-between"
          >
            <Flex
              direction="column"
              textAlign={{
                base: 'center',
                md: 'left'
              }}
            >
              <Flex
                alignItems="center"
                justifyContent={{
                  base: 'center',
                  sm: 'left'
                }}
              >
                <StatusBadge status={status} />
                <Text as="span" fontSize="xs" fontStyle="italic" ml={2}>
                  {formatDistanceToNow(createdAt, { addSuffix: true })}
                </Text>
              </Flex>
              <Box mt={2}>
                <Text as="h1" fontSize="3xl">
                  {fullname(prospect)}
                </Text>
                <Text as="span" fontWeight="bold" fontSize="md" mr={1}>
                  {prospect.companyName}
                </Text>
                <Text as="span" fontSize="md">
                  {prospect.companyPosition}
                </Text>
              </Box>
            </Flex>
            <Flex align="center" p={4}>
              <Button onClick={() => handleViewDetailsClick(id)}>View Details</Button>
            </Flex>
          </Flex>
        </CardBody>
        <CardFooter px={6} py={2} fontSize="xs" bg="#f1f5f9">
          <Flex
            w="100%"
            direction={{
              base: 'column',
              sm: 'row'
            }}
          >
            <Box
              textAlign="center"
              mr={{
                base: 0,
                sm: 7
              }}
            >
              <Text as="span" fontWeight="bold" mr={2}>
                Connector
              </Text>
              &gt;
              <Text as="span" ml={2}>
                {connectorName(introduction, user.personId, anonymousSuperConnectorIds)}
              </Text>
            </Box>
            <Box textAlign="center">
              <Text as="span" fontWeight="bold" mr={2}>
                Requester
              </Text>
              &gt;
              <Text as="span" ml={2}>
                {nameOrYou(requester, user.personId)}
              </Text>
            </Box>
          </Flex>
        </CardFooter>
      </ChakraCard>
    </motion.div>
  )
}
