// Footer.tsx
import React from 'react'
import { Badge, Box, Container, Flex, Image, Link as ChakraLink } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import SmallWorldLogo from '../assets/smallworld-logo.svg'
import { SUPPORT_URL } from '~/settings'

const Footer = () => {
  return (
    <Box
      as="footer"
      mt={32}
      py={16}
      bgColor="transparent"
      borderTopColor="gray.200"
      borderTopWidth="1px"
      borderTopStyle="solid"
    >
      <Container maxW="container.lg">
        <Flex alignItems="center">
          <Image src={SmallWorldLogo} w={170} mr={24} />

          <ChakraLink
            as={RouterLink}
            to="/terms"
            color="gray.500"
            mr={10}
            _hover={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}
            fontWeight="500"
            fontSize=".75rem"
          >
            Terms of Service
          </ChakraLink>

          <ChakraLink
            as={RouterLink}
            to={SUPPORT_URL}
            color="gray.500"
            _hover={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}
            fontWeight="500"
            fontSize=".75rem"
          >
            Support
          </ChakraLink>
        </Flex>
      </Container>
    </Box>
  )
}

export default Footer
