import { Box, SpaceProps, Text } from '@chakra-ui/react'
import { normalizeStrength } from '~/lib'

type StrengthProps = {
  rating: Strengths
  emojiOnly?: boolean
  fontSize?: string | number
  fontWeight?: 'normal' | 'bold'
  fontColor?: string
}

const emojis: { [key: string]: string } = {
  'Very Strong': '🔥',
  Strong: '💪',
  Average: '🤝',
  Weak: '👎',
  'Very Weak': '❓'
}

const displayEmoji = (rating: Strengths): string => {
  const normalizedRating = normalizeStrength(rating)
  return emojis[normalizedRating]
}

const displayRating = (rating: Strengths): string => {
  const normalizedRating = normalizeStrength(rating)
  return normalizedRating === 'Very Weak' ? 'No Relationship' : normalizedRating
}

export const StrengthDisplay = ({
  emojiOnly = false,
  rating,
  fontSize = '1em',
  fontWeight = 'bold',
  fontColor = 'black',
  ...rest
}: StrengthProps & SpaceProps) => {
  return (
    <Box display="inline-flex" alignItems="center" {...rest}>
      <Text as="span" mr={1} fontSize={fontSize}>
        {displayEmoji(rating)}
      </Text>
      {!emojiOnly && (
        <Text fontWeight={fontWeight} fontSize={fontSize} color={fontColor}>
          {displayRating(rating)}
        </Text>
      )}
    </Box>
  )
}
