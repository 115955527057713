import { ChangeEvent, FormEvent, useState } from 'react'
import { ChatIcon } from '@chakra-ui/icons'
import { Box, Divider, Flex, IconButton, Text, Textarea } from '@chakra-ui/react'
import { useUserRoleIn } from './hooks'
import { BackAndForth } from './BackAndForth'
import { useCreateIntroductionMessageMutation, useGetIntroductionMessagesQuery } from '~/api/introductions'

type MessagingProps = {
  introduction: Introduction
  user: User
  currentMembership: Membership
  anonymous: boolean
}

const generatePlaceholder = (introduction: Introduction, isRequester: boolean, isAnonymous: boolean) => {
  if (isRequester && isAnonymous) return 'Type a message to the Super Connector'
  if (isRequester) return `Type a message to ${introduction.connector.firstName}`

  return `Type a message to ${introduction.requester.firstName}`
}

export const Messaging = ({ introduction, user, currentMembership, anonymous }: MessagingProps) => {
  const roles = useUserRoleIn(introduction, user, currentMembership)

  const [messageContent, setMessageContent] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const messagesQuery = useGetIntroductionMessagesQuery(introduction)
  const messagesMutation = useCreateIntroductionMessageMutation(introduction)

  const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessageContent(event.target.value)
  }

  const handleSendMessage = (event: FormEvent) => {
    event.preventDefault()

    if (isSubmitting) return

    const message = messageContent.trim()
    setMessageContent('')

    if (message.length === 0) {
      setIsSubmitting(false)
      return
    }

    setIsSubmitting(true)

    messagesMutation.mutate(
      {
        userId: user.id,
        body: message
      },
      {
        onSuccess: async () => {
          await messagesQuery.refetch()
        },
        onSettled: () => {
          setIsSubmitting(false)
        }
      }
    )
  }

  if (!introduction?.requester || !introduction?.connector) {
    return <Box>Loading...</Box>
  }
  const placeholder = generatePlaceholder(introduction, roles.isRequester, anonymous)

  return (
    <Box>
      <Box p={4}>
        <Text fontWeight="bold" fontSize="sm" mb={8}>
          Messages {messagesQuery.isSuccess && <>({messagesQuery.data.length})</>}
        </Text>
        {messagesQuery.data?.length === 0 && (
          <Box
            borderColor="gray.200"
            borderStyle="dashed"
            borderWidth={1}
            textAlign="center"
            py={'4rem'}
            fontWeight="normal"
            fontSize="md"
            fontStyle="italic"
            color="gray.400"
          >
            There are no messages.
          </Box>
        )}
        {messagesQuery.isSuccess && messagesQuery.data.length > 0 && (
          <BackAndForth
            introduction={introduction}
            messages={messagesQuery.data}
            userId={user.id}
            personId={user.personId}
            anonymous={anonymous}
          />
        )}
      </Box>
      <Divider color="gray.200" />
      <form>
        <Flex px={4} pt={4}>
          <Box flex="1" pr={4}>
            <Textarea
              variant="outline"
              placeholder={placeholder}
              fontSize="sm"
              borderRadius={5}
              onChange={handleMessageChange}
              value={messageContent}
              size="sm"
              data-hj-allow
            />
          </Box>
          <Box flex="0 0 auto">
            <IconButton
              background="blue.400"
              color="white"
              icon={<ChatIcon />}
              aria-label="button"
              isRound={true}
              isLoading={isSubmitting}
              onClick={handleSendMessage}
              type="submit"
            />
          </Box>
        </Flex>
      </form>
      <Box
        pt={3}
        px={5}
        pb={4}
        mr={16}
        fontWeight="normal"
        fontSize="xs"
        fontStyle="italic"
        lineHeight={1.3}
        color="gray.500"
      >
        {roles.isConnector && !introduction.isWilling && (
          <>You remain anonymous until you click &quot;Yes&quot; to this Introduction Request. </>
        )}
        {roles.isConnector && introduction.isWilling && (
          <>Your identity was revealed when you accepted this introduction.</>
        )}
      </Box>
    </Box>
  )
}
