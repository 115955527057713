export const hasSuperConnectorRole = (roles: Role[]): Role | undefined => {
  return roles.find((role: Record<string, string>): boolean => role.name === 'super_connector')
}

export const hasSuperConnectorMembership = (memberships: Membership[]): Membership | undefined => {
  return memberships.find((membership) => hasSuperConnectorRole(membership.roles))
}

export const mapSetting = (setting: Setting): boolean | string | number => {
  const mapping = {
    boolean: (value: string) => {
      const lcv = value.toLowerCase()
      return lcv === 't' || lcv === 'true'
    },
    number: (value: string) => parseInt(value, 10),
    integer: (value: string) => parseInt(value, 10),
    string: (value: string) => value
  }

  return mapping[setting.stype](setting.value)
}

export const deMapSetting = (setting: Setting) => {
  let str = String(setting.value)
  if (setting.stype === 'boolean') str = str.toLowerCase()[0]
  return { ...setting, value: str }
}

export const isEnterpriseUser = (membership: Membership) => {
  if (membership == null) return true
  return membership?.organization?.organizationType === 'enterprise'
}

export const isIndividualUser = (membership: Membership) => {
  return membership?.organization?.organizationType === 'individual'
}

export const isSuperConnectorUser = (membership: Membership) => {
  if (membership == null) return false

  const setting = membership.organization.settings.find(({ name, value }) => {
    return name === 'super_connectors_enabled' && value === 't'
  })

  return setting != undefined
}

export const isClassicOnlyUser = ({ accountId, userId }: Partial<{ accountId: number; userId: number }>) => {
  return accountId && userId == null
}
