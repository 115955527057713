import { Box } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import MultiSelectMenuChakra from '~/components/MultiSelectMenuChakra'
import useDiscoverStore from '~/stores/discover'
import useExpressStore from '~/stores/express'
import { RequesterDashboardApi } from '~/api'
import { useShallow } from 'zustand/react/shallow'

// Define the component
export const LevelsFilter = () => {
  const location = useLocation()

  // Component (True) Constant Variables
  const REQ_DASH_PREFIX = 'req_dash_'
  const REQ_DASH_LEVELS_NAME = `${REQ_DASH_PREFIX}levels`

  //region Store Values
  const membership = useExpressStore((state) => state.membership)
  const { levelsFilterString, setLevels } = useDiscoverStore(
    useShallow((state) => ({
      levelsFilterString: state.levels,
      setLevels: state.setLevels
    }))
  )

  //region Query and Mutation Functions
  const saveMemberSettingsRequest = RequesterDashboardApi.useSaveMemberSettingsRequest()
  const getMemberSettingLevelsRequest = RequesterDashboardApi.useGetMemberSettingRequest(
    !levelsFilterString,
    membership.id,
    REQ_DASH_LEVELS_NAME
  )

  //region Component State
  const [levelsParamProcessed, setLevelsParamProcessed] = useState(false)
  const [levelMenuOptions, setLevelMenuOptions] = useState<MenuOption[]>([
    { label: 'Executive/C-Suite', value: 'executive', isSelected: false },
    { label: 'Senior-Level', value: 'senior', isSelected: false },
    { label: 'Mid-Level', value: 'mid', isSelected: false },
    { label: 'Junior-Level', value: 'junior', isSelected: false },
    { label: 'Individual Contributor', value: 'contributor', isSelected: false }
  ])
  const [levelSelectedCount, setLevelSelectedCount] = useState(0)

  function countSelectedOptions(str: string): number {
    if (!str || typeof str !== 'string') {
      return 0
    }

    // Count non-empty, trimmed words
    return str.split(',').filter((word) => word.trim() !== '').length
  }
  //region Use Effects
  // Get & Set Users Levels Settings
  useEffect(() => {
    // Read Buying Center Params if present
    const searchParams = new URLSearchParams(location.search)
    const levelsParam = searchParams.get('levels')
    if (!levelsParamProcessed && levelsParam) {
      setLevels(levelsParam)
      setLevelsParamProcessed(true)
    } else if (!getMemberSettingLevelsRequest.isFetching && getMemberSettingLevelsRequest.data && !levelsFilterString) {
      const filtStringToSet = getMemberSettingLevelsRequest.data.value
      setLevels(filtStringToSet)
    }
  }, [
    getMemberSettingLevelsRequest.isFetching,
    getMemberSettingLevelsRequest.data,
    levelsParamProcessed,
    levelsFilterString,
    membership,
    setLevels,
    location
  ])

  // Levels filter string use effect
  useEffect(() => {
    if (levelsFilterString?.length > 0) {
      let levelsStringArr: string[] = levelsFilterString.split(',')
      const updatedOptions = levelMenuOptions.map((option) => ({
        ...option,
        isSelected: levelsStringArr.includes(option.value)
      }))
      setLevelMenuOptions(updatedOptions)
    }

    // Count selected filters for the pill
    setLevelSelectedCount(countSelectedOptions(levelsFilterString))
  }, [levelsFilterString, setLevelMenuOptions, membership])

  // region Btn Handlers
  const handleOnCloseLevels = (menuOptionsParam: MenuOption[]) => {
    const selectedMenuOptions: string[] = menuOptionsParam.filter((a) => a.isSelected).map((a) => a.value)

    const selectedMenuOptionsString: string = selectedMenuOptions.join(',')
    setLevels(selectedMenuOptionsString)

    const memberSettingChange: MemberSetting = {
      organizationMemberId: membership.id,
      value: selectedMenuOptionsString,
      name: REQ_DASH_LEVELS_NAME
    }

    saveMemberSettingsRequest.mutate(memberSettingChange, {
      onSuccess: () => {},
      onError: (e) => {
        console.error(e)
      }
    })
  }

  return (
    <Box mr={2}>
      <MultiSelectMenuChakra
        menuOptions={levelMenuOptions}
        menuTitle="Levels"
        menuSelectedCount={levelSelectedCount}
        onClose={handleOnCloseLevels}
      />
    </Box>
  )
}
