import { useQuery, useQueries, UseQueryOptions } from '@tanstack/react-query'
import { API_PATH_SWAG, SWAG_URL } from '~/settings'
import { request } from './utils'

const NAMESPACE_SEARCH = 'search'

const BASE_URL_SWAG = `${SWAG_URL}${API_PATH_SWAG}`

const SWAG_URL_SEARCH = `${BASE_URL_SWAG}/${NAMESPACE_SEARCH}`

export const getSearchProspectsRequest = async (
  nameSearch: string,
  companyId: string,
  requesterPersonId: number,
  organizationId: number,
  organizationMemberId: number
): Promise<Prospects> => {
  const url = new URL(
    `${SWAG_URL_SEARCH}?nameSearch=${nameSearch}&companyId=${companyId}&requesterPersonId=${requesterPersonId}&organizationId=${organizationId}&organizationMemberId=${organizationMemberId}`
  )

  const response = await request<null, Prospects>(url, 'GET')

  return response
}

export const useGetSearchProspectsRequest = (
  enabled: boolean,
  searchName: string,
  companyId: string,
  requesterPersonId: number,
  organizationId: number,
  organizationMemberId: number,
  options?: UseQueryOptions<Prospects, Error>
) => {
  return useQuery<Prospects, Error>(
    ['useGetSearchProspectsRequest', searchName, companyId],
    () => getSearchProspectsRequest(searchName, companyId, requesterPersonId, organizationId, organizationMemberId),
    {
      enabled: enabled,
      ...options
    }
  )
}
