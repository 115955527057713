import { useQuery } from '@tanstack/react-query'
import { BASE_URL } from '~/settings'
import { request } from './utils'

const fetchOrgOwnedByPerson = (id: number) => {
  const url = new URL(`${BASE_URL}/iam/organizations/owned_by_person/${id}`)
  return request<null, Organization>(url)
}

export const useOrganizationOwnedByPersonQuery = (personId: number) => {
  return useQuery({
    queryKey: ['organization', 'owned-by', personId],
    queryFn: () => fetchOrgOwnedByPerson(personId),
    enabled: !!personId
  })
}

const fetchOrganization = (id: number) => {
  const url = new URL(`${BASE_URL}/iam/organizations/${id}`)
  return request<null, Organization>(url)
}

export const useOrganizationQuery = (id: number) => {
  return useQuery({
    queryKey: ['organization', id],
    queryFn: () => fetchOrganization(id),
    enabled: !!id
  })
}
