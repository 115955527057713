import { Box, Divider, Text } from '@chakra-ui/react'
import { BaseCard } from './BaseCard'
import { Messaging } from './Messaging'
import { Participants } from './Participants'
import { statusColors } from './lib'
import { useUserRoleIn } from './hooks'
import { Fragment } from 'react'

const StatusLine = ({ introduction }: { introduction: Introduction }) => {
  const { status } = introduction
  const { bg, color } = statusColors(status)

  return (
    <Box
      color={color}
      background={bg}
      border="none"
      borderTopRadius="0.5rem"
      borderBottomColor="gray.200"
      borderBottomWidth={1}
      borderBottomStyle="solid"
      paddingY={2}
      textAlign="center"
      fontWeight="bold"
      fontSize="sm"
      textTransform="uppercase"
    >
      Introduction {status}
    </Box>
  )
}

const firstName = (introduction: Introduction, isRequester: boolean) => {
  if (isRequester) return 'You'
  return introduction.requester?.firstName
}

const Content = ({ introduction, isRequester }: { introduction: Introduction; isRequester: boolean }) => {
  const { details } = introduction
  const lines = details.split('\n')

  return (
    <Box p={8}>
      <Text fontWeight="bold" fontSize="xs" lineHeight="shorter" mb={6}>
        {firstName(introduction, isRequester)} included the following details:
      </Text>
      <Text fontWeight="regular" fontSize="sm" lineHeight="1.4rem">
        {lines.map((line, index) => (
          <Fragment key={`line-${index}`}>
            {line}
            <br />
          </Fragment>
        ))}
      </Text>
      <Text fontSize="xs" color="blue.400">
        {/*Read More (todo)*/}
      </Text>
    </Box>
  )
}

export const Details = ({
  introduction,
  anonymous,
  connectors,
  user,
  currentMembership
}: {
  introduction: Introduction
  anonymous: boolean
  connectors: { [key: string]: any }
  user: User
  currentMembership: Membership
}) => {
  const { isRequester } = useUserRoleIn(introduction, user, currentMembership)

  // still getting some weird timing errors due to null introduction or parts
  if (!introduction) {
    return <Box />
  }

  return (
    <BaseCard mb={8}>
      <StatusLine introduction={introduction} />
      <Participants
        introduction={introduction}
        user={user}
        currentMembership={currentMembership}
        anonymous={anonymous}
        connectors={connectors}
      />
      <Divider color="gray.300" />
      <Content introduction={introduction} isRequester={isRequester} />
      <Divider color="gray.300" />
      <Messaging
        introduction={introduction}
        user={user}
        currentMembership={currentMembership}
        anonymous={anonymous}
      />
    </BaseCard>
  )
}
