//
//  __   __     ______     ______      __  __     ______     ______     _____
// /\ "-.\ \   /\  __ \   /\__  _\    /\ \/\ \   /\  ___\   /\  ___\   /\  __-.
// \ \ \-.  \  \ \ \/\ \  \/_/\ \/    \ \ \_\ \  \ \___  \  \ \  __\   \ \ \/\ \
//  \ \_\\"\_\  \ \_____\    \ \_\     \ \_____\  \/\_____\  \ \_____\  \ \____-
//   \/_/ \/_/   \/_____/     \/_/      \/_____/   \/_____/   \/_____/   \/____/
//
//
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Container, Flex, Text, useToast } from '@chakra-ui/react'
import { PAGE_WIDTH } from '~/settings'

import { LabeledInput } from '@smallworld-io/pangaea'

import { BASE_URL } from '~/settings'

type Form = {
  firstName: string
  lastName: string
  companyName: string
  jobTitle: string
}

//
// todo: this should stay in the signup for now to deal
// with the User +--+ Person problem
const getUserUrl = `${BASE_URL}/iam/users`

export const Confirmation = () => {
  const { userId } = useParams()

  const toast = useToast()
  const navigate = useNavigate()

  const {
    control,
    formState: { isSubmitting },
    handleSubmit
  } = useForm<Form>({
    defaultValues: async () => {
      const response = await fetch(`${getUserUrl}/${userId}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })

      const { first_name: firstName, last_name: lastName } = await response.json()
      return { firstName, lastName, companyName: '', jobTitle: '' }
    }
  })

  const submitHandler: SubmitHandler<Form> = async (data) => {
    const updateSignUpUser = `${BASE_URL}/sign_up/confirm_information`

    const body = {
      first_name: data.firstName,
      last_name: data.lastName,
      company_name: data.companyName,
      job_title: data.jobTitle
    }

    const response = await fetch(`${updateSignUpUser}/${userId}`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })

    // todo: toast response
  }

  return (
    <Flex maxW={PAGE_WIDTH} direction="column">
      <Container maxW={500} textAlign="center" p={8}>
        <Text as="h1" fontSize="5xl" pb={4}>
          Final Step
        </Text>
        <Text>Before continuing, please confirm your personal information and add your job title below.</Text>
      </Container>

      <form onSubmit={handleSubmit(submitHandler)}>
        <Flex direction={{ base: 'column', md: 'row' }}>
          <Box width={{ base: '100%', md: '50%' }} pr={{ md: 2 }} pb={4}>
            <Controller
              name="firstName"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => (
                <LabeledInput
                  label="First Name"
                  ref={ref}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  isInvalid={invalid}
                />
              )}
            />
          </Box>
          <Box width={{ base: '100%', md: '50%' }} pl={{ md: 2 }} pb={4}>
            <Controller
              name="lastName"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => (
                <LabeledInput
                  label="Last Name"
                  ref={ref}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  isInvalid={invalid}
                />
              )}
            />
          </Box>
        </Flex>

        <Box pb={4}>
          <Controller
            name="companyName"
            defaultValue=""
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => (
              <LabeledInput
                label="Company Name"
                ref={ref}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={invalid}
              />
            )}
          />
        </Box>
        <Box pb={4}>
          <Controller
            name="jobTitle"
            defaultValue=""
            control={control}
            rules={{
              required: true
            }}
            render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => (
              <LabeledInput
                label="Job Title"
                ref={ref}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                isInvalid={invalid}
              />
            )}
          />
        </Box>
        <Box pb={4}>
          <Button type="submit" isLoading={isSubmitting}>
            Save
          </Button>
        </Box>
      </form>
    </Flex>
  )
}
