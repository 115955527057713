import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { request, RequestConfig, isPositiveNumber } from './utils'
import { API_PATH_SWAG, BASE_URL } from '~/settings'

const SWAG_URL_BASE = `${import.meta.env.VITE_API_GW_URL}${API_PATH_SWAG}`

const NAMESPACE = 'hidden-prospects'

const SWAG_URL = `${SWAG_URL_BASE}/${NAMESPACE}`

//region save member settings
export const hideProspectRequest = async (hiddenProspect: HiddenProspect): Promise<void> => {
  const url = new URL(`${SWAG_URL}/`)

  const options: RequestConfig<HiddenProspect> = { body: hiddenProspect }

  const response = await request<HiddenProspect, void>(url, 'POST', options)

  return response
}

export const useHideProspectRequest = (options?: UseMutationOptions<void, Error, HiddenProspect>) =>
  useMutation<void, Error, HiddenProspect>(hideProspectRequest, options)
//endregion save member settings
