import { useCallback, useEffect, useState, useRef } from 'react'
import { Box, Container, Flex, Link, PinInput, PinInputField, Text, useToast } from '@chakra-ui/react'

import { useSignUpStore } from '~/stores/signUp'
import { BASE_URL, PAGE_WIDTH } from '~/settings'
import { useNavigate } from 'react-router-dom'

const errorMessages = {
  notfound: ['error', 'Not Found', "We couldn't find your phone number"],
  invalid: ['warning', 'Incorrect Code', "The code entered didn't match"],
  expired: ['info', 'Expired', 'The verification code has expired'],
  exception: ['error', 'Error', 'We were unable to verify your account']
}

const verifySmsUrl = `${BASE_URL}/sign_up/verify_sms`

export const VerifyPhoneNumber = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
  }

  // Usage:
  const phone = useSignUpStore((state) => state.phone)
  const formattedPhone = phone ? formatPhoneNumber(phone) : '(212) 555-2368'

  const [_isSubmitting, setIsSubmitting] = useState(false)

  const displayErrors = (errors: { [key: string]: string }) => {
    Object.keys(errors).forEach((key) => {
      const [status, title, description] = errorMessages[key]

      toast({
        position: 'top',
        title,
        description,
        status,
        duration: 10_000,
        isClosable: true
      })
    })
  }

  const handleTryAgainClick = () => navigate('..')

  const handleOnComplete = useCallback(async (code: string) => {
    setIsSubmitting(true)

    try {
      const response = await fetch(verifySmsUrl, {
        method: 'post',
        credentials: 'omit',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          phonenumber: phone,
          code
        })
      })

      const { ok, errors = {} } = await response.json()

      if (response.ok && ok) {
        navigate('../credentials')
      }

      if (!ok) {
        displayErrors(errors)
      }
    } catch (err) {
      toast({
        position: 'top',
        title: 'Error',
        description: 'There was an error submitting your request. Please try again.',
        status: 'warning',
        duration: 10_000,
        isClosable: true
      })
      console.log('🧨 '.repeat(20))
      console.log(err)
    } finally {
      setIsSubmitting(false)
    }
  }, [])

  const firstPinInputFieldRef = useRef(null)

  useEffect(() => {
    firstPinInputFieldRef.current?.focus()
  }, [])

  return (
    <Flex direction="column" align="center" maxW={PAGE_WIDTH} px={4}>
      <Container maxW={500} textAlign="center" py={8}>
        <Text as="h1" fontSize="4xl" lineHeight={1.2} pb={4}>
          Verify Mobile Number
        </Text>
        <Text>
          In order to verify your phone number, enter the code that was sent to <strong>{formattedPhone}</strong>.
        </Text>
      </Container>
      <Flex direction="row" mb={4} justify="center">
        <PinInput otp onComplete={handleOnComplete} size="lg">
          <PinInputField ref={firstPinInputFieldRef} m={1} p={3} bg="white" />
          <PinInputField m={1} p={3} bg="white" />
          <PinInputField m={1} p={3} bg="white" />
          <PinInputField m={1} p={3} bg="white" />
          <PinInputField m={1} p={3} bg="white" />
          <PinInputField m={1} p={3} bg="white" />
        </PinInput>
      </Flex>

      <Text fontSize="small" color="gray.600">
        Didn't receive a code? <Link onClick={handleTryAgainClick}>Try again</Link>
      </Text>
    </Flex>
  )
}
