export const findMembershipWithOwnerRole = (membership: Membership) => {
  return membership.roles.find((role) => role.name === 'owner')
}

export const findSettingWithCurrentMembership = (settings: Setting[]) => {
  return settings.find((setting) => setting.name === 'current_membership')
}

export const findMembershipBySetting = (setting: Setting, memberships: Membership[]) => {
  return memberships.find((membership) => membership.id === Number(setting.value))
}

export const findCurrentMembership = (settings: Setting[], memberships: Membership[]) => {
  const setting = findSettingWithCurrentMembership(settings)
  console.dir(settings)
  return findMembershipBySetting(setting, memberships)
}
