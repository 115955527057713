import { Tooltip, Box } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useLocation } from 'react-router-dom'
import MultiSelectMenuChakra from '~/components/MultiSelectMenuChakra'
import useDiscoverStore from '~/stores/discover'
import useExpressStore from '~/stores/express'
import { RequesterDashboardApi, TargetCompaniesApi } from '~/api'

// Define the component
export const TargetCompaniesFilter = () => {
  const location = useLocation()

  // Component (True) Constant Variables
  const REQ_DASH_PREFIX = 'req_dash_'
  const REQ_DASH_TARGET_COMP_IDS_NAME = `${REQ_DASH_PREFIX}target_company_ids`
  const REQ_DASH_TARGET_COMPS_INCLUDE_NAME = `${REQ_DASH_PREFIX}target_companies_include`

  // Read Store Values
  const membership = useExpressStore((state) => state.membership)

  const {
    isBeastMode,
    setHasTargetCompanies,
    targetCompanyIdsFilterString,
    setTargetCompanies,
    targetCompanyIdsInclude,
    setTargetCompaniesInclude
  } = useDiscoverStore(
    useShallow((state) => ({
      isBeastMode: state.isBeastMode,
      setHasTargetCompanies: state.setHasTargetCompanies,
      targetCompanyIdsFilterString: state.targetCompanies,
      setTargetCompanies: state.setTargetCompanies,
      targetCompanyIdsInclude: state.targetCompaniesInclude,
      setTargetCompaniesInclude: state.setTargetCompaniesInclude
    }))
  )

  // Query and Mutation Functions
  const targetCompaniesRequest = TargetCompaniesApi.useGetTargetCompaniesForMember(membership.id)
  const saveMemberSettingsRequest = RequesterDashboardApi.useSaveMemberSettingsRequest()
  const getMemberSettingTargetCompaniesIncludeRequest = RequesterDashboardApi.useGetMemberSettingRequest(
    !targetCompanyIdsInclude,
    membership.id,
    REQ_DASH_TARGET_COMPS_INCLUDE_NAME
  )
  const getMemberSettingTargetCompaniesRequest = RequesterDashboardApi.useGetMemberSettingRequest(
    !targetCompanyIdsFilterString,
    membership.id,
    REQ_DASH_TARGET_COMP_IDS_NAME
  )

  // Component State
  const [targetCompaniesIncludeParamProcessed, setTargetCompaniesIncludeParamProcessed] = useState(false)
  const [targetCompaniesParamProcessed, setTargetCompaniesParamProcessed] = useState(false)
  const [targetCompanySelectedCount, setTargetCompanySelectedCount] = useState(0)
  const [targetCompanyMenuOptions, setTargetCompanyMenuOptions] = useState<MenuOption[]>([])

  // Component Functions - Look to extract to Utils (Hint: navigateOnDeepLinkFilterChangeIfDeepLink)
  function countSelectedTargetCompanies(
    targCompCountInFilterString: number,
    include: boolean,
    totalTargCompsCnt: number
  ): number {
    return include ? targCompCountInFilterString : totalTargCompsCnt - targCompCountInFilterString
  }

  function countSelectedOptions(str: string): number {
    if (!str || typeof str !== 'string') {
      return 0
    }

    // Count non-empty, trimmed words
    return str.split(',').filter((word) => word.trim() !== '').length
  }

  // target companies list settings use effect
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const targetCompanyIdsParam = searchParams.get('targetCompanyIds')
    if (!targetCompaniesParamProcessed && targetCompanyIdsParam) {
      setTargetCompanies(targetCompanyIdsParam)
      setTargetCompaniesParamProcessed(true)
    } else if (
      !getMemberSettingTargetCompaniesRequest.isFetching &&
      getMemberSettingTargetCompaniesRequest.data &&
      !targetCompanyIdsFilterString
    ) {
      const filtStringToSet = getMemberSettingTargetCompaniesRequest.data.value
      setTargetCompanies(filtStringToSet)
    }
  }, [
    getMemberSettingTargetCompaniesRequest.isFetching,
    getMemberSettingTargetCompaniesRequest.data,
    setTargetCompanies,
    targetCompanyIdsFilterString,
    targetCompaniesParamProcessed,
    membership,
    location
  ])

  // target companies include settings use effect
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const targetCompanyIdsIncludeParam = searchParams.get('targetCompanyIdsInclude')
    if (!targetCompaniesIncludeParamProcessed && targetCompanyIdsIncludeParam) {
      setTargetCompaniesInclude(targetCompanyIdsIncludeParam === 'true')
      setTargetCompaniesIncludeParamProcessed(true)
    } else if (
      !getMemberSettingTargetCompaniesIncludeRequest.isFetching &&
      getMemberSettingTargetCompaniesIncludeRequest.data &&
      targetCompanyIdsInclude == null
    ) {
      const filtStringToSet = getMemberSettingTargetCompaniesIncludeRequest.data.value
      setTargetCompaniesInclude(filtStringToSet === 'true')
    }
  }, [
    getMemberSettingTargetCompaniesIncludeRequest.isFetching,
    getMemberSettingTargetCompaniesIncludeRequest.data,
    setTargetCompaniesInclude,
    targetCompaniesIncludeParamProcessed,
    targetCompanyIdsInclude,
    membership,
    location
  ])

  // target company settings and list of actual target companies from db
  useEffect(() => {
    if (
      isBeastMode != null &&
      targetCompanyIdsInclude != null &&
      targetCompanyIdsFilterString != null &&
      !targetCompaniesRequest.isFetching &&
      targetCompaniesRequest.data
    ) {
      // console.log(`all target company settings ready`)
      const targetCompaniesRequestData = targetCompaniesRequest.data
      if (targetCompaniesRequestData?.length > 0) {
        //it's false by default
        setHasTargetCompanies(true)
      }

      let menuOpts: MenuOption[] = targetCompaniesRequestData
        ?.sort((a, b) => a.companyName.localeCompare(b.companyName))
        .reduce((options, targetCompany) => {
          const menuOption: MenuOption = {
            label: targetCompany.companyName,
            value: targetCompany.companyId.toString(),
            isSelected: false
          }
          options.push(menuOption)
          return options
        }, [])

      let targetCompaniesStringArr: string[] = []

      if (targetCompanyIdsFilterString?.length > 0) {
        targetCompaniesStringArr = targetCompanyIdsFilterString.split(',')
      }

      const cntSelected = countSelectedTargetCompanies(
        countSelectedOptions(targetCompanyIdsFilterString),
        targetCompanyIdsInclude,
        targetCompaniesRequestData?.length
      )

      setTargetCompanySelectedCount(cntSelected)

      // for each menu option, if in the list, selected is set to whether list is inclusive or not
      // if not in the list, set to opposite of isListInclusive
      menuOpts.map((a) => {
        if (targetCompaniesStringArr.includes(a.value)) {
          a.isSelected = targetCompanyIdsInclude
        } else {
          a.isSelected = !targetCompanyIdsInclude
        }
      })

      setTargetCompanyMenuOptions(menuOpts)
    } else {
      // console.log(`not all targ comp details set yet`)
    }
  }, [
    isBeastMode,
    // These should be used.  Integrate them!
    getMemberSettingTargetCompaniesIncludeRequest.isFetching,
    getMemberSettingTargetCompaniesIncludeRequest.data,
    getMemberSettingTargetCompaniesRequest.isFetching,
    getMemberSettingTargetCompaniesRequest.data,
    targetCompaniesRequest.isFetching,
    targetCompaniesRequest.data,
    targetCompanyIdsFilterString,
    setHasTargetCompanies,
    targetCompanyIdsInclude,
    membership
  ])
  //

  const handleOnCloseTargetCompanies = (menuOptionsParam: MenuOption[]) => {
    const selectedMenuOptions: number[] = menuOptionsParam.filter((a) => a.isSelected).map((a) => parseInt(a.value))

    const unSelectedMenuOptions: number[] = menuOptionsParam.filter((a) => !a.isSelected).map((a) => parseInt(a.value))

    const selectedMenuOptionsString: string = selectedMenuOptions.join(',')

    const unSelectedMenuOptionsString: string = unSelectedMenuOptions.join(',')

    // include is true if selected count is less than half of total
    const newIncludeSettingIfDifferent: boolean = selectedMenuOptions?.length < menuOptionsParam?.length / 2

    const companyIdsListInclusiveOrExclusive = newIncludeSettingIfDifferent
      ? selectedMenuOptionsString
      : unSelectedMenuOptionsString
    // if deep link, won't get here - see function above for details
    const includeSettingChanged = newIncludeSettingIfDifferent !== targetCompanyIdsInclude

    setTargetCompaniesInclude(newIncludeSettingIfDifferent)

    setTargetCompanies(companyIdsListInclusiveOrExclusive)

    const idsMemberSettingChange: MemberSetting = {
      organizationMemberId: membership.id,
      value: companyIdsListInclusiveOrExclusive,
      name: REQ_DASH_TARGET_COMP_IDS_NAME
    }

    saveMemberSettingsRequest.mutate(idsMemberSettingChange, {
      onSuccess: () => {},
      onError: (e) => {
        console.error(e)
      }
    })

    if (includeSettingChanged) {
      const includeMemberSettingChange: MemberSetting = {
        organizationMemberId: membership.id,
        value: newIncludeSettingIfDifferent.toString(),
        name: REQ_DASH_TARGET_COMPS_INCLUDE_NAME
      }

      saveMemberSettingsRequest.mutate(includeMemberSettingChange, {
        onSuccess: () => {},
        onError: (e) => {
          console.error(e)
        }
      })
    }
  }

  return (
    <Tooltip
      hasArrow={true}
      fontSize="sm"
      label="When displaying non-target companies, selecting target companies is disabled"
      isDisabled={!isBeastMode}
    >
      <Box mr={2}>
        <MultiSelectMenuChakra
          menuOptions={targetCompanyMenuOptions}
          menuTitle="Target Companies"
          menuSelectedCount={targetCompanySelectedCount}
          isDisabled={isBeastMode}
          onClose={handleOnCloseTargetCompanies}
        />
      </Box>
    </Tooltip>
  )
}
