import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'

export const DeleteModal = ({ isOpen, onClose, companyId, companyName, onDelete }) => {
  const handleDeleteClick = () => {
    onDelete(companyId)
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Target Company</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to delete the target company <b>{companyName}</b>?
        </ModalBody>
        <ModalFooter>
          <HStack gap={2}>
            <Button
              size="sm"
              borderRadius="md"
              fontWeight="semibold"
              bg="red.500"
              _hover={{ bg: 'red.600' }}
              onClick={handleDeleteClick}
            >
              Delete
            </Button>
            <Button size="sm" borderRadius="md" fontWeight="semibold" variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
