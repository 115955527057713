import { useEffect, useRef, useState } from 'react'
import { Center, Container, Flex, useDisclosure } from '@chakra-ui/react'
import { useLeadsRequest, useLoggedInUserQuery, useUsersByIdsQuery } from '~/api'
import { Header, QueueCompleteModal, SmallWorldIcon } from '~/components'
import useDocumentTitle from '~/hooks/useDocumentTitle'
import { RelationshipLeadStore as RLS } from '~/stores'

import { useOrganizationQuery } from '~/api/organizations'
import { isEnterpriseUser, isSuperConnectorUser } from '~/lib/userHelpers'
import RelationshipLeadsDevDebug from './RelationshipLeadsDevDebug'
import RelationshipLeadsFooter from './RelationshipLeadsFooter'
import RelationshipLeadsHeader from './RelationshipLeadsHeader'
import RelationshipLeadsCardGrid from './RelationshipLeadsCardGrid'
import { RelationshipLeadsTabs } from './RelationshipLeadsTabs'
import { useRequesterStubRequest } from '~/api/requesters'

export const RelationshipLeads = () => {
  useDocumentTitle('New Relationship Leads – SmallWorld')

  const leadsStore = RLS.useStore((state) => state)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data } = useLoggedInUserQuery()
  const { accountId, currentMembership, currentOrganization, memberships, personId, user } = data

  const orgQuery = useOrganizationQuery(currentOrganization?.id)
  const connectorUsersWithPersonIdsQuery = useUsersByIdsQuery(orgQuery.data?.memberships?.map((m) => m.userId))

  const enterpriseOrSuperConnectorAccountId =
    leadsStore.showingEnterpriseLeads !== undefined && leadsStore.showingEnterpriseLeads ? accountId : null
  const leadsRequest = useLeadsRequest(personId, {
    accountId: enterpriseOrSuperConnectorAccountId,
    membership:
      leadsStore.showingEnterpriseLeads !== undefined && leadsStore.showingEnterpriseLeads ? null : currentMembership
  })

  const requesterStubRequest = useRequesterStubRequest(enterpriseOrSuperConnectorAccountId)

  const [isSticky, setIsSticky] = useState(false)

  const numQueuedLeads = leadsStore.getNumQueuedLeads()

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100 && !isSticky) {
        // Only set if not already sticky
        setTimeout(() => {
          setIsSticky(true)
        }, 400)
      } else if (window.scrollY <= 100 && isSticky) {
        // Only set if currently sticky
        setIsSticky(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isSticky])

  useEffect(() => {
    leadsStore.setShowingEnterpriseLeads(isEnterpriseUser(currentMembership))
  }, [currentMembership, leadsStore.setShowingEnterpriseLeads])

  useEffect(() => {
    if (!leadsRequest.isLoading && leadsRequest.data?.relationshipLeads)
      leadsStore.initialize(leadsRequest.data.relationshipLeads, leadsRequest.data.unassignedLeads)
  }, [
    leadsRequest.isLoading,
    leadsRequest.data?.relationshipLeads,
    leadsRequest.data?.unassignedLeads,
    leadsStore.initialize
  ])

  useEffect(() => {
    if (!requesterStubRequest.isLoading && requesterStubRequest.data) {
      leadsStore.setAccountRequesters(requesterStubRequest.data)
    }
  }, [requesterStubRequest.isLoading, requesterStubRequest.data, leadsStore.setAccountRequesters])

  useEffect(() => {
    if (
      leadsStore.isInitialized &&
      numQueuedLeads.my === 0 &&
      numQueuedLeads.unassigned === 0 &&
      leadsStore.showingEnterpriseLeads === true
    ) {
      onOpen()
    }
  }, [
    leadsStore.relationshipLeads,
    leadsStore.isInitialized,
    onOpen,
    leadsStore.showingEnterpriseLeads,
    numQueuedLeads.my,
    numQueuedLeads.unassigned
  ])

  const [overlayVisible, setOverlayVisible] = useState(false)

  const windowCanSupportOverlay = window.innerWidth > 943
  const toggleOverlay = () => {
    // Unless browser window is
    // larger than the image shown
    if (windowCanSupportOverlay) {
      setTimeout(positionAndShowEducationalOverlay, 200)
    }
  }

  const [topPosition, setTopPosition] = useState(0)
  const [leftPosition, setLeftPosition] = useState(0)
  const firstCardRef = useRef(null)

  // const displayEducationalOverlay = !Cookies.get('ShownRLOverlayV1');

  const positionAndShowEducationalOverlay = () => {
    if (firstCardRef.current) {
      const rect = firstCardRef.current.getBoundingClientRect()
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop

      // Offset the image to
      // align with the window
      const topOffset = 25
      const leftOffset = 222

      setTopPosition(rect.top + scrollTop - topOffset)
      setLeftPosition(rect.left + scrollLeft - leftOffset)

      window.scroll({
        top: 0,
        behavior: 'smooth'
      })

      setOverlayVisible((prevState) => !prevState)
    }
  }

  if (
    // omg 🤮🤮🤮
    ((memberships.length > 0 && connectorUsersWithPersonIdsQuery.isSuccess) || memberships.length === 0) &&
    leadsRequest.isSuccess &&
    leadsStore.isInitialized
  ) {
    return (
      <>
        {isSuperConnectorUser(currentMembership) && <Header />}

        <Container maxWidth="800px">
          <Flex direction="column" gap={6}>
            <RelationshipLeadsHeader
              {...{ overlayVisible, windowCanSupportOverlay, toggleOverlay, topPosition, leftPosition }}
            />

            <RelationshipLeadsTabs />

            <RelationshipLeadsCardGrid {...{ firstCardRef }} />

            <RelationshipLeadsFooter {...{ accountId, windowCanSupportOverlay, toggleOverlay }} />

            <QueueCompleteModal isOpen={isOpen} onClose={onClose} isEnterprise={isEnterpriseUser(currentMembership)} />
            <RelationshipLeadsDevDebug {...{ accountId, currentOrganization, personId, user }} />
          </Flex>
        </Container>
      </>
    )
  }

  return (
    <Center minH="100vh">
      <SmallWorldIcon size={20} isDisabled />
    </Center>
  )
}
