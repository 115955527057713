import { create } from 'zustand'
import { combine } from 'zustand/middleware'

export const useCardStore = create(
  combine(
    {
      selectedId: null
    },
    (set) => {
      return {
        select: (relationship: Relationship) =>
          set(() => {
            return {
              selectedId: relationship.id
            }
          }),
        deselect: () => set(() => ({ selectedId: null }))
      }
    }
  )
)
