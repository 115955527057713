import { Box } from '@chakra-ui/react'
import { useEffect, useState, useMemo } from 'react'
import MultiSelectMenuChakra from '~/components/MultiSelectMenuChakra'
import useDiscoverStore from '~/stores/discover'
import useExpressStore from '~/stores/express'
import { RequesterDashboardApi } from '~/api'
import { useShallow } from 'zustand/react/shallow'

export const BuyingCentersFilter = () => {
  //region Component (True) Constant Variables
  const REQ_DASH_PREFIX = 'req_dash_'
  const REQ_DASH_BUYING_CENTER_IDS_NAME = `${REQ_DASH_PREFIX}buying_center_ids`

  //region Store Values
  const membership = useExpressStore((state) => state.membership)
  const { buyingCenterIdsFilterString, setBuyingCenters } = useDiscoverStore(
    useShallow((state) => ({
      buyingCenterIdsFilterString: state.buyingCenters,
      setBuyingCenters: state.setBuyingCenters
    }))
  )

  //region Query and Mutation Functions
  const getBuyingCentersRequest = RequesterDashboardApi.useGetBuyingCentersRequest()
  const saveMemberSettingsRequest = RequesterDashboardApi.useSaveMemberSettingsRequest()
  const getMemberSettingBuyingCentersRequest = RequesterDashboardApi.useGetMemberSettingRequest(
    !buyingCenterIdsFilterString,
    membership.id,
    REQ_DASH_BUYING_CENTER_IDS_NAME
  )

  //region Component State
  const [buyingCenterSelectedCount, setBuyingCenterSelectedCount] = useState(0)
  const [buyingCenterMenuOptions, setBuyingCenterMenuOptions] = useState<MenuOption[]>([])
  const [buyingCenterParamProcessed, setBuyingCenterParamProcessed] = useState(false)

  //region Component Functions
  function countSelectedOptions(str: string): number {
    if (!str || typeof str !== 'string') {
      return 0
    }

    // Count non-empty, trimmed words
    return str.split(',').filter((word) => word.trim() !== '').length
  }

  //region Use Effects
  //Get the User's Buying Centers and assign them to the buying center filter string
  useEffect(() => {
    // Read Buying Center Params if present
    const searchParams = new URLSearchParams(location.search)
    const buyingCenterIdsParam = searchParams.get('buyingCenterIds')
    if (!buyingCenterParamProcessed && buyingCenterIdsParam) {
      setBuyingCenters(buyingCenterIdsParam)
      setBuyingCenterParamProcessed(true)
    } else if (
      !getMemberSettingBuyingCentersRequest.isFetching &&
      getMemberSettingBuyingCentersRequest.data &&
      !buyingCenterIdsFilterString
    ) {
      const filtStringToSet = getMemberSettingBuyingCentersRequest.data.value
      setBuyingCenters(filtStringToSet)
    }
  }, [
    getMemberSettingBuyingCentersRequest.isFetching,
    getMemberSettingBuyingCentersRequest.data,
    buyingCenterParamProcessed,
    buyingCenterIdsFilterString,
    membership,
    setBuyingCenters
  ])

  // //region buying_center list and filter string useEffect
  useEffect(() => {
    if (
      // not super sure how it will work when all are de-selected
      buyingCenterIdsFilterString?.length > 0 &&
      !getBuyingCentersRequest.isFetching &&
      getBuyingCentersRequest.data
    ) {
      let menuOpts: MenuOption[] = getBuyingCentersRequest.data?.buyingCenters
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .reduce((options, buyingCenter) => {
          const menuOption: MenuOption = {
            label: buyingCenter.name,
            value: buyingCenter.id.toString(),
            isSelected: false
          }
          //
          options.push(menuOption)

          return options
        }, [])

      let buyingCentersStringArr: string[] = []

      if (buyingCenterIdsFilterString?.length > 0) {
        buyingCentersStringArr = buyingCenterIdsFilterString.split(',')
      }

      menuOpts.map((a) => {
        if (buyingCentersStringArr.includes(a.value)) {
          a.isSelected = true
        }
      })

      setBuyingCenterMenuOptions(menuOpts)

      // Count selected filters for the pill
      setBuyingCenterSelectedCount(countSelectedOptions(buyingCenterIdsFilterString))
    }
  }, [
    buyingCenterIdsFilterString,
    getBuyingCentersRequest.isFetching,
    getBuyingCentersRequest.data,
    setBuyingCenterMenuOptions,
    membership
  ])

  // const buyingCentersData = useMemo(() => {
  //   if (getBuyingCentersRequest.isSuccess && getBuyingCentersRequest.data) {
  //     return getBuyingCentersRequest.data.buyingCenters
  //   }
  //   return null
  // }, [getBuyingCentersRequest.isSuccess, getBuyingCentersRequest.data])

  // const buyingCenterMenuOptions = useMemo(() => {
  //   if (buyingCentersData && buyingCenterIdsFilterString) {
  //     console.log("Setting Buying Centers Menu")
  //     const menuOptions = buyingCentersData.map((buyingCenter) => ({
  //       label: buyingCenter.name,
  //       value: buyingCenter.id.toString(),
  //       isSelected: buyingCenterIdsFilterString.includes(buyingCenter.id.toString())
  //     }))
  //     return menuOptions
  //   }
  //   return []
  // }, [buyingCentersData, buyingCenterIdsFilterString])

  // Use Memo to Create Menu Options
  // const buyingCenterMenuOptions = useMemo(() => {
  //   if (!getBuyingCentersRequest.isSuccess || !getBuyingCentersRequest.data || !buyingCenterIdsFilterString) {
  //     return []
  //   }
  //   const buyingCenters = getBuyingCentersRequest.data.buyingCenters.sort((a, b) => a.name.localeCompare(b.name))
  //   const menuOptions = buyingCenters.map((buyingCenter) => ({
  //     label: buyingCenter.name,
  //     value: buyingCenter.id.toString(),
  //     isSelected: buyingCenterIdsFilterString.includes(buyingCenter.id.toString())
  //   }))
  //   console.log("Setting Buying Centers Menu")
  //   return menuOptions
  // }, [buyingCenterIdsFilterString, getBuyingCentersRequest.isSuccess, getBuyingCentersRequest.data])

  // Set Selected Count
  useEffect(() => {
    setBuyingCenterSelectedCount(countSelectedOptions(buyingCenterIdsFilterString))
  }, [buyingCenterIdsFilterString])

  //region btn handlers
  const handleOnCloseBuyingCenters = (menuOptionsParam: MenuOption[]) => {
    const selectedMenuOptions: number[] = menuOptionsParam.filter((a) => a.isSelected).map((a) => parseInt(a.value))

    const selectedMenuOptionsString: string = selectedMenuOptions.join(',')

    if (selectedMenuOptionsString !== buyingCenterIdsFilterString) {
      // navigateOnDeepLinkFilterChangeIfDeepLink(new Map([['buyingCenterIds', selectedMenuOptionsString]]))

      // if deep link, below will not be reached - see function above for details
      setBuyingCenters(selectedMenuOptionsString)

      const memberSettingChange: MemberSetting = {
        organizationMemberId: membership.id,
        value: selectedMenuOptionsString,
        name: REQ_DASH_BUYING_CENTER_IDS_NAME
      }

      saveMemberSettingsRequest.mutate(memberSettingChange, {
        // onSuccess: () => {
        // },
        onError: (e) => {
          console.error(e)
        }
      })
    }
  }

  return (
    <Box mr={2}>
      <MultiSelectMenuChakra
        menuOptions={buyingCenterMenuOptions}
        menuTitle="Buying Centers"
        menuSelectedCount={buyingCenterSelectedCount}
        onClose={handleOnCloseBuyingCenters}
      />
    </Box>
  )
}
