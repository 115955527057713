import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Container, Text, SimpleGrid } from '@chakra-ui/react'
import { BackButton } from '@smallworld-io/pangaea'
import { useLeadsRequest, useLoggedInUserQuery } from '~/api'
import { RelationshipLeadStore as RLS } from '~/stores'
import { RelationshipCard } from '~/components'
import useDocumentTitle from '~/hooks/useDocumentTitle'

export const CompanySavedRelationshipLeads = () => {
  const navigate = useNavigate()
  const { slug } = useParams()

  const { data } = useLoggedInUserQuery()
  const { accountId, currentMembership, personId } = data
  // Keep in mind the toggle!
  const leadsRequest = useLeadsRequest(personId, { accountId, membership: currentMembership })

  const leadsStore = RLS.useStore((state) => state)
  const companyName = (slug: string) =>
    RLS.findByCompanySlug(leadsStore.relationshipLeads, slug)[0]?.prospect?.company?.name

  useDocumentTitle(companyName(slug) + ' Saved Relationship Leads – SmallWorld')

  useEffect(() => {
    if (!leadsRequest.isLoading && leadsRequest.data?.relationshipLeads)
      leadsStore.initialize(leadsRequest.data.relationshipLeads)
  }, [leadsRequest.isLoading, leadsRequest.data?.relationshipLeads, leadsStore.initialize])

  return (
    <Container maxW="800px" pt={4}>
      <BackButton onClick={() => navigate('/saved-leads')}>All Saved Leads</BackButton>
      <Text as="h1" fontSize="4xl" textAlign="center">
        {companyName(slug)}
      </Text>

      <Text as="p" fontSize="m" textAlign="center" mb={8}>
        Saved Relationship Leads
      </Text>

      <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} spacing={4}>
        {RLS.findByState(RLS.findByCompanySlug(leadsStore.relationshipLeads, slug), 'saved', true).map(
          (relationshipLead: RelationshipLead) => {
            return (
              <Box key={relationshipLead.relationship.id}>
                <RelationshipCard relationshipLead={relationshipLead} disableDisplaySave={true} />
              </Box>
            )
          }
        )}
      </SimpleGrid>
    </Container>
  )
}
