import { Flex, useToast } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { CollectResetCredentials } from './CollectResetCredentials'
import { BASE_URL } from '~/settings'

export const PasswordReset = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { token } = useParams()
  const showEmail: boolean = !token

  const handleCredentialsSubmit = async ({ email, password }) => {
    let passwordResetUrl: string
    // let toastEventTitle: string

    if (!token) {
      passwordResetUrl = `${BASE_URL}/iam/password_reset`
      // toastEventTitle = 'Check the email that you provided for reset instructions.'
    } else {
      passwordResetUrl = `${BASE_URL}/iam/password_reset/${token}`
      // toastEventTitle = 'Password was successfuly updated.'
    }

    try {
      const response = await fetch(passwordResetUrl, {
        method: 'post',
        credentials: 'omit',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
      })
      const { message, error } = await response.json()
      if (response.ok && message) {
        toast({
          position: 'top',
          title: message,
          status: 'success',
          duration: 3000
        })
        if (message == 'Password was successfuly updated.  Please proceed to Login.') {
          // Navigate to '/login' after the toast clears if Password Reset successful
          setTimeout(() => {
            navigate('/login')
          }, 3000)
        }
      } else if (error) {
        toast({
          position: 'top',
          title: error,
          status: 'error',
          duration: 5000
        })
      }
    } catch (err) {
      // Handle Network Error
      toast({
        position: 'top',
        title: 'Network Error',
        description: 'Unable to connect to the server. Please check your internet connection and try again.',
        status: 'error',
        duration: 9000,
        isClosable: true
      })
      console.error('Network Error During Form Submission:', err)
    }
  }

  return (
    <Flex height="100vh" justify="center" align="center">
      <CollectResetCredentials showEmail={showEmail} onSubmit={handleCredentialsSubmit} />
    </Flex>
  )
}
