import { fullname } from '~/lib/fullname'

export type StatusColorConfig = {
  bg: string
  color: string
}

export const statusColorMap: Partial<{ [key in IntroductionStatus]: StatusColorConfig }> = {
  Requested: { bg: 'orange.100', color: 'orange.600' },
  'In Progress': { bg: 'blue.100', color: 'blue.500' },
  Completed: { bg: 'green.400', color: 'green.100' },
  Canceled: { bg: '#ffeeee', color: 'red.400' },
  Cancelled: { bg: '#ffeeee', color: 'red.400' },
  Declined: { bg: '#ffeeee', color: 'red.400' }
}

export const statusColors = (status: IntroductionStatus): StatusColorConfig => {
  const { bg = 'yellow.200', color = 'orange.500' } = statusColorMap[status]
  return { bg, color }
}

export const connectorType = ({ isSuperConnector }: { isSuperConnector: boolean }) =>
  isSuperConnector ? 'Super Connector' : 'Connector'

export const connectorName = (connector: Person, isConnector: boolean, isAnonymous: boolean = false) => {
  if (isAnonymous) return 'Anonymous'
  if (isConnector) return 'You'
  return fullname(connector)
}

export const isRequested = (intro: Introduction) => intro.status === 'Requested'
export const isCanceled = (intro: Introduction) => intro.status === 'Canceled' || intro.status === 'Cancelled'
export const isCompleted = (intro: Introduction) => intro.status === 'Completed'
export const isDeclined = (intro: Introduction) => intro.status === 'Declined'
export const isInProgress = (intro: Introduction) => intro.status === 'In Progress'

export const anonCheck = (intro: Introduction, connectorsResponse: { [key: string]: number[] }) => {
  if (isCompleted(intro)) {
    return !connectorsResponse.named.includes(intro.connectorPersonId)
  }

  return !isInProgress(intro)
}

// Straight up stolen from MDN
const ordinalRules = new Intl.PluralRules('en-US', { type: 'ordinal' })
const ordSuffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th']
])
export const ordinal = (n: number | string) => {
  const number = parseInt(String(n), 10)
  if (isNaN(number)) return n

  const rule = ordinalRules.select(number)
  const suffix = ordSuffixes.get(rule)
  return `${number}${suffix}`
}
