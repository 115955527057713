import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { request } from './utils'
import { RAILS_URL } from '~/settings'

const NAMESPACE = 'companies'

const BASE_URL = `${RAILS_URL}/${NAMESPACE}`

export type Credentials = {
  email: string
  password: string
}

const normalizeAndValidateCompanySearchResult = (item: unknown): CompanySearchResult | null => {
  if (Array.isArray(item) && item.length === 2 && typeof item[0] === 'string' && typeof item[1] === 'string') {
    const companyId = Number(item[0])
    const companyName = item[1].trim()
    return !isNaN(companyId) && companyName !== '' ? { companyId, companyName } : null
  }
  console.warn('Invalid company search result format:', item)
  return null
}

const processCompanySearchResults = (data: unknown): CompanySearchResult[] => {
  if (!Array.isArray(data)) {
    console.warn('Invalid API response format:', data)
    return []
  }

  return data.reduce<CompanySearchResult[]>((acc, item) => {
    const result = normalizeAndValidateCompanySearchResult(item)
    if (result) {
      acc.push(result)
    }
    return acc
  }, [])
}

export const getCompanySearchResults = async (query: string): Promise<CompanySearchResult[]> => {
  const url = new URL(`${BASE_URL}/autosuggest?name=${encodeURIComponent(query.slice(0, 64))}`)
  const response = await request<null, unknown>(url, 'GET')
  return processCompanySearchResults(response)
}

export const useGetCompanySearchResults = (
  enabled: boolean,
  query: string,
  options?: UseQueryOptions<CompanySearchResult[], Error>
) => {
  return useQuery<CompanySearchResult[], Error>(
    ['getCompanySearchResults', query],
    () => getCompanySearchResults(query),
    {
      enabled: enabled && Boolean(query),
      ...options
    }
  )
}