import { useCallback, useState, useEffect } from 'react'
import {
  Box,
  Button,
  Center,
  Container,
  Checkbox,
  Flex,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { BASE_URL, PAGE_WIDTH, RAILS_URL } from '~/settings'
import { useSignUpStore } from '~/stores/signUp'
import { LabeledInput } from '@smallworld-io/pangaea'
import { isMobileDevice } from '~/lib/deviceDetection'
import { Trademark, SmallWorldLogo } from '~/components'

const simpleUsPhoneRegex = /^(\d{0,3})?(\d{0,3})?(\d{0,4})?$/
const onlyTenDigits = (s: string = '') => s.replace(/\D/g, '').slice(0, 10)
const usFormatted = (s: string = '') => {
  const digits = onlyTenDigits(s)
  const matched = digits.match(simpleUsPhoneRegex)

  return [
    matched[1] ? '(' : '',
    matched[1],
    matched[2] ? ') ' : '',
    matched[2],
    matched[3] ? '-' : '',
    matched[3]
  ].join('')
}

type Form = {
  phone: string
  optIn: boolean
}

const signUpWithSmsUrl = `${BASE_URL}/sign_up/with_sms`

export const CollectPhoneNumber = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const setVerified = useSignUpStore((state) => state.setVerified)
  const updatePhone = useSignUpStore((state) => state.updatePhone)
  const updateOptIn = useSignUpStore((state) => state.updateOptIn)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (isMobileDevice()) {
      setIsMobile(true)
      onOpen()
    }
  }, [onOpen])

  const {
    control,
    handleSubmit,
    formState: { isValid }
  } = useForm<Form>({
    mode: 'onChange' // This will ensure validation on each input change
  })

  const submitHandler: SubmitHandler<Form> = useCallback(
    async ({ optIn, phone }) => {
      setIsSubmitting(true)

      const phonenumber = onlyTenDigits(phone)
      updatePhone(phonenumber)
      updateOptIn(optIn)

      try {
        const response = await fetch(signUpWithSmsUrl, {
          method: 'post',
          credentials: 'omit',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ phonenumber, optin: optIn })
        })

        const {
          ok,
          data: { verified }
        } = await response.json()

        if (response.ok && ok) {
          setVerified(verified)
          navigate(verified ? './credentials' : './verify')
        }
      } catch (err) {
        toast({
          position: 'top',
          title: 'Error',
          description: 'There was an error submitting your request. Please try again.',
          status: 'warning',
          duration: 10_000,
          isClosable: true
        })
        console.log('🧨 '.repeat(20))
        console.log(err)
      } finally {
        setIsSubmitting(false)
      }
    },
    [setIsSubmitting, updatePhone]
  )

  return (
    <Flex direction="column" align="center" maxW={PAGE_WIDTH} px={4}>
      <Container maxW={600} textAlign="center" py={8}>
        <Center mb={8}>
          <SmallWorldLogo
            size={200}
            onClick={() => {
              window.location.href = 'http://www.smallworld.ai'
            }}
          />
        </Center>
        <Text as="h1" fontSize="4xl" pb={4} lineHeight={1.2}>
          Get Started
        </Text>
        <Text>
          Enter your phone number below to get started. This is only used to verify that you're a real person, and for
          nothing else.
        </Text>
      </Container>
      <Box maxW={450}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Box mx={'auto'} mb={4} minWidth={200} width="100%">
            <Controller
              name="phone"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                minLength: 10
              }}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => (
                <LabeledInput
                  type="tel"
                  label="Mobile Number"
                  placeholder="(212) 555-2368"
                  // tooltip="Your phone number is only used to verify that you're a real person. We'll never use it for any other purpose, unless you opt-in to receive messages in the future."
                  ref={ref}
                  name={name}
                  value={value}
                  fontSize={20}
                  letterSpacing={0.9}
                  w={'full'}
                  onBlur={onBlur}
                  onChange={(event) => {
                    event.target.value = usFormatted(event.target.value)
                    onChange(event)
                  }}
                  isInvalid={invalid}
                  data-hj-allow
                />
              )}
            />
          </Box>
          {/*<Box pb={4}>
            <Controller
              name="optIn"
              defaultValue={false}
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <Checkbox
                  ref={ref}
                  name={name}
                  isChecked={value}
                  onBlur={onBlur}
                  onChange={(event) => {
                    updateOptIn(!value)
                    onChange(event)
                  }}
                >
                  <Text fontSize="small">I agree to receive important account updates via SMS</Text>
                </Checkbox>
              )}
            />
          </Box>*/}
          <Box pb={4}>
            <Button type="submit" mb={4} isLoading={isSubmitting} isDisabled={isSubmitting || !isValid}>
              Verify
            </Button>

            <Text fontSize="small" textAlign="center" lineHeight={1.3} color="gray.700">
              By continuing, you agree to SmallWorld's{' '}
              <Link href={`${RAILS_URL}/terms`} isExternal>
                Terms of Use
              </Link>
              . <br />
              Messaging and data rates may apply.
            </Text>
          </Box>
        </form>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={8}>
            <Text fontSize="2xl" pb={4} lineHeight={1.3} textAlign="center">
              Please Visit on a Desktop Computer
            </Text>

            <Text mb={8} textAlign="center">
              In order to sync your relationships, you'll need to visit SmallWorld on a computer where you can install a
              Chrome extension.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}
