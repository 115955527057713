import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { request, RequestConfig, isPositiveNumber } from './utils'
import { API_PATH_SWAG, BASE_URL } from '~/settings'

//region consts
const NAMESPACE_REQ_DASH = 'requester-dashboard'

const BASE_URL_SWAG = `${import.meta.env.VITE_API_GW_URL}${API_PATH_SWAG}`

const SWAG_URL_REQ_DASH = `${BASE_URL_SWAG}/${NAMESPACE_REQ_DASH}`
//endregion consts

//region get buying centers
export const getBuyingCentersRequest = async (): Promise<BuyingCenters> => {
  const url = new URL(`${SWAG_URL_REQ_DASH}/buying-centers`)

  const response = await request<null, BuyingCenters>(url, 'GET')

  return response
}

export const useGetBuyingCentersRequest = (options?: UseQueryOptions<BuyingCenters, Error>) => {
  return useQuery<BuyingCenters, Error>(['useGetBuyingCentersRequest'], () => getBuyingCentersRequest(), {
    ...options
  })
}
//endregion get buying centers

//region get member settings request
export const getMemberSettingRequest = async (
  isEnabled: boolean,
  organizationMemberId: number,
  name: string
): Promise<MemberSetting> => {
  if (!isEnabled) {
    return Promise.resolve<MemberSetting>({
      name: name,
      organizationMemberId: organizationMemberId,
      value: ''
    })
  }

  const url = new URL(`${BASE_URL}/iam/memberships/${organizationMemberId}/settings?name=${name}`)
  const response = await request<null, MemberSetting>(url, 'GET')

  return response
}

export const useGetMemberSettingRequest = (
  isEnabled: boolean,
  organizationMemberId: number,
  name: string,
  options?: UseQueryOptions<MemberSetting, Error>
) => {
  //output
  return useQuery<MemberSetting, Error>(
    ['useGetMemberSettingRequest', name],
    () => getMemberSettingRequest(isEnabled, organizationMemberId, name),
    {
      ...options
    }
  )
}
//endregion get member settings request

//region get prospect details                                                                                                                    //output
export const fetchProspectDetailsRequest = async (
  prospectMetas: ProspectMeta[],
  buyingCenterIds: number[]
): Promise<Prospects> => {
  if (prospectMetas.length === 0 || buyingCenterIds.length === 0) {
    return Promise.resolve<Prospects>({
      prospects: []
    })
  }

  const url = new URL(`${SWAG_URL_REQ_DASH}/prospect-details`)

  const prospectDetailsInputParameter: ProspectDetailsInputParameter = {
    prospectMetas: prospectMetas,
    buyingCenterIds: buyingCenterIds
  }

  const options: RequestConfig<ProspectDetailsInputParameter> = { body: prospectDetailsInputParameter }
  // ProspectDetailsInputParameter=input ==> Prospects=output
  const response = await request<ProspectDetailsInputParameter, Prospects>(url, 'POST', options)

  return response
}

export const useFetchProspectDetailsRequest = (
  prospectMetas: ProspectMeta[],
  buyingCenterIds: number[],
  options?: UseQueryOptions<Prospects, Error>
) => {
  const prospectIdsQueryKey: number[] = prospectMetas.map((a) => a.prospectPersonId)

  // console.log(`prospectIdsQueryKey: ${prospectIdsQueryKey}`)

  //prospects is output
  return useQuery<Prospects, Error>(
    ['getProspectDetailsRequest', prospectIdsQueryKey, buyingCenterIds],
    () => fetchProspectDetailsRequest(prospectMetas, buyingCenterIds),
    {
      enabled: Boolean(prospectMetas?.length) || Boolean(buyingCenterIds?.length),
      ...options
    }
  )
}
//endregion get prospect details

//region get prospect metas
export const getProspectMetasRequest = async (
  sourceId: number,
  organizationId: number,
  organizationMemberId: number,
  relationshipStrengths: string,
  levels: string,
  buyingCenterIds: string,
  targetCompanyIdsFilterString: string,
  targetCompanyIdsInclude: boolean,
  excludeConnIdsFilterString: string,
  showAnonFilterBool: boolean,
  isBeastMode: boolean
): Promise<ProspectMetas> => {
  if (
    !isPositiveNumber(sourceId) ||
    showAnonFilterBool == null ||
    isBeastMode == null ||
    (targetCompanyIdsFilterString == null && !isBeastMode) ||
    levels == null ||
    buyingCenterIds == null ||
    excludeConnIdsFilterString == null ||
    relationshipStrengths == null
  ) {
    console.warn(`Get prospects request called with parameters that would return nothing.`)

    return Promise.resolve<ProspectMetas>({
      prospectMetas: [],
      resolved: false
    })
  }

  const url = new URL(
    `${SWAG_URL_REQ_DASH}/prospect-metas?sourceId=${sourceId}&organizationId=${organizationId}&organizationMemberId=${organizationMemberId}&relationshipStrengths=${relationshipStrengths}&levels=${levels}&buyingCenterIds=${buyingCenterIds}&targetCompanyIds=${targetCompanyIdsFilterString}&targetCompaniesInclude=${targetCompanyIdsInclude}&excludeConnIds=${excludeConnIdsFilterString}&showAnonFilterBool=${showAnonFilterBool}&isBeastMode=${isBeastMode}`
  )

  const response = await request<null, ProspectMetas>(url, 'GET')
  if (response.prospectMetas == null || response.prospectMetas.length == 0) {
    return Promise.resolve<ProspectMetas>({
      prospectMetas: [],
      resolved: true
    })
  }

  return response
}

export const useGetProspectMetasRequest = (
  sourceId: number,
  organizationId: number,
  organizationMemberId: number,
  relationshipStrengths: string,
  levels: string,
  buyingCenterIds: string,
  targetCompanyIdsFilterString: string,
  targetCompanyIdsInclude: boolean,
  excludeConnIdsFilterString: string,
  showAnonFilterBool: boolean,
  isBeastMode: boolean,
  options?: UseQueryOptions<ProspectMetas, Error>
) => {
  return useQuery<ProspectMetas, Error>(
    ['getProspectMetasRequest', relationshipStrengths, levels, buyingCenterIds],
    () =>
      getProspectMetasRequest(
        sourceId,
        organizationId,
        organizationMemberId,
        relationshipStrengths,
        levels,
        buyingCenterIds,
        targetCompanyIdsFilterString,
        targetCompanyIdsInclude,
        excludeConnIdsFilterString,
        showAnonFilterBool,
        isBeastMode
      ),
    {
      enabled: isPositiveNumber(sourceId) && isPositiveNumber(organizationId) && isPositiveNumber(organizationMemberId),
      ...options
    }
  )
}
//endregion get prospect metas

//region save member settings
export const saveMemberSettingsRequest = async (memberSettingChange: MemberSetting): Promise<void> => {
  const url = new URL(`${BASE_URL}/iam/memberships/${memberSettingChange.organizationMemberId}/settings`)

  const options: RequestConfig<MemberSetting> = { body: memberSettingChange }

  const response = await request<MemberSetting, void>(url, 'POST', options)

  return response
}

export const useSaveMemberSettingsRequest = (options?: UseMutationOptions<void, Error, MemberSetting>) =>
  useMutation<void, Error, MemberSetting>(saveMemberSettingsRequest, options)
//endregion save member settings
