import { Box, Button, Container, Flex, Image, Link, Text } from '@chakra-ui/react'
import { CHROME_EXT_URL, PAGE_WIDTH, SUPPORT_URL } from '~/settings'
import iconClock from '~/assets/icons/icon-clock.svg'

export const Sync = () => {
  return (
    <Flex direction="column" align="center" px={4} pb={32} maxW={PAGE_WIDTH}>
      <Container maxW={800} textAlign="center" px={8} pb={8}>
        <Text as="h1" fontSize="4xl" pb={4} lineHeight={1.2}>
          Sync Your Relationships
        </Text>
        <Text>
          When you sync your connections from LinkedIn, we’ll prioritize your network of relationships to increase the
          likelihood of finding a match with one of your target prospects.
        </Text>
      </Container>

      <Box w="full">
        <Box bg="white" borderWidth="1px" borderRadius="md" borderColor="gray.200" mx="auto" p={8} maxW={750}>
          <Flex direction={{ base: 'column', md: 'row' }} justifyContent="space-between" alignItems="center">
            <Box flex={{ base: 1, md: 1 }} textAlign={{ base: 'center', md: 'left' }}>
              <Text as="h2" fontWeight={700} fontSize={'2xl'} mb={2} lineHeight={1.2}>
                Intelligent Relationship Sync
              </Text>
              <Text color="gray.500" lineHeight={1.4} pr={{ base: 0, md: 8 }} pb={4}>
                Install our Chrome Extension, and we'll sync your LinkedIn connections in just a couple of minutes.
              </Text>
            </Box>

            <Box w={{ base: 'full', md: 'auto' }} textAlign="center">
              <Button as="a" href={`${CHROME_EXT_URL}`} target="_blank" w={{ base: 'full', md: 'auto' }}>
                Visit Chrome Store
              </Button>

              <Flex alignItems="center" justifyContent="center" pt={2} w="full" color="gray.600" fontSize="small">
                <Image src={iconClock} alt="Clock Icon" mr={1} />
                Only takes 1-2 minutes
              </Flex>
            </Box>
          </Flex>
        </Box>

        <Box textAlign="center">
          <Text pt={4} fontSize="small" fontWeight={500}>
            SmallWorld<sup>®</sup> always respects your privacy.{' '}
            <Link href={`${SUPPORT_URL}/article/data-privacy`} isExternal>
              Learn More
            </Link>
          </Text>
        </Box>
      </Box>
    </Flex>
  )
}
