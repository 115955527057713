import { useQuery, useQueries, UseQueryOptions } from '@tanstack/react-query'
import { API_PATH_SWAG, BASE_URL, SWAG_URL } from '~/settings'
import { request } from './utils'

const NAMESPACE_PEOPLE = 'people'

const BASE_URL_SWAG = `${SWAG_URL}${API_PATH_SWAG}`

const SWAG_URL_PEOPLE = `${BASE_URL_SWAG}/${NAMESPACE_PEOPLE}`

// todo: type when the json payload is solidified
export const fetchPerson = async (id: number) => {
  const { person } = await request<null, { [key: string]: any }>(new URL(`${SWAG_URL_PEOPLE}/${id}`))
  return person
}

export const usePersonQuery = ({ id }: { id: number }) => {
  return useQuery({
    queryKey: ['swag', 'people', id],
    queryFn: () => fetchPerson(id),
    enabled: !!id,
    retry: 3
  })
}

export const usePeopleQuery = (ids: number[] = []) => {
  return useQueries({
    queries: ids.map((id) => ({
      queryKey: ['swag', 'people', id],
      queryFn: () => fetchPerson(id),
      enabled: ids.length > 0
    }))
  })
}

export const getNamedConnectorsRequest = async (organizationId: number): Promise<People> => {
  const url = new URL(`${SWAG_URL_PEOPLE}/named-connectors/${organizationId}`)

  const response = await request<null, People>(url, 'GET')

  return response
}

export const useGetNamedConnectorsRequest = (organizationId: number, options?: UseQueryOptions<People, Error>) => {
  return useQuery<People, Error>(['useNamedConnectorsRequest'], () => getNamedConnectorsRequest(organizationId), {
    ...options
  })
}
