import { Honeybadger } from '@honeybadger-io/react'

const DEFAULT_PATH_SWAG = '/swag/v1'

export const APP_NAME = 'SmallWorld'
export const API_PATH = '/api'
// use below if you're running swag as docker image or live (STAGING/DEMO/PROD)
export const API_PATH_SWAG = import.meta.env.VITE_API_PATH_SWAG || DEFAULT_PATH_SWAG

export const SWAG_URL = import.meta.env.VITE_API_GW_URL
export const RAILS_URL = import.meta.env.VITE_RAILS_URL
export const BASE_URL = `${RAILS_URL}${API_PATH}`
export const LOGIN_PATH = '/people/sign_in'
export const SUPPORT_URL = 'https://small-world.tawk.help'
export const CHROME_EXT_URL =
  'https://chrome.google.com/webstore/detail/smallworld-for-chrome/pkdinajgjbmbbaabpkddlccaojgfjgcl?hl=en-US'
export const PAGE_WIDTH = '1000px'

export const discoverParams = new URLSearchParams({
  isDeepLink: 'true',
  targetCompanyIds: '',
  targetCompanyIdsInclude: 'false',
  buyingCenterIds: '1,2,3,4,5,6,7,8,9,10,12,13',
  levels: 'executive,senior,mid,contributor,junior',
  strengths: 'Very Weak,Weak,Average,Strong,Very Strong,Unrated',
  excludeConnIds: '',
  isBeastMode: 'true',
  showAnonConns: 'false'
})

export const RECIPOCATE_URL = `/discover?${discoverParams.toString()}`

const honebadgerConfig = {
  apiKey: import.meta.env.VITE_HONEYBADGER_API_KEY,
  environment: import.meta.env.MODE,
  revision: import.meta.env.VITE_HONEYBADGER_REVISION,
  maxErrors: 32
}
export const honeybadger = Honeybadger.configure(honebadgerConfig)
