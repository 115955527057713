//region imports
import { useEffect, useState, useRef } from 'react'
import { Box, HStack, VStack, Grid, GridItem, Link as ChakraLink, Skeleton, Text, useToast } from '@chakra-ui/react'
import { useAuth } from '~/auth'
import { convertStringToNumberArray, HiddenProspectsApi, RequesterDashboardApi } from '~/api'
import { ProspectCard } from '~/components/ProspectCard/ProspectCard'
import InfiniteScroll from 'react-infinite-scroll-component'
import useExpressStore from '~/stores/express'
import useDiscoverStore from '~/stores/discover'
import { Link as RouterLink } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'

export const ProspectList = () => {
  const auth = useAuth()
  const itemRefs = useRef({})
  const toast = useToast()
  const toastIdRef = useRef<string | number | undefined>()

  //region Stores
  const membership = useExpressStore((state) => state.membership)
  const lastProspectPersonIdSeen = useExpressStore((state) => state.reqDashLastProspectPersonIdSeen)
  const totalProspectsDisplayed = useExpressStore((state) => state.reqDashTotalProspectsDisplayed)
  const { setReqDashTotalProspectsDisplayed } = useExpressStore(
    useShallow((state) => ({
      setReqDashTotalProspectsDisplayed: state.setReqDashTotalProspectsDisplayed
    }))
  )
  const {
    strengthsFilterString,
    levelsFilterString,
    isBeastMode,
    targetCompanyIdsFilterString,
    targetCompanyIdsInclude,
    buyingCenterIdsFilterString,
    excludeConnIdsFilterString,
    showAnonFilterBool,
    setProspectMetasCount
  } = useDiscoverStore(
    useShallow((state) => ({
      strengthsFilterString: state.relationshipStrengths,
      levelsFilterString: state.levels,
      buyingCenterIdsFilterString: state.buyingCenters,
      targetCompanyIdsFilterString: state.targetCompanies,
      targetCompanyIdsInclude: state.targetCompaniesInclude,
      excludeConnIdsFilterString: state.excludedConnectors,
      showAnonFilterBool: state.showAnonFilter,
      isBeastMode: state.isBeastMode,
      setProspectMetasCount: state.setProspectMetasCount
    }))
  )

  //region styling consts
  const linkStyle = { textColor: 'gray.600', textDecoration: 'underline' }

  //region Component State
  const [isBackFromDetails, setIsBackFromDetails] = useState('')
  // const [lastProspectMetaPersonId, setLastProspectMetaPersonId] = useState<number>(null)
  // const [lastDisplayedProspectPersonId, setLastDisplayedProspectPersonId] = useState<number>(null)
  const [prospectMetasToGetDetailsFor, setProspectMetasToGetDetailsFor] = useState([])
  const [prospectsToDisplay, setProspectsToDisplay] = useState<Prospect[]>([])
  const [prospectsPresent, setProspectsPresent] = useState<boolean>(true)
  const [buyingCenterIdsNumsArray, setBuyingCenterIdsNumsArray] = useState([])
  const [haveAllPropsectsBeenDisplayed, setHaveAllPropsectsBeenDisplayed] = useState<boolean>(true)

  //region Query and Mutations
  const getProspectMetasRequest = RequesterDashboardApi.useGetProspectMetasRequest(
    auth.personId,
    auth.organizationId,
    membership.id,
    strengthsFilterString,
    levelsFilterString,
    buyingCenterIdsFilterString,
    targetCompanyIdsFilterString,
    targetCompanyIdsInclude,
    excludeConnIdsFilterString,
    showAnonFilterBool,
    isBeastMode
  )

  const getProspectDetailsRequest = RequesterDashboardApi.useFetchProspectDetailsRequest(
    prospectMetasToGetDetailsFor,
    buyingCenterIdsNumsArray
  )

  const hideProspectRequest = HiddenProspectsApi.useHideProspectRequest()

  //region Use Effects

  //****when all the filter strings are set, fetch prospect metas
  useEffect(() => {
    const areAllNotBlank: boolean =
      !!buyingCenterIdsFilterString &&
      !!levelsFilterString &&
      !!strengthsFilterString &&
      showAnonFilterBool != null &&
      isBeastMode != null

    if (areAllNotBlank) {
      // console.log(`all not blank - refetching`)
      setProspectsToDisplay([])
      getProspectMetasRequest.refetch()
    } else {
      // console.log(`at least 1 filter string is missing`)
      // if (buyingCenterIdsFilterString?.length === 0) {
      //   console.log(`at least 1 filter string is missing because buyingCenterIdsFilterString is blank`)
      // }
      // if (levelsFilterString?.length === 0) {
      //   console.log(`at least 1 filter string is missing because levelsFilterString is blank`)
      // }
      // if (strengthsFilterString?.length === 0) {
      //   console.log(`at least 1 filter string is missing because strengthsFilterString is blank`)
      // }
      // if (excludeConnIdsFilterString == null) {
      //   console.log(`at least 1 filter string is missing because excludeConnIdsFilterString is null`)
      // }
      // if (showAnonFilterBool == null) {
      //   console.log(`at least 1 filter string is missing because Show anon filter bool is null`)
      // }
      // if (targetCompanyIdsInclude == null) {
      //   console.log(`at least 1 filter string is missing because targetCompanyIdsInclude is null`)
      // }
      // if (targetCompanyIdsFilterString == null) {
      //   console.log(`at least 1 filter string is missing because targetCompanyIdsFilterString is null`)
      // }
      // if (isBeastMode == null) {
      //   console.log(`at least 1 filter string is missing because isBeastMode is null`)
      // }
    }
  }, [
    buyingCenterIdsFilterString,
    levelsFilterString,
    strengthsFilterString,
    excludeConnIdsFilterString,
    showAnonFilterBool,
    targetCompanyIdsInclude,
    targetCompanyIdsFilterString,
    membership,
    isBeastMode
  ])

  // when buying centers string filter changes, update the int array version of that
  useEffect(() => {
    setBuyingCenterIdsNumsArray(convertStringToNumberArray(buyingCenterIdsFilterString))
  }, [buyingCenterIdsFilterString, setBuyingCenterIdsNumsArray])

  useEffect(() => {
    if (getProspectMetasRequest.isFetching) {
      const timer = setTimeout(() => {
        const id = toast({
          position: 'bottom',
          duration: null,
          isClosable: false,
          render: () => (
            <Box
              color="white"
              py={4}
              px={8}
              bg="#FEFEEE"
              border={'1px solid'}
              borderColor={'#E9E7BE'}
              borderRadius="md"
              textAlign={'center'}
              textColor={'#936F4E'}
            >
              <Text fontWeight={600}>Results still loading...</Text>
              <Text>
                We're sorting through millions of prospects, <br />
                just a moment more please.
              </Text>
            </Box>
          )
        })
        toastIdRef.current = id
      }, 4000)

      return () => clearTimeout(timer) // Cleanup the timer
    } else {
      if (toastIdRef.current) {
        toast.close(toastIdRef.current)
        toastIdRef.current = undefined
      }
    }
  }, [getProspectMetasRequest.isFetching, toast])

  //prospect metas data ready
  useEffect(() => {
    if (!getProspectMetasRequest.isFetching && getProspectMetasRequest.data?.prospectMetas) {
      // if (getProspectMetasRequest.data.prospectMetas.length > 0) {
      //   setLastProspectMetaPersonId(getProspectMetasRequest.data.prospectMetas[getProspectMetasRequest.data.prospectMetas.length - 1].prospectPersonId)
      // }
      setProspectsToDisplay([])
      const prospectMetas = getProspectMetasRequest.data?.prospectMetas

      setProspectMetasCount(prospectMetas.length)

      if (prospectMetas.length > 0) {
        // setLastProspectMetaPersonId(prospectMetas[prospectMetas.length - 1].prospectPersonId)
        setProspectsPresent(true)
      } else if (prospectMetas.length == 0 && getProspectMetasRequest.data?.resolved) {
        setProspectsPresent(false)
      }

      let sliceEnd = 20
      if (prospectMetas.length < sliceEnd) {
        // setLastDisplayedProspectPersonId(prospectMetas[prospectMetas.length - 1].prospectPersonId)
        // console.log("🚀 ~ useEffect ~ prospectMetas[prospectMetas.length - 1].prospectPersonId:", prospectMetas[prospectMetas.length - 1].prospectPersonId)
        setHaveAllPropsectsBeenDisplayed(true)
      } else {
        setHaveAllPropsectsBeenDisplayed(false)
      }

      if (isBackFromDetails == 'true') {
        // gets next multiple of 20
        sliceEnd = Math.max(Math.ceil(totalProspectsDisplayed / 20) * 20, sliceEnd)
      }

      const noMoreThanFullArray = Math.min(getProspectMetasRequest.data?.prospectMetas?.length, sliceEnd)

      setProspectMetasToGetDetailsFor(getProspectMetasRequest.data.prospectMetas.slice(0, noMoreThanFullArray))
    }
  }, [
    getProspectMetasRequest.isFetching,
    getProspectMetasRequest.data,
    setProspectMetasToGetDetailsFor,
    setProspectsToDisplay,
    membership
  ])

  //prospect metas to get details for changes
  useEffect(() => {
    if (prospectMetasToGetDetailsFor?.length > 0) {
      getProspectDetailsRequest.refetch()
    }
  }, [prospectMetasToGetDetailsFor])

  // prospect details ready
  useEffect(() => {
    if (
      !getProspectDetailsRequest.isLoading &&
      !getProspectDetailsRequest.isFetching &&
      getProspectDetailsRequest.data?.prospects
    ) {
      const prospects = getProspectDetailsRequest.data?.prospects
      setProspectsToDisplay((prevArray) => [...prevArray, ...prospects])

      // if (prospects.length > 0) {
      //   setLastDisplayedProspectPersonId(prospects[prospects.length - 1].prospectPersonId)
      // }
    }
  }, [
    getProspectDetailsRequest.isFetching,
    getProspectDetailsRequest.isLoading,
    getProspectDetailsRequest.data
    // setProspectsToDisplay
  ])

  //prospects to display changes
  useEffect(() => {
    if (prospectsToDisplay?.length > 0) {
      setReqDashTotalProspectsDisplayed(prospectsToDisplay?.length)
      if (isBackFromDetails == 'true' && itemRefs.current[lastProspectPersonIdSeen]) {
        itemRefs.current[lastProspectPersonIdSeen].scrollIntoView({ behavior: 'smooth' })
        setIsBackFromDetails('false')
      }
    }
  }, [
    prospectsToDisplay,
    setReqDashTotalProspectsDisplayed,
    lastProspectPersonIdSeen,
    isBackFromDetails,
    getProspectDetailsRequest
  ])

  // useEffect(() => {
  //   if (lastDisplayedProspectPersonId != null && lastProspectMetaPersonId != null) {
  //     setHaveAllPropsectsBeenDisplayed(lastDisplayedProspectPersonId === lastProspectMetaPersonId)
  //   }
  // }, [lastDisplayedProspectPersonId, lastProspectMetaPersonId, setHaveAllPropsectsBeenDisplayed])

  //region Btn handlers
  const onHide = (prospect: Prospect) => {
    setProspectsToDisplay(prospectsToDisplay.filter((a) => a.prospectPersonId !== prospect.prospectPersonId))

    const hiddenProspect: HiddenProspect = {
      organizationMemberId: membership.id,
      prospectPersonId: prospect.prospectPersonId
    }

    hideProspectRequest.mutate(hiddenProspect, {
      onSuccess: () => {},
      onError: (e) => {
        console.error(e)
      }
    })
  }

  // region Functions
  const fetchMoreData = () => {
    if (getProspectMetasRequest && getProspectMetasRequest.data && getProspectMetasRequest.data.prospectMetas) {
      // this gets reset to false after scrolling is done
      if (isBackFromDetails === 'true') {
        return
      }

      // Total prospects displayed is the first index of the next batch
      const firstIndex = totalProspectsDisplayed
      const lastIndexToGet = firstIndex + 20
      const next20ToSend = getProspectMetasRequest.data.prospectMetas.slice(firstIndex, lastIndexToGet)

      if (lastIndexToGet >= getProspectMetasRequest.data.prospectMetas.length) {
        setHaveAllPropsectsBeenDisplayed(true)
      }

      if (next20ToSend) {
        setProspectMetasToGetDetailsFor(next20ToSend)
      }
    }
  }

  return (
    <>
      {/* Prospect data and scroll */}
      <Grid templateColumns="repeat(12, 1fr)" gap="4">
        {/* infinite scroll */}
        <GridItem colSpan={12} minH={200}>
          {getProspectMetasRequest.isFetching ? (
            <Box>
              <Box border={'1px solid'} mb={4} borderColor="gray.200" borderRadius={'5px'}>
                <Skeleton height="175px" startColor="gray.100" endColor="gray.200" width="100%" />
              </Box>

              <Box border={'1px solid'} mb={4} borderColor="gray.200" borderRadius={'5px'}>
                <Skeleton height="175px" startColor="gray.100" endColor="gray.200" width="100%" />
              </Box>

              <Box border={'1px solid'} mb={4} borderColor="gray.200" borderRadius={'5px'}>
                <Skeleton height="175px" startColor="gray.100" endColor="gray.200" width="100%" />
              </Box>
            </Box>
          ) : prospectsPresent ? (
            <InfiniteScroll
              dataLength={prospectsToDisplay?.length}
              next={fetchMoreData}
              loader={''}
              // create a use state for detailsCount - when details returns 0, hasMore = false
              hasMore={!haveAllPropsectsBeenDisplayed}
            >
              {prospectsToDisplay.map((prospect, index) => (
                <div
                  // can't use index as index will change on hide
                  key={prospect.prospectPersonId}
                  ref={(el) => (itemRefs.current[prospect.prospectPersonId] = el)} // Storing ref of each item
                >
                  <ProspectCard prospect={prospect} index={index} onHide={onHide} />
                </div>
              ))}
            </InfiniteScroll>
          ) : (
            <Box
              borderRadius="3px"
              borderWidth={1}
              borderStyle="dashed"
              borderColor="gray.300"
              textAlign="center"
              padding="5rem"
            >
              <Text fontWeight="semibold" as="h2">
                There are no results for the filters you’ve applied. Please adjust your filters.
              </Text>
            </Box>
          )}
        </GridItem>
        {/* want to see more prospects */}
        {haveAllPropsectsBeenDisplayed && (
          <GridItem colSpan={12} mb={4}>
            <VStack>
              <Text as="h3" fontSize={24} textColor={'gray.600'}>
                Want to see more Prospects?
              </Text>
              <HStack>
                <Text fontSize={16} textColor={'gray.600'}>
                  <ChakraLink as={RouterLink} to={'../rate-relationships'} {...linkStyle}>
                    Rate More Relationships
                  </ChakraLink>
                  ,
                  <ChakraLink as={RouterLink} to={'../target-companies'} ml={2} {...linkStyle}>
                    Add More Target Companies
                  </ChakraLink>
                  , or
                  {/*
                    TODO: "Invite Your Network" lauch the 
                    share modal when the other PR is merged
                  */}
                  <ChakraLink as={RouterLink} to={'#'} mx={2} {...linkStyle}>
                    Invite Your Network
                  </ChakraLink>
                  to join Super Connectors to see more!
                </Text>
              </HStack>
            </VStack>
          </GridItem>
        )}
      </Grid>
    </>
  )
}
