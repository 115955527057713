// export default MultiSelectMenuChakra
import { useState, ChangeEvent, useEffect } from 'react'
import { Box, Checkbox, Menu, MenuButton, MenuItem, MenuList, Text, CloseButton } from '@chakra-ui/react'
import InfiniteScroll from 'react-infinite-scroll-component'

type MultiSelectMenuChakraProps = {
  menuOptions: MenuOption[]
  menuTitle: string
  menuSelectedCount: number
  isDisabled?: boolean
  onClose: (menuOptions: MenuOption[]) => void
}

export const MultiSelectMenuChakra = ({
  menuOptions: options,
  menuTitle,
  menuSelectedCount,
  isDisabled = false,
  onClose
}: MultiSelectMenuChakraProps) => {
  const [allOptions, setAllOptions] = useState<MenuOption[]>([])
  const [anyItemSelected, setAnyItemSelected] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const [lastOptionDisplayed, setLastOptionDisplayed] = useState(0)

  const sliceSize = 50

  useEffect(() => {
    if (options.length < sliceSize) {
      setAllOptions(options)
      setHasMore(false)
    } else {
      setHasMore(true)
    }
    const noMoreThanFullArray = Math.min(options.length, sliceSize)
    setAllOptions(options.slice(0, noMoreThanFullArray))
    setLastOptionDisplayed(noMoreThanFullArray)
    setAnyItemSelected(options.some((option) => option.isSelected))
  }, [options])

  const displayMoreData = () => {
    const moreOptions = options.slice(lastOptionDisplayed, lastOptionDisplayed + sliceSize)
    setAllOptions((prevOptions) => [...prevOptions, ...moreOptions])
    setLastOptionDisplayed(lastOptionDisplayed + sliceSize)
    if (lastOptionDisplayed + sliceSize >= options.length) {
      setHasMore(false)
    }
  }

  const handleOptionClick = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target

    const updatedOptions = allOptions.map((option) => {
      if (option.value === name) {
        return { ...option, isSelected: checked }
      }
      return option
    })
    setAnyItemSelected(updatedOptions.some((option) => option.isSelected))
    setAllOptions(updatedOptions)
  }

  const handleSelectAllOrNone = (all: boolean) => {
    const updatedOptions = allOptions.map((option) => {
      option.isSelected = all
      return option
    })

    setAllOptions(updatedOptions)
    setAnyItemSelected(all)
  }

  const getMenuState = () => {
    if (menuSelectedCount === 0) return 'none'
    if (menuSelectedCount < options.length) return 'modified'
    return 'default'
  }

  const menuState = getMenuState()

  const getMenuStyles = () => {
    switch (menuState) {
      case 'none':
        return {
          buttonStyle: { color: 'red.500', backgroundColor: 'white', borderColor: 'red.500' },
          textStyle: { backgroundColor: 'red.100', textColor: 'red.500' }
        }
      case 'modified':
        return {
          buttonStyle: { color: 'white', backgroundColor: 'blue.400', borderColor: 'blue.400' },
          textStyle: { backgroundColor: 'white', textColor: 'blue.400' }
        }
      default:
        return {
          buttonStyle: { backgroundColor: 'white', borderColor: 'blue.200' },
          textStyle: { backgroundColor: 'blue.200', textColor: 'blue.400' }
        }
    }
  }

  const { buttonStyle, textStyle } = getMenuStyles()

  return (
    <>
      <Menu closeOnBlur={true} closeOnSelect={false} onClose={() => onClose(allOptions)}>
        <MenuButton
          {...buttonStyle}
          disabled={isDisabled}
          fontSize={13}
          px={3}
          py={1}
          borderWidth="1px"
          borderRadius="9rem"
        >
          {menuTitle}
          <Text {...textStyle} as="span" ml={1} borderRadius="13px" px={1.5} fontSize="10px" fontWeight="bold">
            {menuSelectedCount}
          </Text>
        </MenuButton>
        <MenuList p={0} position="relative" overflow={'hidden'} w="290px">
          <Box maxH="228px" overflowY="auto" p={3} id="scrollableDiv">
            <InfiniteScroll
              dataLength={allOptions.length}
              next={displayMoreData}
              hasMore={hasMore}
              loader={<h4>Loading...</h4>}
              scrollableTarget="scrollableDiv"
            >
              {allOptions &&
                allOptions.map((option) => (
                  <MenuItem key={option.value} p={0} bg="transparent" _hover={{ fontWeight: '600', background: 'transparent' }}>
                    <Checkbox
                      isChecked={option.isSelected}
                      onChange={handleOptionClick}
                      name={option.value}
                      width={'full'}
                      p={1.5}
                    >
                      <Text fontSize={14}>{option.label}</Text>
                    </Checkbox>
                  </MenuItem>
                ))}
              {/* select/deselect */}
          </InfiniteScroll>
          </Box>

          <Box backgroundColor={'gray.100'} textAlign={'right'} pr={1}>
            <MenuItem
              onClick={() => handleSelectAllOrNone(true)}
              display={'inline'}
              fontSize={12}
              py={3}
              w={'80px'}
              textColor="gray.600"
              textAlign={'center'}
              _hover={{ textColor: 'black' }}
              background={'transparent'}
            >
              Select All
            </MenuItem>
            <MenuItem
              onClick={() => handleSelectAllOrNone(false)}
              display={'inline'}
              fontSize={12}
              p={2}
              w={'70px'}
              textColor="gray.600"
              textAlign={'right'}
              _hover={{ textColor: 'black' }}
              background={'transparent'}
            >
              Clear All
            </MenuItem>
            <MenuItem
              disabled={!anyItemSelected}
              onClick={() => {
                if (anyItemSelected) {
                  onClose(allOptions)
                }
              }}
              display={'inline'}
              fontSize={12}
              p={2}
              textColor={anyItemSelected ? 'blue.400' : 'gray.300'}
              fontWeight="bold"
              closeOnSelect={anyItemSelected ? true : false}
              w={'105px'}
              textAlign={'right'}
              _hover={{ textColor: anyItemSelected ? 'blue.500' : 'gray.300', cursor: anyItemSelected ? 'pointer' : 'not-allowed' }}
              background={'transparent'}
            >
              Show Results
            </MenuItem>
          </Box>
        </MenuList>
      </Menu>
    </>
  )
}

export default MultiSelectMenuChakra
