import { Box, Flex, Text } from '@chakra-ui/react'

import { AssignIcon } from './AssignIcon'

export type InvitedContentProps = {
  invitedEmail: string
}

// TODO: Fix hover of button

export const InvitedContent = (props: InvitedContentProps) => {
  return (
    <Flex direction="column" flex="2" justifyContent="center" alignItems="center" p={4} width="100%">
      <Text fontSize="xs" align="center" fontWeight="bold" lineHeight=".7rem" mb={1}>
        <AssignIcon boxSize={4} color="blue.400" mr={1} mb={1} fill="none" />
        {props.invitedEmail}
        <Box fontWeight="normal">was invited to join SmallWorld!</Box>
      </Text>
    </Flex>
  )
}
