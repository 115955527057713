// src/lib/linkedinUtils.ts

export const hasLinkedIn = (url: string) => {
  if (!url || url === 'null') return false
  return url.trim().length > 0
}

export const openLinkedIn = (url: string) => {
  if (!hasLinkedIn(url)) return

  const hasHttp = url.startsWith('http://')
  const hasHttps = url.startsWith('https://')

  const finalUrl = hasHttp || hasHttps ? url : `https://${url}`
  window.open(finalUrl, '_blank')
}
