import { Box, Button, Container, Flex, Text, useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { PAGE_WIDTH } from '~/settings'
import { useSignUpStore } from '~/stores/signUp'

import { LabeledInput } from '@smallworld-io/pangaea'

const VALID_EMAIL_PATTERN = /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i
const VALID_PASSWORD_PATTERN = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])/

type Form = {
  email?: string
  password: string
  passwordConfirmation?: string
}

type CollectResetCredentialsProps = {
  onSubmit: (formData: Form) => void
  email?: string
  showEmail: boolean
}

export const CollectResetCredentials = ({ onSubmit, showEmail }: CollectResetCredentialsProps) => {
  const toast = useToast()

  const isVerified = useSignUpStore((state) => state.verified)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const { control, handleSubmit } = useForm<Form>()

  const submitHandler: SubmitHandler<Form> = useCallback(
    async ({ email, password }) => {
      setIsSubmitting(true)
      try {
        // Pass form data to the Password Reset component's onSubmit function
        await onSubmit({ email, password })
      } catch (err) {
        // Handle errors
      } finally {
        setIsSubmitting(false)
      }
    },
    [onSubmit]
  )

  useEffect(() => {
    if (isVerified) {
      toast({
        position: 'top',
        title: 'Phone Number Verified',
        description: 'Your phone number has previously been verified, thank you.',
        status: 'info',
        duration: 10_000,
        isClosable: true
      })
    }
  }, [isVerified])

  return (
    <Flex direction="column" align="center" maxW={PAGE_WIDTH} px={4}>
      <Container maxW={600} textAlign="center" py={8}>
        <Text as="h1" fontSize="4xl" lineHeight={1.2} pb={4}>
          Reset Your Password
        </Text>
        {showEmail ? (
          <Text>
            Provide the email asssociated with your Super Connector account and we will send an email link to reset your
            password to that address.
          </Text>
        ) : (
          <Text>
            Your password should be at least 8 characters and include at least one uppercase letter, one lowercase
            letter, and one number.
          </Text>
        )}
      </Container>

      <Box w="full" maxW={400}>
        <form onSubmit={handleSubmit(submitHandler)}>
          {showEmail && (
            <Box pb={4}>
              <Controller
                name="email"
                defaultValue=""
                control={control}
                rules={{
                  required: true,
                  pattern: VALID_EMAIL_PATTERN
                }}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => (
                  <LabeledInput
                    label="Email Address"
                    placeholder="email@address.com"
                    type="email"
                    ref={ref}
                    name={name}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    isInvalid={invalid}
                    tooltip="This email address will serve as the primary means of communication between you and SmallWorld, as well as for interactions with other Super Connectors."
                  />
                )}
              />
            </Box>
          )}
          {!showEmail && (
            <>
              <Box pb={4}>
                <Controller
                  name="password"
                  defaultValue=""
                  control={control}
                  rules={{
                    required: true,
                    minLength: 8,
                    pattern: VALID_PASSWORD_PATTERN
                  }}
                  render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => (
                    <LabeledInput
                      label="Password"
                      type="password"
                      ref={ref}
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      isInvalid={invalid}
                      autoComplete="new-password"
                    />
                  )}
                />
              </Box>
              <Box pb={4}>
                <Controller
                  name="passwordConfirmation"
                  defaultValue=""
                  control={control}
                  rules={{
                    required: true,
                    validate: (value, formValues) => {
                      return value === formValues['password']
                    }
                  }}
                  render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => (
                    <LabeledInput
                      label="Password Confirmation"
                      type="password"
                      ref={ref}
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      isInvalid={invalid}
                      autoComplete="new-password"
                    />
                  )}
                />
              </Box>
            </>
          )}
          <Box pb={4}>
            <Button type="submit" isLoading={isSubmitting}>
              Continue
            </Button>
          </Box>
        </form>
      </Box>
    </Flex>
  )
}
