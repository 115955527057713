import { Box, Flex, Skeleton, Text } from '@chakra-ui/react'
import { fullname } from '~/lib/fullname'
import LinkedInIcon from '~/components/LinkedInIcon'
import { hasLinkedIn, openLinkedIn } from '~/lib/linkedinUtils'

export type PersonAndPositionHeadingProps = {
  company?: string
  person: Person
  isLoading: boolean
  isError: boolean
}

export const PersonAndPositionHeading = (props: PersonAndPositionHeadingProps) => {
  const { company, person, isLoading, isError } = props

  if (isError) {
    return (
      <Box mb={10}>
        <Text as="h3" fontSize="2rem" lineHeight="normal" color="black" mb={2}>
          There was an error starting this introduction request.
        </Text>
      </Box>
    )
  }

  return (
    <Box mb={10}>
      <Skeleton isLoaded={!isLoading}>
        <Text as="h3" fontSize="2rem" lineHeight="normal" color="black" mb={2}>
          {person ? fullname(person) : 'Person Name'}
          <LinkedInIcon
            cursor="pointer"
            boxSize="6"
            mt={-1}
            ml={3}
            onClick={() => openLinkedIn(person.linkedinUrl)}
            sx={{ transform: 'translateY(1px)' }}
          />
        </Text>
      </Skeleton>
      <Flex alignItems="center">
        <Box fontSize="lg" fontWeight="semibold" color="black" mr={4}>
          <Skeleton isLoaded={!isLoading}>{company || person?.companyName || 'Company Name'}</Skeleton>
        </Box>
        <Box
          fontSize="lg"
          fontWeight="light"
          color="black"
          pl={4}
          borderLeftColor="gray.400"
          borderLeftWidth={1}
          borderLeftStyle="solid"
        >
          <Skeleton isLoaded={!isLoading}>{person ? person.companyPosition : 'Position Held at the Company'}</Skeleton>
        </Box>
      </Flex>
    </Box>
  )
}
