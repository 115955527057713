import { Badge } from '@chakra-ui/react'
import { statusColors } from './lib'

type StatusBadgeProps = {
  status: IntroductionStatus
}

export const StatusBadge = ({ status }: StatusBadgeProps) => {
  const { bg, color } = statusColors(status)

  return (
    <Badge
      fontSize="xs"
      fontWeight="bold"
      textTransform="uppercase"
      textAlign="center"
      borderRadius="3px"
      p={0.5}
      minW="3.75em"
      bg={bg}
      color={color}
    >
      {status}
    </Badge>
  )
}
