import { Box } from '@chakra-ui/react'

export const DotContainer = ({ children }) => {
  return (
    <Box
      position="relative"
      borderLeftColor="gray.300"
      borderLeftWidth="1px"
      borderLeftStyle="solid"
      ml={{
        base: 0,
        sm: 5
      }}
      pl={{
        base: 4,
        sm: 8
      }}
      mb={8}
    >
      <Box
        position="absolute"
        borderRadius="999px"
        top="0rem"
        left="-0.5rem"
        height="1rem"
        width="1rem"
        background="green.400"
      />
      {children}
    </Box>
  )
}
