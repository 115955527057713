import { useEffect, useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  Tooltip
} from '@chakra-ui/react'
import { InfoOutlineIcon } from '@chakra-ui/icons'

import { SectionHeader } from '@smallworld-io/pangaea'

import { Footer } from './Footer'
import { Header } from './Header'

import { writeIntroductionRequest, writePointOfSale } from '~/lib/prefill'

import { useAuth } from '~/auth'
import { useAccount } from '~/api/accounts'
import { fullname } from '~/lib'

export type RequestModalBodyForm = {
  msgForConnector: string
  salesStage: SalesStage
  prospectEmail?: string
  msgForProspect: string
}

type RelationshipRequestModalProps = {
  prospect: Person
  connector: Connector
  requester: Requester
  isOpen: boolean
  isLoading: boolean
  onClose: () => void
  onCancelClick: () => void
  onRequestClick: (data: RequestModalBodyForm) => void
}

const stages = [
  ['prospect', 'New Prospect'],
  ['active', 'Active Opportunity'],
  ['decision', 'Decision Pending']
]

export const RelationshipRequestModal = ({
  prospect,
  connector,
  requester,
  isOpen,
  isLoading,
  onClose,
  onCancelClick,
  onRequestClick
}: RelationshipRequestModalProps) => {
  const { accountId } = useAuth()
  const { data: account } = useAccount(accountId)

  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue
  } = useForm<RequestModalBodyForm>({
    defaultValues: {
      msgForConnector: writeIntroductionRequest(connector, prospect)
    }
  })

  const [isPreviewing, setIsPreviewing] = useState(false)

  useEffect(() => {
    if (account?.about) {
      setValue('msgForProspect', account.about)
    }
  }, [account, setValue])

  const handleOnRequestClick: SubmitHandler<RequestModalBodyForm> = onRequestClick

  const togglePreview = () => {
    setIsPreviewing(!isPreviewing)
  }

  const handleOnSalesStageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = e.target.value

    let content = getValues('msgForConnector')

    const stagesWithUnkown = [['', 'unknown'], ...stages]
    stagesWithUnkown.forEach(([key]) => {
      content = content.replace(writePointOfSale(prospect, key), writePointOfSale(prospect, selected))
    })

    setValue('msgForConnector', content, { shouldDirty: true })
    setValue('salesStage', selected as SalesStage)
  }

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        {isPreviewing && (
          <>
            <ModalHeader textAlign="center" p={6}>
              <Heading fontSize="2xl" mb={4}>
                Email Preview
              </Heading>
              <Text fontSize="sm" fontWeight="normal">
                The Connector will have the option of forwarding to the target prospect as part of the introduction.
              </Text>
            </ModalHeader>
            <ModalBody>
              <Box p={6} bg="gray.100">
                {!connector.isAnonymous && (
                  <Text fontWeight="bold" pb={6}>
                    A message from {fullname(requester)} at {account.name} (
                    <Link fontWeight="semibold" href={requester.linkedinUrl} isExternal>
                      LinkedIn
                    </Link>
                    ):
                  </Text>
                )}
                <Text pb={6}>{getValues('msgForProspect')}</Text>
                <Text>
                  Powered by{' '}
                  <Link color="blue.400" href="https://smallworld.ai" isExternal>
                    smallworld.ai
                  </Link>
                </Text>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Link fontSize="xs" onClick={togglePreview}>
                &lt; Return to the Introduction Request
              </Link>
            </ModalFooter>
          </>
        )}

        {!isPreviewing && (
          <>
            <ModalHeader textAlign="center" p={6}>
              <Header connector={connector} prospect={prospect} />
            </ModalHeader>
            <ModalBody>
              <Box mb={6}>
                <Box mb={2}>
                  <SectionHeader>Details of your Introduction Request for the Connector</SectionHeader>
                </Box>
                <FormControl isInvalid={!!errors.msgForConnector}>
                  <Textarea
                    variant="label-inside"
                    placeholder="Message for the connector"
                    height={connector.isAnonymous ? 300 : 100}
                    {...register('msgForConnector', {
                      required: true
                    })}
                  />
                  <FormLabel variant="inside">
                    Message{' '}
                    <Tooltip
                      hasArrow={true}
                      placement="top"
                      fontSize="xs"
                      label="We’ve pre-filled a message based on the type of Connector and strength of the relationship. You can customize however you see fit!"
                    >
                      <InfoOutlineIcon mt="-1px" color="gray.300" cursor="pointer" />
                    </Tooltip>
                  </FormLabel>
                </FormControl>
              </Box>
              {!connector.isAnonymous && (
                <>
                  <Flex gap={4} mb={4}>
                    <Box flex="1">
                      <FormControl isInvalid={!!errors.salesStage}>
                        <Select
                          variant="label-inside"
                          placeholder="Choose stage"
                          {...register('salesStage', {
                            onChange: handleOnSalesStageChange,
                            required: true
                          })}
                        >
                          {stages.map(([value, display]) => (
                            <option key={value} value={value}>
                              {display}
                            </option>
                          ))}
                        </Select>
                        <FormLabel variant="inside">
                          Sales Stage{' '}
                          <Tooltip
                            hasArrow={true}
                            placement="top"
                            fontSize="xs"
                            label="Let the Connector know where you are in the sales process for this request."
                          >
                            <InfoOutlineIcon mt="-1px" color="gray.300" cursor="pointer" />
                          </Tooltip>
                        </FormLabel>
                      </FormControl>
                    </Box>
                    <Box flex="1">
                      <FormControl isInvalid={!!errors.prospectEmail}>
                        <Input
                          variant="label-inside"
                          {...register('prospectEmail', {
                            pattern: /^[^@\s]+@[^@\s]+\.[a-zA-Z]{2,6}$/ // the Devise regex
                          })}
                        />
                        <FormLabel variant="inside">
                          Prospect&apos;s Email{' '}
                          <Tooltip
                            hasArrow={true}
                            placement="top"
                            fontSize="xs"
                            label="We will never contact the Prospect on your behalf, but providing the Connector with their email is a helpful way to assure it’s sent to the right address!"
                          >
                            <InfoOutlineIcon mt="-1px" color="gray.300" cursor="pointer" />
                          </Tooltip>
                        </FormLabel>
                      </FormControl>
                    </Box>
                  </Flex>
                  <Box mb={2}>
                    <Flex alignItems="middle" mb={2}>
                      <SectionHeader>Messaging for the Connector to send to the Prospect</SectionHeader>
                    </Flex>
                    <FormControl isInvalid={!!errors.msgForProspect}>
                      <Textarea
                        variant="label-inside"
                        placeholder="Message for the prospect"
                        height={100}
                        {...register('msgForProspect', {
                          required: true
                        })}
                      />
                      <FormLabel variant="inside">
                        Message{' '}
                        <Tooltip
                          hasArrow={true}
                          placement="top"
                          fontSize="xs"
                          label="You can include a message here for the Prospect that the Connector can easily forward as part of your request."
                        >
                          <InfoOutlineIcon mt="-1px" color="gray.300" cursor="pointer" />
                        </Tooltip>
                      </FormLabel>
                    </FormControl>
                  </Box>
                  <Box>
                    <Link fontSize="xs" onClick={togglePreview} whiteSpace="nowrap">
                      Preview Forwardable Email
                      <Tooltip
                        hasArrow={true}
                        placement="top"
                        fontSize="xs"
                        label="When you include a message above, we’ll package everything up nicely into a Forwardable Email the Connector can easily forward to the Prospect."
                      >
                        <InfoOutlineIcon mt="-1px" ml={1} color="gray.300" cursor="pointer" />
                      </Tooltip>
                    </Link>
                  </Box>
                </>
              )}
            </ModalBody>
            <ModalFooter gap="4">
              <Footer
                isLoading={isLoading}
                onCancelClick={onCancelClick}
                onRequestClick={handleSubmit(handleOnRequestClick)}
              />
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
