import { Tooltip, Box, Switch, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useLocation } from 'react-router-dom'
import useDiscoverStore from '~/stores/discover'
import useExpressStore from '~/stores/express'
import { RequesterDashboardApi } from '~/api'

// Define the component
export const BeastModeSwitch = () => {
  const location = useLocation()

  // Component (True) Constant Variables
  const REQ_DASH_PREFIX = 'req_dash_'
  const REQ_DASH_BEAST_MODE_NAME = `${REQ_DASH_PREFIX}beast_mode`

  //region Store Values
  const membership = useExpressStore((state) => state.membership)
  const { isBeastMode, setIsBeastMode, hasTargetCompanies } = useDiscoverStore(
    useShallow((state) => ({
      isBeastMode: state.isBeastMode,
      setIsBeastMode: state.setIsBeastMode,
      hasTargetCompanies: state.hasTargetCompanies
    }))
  )

  //region Query and Mutation Functions
  const saveMemberSettingsRequest = RequesterDashboardApi.useSaveMemberSettingsRequest()
  const getMemberSettingBeastModeRequest = RequesterDashboardApi.useGetMemberSettingRequest(
    isBeastMode === null,
    membership.id,
    REQ_DASH_BEAST_MODE_NAME
  )

  //region Component State
  const [beastModeParamProcessed, setBeastModeParamProcessed] = useState(false)

  // region Use Effects
  // use effect to set is beast mode
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const isBeastModeParam = searchParams.get('isBeastMode')
    if (!beastModeParamProcessed && isBeastModeParam) {
      setIsBeastMode(isBeastModeParam === 'true')
      setBeastModeParamProcessed(true)
    } else if (
      !getMemberSettingBeastModeRequest.isFetching &&
      getMemberSettingBeastModeRequest.data &&
      isBeastMode === null
    ) {
      const filtStringToSet = getMemberSettingBeastModeRequest.data.value
      setIsBeastMode(filtStringToSet === 'true')
    }
  }, [
    getMemberSettingBeastModeRequest.isFetching,
    getMemberSettingBeastModeRequest.data,
    beastModeParamProcessed,
    isBeastMode,
    membership,
    setIsBeastMode,
    location
  ])

  const handleBeastModeChange = () => {
    const updatedBeastMode = !isBeastMode

    // if deep link, won't get here - see function above for details
    setIsBeastMode(updatedBeastMode)
    const idsMemberSettingChange: MemberSetting = {
      organizationMemberId: membership.id,
      value: updatedBeastMode.toString(),
      name: REQ_DASH_BEAST_MODE_NAME
    }

    saveMemberSettingsRequest.mutate(idsMemberSettingChange, {
      onSuccess: () => {},
      onError: (e) => {
        console.error(e)
      }
    })
  }

  return (
    <Flex>
      <Text fontSize={13} lineHeight={4} maxW={80} mt={-0.5} mr={4} textAlign="right" textColor="gray.600">
        Include Non-Target <br />
        Companies
      </Text>
      <Tooltip
        hasArrow={true}
        fontSize="sm"
        label="To disable, please add target companies"
        isDisabled={hasTargetCompanies || (isBeastMode != null && !isBeastMode)}
      >
        <Box>
          <Switch
            size="lg"
            colorScheme="green"
            isChecked={isBeastMode != null && isBeastMode} // Bind isChecked to the state
            onChange={handleBeastModeChange}
            isDisabled={!hasTargetCompanies && isBeastMode != null && isBeastMode}
          />
        </Box>
      </Tooltip>
    </Flex>
  )
}
