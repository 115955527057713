import { useState, useEffect, useRef } from 'react'
import {
  VStack,
  Heading,
  HStack,
  Button,
  Icon,
  Link,
  Text,
  useDisclosure,
  useToast,
  Box,
  Spinner,
  CloseButton,
  Alert,
  AlertIcon,
  AlertDescription,
  Tooltip
} from '@chakra-ui/react'
import { PiTrash, PiPlusCircle } from 'react-icons/pi'
import { TargetCompaniesApi, HttpError } from '~/api'
import useExpressStore from '~/stores/express'
import { DataTable } from './DataTable'
import { BulkUploadModal } from './BulkUploadModal'
import { DeleteAllModal } from './DeleteAllModal'
import { CompanySearchModal } from './CompanySearchModal'
import Header from '~/components/Header'
import Footer from '~/components/Footer'
import useDocumentTitle from '~/hooks/useDocumentTitle'

export const TargetCompanies = () => {
  // region Store
  const membership: Membership = useExpressStore((state) => state.membership)

  // region API requests
  const getTargetCompaniesRequest = TargetCompaniesApi.useGetTargetCompaniesForMember(membership?.id)
  const createTargetCompanyRequest = TargetCompaniesApi.useCreateTargetCompany()
  const deleteTargetCompanyRequest = TargetCompaniesApi.useDeleteTargetCompany()
  const getBulkUploadStatus = TargetCompaniesApi.useGetBulkUploadStatus(membership?.id)
  const deleteAllTargetCompaniesRequest = TargetCompaniesApi.useDeleteAllTargetCompanies()

  const { isOpen: isOpenAdd, onOpen: onOpenAdd, onClose: onCloseAdd } = useDisclosure()
  const { isOpen: isOpenDeleteAll, onOpen: onOpenDeleteAll, onClose: onCloseDeleteAll } = useDisclosure()
  const { isOpen: isOpenUpload, onOpen: onOpenUpload, onClose: onCloseUpload } = useDisclosure()

  // region Page State
  const [targetCompanies, setTargetCompanies] = useState<TargetCompany[]>([])
  const [bulkUploadStatus, setBulkUploadStatus] = useState<BulkUploadStatus>()
  const [isLimitReached, setIsLimitReached] = useState(false)
  const toast = useToast()
  const intervalIdRef = useRef(null)

  const MAX_TARGET_COMPANIES = 500

  useDocumentTitle('Target Companies')

  // region Page Functions
  const handleAdd = (id: number, name: string) =>
    createTargetCompanyRequest.mutate(
      {
        companyId: id,
        organizationMemberId: membership.id
      },
      {
        onSuccess: (data) => {
          const tc: TargetCompany = {
            id: data.id,
            companyId: id,
            companyName: name,
            organizationMemberId: membership.id
          }
          setTargetCompanies(() => [...targetCompanies, tc])
          onCloseAdd()
          toast({
            position: 'top',
            title: 'Success',
            description: `Company was added to your list of Target Companies.`,
            status: 'success',
            duration: 3000,
            isClosable: true
          })
        },
        onError: (error: HttpError) => {
          if (error.statusCode === 409) {
            // Handle status 409 (Conflict) error
            toast({
              position: 'top',
              title: 'Error',
              description: `${name} already exists in your list of Target Companies`,
              status: 'error',
              duration: 3_000,
              isClosable: true
            })
          } else {
            // Handle other errors
            toast({
              position: 'top',
              title: 'An Error Occurred',
              description: 'There was a problem adding the Target Company. Please try again.',
              status: 'error',
              duration: 3_000,
              isClosable: true
            })
          }
          return
        }
      }
    )

  // We have the company and the target company ids mixed up.
  // Need to separate the two.
  // Sending the company id from the company search modal NOT the target company id.
  const handleDelete = (targetCompanyId: number) =>
    deleteTargetCompanyRequest.mutate(targetCompanyId, {
      onSuccess: () => {
        setTargetCompanies(targetCompanies?.filter((tc) => tc.id !== targetCompanyId) || [])
        toast({
          position: 'top',
          title: 'Success',
          description: `Company was removed from your list of Target Companies.`,
          status: 'success',
          duration: 3_000,
          isClosable: true
        })
      },
      onError: () => {
        toast({
          position: 'top',
          title: 'An Error Occurred',
          description: 'There was a problem deleting the Target Company, please try again.',
          status: 'error',
          duration: 3_000,
          isClosable: true
        })
        return
      }
    })

  const handleDeleteAll = () =>
    deleteAllTargetCompaniesRequest.mutate(membership.id, {
      onSuccess: () => {
        setTargetCompanies([])
        toast({
          position: 'top',
          title: 'Success',
          description: `We've removed all of your Target Companies.`,
          status: 'success',
          duration: 3_000,
          isClosable: true
        })
      },
      onError: () => {
        toast({
          position: 'top',
          title: 'An Error Occurred',
          description: 'There was a problem deleting all Target Companies, please try again.',
          status: 'error',
          duration: 3_000,
          isClosable: true
        })
        return
      }
    })

  const handleUpload = () => {
    setTimeout(() => {
      getBulkUploadStatus.refetch()
    }, 1000)
  }

  // region useState
  useEffect(() => {
    if (!getTargetCompaniesRequest.isLoading && getTargetCompaniesRequest.data) {
      setTargetCompanies(getTargetCompaniesRequest.data)
      setIsLimitReached(getTargetCompaniesRequest.data.length >= MAX_TARGET_COMPANIES)
    }
  }, [getTargetCompaniesRequest.isLoading, getTargetCompaniesRequest.data, setTargetCompanies])

  useEffect(() => {
    // TODO: This is a bit of a hack because we don't have the TC ID handy 100% of the time
    // FIX: Either refactor the create to
    getTargetCompaniesRequest.refetch()
  }, [targetCompanies])

  useEffect(() => {
    if (!getBulkUploadStatus.isFetching && getBulkUploadStatus.data) {
      const toastId = 'loading toast'
      const uploadInfo = getBulkUploadStatus.data
      if (uploadInfo.uploadStatus === 'processing' && !toast.isActive(toastId)) {
        toast({
          id: toastId,
          render: () => (
            <Box color="white" bg="blue.400" borderRadius={6}>
              <HStack justifyContent={'right'}>
                <CloseButton
                  size="sm"
                  onClick={() => {
                    toast.close(toastId)
                  }}
                />
              </HStack>
              <HStack px={7} pb={7}>
                <Spinner mr={2} size="md" color="white" thickness="4px" />
                <Text fontWeight={500}>Processing your Target Companies. Please wait…</Text>
              </HStack>
            </Box>
          ),
          duration: null,
          isClosable: true,
          onCloseComplete: () => {
            // Stop fetching upload status when user dismisses
            clearInterval(intervalIdRef.current!)
          }
        })
        intervalIdRef.current = setInterval(() => {
          getBulkUploadStatus.refetch()
        }, 5000)
      } else if (uploadInfo.uploadStatus === 'completed') {
        // Once upload is complete, get their TCs from SWAG
        getTargetCompaniesRequest.refetch()
        clearInterval(intervalIdRef.current!)
        toast.closeAll()
        setBulkUploadStatus(uploadInfo)
      }
    }
  }, [getBulkUploadStatus.isFetching, getBulkUploadStatus.data])
  // End of Check Bulk Upload Status

  // region JSX
  return (
    <>
      <Header />
      <VStack maxW="container.lg" mx="auto" px={{ base: 2, sm: 20, lg: 40 }} mb={8} gap={10}>
        <Heading as="h1" fontWeight="400">
          Target Companies
        </Heading>
        <Text textAlign={'center'}>
          When you add the companies that you're looking to get introductions to, we'll prioritize the Prospects who
          work at this company on your Discover Page.
        </Text>
        <CompanySearchModal isOpen={isOpenAdd} onClose={onCloseAdd} onAdd={handleAdd} onDelete={handleDelete} />
        <DeleteAllModal isOpen={isOpenDeleteAll} onClose={onCloseDeleteAll} onDelete={handleDeleteAll} />
        <BulkUploadModal
          isOpen={isOpenUpload}
          onClose={onCloseUpload}
          memberId={membership?.id}
          onUpload={handleUpload}
          memberTargetCompanyCount={targetCompanies.length}
          maxTargetCompanies={MAX_TARGET_COMPANIES}
        />
        {bulkUploadStatus && (
          <Alert status="success">
            <AlertIcon />
            <AlertDescription>
              Last CSV Upload Result: {bulkUploadStatus.createdTargetCompanies} New Target Companies created out of{' '}
              {bulkUploadStatus.totalTargetCompanies} records uploaded on&nbsp;
              {new Date(bulkUploadStatus.latestUploadDate).toLocaleString(undefined, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
              })}
            </AlertDescription>
          </Alert>
        )}
        <VStack w="full" gap={4}>
          <HStack w="full" justify="space-between">
            <HStack>
              <Tooltip
                label="You have reached the maximum limit of 500 Target Companies."
                isDisabled={!isLimitReached}
                placement="top"
                hasArrow
              >
                <Button
                  w={32}
                  disabled={isLimitReached}
                  size="sm"
                  borderRadius="md"
                  fontWeight="semibold"
                  bg={isLimitReached ? 'gray.400' : 'green.400'}
                  _hover={{ bg: isLimitReached ? 'gray.400' : 'green.500' }}
                  onClick={isLimitReached ? (e) => e.preventDefault() : onOpenAdd}
                  leftIcon={<Icon as={PiPlusCircle} boxSize="16px" />}
                >
                  Add
                </Button>
              </Tooltip>
              <Link
                ml={4}
                fontSize="sm"
                textDecoration="underline"
                fontWeight="semibold"
                color={isLimitReached ? 'gray.400' : 'blue.400'}
                _hover={{ color: isLimitReached ? 'gray.400' : 'green.500' }}
                onClick={isLimitReached ? (e) => e.preventDefault() : onOpenUpload}
              >
                <Tooltip
                  label="You have reached the maximum limit of 500 Target Companies."
                  isDisabled={!isLimitReached}
                  placement="top"
                  hasArrow
                >
                  CSV Upload
                </Tooltip>
              </Link>
            </HStack>
            <Button
              w={32}
              size="sm"
              borderRadius="md"
              fontWeight="semibold"
              bg="red.500"
              _hover={{ bg: 'red.600' }}
              onClick={onOpenDeleteAll}
              leftIcon={<Icon as={PiTrash} boxSize="16px" />}
            >
              Delete All
            </Button>
          </HStack>
          <DataTable data={targetCompanies} onDelete={handleDelete} />
        </VStack>
      </VStack>
      <Footer />
    </>
  )
}
