import { UseMutationOptions, useMutation } from '@tanstack/react-query'
import { API_PATH } from '~/settings'
import { RequestConfig, request } from './utils'

const NAMESPACE = 'invitations'
const BASE_URL = `${import.meta.env.VITE_RAILS_URL}${API_PATH}`

export type InviteRequesterResponse = {
  success: 'Mailer Scheduled'
}

export type InviteRequesterOptions = {
  requesterEmail: string
  accountId: number
  adminPersonId: number
  noteToRequester?: string
}

const inviteRequesterRequest = async (options: InviteRequesterOptions) => {
  const url = new URL(`${BASE_URL}/${NAMESPACE}/invite_requester`)
  const requestConfig: RequestConfig<InviteRequesterOptions> = { body: options }
  return await request<InviteRequesterOptions, InviteRequesterResponse>(url, 'POST', requestConfig)
}

export const useInviteRequesterRequest = (
  options?: UseMutationOptions<InviteRequesterResponse, Error, InviteRequesterOptions>
) => useMutation<InviteRequesterResponse, Error, InviteRequesterOptions>(inviteRequesterRequest, options)
