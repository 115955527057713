import { Box, Text } from '@chakra-ui/react'
import { BaseCard } from './BaseCard'

export const HowIntrosWork = ({ role }) => {
  return (
    <BaseCard p={8}>
      <Text pb={6} fontWeight="bold" fontSize="sm" color="blue.400" textAlign="center">
        How Introductions Work
      </Text>
      <Box fontSize="xs" mb={4}>
        <ul>
          <li>
            <Box mb={4}>
              <Text as="span" fontWeight="bold">
                Trusted Relationship:{' '}
              </Text>
              <Text as="span" mb={8}>
                A SmallWorld trusted relationship is a "strong" relationship you share in common with another Super
                Connector.
              </Text>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Text as="span" fontWeight="bold">
                Anonymous until Accepted:{' '}
              </Text>
              <Text as="span" pb={4}>
                {role === 'Connector' ? (
                  <>
                    Once the introduction request is accepted, your identity is revealed to the Requester (for this
                    introduction request only).
                  </>
                ) : (
                  <>
                    Once the introduction request is accepted, the identity of the other Super Connector will be
                    revealed.
                  </>
                )}
              </Text>
            </Box>
          </li>
          <li>
            <Box mb={4}>
              <Text as="span" fontWeight="bold">
                {role === 'Connector' ? 'Return the Favor:' : 'Grow Your Network:'}{' '}
              </Text>
              <Text as="span">
                {role === 'Connector' ? (
                  <>
                    If you accept and complete the introduction, the Requester will be added to their network for future
                    requests and reciprocation.
                  </>
                ) : (
                  <>
                    If the Super Connector accepts and completes the introduction, you will be added to their network
                    for future requests and reciprocation.
                  </>
                )}
              </Text>
            </Box>
          </li>
          <li>
            <Box>
              <Text as="span" fontWeight="bold">
                {'Status Updates: '}
              </Text>
              <Text as="span">
                You or the other Super Connector can update the status of the introduction as it progresses so the
                request is tracked and credited in SmallWorld.
              </Text>
            </Box>
          </li>
        </ul>
      </Box>
    </BaseCard>
  )
}
