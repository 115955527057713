import { create } from 'zustand'
import { produce } from 'immer'
import { RequesterStub } from '~/api/requesters'
import { AssignedLead } from '~/api'

export type TabName = 'my' | 'unassigned'

type LeadAssignmentListener = {
  relationshipLead: RelationshipLead
  eventHandler: () => void
}

type UnassignedLeadInstantiation = {
  instantiatedLeadId: string
  relationshipId: number
}

function leadAssignmentPublisher() {
  let listeners: LeadAssignmentListener[] = []

  function subscribe(listener: LeadAssignmentListener) {
    listeners = produce(listeners, (draft) => {
      draft.push(listener)
    })
  }

  function unSubscribe(relationshipLead: RelationshipLead) {
    listeners = produce(listeners, (draft) =>
      draft.filter(
        (l) =>
          !(
            l.relationshipLead.prospect.id === relationshipLead.prospect.id &&
            l.relationshipLead.relationship.id === relationshipLead.relationship.id
          )
      )
    )
  }

  function publish(assignedLead: AssignedLead) {
    listeners.forEach((listener) => {
      if (
        listener.relationshipLead.prospect.id === assignedLead.prospectId &&
        listener.relationshipLead.relationship.id === assignedLead.relationshipId
      ) {
        listener.eventHandler()
      }
    })
  }

  return [subscribe, unSubscribe, publish] as const
}

type Store = {
  relationshipLeads: RelationshipLead[]
  unassignedLeads: RelationshipLead[]
  // At the moment AssignedLeads come back from the api in a raw format
  assignedLeads: AssignedLead[]
  accountRequesters: RequesterStub[]
  showingEnterpriseLeads: boolean | null
  isInitialized: boolean
  prospectCompanyCounts: { [companyId: number]: number }
  activeTab: TabName
  initialize: (relationshipLeads: RelationshipLead[], unassignedLeads?: RelationshipLead[]) => void
  setAccountRequesters: (requesters: RequesterStub[]) => void
  changeState: (stateChange: RelationshipLeadStateChange) => void
  instantiateUnassignedLead: (instantiation: UnassignedLeadInstantiation) => void
  subscribeToLeadAssignment: (listener: LeadAssignmentListener) => void
  unsubscribeFromLeadAssignment: (relationshipLead: RelationshipLead) => void
  assignLeads: (assignedLeads: AssignedLead[]) => void
  setShowingEnterpriseLeads: (showing: boolean) => void
  setActiveTab: (tab: TabName) => void
  isAssigned: (relationshipLead: RelationshipLead) => boolean
  isUnassigned: (relationshipLead: RelationshipLead) => boolean
  getNumQueuedLeads: () => { my: number; unassigned: number }
}

function getProspectCompanyCounts(relationshipLeads: RelationshipLead[]) {
  return relationshipLeads.reduce(
    (acc: { [compayId: number]: number }, lead: RelationshipLead) => {
      acc[lead.prospect.company.id] = acc[lead.prospect.company.id] + 1 || 1
      return acc
    },
    {} as { [companyId: number]: number }
  )
}

export const useStore = create<Store>((set, get) => {
  const [subscribeToLeadAssignment, unsubscribeFromLeadAssignment, publishLeadAssignment] = leadAssignmentPublisher()

  return {
    relationshipLeads: [],
    unassignedLeads: [],
    assignedLeads: [],
    accountRequesters: [],
    showingEnterpriseLeads: null,
    isInitialized: false,
    prospectCompanyCounts: {},
    activeTab: 'my',

    initialize: (relationshipLeads, unassignedLeads) => {
      set(() => ({ relationshipLeads: relationshipLeads }))
      set(() => ({ unassignedLeads: unassignedLeads ?? [] }))
      set(() => ({ assignedLeads: [] }))
      set(() => ({ isInitialized: true }))
      set(() => ({ prospectCompanyCounts: getProspectCompanyCounts(unassignedLeads ?? []) }))

      const numQueuedLeads = get().getNumQueuedLeads()
      if (numQueuedLeads.my === 0 && numQueuedLeads.unassigned > 0) {
        set(() => ({ activeTab: 'unassigned' }))
      } else {
        set(() => ({ activeTab: 'my' }))
      }
    },

    getNumQueuedLeads: () => ({
      my: findByState(get().relationshipLeads, 'queued').length,
      unassigned: findByState(get().unassignedLeads, 'queued').length
    }),

    setAccountRequesters: (requesters) => set(() => ({ accountRequesters: requesters })),

    changeState: (stateChange) =>
      set(
        produce((draft: Store) => {
          const lead = draft.relationshipLeads.find((l) => l.id === stateChange.id)
          if (lead) {
            if (!lead.originalState) lead.originalState = lead.state
            lead.state = stateChange.state
            lead.dismissedReason = stateChange.dismissedReason
            lead.dismissedAt = stateChange.dismissedAt
          }
        })
      ),

    instantiateUnassignedLead: (instantiation) => {
      set(
        produce((draft: Store) => {
          const unassignedLead = draft.unassignedLeads.find((l) => l.relationship.id === instantiation.relationshipId)
          if (unassignedLead) {
            unassignedLead.id = instantiation.instantiatedLeadId
          }
        })
      )
    },

    subscribeToLeadAssignment,

    unsubscribeFromLeadAssignment,

    assignLeads: (assignedLeads) => {
      set(
        produce((draft: Store) => {
          draft.assignedLeads.push(...assignedLeads)
        })
      )
      for (const assignedLead of assignedLeads) {
        publishLeadAssignment(assignedLead)
      }
    },

    setShowingEnterpriseLeads: (showing) => set(() => ({ showingEnterpriseLeads: showing, isInitialized: false })),

    setActiveTab: (tab) => set(() => ({ activeTab: tab })),

    isAssigned: (relationshipLead) => {
      return get().assignedLeads.some(
        (assignedLead) =>
          assignedLead.prospectId === relationshipLead.prospect.id &&
          assignedLead.relationshipId === relationshipLead.relationship.id
      )
    },

    isUnassigned: (relationshipLead) => {
      return get().unassignedLeads.some(
        (assignedLead) =>
          assignedLead.prospect.id === relationshipLead.prospect.id &&
          assignedLead.relationship.id === relationshipLead.relationship.id
      )
    }
  }
})

// Allows filter by a state value (e.g., 'queued') or an array of state values (e.g., ['queued', 'saved'])
// You can opt-in to including leads originally in the targeted state but are currently in a different state
export const findByState = (
  leads: RelationshipLead[],
  state: RelationshipLeadStates | RelationshipLeadStates[],
  includeReviewed = false
) =>
  leads.filter((l) =>
    (Array.isArray(state) ? state : [state]).some((t) =>
      (includeReviewed ? [l.state, l.originalState] : [l.state]).includes(t)
    )
  )

// Filter leads by Company.slug
export const findByCompanySlug = (leads: RelationshipLead[], slug: string) =>
  leads.filter((l) => l.prospect.company.slug === slug)

// Group leads by company, then sort by company name
// Structure: [Company.name, Company.slug, Array<RelationshipLead>]
export const groupLeadsByCompany = (leads: RelationshipLead[]): [string, string, RelationshipLead[]][] =>
  Array.from(
    leads.reduce((acc: Map<string, [string, RelationshipLead[]]>, lead: RelationshipLead) => {
      const name = lead.prospect.company.name
      const slug = lead.prospect.company.slug
      const group = acc.get(name) || [slug, []]
      group[1].push(lead)
      acc.set(name, group)
      return acc
    }, new Map<string, [string, RelationshipLead[]]>())
  )
    .map(([name, [slug, leads]]) => [name, slug, leads] as [string, string, RelationshipLead[]])
    .sort((a, b) => a[0].localeCompare(b[0]))
