import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { Location } from 'react-router-dom'

//
// App-wide "global" variables
//

export type ExpressStoreState = {
  countRated: number | null

  membership: Membership | null // "current_affiliation"
  organization: Organization | null // "current_account"
  user: User | null // "current_person"
  personId: number | null // "current_person_model_id"
  accountId: number | null

  reqDashLastProspectPersonIdSeen: number | null
  reqDashTotalProspectsDisplayed: number | null
  previousLocations: (Location | undefined)[]
}

export type ExpressStoreActions = {
  setCountRated: (count: number) => void

  setMembership: (membership: Membership) => void
  setUser: (user: User) => void
  setPersonId: (id: number) => void
  setAccountId: (id: number) => void

  setReqDashLastProspectPersonIdSeen: (lastProspectPersonIdSeen: number) => void
  setReqDashTotalProspectsDisplayed: (reqDashTotalProspectsDisplayed: number) => void
  setPreviousLocations: (currentLocation: Location) => void
}

export type ExpressStore = ExpressStoreState & ExpressStoreActions

const defaults: ExpressStoreState = {
  countRated: null,

  membership: null,
  organization: null,
  user: null,
  personId: null,
  accountId: null,

  reqDashLastProspectPersonIdSeen: null,
  reqDashTotalProspectsDisplayed: null,
  previousLocations: null
}

const useExpressStore = create<ExpressStore>()(
  persist(
    (set) => ({
      ...defaults,
      setCountRated: (count) => set(() => ({ countRated: count })),

      setMembership: (membership) =>
        set(() => ({
          membership: membership,
          organization: membership.organization
        })),
      setUser: (user) => set(() => ({ user: user })),
      setPersonId: (id) => set(() => ({ personId: id })),
      setAccountId: (id) => set(() => ({ accountId: id })),

      setReqDashLastProspectPersonIdSeen: (reqDashLastProspectPersonIdSeen) =>
        set(() => ({ reqDashLastProspectPersonIdSeen: reqDashLastProspectPersonIdSeen })),
      setReqDashTotalProspectsDisplayed: (reqDashTotalProspectsDisplayed) =>
        set(() => ({ reqDashTotalProspectsDisplayed: reqDashTotalProspectsDisplayed })),
      setPreviousLocations: (currentLocation) =>
        set((state) => {
          const updatedLocations = state.previousLocations
            ? [...state.previousLocations]
            : [undefined, undefined, undefined]

          const envMode = import.meta.env.MODE

          // in development, the useEffect gets hit twice for development purposes
          // to test locally with how it will look in prod, comment out the if just below and comment out strict from main.tsx
          if (envMode !== 'development' || updatedLocations[2]?.pathname !== currentLocation.pathname) {
            updatedLocations[0] = updatedLocations[1]
            updatedLocations[1] = updatedLocations[2]
            updatedLocations[2] = currentLocation
          }

          return { previousLocations: updatedLocations }
        })
    }),
    { name: 'express-app-storage' }
  )
)

export default useExpressStore
