import { motion } from 'framer-motion'
import { Box, Flex, Spacer, Text } from '@chakra-ui/react'
import { connectorName, fullname } from '~/lib'
import { BuyingCenters, BuyingCenterFlair, Flair, ThreeDotsIcon } from '@smallworld-io/pangaea'
import { StrengthDisplay } from '../RatingModule/StrengthDisplay'

const prospectMotion = {
  rest: {
    transition: {
      duration: 0.2,
      ease: 'easeInOut'
    }
  },
  hover: {
    transition: {
      duration: 0.4,
      ease: 'easeInOut'
    }
  }
}

const prospectTitleMotion = {
  rest: {
    opacity: 0,
    y: -10,
    height: 0,
    transition: {
      duration: 0.2,
      ease: 'easeInOut'
    }
  },
  hover: {
    opacity: 1,
    y: 0,
    height: 'initial',
    transition: {
      duration: 0.4,
      ease: 'easeInOut'
    }
  }
}

const strengthRatingMotion = {
  rest: {
    opacity: 1,
    height: 'initial',
    y: 0,
    transition: {
      duration: 0.2,
      ease: 'easeInOut'
    }
  },
  hover: {
    opacity: 0,
    height: 0,
    y: -20,
    transition: {
      duration: 0.4,
      ease: 'easeInOut'
    }
  }
}

// See bottom of file for motion to be made
const connectorMotion = {
  rest: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.2,
      ease: 'easeInOut'
    }
  },
  hover: {
    opacity: 1,
    height: 'initial',
    transition: {
      duration: 0.4,
      ease: 'easeInOut'
    }
  }
}

export type RelationshipContentProps = {
  onMore: () => void
  relationshipLead: RelationshipLead
  children?: React.ReactNode
}

export const RelationshipContent = ({ onMore, relationshipLead, children }: RelationshipContentProps) => {
  const { connector, prospect, relationship } = relationshipLead
  const { buyingCenter, company } = prospect

  return (
    <>
      <motion.div initial="rest" whileHover="hover">
        <Flex
          position="relative"
          height={168}
          overflowY="hidden"
          pt={8}
          pb={2}
          pl={0.5}
          pr={0.5}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box position="absolute" top={0} left={0} width="100%" zIndex="1">
            <Flex padding={2}>
              {buyingCenter?.name && <BuyingCenterFlair label={buyingCenter.name as BuyingCenters} />}
              {prospect.jobRole?.level && <Flair color="purple">{`${prospect.jobRole.level}-level`}</Flair>}
              <Spacer />
              <ThreeDotsIcon onClick={onMore} cursor="pointer" color="gray.300" />
            </Flex>
          </Box>

          {/* The Connector Part of the Card */}

          <motion.div variants={prospectMotion}>
            <Box mb={3}>
              <Text fontSize="2xl" color="black" lineHeight={1} textAlign="center">
                {fullname(prospect)}
              </Text>
              <Text fontSize="xs" fontWeight="bold" color="black" textAlign="center">
                {company.name}
              </Text>
              <motion.div variants={prospectTitleMotion}>
                <Text fontSize="xs" color="gray.600" noOfLines={2} lineHeight={1.2} textAlign="center">
                  {prospect?.jobRole?.name}
                </Text>
              </motion.div>
            </Box>
          </motion.div>

          <motion.div variants={strengthRatingMotion}>
            <Flex alignItems="center" justifyContent="center">
              <StrengthDisplay rating={relationship.strength} fontSize="xs" />
            </Flex>
          </motion.div>

          <motion.div variants={connectorMotion}>
            <Flex alignItems="center" justifyContent="center">
              <Box>
                <StrengthDisplay rating={relationship.strength} emojiOnly />
              </Box>

              <Box>
                <Text ml={1} fontSize="xs" fontWeight="bold" color="block">
                  {connectorName(connector)}
                </Text>
                <Text ml={1} fontSize="2xs" color="gray.600" mt={-0.5}>
                  {connector.role !== 'super_connector' ? connector.role : ''}
                </Text>
              </Box>
            </Flex>
          </motion.div>
        </Flex>
        {children}
      </motion.div>
    </>
  )
}
