// src/pages/Settings.tsx
import React from 'react'
import { Box, Container, Link, Text, VStack, HStack, Icon } from '@chakra-ui/react'

import Header from '~/components/Header'
import Footer from '~/components/Footer'

import { FaBolt as CompetitorCompaniesIcon } from 'react-icons/fa'
import { MdAddBusiness as TargetCompaniesIcon } from 'react-icons/md'
import { BiSupport as SupportIcon, BiPlayCircle as PlayIcon } from 'react-icons/bi'
import { TbPassword as PasswordIcon } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'

import { SUPPORT_URL } from '~/settings'

export const Settings = () => {
  const iconColor = '#9DA1A5' // would really like to use gray.300
  const navigate = useNavigate()
  return (
    <>
      <Header />
      <Container maxW="container.lg" minH={300}>
        <Text as="h1" fontSize="3xl" color="black" mb={8}>
          Settings
        </Text>

        <VStack spacing={4} align="stretch" maxW={600}>
          <HStack spacing={8} mb={8} align="center">
            <Box>
              <TargetCompaniesIcon style={{ color: iconColor }} size="32px" />
            </Box>
            <VStack align="start" spacing={0}>
              <Link href={`/target-companies`} color="blue.400">
                <Text fontWeight="bold">Add Target Companies</Text>
              </Link>
              <Text fontSize="sm">
                Adding Target Companies will update your Dashboard to include Prospects that work at these companies.
              </Text>
            </VStack>
          </HStack>

          <HStack spacing={8} mb={8} align="center">
            <Box>
              <CompetitorCompaniesIcon style={{ color: iconColor }} size="32px" />
            </Box>
            <VStack align="start" spacing={0}>
              <Link href={`/competitor-companies`} color="blue.400">
                <Text fontWeight="bold">Add Your Competitors</Text>
              </Link>
              <Text fontSize="sm">
                Adding your Company's Competitors will make sure you don't request introductions, or get introduction
                requests, from anyone working at those companies.
              </Text>
            </VStack>
          </HStack>

          <HStack spacing={8} mb={8} align="center">
            <Box>
              <PasswordIcon style={{ color: iconColor }} size="32px" />
            </Box>
            <VStack align="start" spacing={0}>
              <Link onClick={() => navigate('/password-reset')} color="blue.400">
                <Text fontWeight="bold">Change Your Password</Text>
              </Link>
              <Text fontSize="sm">
                Use the email address associated with your Super Connector account to change your password.
              </Text>
            </VStack>
          </HStack>

          <HStack spacing={8} mb={8} align="center">
            <Box>
              <SupportIcon style={{ color: iconColor }} size="32px" />
            </Box>
            <VStack align="start" spacing={0}>
              <Link href={`${SUPPORT_URL}/category/super-connectors`} color="blue.400" isExternal>
                <Text fontWeight="bold">Support and FAQ</Text>
              </Link>
              <Text fontSize="sm">
                Check out our knowledgebase for support articles and frequently asked questions about Super Connectors™
                from SmallWorld.
              </Text>
            </VStack>
          </HStack>

          <HStack spacing={8} mb={8} align="center">
            <Box>
              <PlayIcon style={{ color: iconColor }} size="32px" />
            </Box>
            <VStack align="start" spacing={0}>
              <Link href="https://www.youtube.com/watch?v=jLkVdnp8KO8" color="blue.400" isExternal>
                <Text fontWeight="bold">Video Tutorial</Text>
              </Link>
              <Text fontSize="sm">
                Watch this 4-minute video on Super Connectors to get a better sense of how to get the most out of it.
              </Text>
            </VStack>
          </HStack>
        </VStack>
      </Container>

      <Footer />
    </>
  )
}

export default Settings
