import { useEffect } from 'react'
import { useNavigate, useOutlet, useLocation } from 'react-router-dom'
import { useAuth } from '~/auth'
import { Center } from '@chakra-ui/react'
import { LoadingSpinner } from '~/components'
import useExpressStore from '~/stores/express'
import { useShallow } from 'zustand/react/shallow'
import { RateRelationshipsApi } from '~/api'

export const QualitySuperConnector = () => {
  const navigate = useNavigate()
  const outlet = useOutlet()
  const location = useLocation()

  const auth = useAuth()

  const countRated = useExpressStore((state) => state.countRated)

  const ratedCountRequest = RateRelationshipsApi.useRatedCountRelationshipsRequest(auth.personId, countRated == null)

  const { setCountRated } = useExpressStore(
    useShallow((state) => ({
      setCountRated: state.setCountRated
    }))
  )

  useEffect(() => {
    if (countRated == null) {
      ratedCountRequest.refetch()
    }
  }, [countRated])

  useEffect(() => {
    if (!ratedCountRequest.isLoading && ratedCountRequest.data != null) {
      const countFromDb = ratedCountRequest.data.count
      setCountRated(countFromDb)
    }
  }, [ratedCountRequest.data, setCountRated])

  useEffect(() => {
    if (countRated != null && countRated < 100) {
      navigate('/rate-relationships')
    } else {
      const pathPlusSearch = location.pathname + location.search
      navigate(pathPlusSearch)
    }
  }, [countRated])

  if (countRated == null && ratedCountRequest.isLoading) {
    return (
      <Center minH="100vh">
        <LoadingSpinner />
      </Center>
    )
  }

  return outlet
}
