import {
  Text,
  Button,
  Modal,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  HStack
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

type QueueCompleteModalProps = {
  isOpen: boolean
  onClose: () => void
  isEnterprise: boolean
}

export const QueueCompleteModal = ({ isOpen, onClose, isEnterprise }: QueueCompleteModalProps) => {
  const navigate = useNavigate()

  const destination = isEnterprise ? `${import.meta.env.VITE_RAILS_URL}/v2/dashboard` : '/discover'

  return (
    <>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minW={600}>
          <ModalHeader>
            <Text as="h1" fontSize="xx-large" textAlign="center">
              Nice Job!
            </Text>
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody pb={6}>
            <Text textAlign="center">
              You’ve cleared out your new Relationship Leads. Browse your Saved Relationships Leads, or uncover more by
              requesting strength from Connectors!
            </Text>
          </ModalBody>

          <ModalFooter>
            <HStack width="100%" justifyContent="center" spacing={4}>
              <Button
                onClick={() => navigate('/saved-leads')}
                flex="1"
                bgColor="yellow.400"
                _hover={{ bgColor: 'yellow.400' }}
                _active={{ bgColor: 'yellow.400' }}
                _focus={{ bgColor: 'yellow.400', boxShadow: 'none' }}
              >
                My Saved Leads
              </Button>
              <Button
                as="a"
                href={destination}
                bgColor="blue.400"
                flex="1"
                _hover={{ bgColor: 'blue.400' }}
                _active={{ bgColor: 'blue.400' }}
                _focus={{ bgColor: 'blue.400', boxShadow: 'none' }}
              >
                Discover Prospects
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
