import { fullname } from './fullname'

export const writeIntroductionRequest = (connector: Connector, prospect: Person) => {
  return [writeTheAsk(connector, prospect), writePointOfSale(prospect), writeThanks()].join('') // no space join, must add a space to end of sentences.
}

export const writeThanks = () => {
  return (
    'Thank you, and let me know if there are any additional details ' +
    'or context I can share about this request. I hope to reciprocate ' +
    'depending on your target accounts and interests.'
  )
}

export const writeTheAsk = (connector: Connector, prospect: Person) => {
  const { firstName: connectorFirstName, isAnonymous } = connector
  const { firstName: prospectFirstName, lastName: prospectLastName } = prospect

  const opener = isAnonymous ? 'Given' : `${connectorFirstName}, given`

  return (
    `${opener} your relationship with${' '}` +
    `${prospectFirstName} ${prospectLastName}, I was hoping you${' '}` +
    `could introduce me as${' '}`
  )
}

export const writePointOfSale = (prospect: Person, pos: string | null = '') => {
  const {
    company: { name: companyName },
    firstName
  } = prospect

  let content = ''

  switch (pos) {
    case 'prospect':
      content = `we’d love to connect with the team at ${companyName} for an introductory call.${' '}`
      break
    case 'active':
      content = `we’re currently working on an active opportunity at ${companyName} and connecting with ${firstName} could be super helpful in our sales process.${' '}`
      break
    case 'decision':
      content = `we have a decision pending at ${companyName} and connecting with ${firstName} could be super helpful in our sales process.${' '}`
      break
    default:
      content = `connecting with ${firstName} could be super helpful.${' '}`
      break
  }

  return content
}

export const writeBody = (connection: { [key: string]: string | number }, prospect: Person) => {
  const degree = parseInt(String(connection['degree_num']), 10)
  if (isNaN(degree) || degree === 1) return writeFirstDegreeBody(prospect)
  return writeSecondDegreeBody(connection, prospect)
}

export const writeFirstDegreeBody = (prospect: Person) => {
  return (
    `I see we are already connected on LinkedIn and now here in SmallWorld!${' '}` +
    `I was hoping you might help me with an introduction to ${fullname(prospect)} at ${prospect.companyName}.${' '}` +
    '[***Include additional context here. See the TIPS section to the right for ideas***]\n'.trim()
  )
}

export const writeSecondDegreeBody = (connection: { [key: string]: string | number }, prospect: Person) => {
  const friend = connection['trusted_friend_name']
  const firstName = String(friend).split(/\s+/)[0]

  if (Number(connection['total_trusted_friends']) > 1) {
    return (
      `I see we share multiple trusted relationships.${' '}` +
      `I was hoping you might help me with an introduction to ${fullname(prospect)} at ${prospect.companyName}.${' '}` +
      '[***Include additional context here. See the TIPS section to the right for ideas***]\n'.trim()
    )
  }

  return (
    `I see we share a relationship with ${friend}.${' '}` +
    `I know ${firstName} from${' '}[*** Insert how you know ${firstName} here ***]${' '}` +
    `and would love to hear how you know ${firstName} as well.\n\n` +
    `I was hoping you might help me with an introduction to ${fullname(prospect)} at ${prospect.companyName}.${' '}` +
    '[***Include additional context here. See the TIPS section to the right for ideas***]\n'.trim()
  )
}

export const writeGreeting = () => {
  return 'Hello --'
}

export const writeComplimentaryClose = (user: User) => {
  return `Regards, \n` + writeSignature(user)
}

export const writeSignature = (user: User) => {
  return `${fullname(user)} `
}

export const writeIntroductionRequestDetails = ({
  user,
  connection,
  prospect
}: {
  user: User
  connection: { [key: string]: string | number }
  prospect: Person
}) => {
  return [writeGreeting(), writeBody(connection, prospect), writeThanks(), writeComplimentaryClose(user)].join('\n\n')
}
