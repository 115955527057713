//region imports
import { useEffect, useState } from 'react'
import { Flex, Skeleton } from '@chakra-ui/react'
import { TargetCompaniesFilter } from './TargetCompaniesFilter'
import { BuyingCentersFilter } from './BuyingCentersFilter'
import { LevelsFilter } from './LevelsFilter'
import { StrengthsFilter } from './StrengthsFilter'
import { ConnectorsFilter } from './ConnectorsFilter'
import { BeastModeSwitch } from './BeastModeSwitch'
import useDiscoverStore from '~/stores/discover'
import { useShallow } from 'zustand/react/shallow'

export const FilterMenus = () => {
  const [filterPillRequestsComplete, setFilterPillRequestsComplete] = useState(false)
  const { relationshipStrengths, levels, buyingCenters, excludedConnectors } = useDiscoverStore(
    useShallow((state) => ({
      relationshipStrengths: state.relationshipStrengths,
      levels: state.levels,
      buyingCenters: state.buyingCenters,
      excludedConnectors: state.excludedConnectors
    }))
  )

  useEffect(() => {
    setFilterPillRequestsComplete(
      buyingCenters !== null && excludedConnectors !== null && relationshipStrengths !== null && levels !== null
    )
  }, [buyingCenters, excludedConnectors, relationshipStrengths, levels])

  return (
    <>
      {/* Filter pill menus below */}
      <Flex alignItems="center" justifyContent="space-between" mb={4}>
        {!filterPillRequestsComplete && (
          // Filter Skeletons
          <Flex alignItems="center">
            <Skeleton
              mr={2}
              height="30px"
              startColor="gray.200"
              endColor="gray.100"
              width="167px"
              borderRadius={'full'}
            />
            <Skeleton
              mr={2}
              height="30px"
              startColor="gray.200"
              endColor="gray.100"
              width="152px"
              borderRadius={'full'}
            />
            <Skeleton
              mr={2}
              height="30px"
              startColor="gray.200"
              endColor="gray.100"
              width="152px"
              borderRadius={'full'}
            />

            <Skeleton
              mr={2}
              height="30px"
              startColor="gray.200"
              endColor="gray.100"
              width="167px"
              borderRadius={'full'}
            />
          </Flex>
        )}
        <Flex alignItems="center" className="pendo-filters" display={filterPillRequestsComplete ? 'flex' : 'none'}>
          <TargetCompaniesFilter />
          <BuyingCentersFilter />
          <LevelsFilter />
          <StrengthsFilter />
          <ConnectorsFilter />
        </Flex>
        <BeastModeSwitch />
      </Flex>
    </>
  )
}
