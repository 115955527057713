import { IconButton } from '@chakra-ui/react'
import { PiTrashThin } from 'react-icons/pi'

export const DeleteIcon = ({ onOpen, companyName }) => (
  <IconButton
    aria-label="Delete item"
    icon={<PiTrashThin size="1.25em" />}
    onClick={() => onOpen(companyName)}
    size="sm"
    variant="ghost"
    w={8}
    borderRadius="lg"
  />
)
