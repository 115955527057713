import { Image } from '@chakra-ui/react'
import logo from '~/assets/smallworld-logo.svg'

type SmallWorldLogoProps = {
  size: number
  onClick?: () => void
  onLoad?: () => void
  isDisabled?: boolean
}

export const SmallWorldLogo = (props: SmallWorldLogoProps) => {
  const handleClick = () => {
    if (props.onClick && !props.isDisabled) props.onClick()
  }

  const handleLoad = () => {
    if (props.onLoad) props.onLoad()
  }

  return (
    <Image
      src={logo}
      alt="Logo"
      maxW={props.size}
      cursor={props.isDisabled ? 'default' : 'pointer'}
      onClick={handleClick}
      onLoad={handleLoad}
    />
  )
}
