import { useEffect, useState, useRef } from 'react'
import {
  Box,
  Button,
  Container,
  HStack,
  Text,
  InputGroup,
  Input,
  InputRightElement,
  ListItem,
  List
} from '@chakra-ui/react'
import { useAuth } from '~/auth'
import useDocumentTitle from '~/hooks/useDocumentTitle'
import { ProspectCard } from '~/components/ProspectCard/ProspectCard'
import useExpressStore from '~/stores/express'
import Header from '~/components/Header'
import Footer from '~/components/Footer'
import { SearchIcon } from '@chakra-ui/icons'
import { useSearchParams } from 'react-router-dom'
import { useGetCompanySearchResults } from '~/api/companies'
import { useGetSearchProspectsRequest } from '~/api/search'

const company = 'company'
const companyId = 'companyId'

const findCompanyIdInSuggestions = (name: string, suggestions: CompanySearchResult[]) => {
  const suggestion = suggestions.find(({ companyName }) => {
    return companyName.toLowerCase() === name.toLowerCase()
  })

  if (!suggestion) return ''
  return String(suggestion.companyId)
}

export const ProspectSearch = () => {
  useDocumentTitle('Search Prospects – SmallWorld')

  // region constants
  const auth = useAuth()
  const membership = useExpressStore((state) => state.membership)
  const listRef = useRef(null)
  const [searchParams] = useSearchParams()
  const [companyQuery, setCompanyQuery] = useState(searchParams.get(company) || '')
  const [completeSearchEnabled, setCompleteSearchEnabled] = useState<boolean>(false)
  const [companySuggestions, setCompanySuggestions] = useState<CompanySearchResult[]>([])
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null)
  const [personNameQuery, setPersonNameQuery] = useState('')
  const [prospectSearchReturnedZeroRows, setProspectSearchReturnedZeroRows] = useState<boolean>(false)
  const [prospectsToDisplay, setProspectsToDisplay] = useState<Prospect[]>([])
  const [selectedCompanyId, setSelectedCompanyId] = useState(searchParams.get(companyId) || '')
  const [showSuggestions, setShowSuggestions] = useState<boolean>(!searchParams.get(company))

  let paramCompanyId = ''
  const companySearchResults = useGetCompanySearchResults(!!searchParams.get(company) || showSuggestions, companyQuery)
  const prospectSearchResults = useGetSearchProspectsRequest(
    paramCompanyId !== '' || completeSearchEnabled,
    personNameQuery,
    paramCompanyId || selectedCompanyId,
    auth.personId,
    auth.organizationId,
    membership.id
  )

  if (searchParams.has(company)) {
    paramCompanyId = findCompanyIdInSuggestions(searchParams.get(company), companySuggestions)
  }

  if (searchParams.has(companyId)) {
    const id = parseInt(searchParams.get(companyId), 10)
    if (!isNaN(id)) paramCompanyId = String(id)
  }
  // region useEffects
  // listens to company query changes, and company search results and sets company suggestions
  useEffect(() => {
    if (companyQuery.length > 1) {
      if (!companySearchResults.isLoading && companySearchResults.data) {
        setCompanySuggestions(companySearchResults.data)
      }
    } else {
      setCompanySuggestions([])
    }
  }, [companyQuery, companySearchResults.isLoading, companySearchResults.data, setCompanySuggestions])

  useEffect(() => {
    if (
      !prospectSearchResults.isLoading &&
      !prospectSearchResults.isFetching &&
      Boolean(prospectSearchResults?.data?.prospects)
    ) {
      const countPros = prospectSearchResults.data.prospects.length

      setProspectSearchReturnedZeroRows(countPros === 0)
      setProspectsToDisplay(prospectSearchResults.data.prospects)
      setCompleteSearchEnabled(false)
    }
  }, [prospectSearchResults.isLoading, prospectSearchResults.data?.prospects, setProspectsToDisplay])

  useEffect(() => {
    if (listRef.current && highlightedIndex !== null) {
      const listItem = listRef.current.querySelector(`[aria-selected="true"]`)
      if (listItem) {
        listItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    }
  }, [highlightedIndex])

  // region Misc Functions
  const searchProspects = () => {
    const enabled = personNameQuery.length > 1 || selectedCompanyId.length > 0

    setCompleteSearchEnabled(enabled)

    if (enabled) {
      if (selectedCompanyId.length == 0) {
        setCompanyQuery('')
      }

      setCompanySuggestions([])
      setCompleteSearchEnabled(true)

      prospectSearchResults.refetch()
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSuggestions(true)
    setCompanyQuery(event.target.value)
    setHighlightedIndex(null)
  }

  const handleNameInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPersonNameQuery(event.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLLIElement>) => {
    // Handle an Enter Keystroke
    if (event.key === 'Enter' && highlightedIndex !== null) {
      handleCompanySelect(companySuggestions[highlightedIndex])
    } else if (event.key === 'Enter' && companySuggestions.length > 0) {
      // Determine if the company name that was entered by the user is in the list of companyNames in suggestions and use the company id that corresponds to that name; if not default to the first in the list
      // TODO: Make this right
      const companyId = findCompanyIdInSuggestions(companyQuery, companySuggestions)
      if (companyId.length > 0) {
        setSelectedCompanyId(companyId)
      } else {
        setSelectedCompanyId(String(companySuggestions[0].companyId))
      }
      searchProspects()
    }

    // Handle arrow navigation
    if ((event.key === 'ArrowUp' || event.key === 'ArrowDown') && highlightedIndex === null) {
      setHighlightedIndex(0)
    } else if (event.key === 'ArrowDown' && highlightedIndex < companySuggestions.length - 1) {
      setHighlightedIndex(highlightedIndex + 1)
    } else if (event.key === 'ArrowUp' && highlightedIndex > 0) {
      setHighlightedIndex(highlightedIndex - 1)
    }
  }

  const handleCompanySelect = (companySearchRecord: CompanySearchResult) => {
    setSelectedCompanyId(companySearchRecord.companyId.toString())
    setCompanyQuery(companySearchRecord.companyName)
    // this also disables the query
    setShowSuggestions(false)
    setCompanyQuery(companySearchRecord.companyName)
    searchProspects()
  }
  // region JSX
  return (
    <>
      <Header />
      <Container maxW="container.lg" minH={350}>
        <Text as="h1" fontSize="3xl" color="black" mb={2}>
          Search
        </Text>

        <Text mb={4}>Search for prospects name, or company name.</Text>

        <InputGroup maxW={600}>
          <Input
            type="text"
            placeholder="Name"
            aria-label="Name"
            borderColor="gray.300"
            focusBorderColor="blue.400"
            bg="white"
            borderRadius="md"
            mb={4}
            p={6}
            onChange={handleNameInputChange}
            value={personNameQuery}
            data-hj-allow
          />
        </InputGroup>

        <InputGroup maxW={600}>
          <Input
            type="text"
            placeholder="Search Companies"
            aria-label="Search Companies"
            borderColor="gray.300"
            focusBorderColor="blue.400"
            bg="white"
            p={6}
            borderRadius="md"
            onChange={handleInputChange}
            value={companyQuery}
            onKeyDown={handleKeyDown}
            data-hj-allow
          />
          <InputRightElement mt={1.5} mr={2} children={<SearchIcon color="gray.400" />} />
        </InputGroup>

        <div style={{ position: 'relative' }}>
          {showSuggestions && companySuggestions.length > 0 && (
            <Box
              position="absolute"
              zIndex="dropdown"
              borderRadius="md"
              bg="white"
              maxW={600}
              mt={1}
              border="1px solid"
              borderColor="gray.200"
              width="full"
            >
              <List maxH="300px" overflowY="auto" ref={listRef}>
                {companySuggestions.map((csr, index) => (
                  <ListItem
                    px={4}
                    py={4}
                    key={`${csr.companyId}-${index}`}
                    _hover={{ fontWeight: 'medium', bg: 'gray.100' }}
                    onClick={() => handleCompanySelect(csr)}
                    _focus={{ bg: 'gray.200' }}
                    onKeyDown={(event) => handleKeyDown(event)}
                    tabIndex={0}
                    aria-selected={highlightedIndex === index}
                    borderRadius={
                      index === 0 ? 'lg top' : index === companySuggestions.length - 1 ? 'md bottom' : 'none'
                    }
                    bg={highlightedIndex === index ? 'gray.200' : 'transparent'}
                  >
                    <HStack justify="space-between">
                      <Text>{csr.companyName}</Text>
                    </HStack>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </div>

        <Button bg="green.400" my={4} size="md" maxW="300" onClick={() => searchProspects()}>
          Search
        </Button>

        {/* prospects to display */}
        <Box maxW={900}>
          {prospectsToDisplay.map((prospect: Prospect, index: number) => (
            <div key={prospect.prospectPersonId}>
              <ProspectCard prospect={prospect} index={index} onHide={() => {}} />
            </div>
          ))}

          {prospectSearchReturnedZeroRows && (
            <Box
              width="100%"
              textAlign="center"
              borderStyle="dashed"
              borderWidth="2px"
              borderColor="gray.200"
              background="gray.100"
              color="gray.600"
              p={4}
              borderRadius="md"
              alignItems="center"
            >
              <Text fontWeight="bold">Your search returned no results. Try again.</Text>
            </Box>
          )}
        </Box>
      </Container>

      <Footer />
    </>
  )
}
