import React, { useState } from 'react'
import {
  useToast,
  Button,
  VStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import { TargetCompaniesApi } from '~/api'
import { useAuth } from '~/auth'

type BulkUploadProps = {
  memberId: number
  memberTargetCompanyCount: number
  maxTargetCompanies: number
  isOpen?: boolean
  onClose?: () => void
  onOpen?: () => void
  onUpload?: () => void
}

export const BulkUploadModal = ({ 
  memberId,
  memberTargetCompanyCount,
  maxTargetCompanies,
  isOpen,
  onClose,
  onUpload
}: BulkUploadProps) => {
  const auth = useAuth()
  const [companyNames, setCompanyNames] = useState<string>('')
  const createTargetCompaniesRequest = TargetCompaniesApi.useCreateBulkTargetCompanies()
  const toast = useToast()
  // region Page Functions
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return

    const reader = new FileReader()
    reader.readAsText(file)

    reader.onload = () => {
      const csv = reader.result as string
      const rows = csv.split('\n').map((row) => row.trim())
      const numColumns = rows[0].split(',').length

      if (numColumns !== 1) {
        toast({
          position: 'top',
          title: 'An Error Occurred',
          description: 'The CSV you provided contains more than one column.',
          status: 'error',
          duration: 5000,
          isClosable: true
        })
        return
      }

      let companyNamesArray = rows
        .filter((row) => row !== '' && /[a-zA-Z0-9]/.test(row))
        .map((row) =>
          row
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/\[\\\]/gi, ' ')
            .replace(/\s+/g, ' ')
            .trim()
        )
      if (companyNamesArray.length === 0) {
        companyNamesArray = null
        toast({
          position: 'top',
          title: 'An Error Occurred',
          description: 'There was a problem reading the company names in the file provided.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {
            e.target.value = ''
          }
        })
        return
      } else if (companyNamesArray.length + memberTargetCompanyCount > maxTargetCompanies) {
        companyNamesArray = null
        toast({
          position: 'top',
          title: 'An Error Occurred',
          description: 'You can only carry up to 500 target companies at a time.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {
            e.target.value = ''
          }
        })
        return
      }

      const processedCompanyNames = companyNamesArray.join(',')
      setCompanyNames(processedCompanyNames)
    }

    reader.onerror = () => {
      toast({
        position: 'top',
        title: 'An Error Occurred',
        description: 'There was a problem reading the file provided.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {
          e.target.value = ''
        }
      })
    }
  }

  const handleUpload = () => {
    createTargetCompaniesRequest.mutate(
      {
        companyNames: companyNames,
        memberId: memberId,
        personId: auth.personId
      },
      {
        onSuccess: () => {}
      }
    )
    onClose()
    onUpload()
  }
  // region JSX
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Upload Target Companies CSV</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Select a .csv file containing a single column of company names you wish to identify as your Target Companies. Please ensure the .csv is title-free and includes a maximum of 500 Target Companies.
        </ModalBody>
        <ModalFooter justifyContent="left" pb={8}>
          <VStack spacing={4} alignItems="center">
            <input type="file" accept=".csv" onChange={handleFileChange} />
            {companyNames && (
              <Button colorScheme="blue" onClick={handleUpload} mt={2}>
                Upload
              </Button>
            )}
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
