// src/pages/MyNetwork.tsx
import React from 'react'
import { Box, Container, Link, Text } from '@chakra-ui/react'
import { Header, Footer, RatingModule } from '~/components'

export const MyNetwork = () => {
  return (
    <>
      <Header />
      <Container maxW="container.lg">
        <Text as="h1" fontSize="3xl" color="black" mb={4}>
          My Network
        </Text>

        <RatingModule onboarding={false} />
      </Container>

      <Footer />
    </>
  )
}

export default MyNetwork
