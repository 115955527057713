import { Center, Code, Container, Flex } from '@chakra-ui/react'

interface Props {
  accountId: number
  currentOrganization: Organization
  personId: number
  user: User
}

export default function RelationshipLeadsDevDebug(props: Props) {
  const { accountId, currentOrganization, personId, user } = props

  if (!import.meta.env.DEV) return null

  return (
    <Container maxW="800px" mb={8}>
      <Flex fontSize={'small'} fontFamily={'monospace'} alignItems={'baseline'} justify={'space-around'}>
        <Center>
          <Code colorScheme="purple">Person</Code>: {personId}
        </Center>
        <Center>
          <Code colorScheme="purple">Account</Code>: {accountId || 'X'}
        </Center>
        <Center>
          <Code colorScheme="purple">User</Code>: {user?.id || 'X'}
        </Center>
        <Center>
          <Code colorScheme="purple">Organization</Code>: {currentOrganization?.id || 'X'}
        </Center>
      </Flex>
    </Container>
  )
}
