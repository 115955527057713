import { useQuery } from '@tanstack/react-query'
import { request } from './utils/request'
import { BASE_URL } from '~/settings'

import { useShallow } from 'zustand/react/shallow'
import useExpressStore from '~/stores/express'
import { useAuth } from '~/auth'
import { findMembershipBySetting, findMembershipWithOwnerRole, findSettingWithCurrentMembership } from '~/lib'

type IamMeResponse = {
  data: {
    user: User
    memberships: Membership[]
  }
}

const extractWorkingMembership = (memberships: Membership[] = [], orgId: number): Membership => {
  return memberships.find((membership) => membership.organization.id === orgId)
}

const url = new URL(`${BASE_URL}/iam/me`)
const fetchLoggedInUser = (): Promise<IamMeResponse> => request(url, 'GET')

export const useLoggedInUserQuery = () => {
  const { accountId, organizationId, personId, userId } = useAuth()

  // console.log(`u:${userId} / p:${personId} / o:${organizationId} / a:${accountId}`) // paou!!

  const { setMembership, setUser, setPersonId, setAccountId } = useExpressStore(
    useShallow((state) => ({
      setMembership: state.setMembership,
      setUser: state.setUser,
      setPersonId: state.setPersonId,
      setAccountId: state.setAccountId
    }))
  )

  return useQuery({
    queryKey: ['ME', userId],
    queryFn: fetchLoggedInUser,
    enabled: !!userId,
    cacheTime: Infinity,
    staleTime: Infinity,
    placeholderData: {
      data: {
        user: undefined,
        memberships: [] as Membership[]
      }
    },
    select: ({ data }) => {
      // Nice, this is even called with placeholderData! Well done tanstack
      const { user, memberships } = data

      if (!user) {
        return {
          ...data,
          ...{
            currentMembership: null,
            ownedMembership: null,
            currentOrganization: null,
            accountId,
            personId
          }
        }
      }

      const workingMembership = extractWorkingMembership(memberships, organizationId)
      setUser(user)
      setMembership(workingMembership)
      setPersonId(user.personId)
      setAccountId(accountId)

      const { settings } = user
      const setting = findSettingWithCurrentMembership(settings)

      const ownedMembership = memberships.find(findMembershipWithOwnerRole)
      const currentMembership = setting != undefined ? findMembershipBySetting(setting, memberships) : ownedMembership

      return {
        ...data,
        ...{
          currentMembership,
          ownedMembership,
          currentOrganization: currentMembership.organization,
          accountId,
          personId
        }
      }
    }
  })
}

const fetchUserForPerson = (personId: number) => {
  const url = new URL(`${BASE_URL}/iam/users/for_person/${personId}`)
  return request<null, { [key: string]: any }>(url)
}

export const useUserForPersonQuery = (personId: number) => {
  return useQuery({
    queryKey: ['users', 'person', personId],
    queryFn: () => fetchUserForPerson(personId),
    enabled: !!personId
  })
}

type PartialUserWithPerson = Pick<User, 'id'> & {
  person: Pick<Person, 'id'>
}

type UserByIdsResponse = {
  users: PartialUserWithPerson[]
}

type SomeIds = {
  ids: number[]
  personIds: number[]
}

type UserByIdsRequest = {
  user: Partial<SomeIds>
}

export const fetchUsersByIds = (personIds: number[]) => {
  const url = new URL(`${BASE_URL}/iam/users/translate_ids`)
  return request<UserByIdsRequest, UserByIdsResponse>(url, 'POST', {
    body: {
      user: {
        ids: personIds
      }
    }
  })
}

export const useUsersByIdsQuery = (personIds: number[] = []) => {
  return useQuery({
    queryKey: ['users', 'persons', personIds.join(',')],
    queryFn: () => fetchUsersByIds(personIds),
    enabled: personIds.length > 0,
    select: (data: UserByIdsResponse) => {
      return data.users
    }
  })
}
