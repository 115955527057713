import { Button, Flex, Text } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

import { RequestIntroIcon } from '@smallworld-io/pangaea'

export type RequestIntroductionContentProps = {
  introUrl?: string
}

export const RequestIntroductionContent = ({ introUrl }: RequestIntroductionContentProps) => {
  const openIntroduction = () => {
    if (introUrl) window.open(introUrl, '_blank')
  }

  return (
    <>
      <Flex direction="column" flex="2" justify="center" align="center" p={2} height="100%" position="relative">
        <Flex direction="column" flex="2" justifyContent="center" alignItems="center">
          <Text fontWeight="bold" fontSize="xs" align="center" mb={1}>
            <RequestIntroIcon color="blue.400" boxSize={5} mr={1} mb={1} fill="blue.400" fillOpacity={0.22} />
            Intro was Requested
          </Text>
          <Button
            fontSize="xs"
            bg="blue.400"
            color="white"
            fontWeight="bold"
            rightIcon={<ExternalLinkIcon />}
            onClick={openIntroduction}
          >
            View Intro Request
          </Button>
        </Flex>
      </Flex>
    </>
  )
}
