import { ChakraComponent, IconProps, createIcon } from '@chakra-ui/react'

const LinkedInIcon: ChakraComponent<'svg', IconProps> = createIcon({
  displayName: 'LinkedInIcon',
  viewBox: '0 0 48 48',
  path: (
    <path
      d="M40.82 4.00028H6.94C6.55916 3.99499 6.181 4.06477 5.82713 4.20565C5.47326 4.34652 5.15061 4.55572 4.87759 4.8213C4.60458 5.08689 4.38656 5.40365 4.23598 5.7535C4.0854 6.10335 4.00522 6.47944 4 6.86028V41.1403C4.00522 41.5211 4.0854 41.8972 4.23598 42.2471C4.38656 42.5969 4.60458 42.9137 4.87759 43.1793C5.15061 43.4448 5.47326 43.654 5.82713 43.7949C6.181 43.9358 6.55916 44.0056 6.94 44.0003H40.82C41.2008 44.0056 41.579 43.9358 41.9329 43.7949C42.2867 43.654 42.6094 43.4448 42.8824 43.1793C43.1554 42.9137 43.3734 42.5969 43.524 42.2471C43.6746 41.8972 43.7548 41.5211 43.76 41.1403V6.86028C43.7548 6.47944 43.6746 6.10335 43.524 5.7535C43.3734 5.40365 43.1554 5.08689 42.8824 4.8213C42.6094 4.55572 42.2867 4.34652 41.9329 4.20565C41.579 4.06477 41.2008 3.99499 40.82 4.00028V4.00028ZM16.06 37.4803H10.06V19.4803H16.06V37.4803ZM13.06 16.9603C12.2325 16.9603 11.4389 16.6316 10.8538 16.0465C10.2687 15.4613 9.94 14.6678 9.94 13.8403C9.94 13.0128 10.2687 12.2192 10.8538 11.6341C11.4389 11.049 12.2325 10.7203 13.06 10.7203C13.4994 10.6704 13.9444 10.714 14.3658 10.848C14.7871 10.9821 15.1755 11.2037 15.5053 11.4982C15.8351 11.7927 16.0991 12.1536 16.2797 12.5572C16.4604 12.9609 16.5538 13.3981 16.5538 13.8403C16.5538 14.2825 16.4604 14.7197 16.2797 15.1233C16.0991 15.5269 15.8351 15.8878 15.5053 16.1824C15.1755 16.4769 14.7871 16.6985 14.3658 16.8325C13.9444 16.9666 13.4994 17.0101 13.06 16.9603V16.9603ZM37.7 37.4803H31.7V27.8203C31.7 25.4003 30.84 23.8203 28.66 23.8203C27.9853 23.8252 27.3284 24.0368 26.7777 24.4266C26.227 24.8164 25.809 25.3656 25.58 26.0003C25.4235 26.4704 25.3557 26.9654 25.38 27.4603V37.4603H19.38C19.38 37.4603 19.38 21.1003 19.38 19.4603H25.38V22.0003C25.9251 21.0545 26.7178 20.2753 27.6729 19.7467C28.6279 19.218 29.7091 18.96 30.8 19.0003C34.8 19.0003 37.7 21.5803 37.7 27.1203V37.4803Z"
      fill="#0E76A8"
    />
  )
})

export default LinkedInIcon
