import { Badge, Button, Flex, Text } from '@chakra-ui/react'
import { RelationshipLeadStore } from '~/stores'
import { TabName } from '~/stores/relationshipLeads'

type TabProps = {
  label: string
  count: number
  tabName: TabName
}

export const RelationshipLeadsTabs = () => {
  const leadsStore = RelationshipLeadStore.useStore()

  const myLeadsLength = RelationshipLeadStore.findByState(leadsStore.relationshipLeads, 'queued').length
  const unassignedLeadsLength = RelationshipLeadStore.findByState(leadsStore.unassignedLeads, 'queued').length

  if (unassignedLeadsLength < 1) return null

  function Tab(props: TabProps) {
    const isActiveTab = leadsStore.activeTab === props.tabName
    const bgColor = isActiveTab ? 'white' : '#F0F1F3'
    return (
      <Button
        size="sm"
        px={5}
        borderRadius="full"
        width="min-content"
        bgColor={bgColor}
        textColor="gray.600"
        textTransform={'none'}
        fontWeight={isActiveTab ? '700' : '500'}
        disabled={isActiveTab}
        _hover={isActiveTab ? {} : undefined}
        cursor={isActiveTab ? 'default' : undefined}
        boxShadow={isActiveTab ? '0 .25rem 1rem rgba(0,0,0,.12)' : 'none'}
        onClick={() => leadsStore.setActiveTab(props.tabName)}
      >
        <Flex gap={1}>
          <Text>{props.label}</Text>
          {props.count > 0 && (
            <Badge
              borderRadius="full"
              fontSize="2xs"
              bgColor="red.400"
              textAlign="center"
              textColor="white"
              display="flex"
              alignItems="center"
            >
              {props.count > 99 ? '99+' : props.count}
            </Badge>
          )}
        </Flex>
      </Button>
    )
  }

  return (
    <Flex justifyContent="center" gap={4}>
      <Tab label="My Relationship Leads" count={myLeadsLength} tabName="my" />
      <Tab label="Unassigned Leads" count={unassignedLeadsLength} tabName="unassigned" />
    </Flex>
  )
}
