import { useMutation, useQuery, UseQueryOptions, useQueryClient } from '@tanstack/react-query'
import { request } from './utils'
import { API_PATH_SWAG, BASE_URL, SWAG_URL } from '~/settings'

const NAMESPACE = 'introductions'

const makeKey = (...keys: any) => [...['swag', 'intros'], ...keys]

type ListIntroductionsResponse = {
  introductions: Introduction[]
  people: { [key: number]: Person }
}

export type StatusFilter = 'active' | 'successful' | 'unfulfilled' | 'all'

type IntroductionsQueryFilters = {
  personId: number
  membership: Membership
  status?: StatusFilter
}

export const useIntroductionsQuery = ({ personId, membership, status = 'all' }: IntroductionsQueryFilters) => {
  const searchParams = new URLSearchParams({ person: `${personId}`, membership: `${membership?.id}`, status })
  const url = new URL(`${API_PATH_SWAG}/introductions?${searchParams}`, SWAG_URL)

  return useQuery({
    queryKey: makeKey('person', personId, 'membership', membership?.id, status),
    queryFn: () => {
      return request<null, ListIntroductionsResponse>(url)
    },
    select: (data) => {
      return data.introductions.map((intro) => {
        // associate people data into the introduction itself
        return {
          ...intro,
          requester: data.people[intro.requesterPersonId],
          connector: data.people[intro.connectorPersonId],
          prospect: data.people[intro.prospectPersonId]
        }
      })
    },
    enabled: !!personId && !!membership?.id
  })
}

const fetchIntroduction = async (id: number, personId: number, membershipId: number) => {
  const url = new URL(
    `${API_PATH_SWAG}/introductions/${id}/for-person/${personId}?membership=${membershipId}`,
    SWAG_URL
  )
  return request<null, { [key: string]: any }>(url)
}

export const useIntroductionForPersonQuery = (id: number, personId: number, membership: Membership) => {
  return useQuery({
    queryKey: makeKey(id),
    queryFn: () => {
      try {
        return fetchIntroduction(id, personId, membership.id)
      } catch (x) {
        throw x
      }
    },
    select: (data) => {
      const { introduction } = data
      return {
        ...introduction,
        requester: data.people[introduction.requesterPersonId],
        connector: data.people[introduction.connectorPersonId],
        prospect: data.people[introduction.prospectPersonId]
      }
    },
    onError: (err) => {
      console.error(err)
    },
    enabled: !!id,
    retry: false
  })
}

export type IntroductionRequest = {
  requesterId: number
  requesterMembershipId: number
  connectorMembershipId: number
  companyId: number
  relationshipId: number
  prospectEmail: string | null
  details: string | null
}

export type IntroductionRequestResponse = {
  introduction: { [key: string]: string | number | null }
}

const createIntroductionRequestUrl = new URL(`${API_PATH_SWAG}/${NAMESPACE}/request`, SWAG_URL)

export const createIntroductionRequest = (introRequest: IntroductionRequest) => {
  return request<IntroductionRequest, IntroductionRequestResponse>(createIntroductionRequestUrl, 'POST', {
    body: introRequest
  })
}

export const useRequestIntroduction = () => {
  return useMutation({
    mutationFn: createIntroductionRequest
  })
}

export type IntroductionRequestFromUnassignedLeadParams = {
  relationshipId: number
  requesterId: number
  accountId: number
  assignedById: number
  msgForProspect: string
  msgForConnector: string
  prospectEmail?: string
}

export type IntroductionRequestFromUnassignedLeadResponse = {
  introduction: Introduction
}

export const createIntroductionRequestFromUnassignedLead = (params: IntroductionRequestFromUnassignedLeadParams) => {
  const url = new URL(`${BASE_URL}/${NAMESPACE}/by_admin`)
  return request<IntroductionRequestFromUnassignedLeadParams, IntroductionRequestFromUnassignedLeadResponse>(
    url,
    'POST',
    { body: params }
  )
}

export const useCreateIntroductionRequestFromUnassignedLead = () => {
  return useMutation({
    mutationFn: createIntroductionRequestFromUnassignedLead
  })
}

type CreateMessageRequest = {
  userId: number
  body: string
}

type CreateMessageResponse = {
  message: Message
}

export const useCreateIntroductionMessageMutation = (introduction: Introduction) => {
  const url = new URL(`${API_PATH_SWAG}/introductions/${introduction.id}/messages`, SWAG_URL)

  return useMutation({
    mutationFn: ({ body, userId }: { body: string; userId: number }) => {
      return request<CreateMessageRequest, CreateMessageResponse>(url, 'POST', {
        body: { body, userId }
      })
    }
  })
}

type GetMessagesResponse = {
  messages: Message[]
}

export const useGetIntroductionMessagesQuery = (introduction: Introduction) => {
  const url = new URL(`${API_PATH_SWAG}/introductions/${introduction.id}/messages`, SWAG_URL)

  return useQuery({
    queryKey: ['swag', 'introductions', introduction.id, 'messages'],
    queryFn: () => request<null, GetMessagesResponse>(url, 'GET'),
    select: ({ messages }) => {
      return messages.sort((a, b) => {
        return new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
      })
    },
    enabled: !!introduction.id
  })
}

type AcceptIntroductionRequest = {
  membershipId: number
  personId: number
}

export const useAcceptIntroductionMutation = (introduction: Introduction) => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: ({ membership, personId }: { membership: Membership; personId: number }) => {
      const url = new URL(`${API_PATH_SWAG}/introductions/${introduction.id}/accept`, SWAG_URL)
      return request<AcceptIntroductionRequest>(url, 'PATCH', {
        body: {
          membershipId: membership.id,
          personId
        }
      })
    },
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: makeKey(introduction.id)
      })
    }
  })
}

type DeclineIntroductionRequest = {
  membershipId: number
  personId: number
  declineReason: string
}

type DeclineIntroductionResponse = { [key: string]: any }

export const useDeclineIntroductionMutation = (introduction: Introduction) => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: ({
      membership,
      personId,
      declineReason
    }: {
      membership: Membership
      personId: number
      declineReason: string
    }) => {
      const url = new URL(`${API_PATH_SWAG}/introductions/${introduction.id}/connector-decline`, SWAG_URL)
      return request<DeclineIntroductionRequest, DeclineIntroductionResponse>(url, 'PATCH', {
        body: {
          membershipId: membership.id,
          declineReason,
          personId
        }
      })
    },
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: makeKey(introduction.id)
      })
    }
  })
}

type CompleteIntroductionRequest = {
  membershipId: number
  personId: number
  note: string
}

type CompleteIntroductionResponse = { [key: string]: any }

export const useCompleteIntroductionMutation = (introduction: Introduction) => {
  const qc = useQueryClient()

  type MakeKnownToBody = {
    user: number
    organization: number
  }

  const makeRequesterKnownToConnector = (requesterUserId: number, connectorOrgId: number) => {
    const url = new URL(`${BASE_URL}/iam/super_connectors/make_known_to`)

    const body: MakeKnownToBody = {
      user: requesterUserId,
      organization: connectorOrgId
    }

    return request<MakeKnownToBody>(url, 'POST', { body })
  }

  const fetchConnectorOwnedOrg = (userId: number) => {
    const url = new URL(`${BASE_URL}/iam/organizations/owned_by_user/${userId}`)
    return request<null, Organization>(url)
  }

  // TODO: Reuse existing
  const fetchMembership = async (id: number) => {
    const url = new URL(`${BASE_URL}/iam/memberships/${id}`)
    return request<null, Membership>(url)
  }

  const addRequesterToConnectorOrg = async (intro: Introduction) => {
    const requesterMembership = await fetchMembership(intro.requesterMembershipId)
    const connectorMembership = await fetchMembership(intro.connectorMembershipId)
    const connectorOrganization = await fetchConnectorOwnedOrg(connectorMembership['user_id'])

    // Gaaagggghhhhh, rails!!
    return makeRequesterKnownToConnector(requesterMembership['user_id'], connectorOrganization.id)
  }

  return useMutation({
    mutationFn: ({ membership, personId, note }: { membership: Membership; personId: number; note: string }) => {
      const url = new URL(`${API_PATH_SWAG}/introductions/${introduction.id}/complete`, SWAG_URL)
      return request<CompleteIntroductionRequest, CompleteIntroductionResponse>(url, 'PATCH', {
        body: {
          membershipId: membership.id,
          personId,
          note
        }
      })
    },
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: makeKey(introduction.id)
      })

      addRequesterToConnectorOrg(introduction)
        .then((response) => {
          console.dir(response)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  })
}

type CancelIntroductionRequest = {
  membershipId: number
  introId: number
}

export const useCancelIntroductionMutation = (data: CancelIntroductionRequest) => {
  const qc = useQueryClient()

  return useMutation({
    mutationFn: () => {
      const url = new URL(`${API_PATH_SWAG}/introductions/${data.introId}/cancel`, SWAG_URL)
      return request<{ [key: string]: number }>(url, 'PATCH', {
        body: {
          membershipId: data.membershipId
        }
      })
    },
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: makeKey(data.introId)
      })
    }
  })
}

export const getIntroductionsForPersonRequest = async (personId: number): Promise<Introductions> => {
  const url = new URL(`${API_PATH_SWAG}/introductions?person=${personId}`, SWAG_URL)
  const response = await request<null, Introductions>(url, 'GET')
  return response
}

export const useGetIntroductionsForPersonRequest = (
  personId: number,
  options?: UseQueryOptions<Introductions, Error>
) => {
  return useQuery<Introductions, Error>(
    ['useGetIntroductionsForPersonRequest'],
    () => getIntroductionsForPersonRequest(personId),
    {
      ...options
    }
  )
}

export type ConnectorsResponse = {
  anonymous: number[] | null
  named: number[] | null
}

const getConnectorsWithRatings = (organizationId: number) => {
  const url = new URL(`${API_PATH_SWAG}/relationships/get-connectors-rated/${organizationId}`, SWAG_URL)
  return request<null, ConnectorsResponse>(url)
}

export const useMembershipConnectorsQuery = (organizationId: number) => {
  return useQuery({
    queryKey: ['iam', 'organization', organizationId, 'named-and-anon-connectors'],
    queryFn: () => getConnectorsWithRatings(organizationId),
    select: (data) => {
      data.anonymous ||= []
      data.named ||= []
      return data
    },
    enabled: !!organizationId,
    retry: 2
  })
}

export const useConnectorsForProspectQuery = ({
  prospectId,
  requesterPersonId,
  organizationId
}: {
  prospectId: number
  requesterPersonId: number
  organizationId: number
}) => {
  const { data, isSuccess } = useQuery({
    queryKey: ['iam', 'organization', organizationId, 'named-and-anon-connectors'],
    queryFn: () => getConnectorsWithRatings(organizationId),
    select: (data) => {
      data.anonymous ||= []
      data.named ||= []
      return data
    },
    enabled: !!prospectId && !!requesterPersonId && !!organizationId,
    retry: 2
  })

  return useQuery({
    queryKey: ['swag', 'connectors-for', requesterPersonId, prospectId],
    queryFn: () => {
      const url = new URL(`${API_PATH_SWAG}/introductions/find-connectors-for/${prospectId}`, SWAG_URL)

      return request(url, 'POST', {
        body: {
          requesterPersonId,
          anonConnectorIds: data.anonymous || [],
          knownConnectorIds: data.named || [],
          buyingCenterIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13], // <-- get from link click
          strengths: ['Very Weak', 'Weak', 'Average', 'Strong', 'Very Strong'] // <-- get from link click?
        }
      })
    },
    select: ({ data }) => {
      return data
    },
    enabled: isSuccess,
    retry: 4
  })
}

//
// -- Classic --
//

export type ClassicIntroductionRequest = {
  accountId: number
  requesterId: number
  relationshipId: number
  msgForConnector: string
  msgForProspect: string
  prospectEmail: string
  salesStage: SalesStage
}

export type ClassicIntroductionResponse = {
  id: number
  url: string
}

export type ClassicIntroductionError = {
  errors: Record<string, string>
}

const createClassicIntroductionRequestUrl = new URL(`${BASE_URL}/${NAMESPACE}`)
export const createClassicIntroductionRequest = (introRequest: ClassicIntroductionRequest) => {
  return request<ClassicIntroductionRequest, ClassicIntroductionResponse | ClassicIntroductionError>(
    createClassicIntroductionRequestUrl,
    'POST',
    {
      body: introRequest
    }
  )
}
