import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast
} from '@chakra-ui/react'
import { useState } from 'react'
import { useInviteRequesterRequest } from '~/api/invitations'
import { getEmojiPlusText } from '~/lib'

type Props = {
  isOpen: boolean
  onClose: () => void
  onSuccess: (invitationEmail: string) => void
  prospectCompanyName: string
  accountId: number
  adminPersonId: number
  relationshipStrength: string
}

export const InviteRequesterModal = (props: Props) => {
  const initialNote = [
    `Hello, I noticed a ${getEmojiPlusText(
      props.relationshipStrength
    )} Relationship that one of our Connectors has at ${
      props.prospectCompanyName
    }, an account you might be interested in. I wanted to invite you to SmallWorld to potentially explore this opportunity and others.`,
    '',
    `A 'Relationship Lead' in SmallWorld refers to someone with a Strong or Very Strong Relationship to one of your Target Companies. When you join and include ${props.prospectCompanyName} as one of your target companies, you'll immediately start receiving such leads the next day.`
  ].join('\n')

  const [emailAddress, setEmailAddress] = useState('')
  const [note, setNote] = useState(initialNote)

  const toast = useToast()
  const inviteRequesterRequest = useInviteRequesterRequest()

  function sendInvite() {
    inviteRequesterRequest.mutate(
      {
        requesterEmail: emailAddress,
        accountId: props.accountId,
        adminPersonId: props.adminPersonId,
        noteToRequester: note
      },
      {
        onSuccess: () => {
          props.onSuccess(emailAddress)
          props.onClose()
        },
        onError: () => {
          toast({
            title: `An error ocurred while sending the invitation to ${emailAddress}. Please try again later.`,
            status: 'error',
            duration: 2000
          })
        }
      }
    )
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="2xl" fontWeight={400} textAlign={'center'}>
          Invite a Requester to SmallWorld
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex px={4} direction="column" gap={6}>
            <FormControl>
              <FormLabel fontWeight="bold">Enter an Email</FormLabel>
              <Input
                type="email"
                size="sm"
                placeholder="email@address.com"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel fontWeight="bold">Include a Note</FormLabel>
              <Textarea h={44} resize="vertical" size="sm" value={note} onChange={(e) => setNote(e.target.value)} />
            </FormControl>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button fontSize="sm" colorScheme="blue" onClick={sendInvite}>
            Invite
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
