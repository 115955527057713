import { Box, Flex, SlideFade, SimpleGrid, Text } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLoggedInUserQuery, useUsersByIdsQuery } from '~/api'
import { useOrganizationQuery } from '~/api/organizations'
import { RelationshipCard } from '~/components'
import { UnassignedRelationshipCard } from '~/components/RelationshipCard/UnassignedRelationshipCard'
import { RelationshipLeadStore } from '~/stores'

const INFINITE_SCROLL_BATCH_SIZE = 30

interface Props {
  firstCardRef: React.MutableRefObject<HTMLDivElement>
}

export default function RelationshipLeadsCardGrid(props: Props) {
  const [infiniteScrollCutoff, setInfiniteScrollCutoff] = useState(INFINITE_SCROLL_BATCH_SIZE)

  const leadsStore = RelationshipLeadStore.useStore((state) => state)

  const userQuery = useLoggedInUserQuery()
  const { currentMembership, currentOrganization } = userQuery.data
  const orgQuery = useOrganizationQuery(currentOrganization?.id)
  const connectorUsersWithPersonIdsQuery = useUsersByIdsQuery(orgQuery.data?.memberships?.map((m) => m.userId))

  useEffect(() => setInfiniteScrollCutoff(INFINITE_SCROLL_BATCH_SIZE), [leadsStore.activeTab])

  const findConnectorMembership = useCallback(
    (relationshipLead: RelationshipLead) => {
      const connectorUser = connectorUsersWithPersonIdsQuery.data.find((user) => {
        if (!user.person) return undefined // No person, what?!
        return user.person.id === relationshipLead.connector.id
      })

      if (!connectorUser) return undefined

      return orgQuery.data.memberships.find((membership) => {
        return membership.userId === connectorUser.id
      })
    },
    [orgQuery.data, connectorUsersWithPersonIdsQuery.data]
  )

  const allActiveLeads = leadsStore.activeTab === 'my' ? leadsStore.relationshipLeads : leadsStore.unassignedLeads
  const allDisplayedLeads = RelationshipLeadStore.findByState(allActiveLeads, 'queued', true)

  function nextBatch() {
    setInfiniteScrollCutoff((prev) => prev + INFINITE_SCROLL_BATCH_SIZE)
  }

  const noLeadsMessaging =
    leadsStore.activeTab === 'my' ? 'You have no Relationship Leads' : 'There are no unassigned Relationship Leads'
  const displayedLeadsCount = allDisplayedLeads.length

  if (displayedLeadsCount === 0) {
    return (
      <Flex width="full" height="200px" alignItems="center" justifyContent="center" border="3px dashed #E1E5E9">
        <Text textAlign="center" fontSize="md">
          {noLeadsMessaging}.
        </Text>
      </Flex>
    )
  }

  return (
    <SlideFade offsetY="1000px" in={allActiveLeads.length > 0}>
      <InfiniteScroll
        dataLength={infiniteScrollCutoff}
        next={nextBatch}
        hasMore={allDisplayedLeads.length > infiniteScrollCutoff}
        loader={<h4>Loading...</h4>}
        hasChildren
        // CR: It took me an hour to figure out that overflow:auto messes with
        // box shadows in the descendent cards, and I still have no idea why.
        style={{ overflow: 'unset' }}
      >
        <SimpleGrid columns={{ base: 1, md: 3, lg: 3 }} spacing={4}>
          {allDisplayedLeads
            .slice(0, infiniteScrollCutoff)
            .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime())
            .map((relationshipLead: RelationshipLead, index: number) => {
              // FIXME: Don't need this for enterprise...
              const { connector } = relationshipLead
              const connectorMembership =
                connector.isAnonymous === true ? findConnectorMembership(relationshipLead) : null

              return (
                <Box key={relationshipLead.relationship.id} ref={index === 0 ? props.firstCardRef : null}>
                  {leadsStore.activeTab === 'unassigned' ? (
                    <UnassignedRelationshipCard
                      relationshipLead={relationshipLead}
                      requesterMembership={currentMembership}
                      connectorMembership={connectorMembership}
                    />
                  ) : (
                    <RelationshipCard
                      relationshipLead={relationshipLead}
                      requesterMembership={currentMembership}
                      connectorMembership={connectorMembership}
                    />
                  )}
                </Box>
              )
            })}
        </SimpleGrid>
      </InfiniteScroll>
    </SlideFade>
  )
}
