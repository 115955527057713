interface IRelationshipStrength {
  emoji: string
  textValue: string
  article: string
  databaseValue: Strengths
  sortValue: number
  description: string
}

const allStrengths: Record<Strengths, IRelationshipStrength> = {
  'Very Weak': {
    emoji: '❓',
    databaseValue: 'Very Weak',
    textValue: 'No Relationship',
    article: '',
    sortValue: 1,
    description: 'Do not know the person or do not have any current relationship. Introduction is highly unlikely.'
  },
  Weak: {
    emoji: '👎',
    databaseValue: 'Weak',
    textValue: 'Weak',
    article: 'a',
    sortValue: 2,
    description: 'Know the person and they know me, but little or no communication. Introduction is less likely.'
  },
  Average: {
    emoji: '🤝',
    databaseValue: 'Average',
    textValue: 'Average',
    article: 'an',
    sortValue: 3,
    description:
      'Acquaintance in good standing where communication is less frequent, but relationship very intact. Introduction is likely or possible.'
  },
  Strong: {
    emoji: '💪',
    databaseValue: 'Strong',
    textValue: 'Strong',
    article: 'a',
    sortValue: 4,
    description:
      'Communicate with consistently at least once a quarter, have common friends or colleagues. Introduction very likely.'
  },
  'Very Strong': {
    emoji: '🔥',
    databaseValue: 'Very Strong',
    textValue: 'Very Strong',
    article: 'a',
    sortValue: 5,
    description: 'Communicate frequently, a close friend or colleague. Introduction is almost assured.'
  }
}

export default class RelationshipStrength {
  private constructor(private strength: IRelationshipStrength) {}

  public get databaseValue() {
    return this.strength.databaseValue
  }

  public get emoji() {
    return this.strength.emoji
  }

  public get textValue() {
    return this.strength.textValue
  }

  public get descriptor() {
    return this.textValue.toLowerCase().endsWith('relationship') ? this.textValue : this.textValue + ' Relationship'
  }

  public get fullPhraseWithArticle() {
    const pieces = [
      this.article,
      this.emoji,
      this.textValue.replace(/ relationship$/i, '') + ' Relationship' // remove duplicate "relationship"
    ]
    return pieces.filter((piece) => piece !== undefined && piece !== null && piece !== '').join(' ')
  }

  public get emojiPlusText() {
    return this.emoji + ' ' + this.textValue
  }

  public get article() {
    return this.strength.article
  }

  public get proseDescription() {
    return this.strength.description
  }

  public compare(other: RelationshipStrength) {
    return this.strength.sortValue - other.strength.sortValue
  }

  public equals(other: RelationshipStrength | null | undefined) {
    if (other === null || other === undefined) {
      return false
    }

    return this.compare(other) === 0
  }

  public static fromDatabaseValue(databaseValue: Strengths) {
    return new RelationshipStrength(allStrengths[databaseValue])
  }
}

const relationshipStrengths = Object.values(allStrengths).map((strength) =>
  RelationshipStrength.fromDatabaseValue(strength.databaseValue)
)
relationshipStrengths.sort((a, b) => a.compare(b))

export { relationshipStrengths }
