import { useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query'
import { request, RequestConfig } from './utils'
import { API_PATH } from '~/settings'
import { isEnterpriseUser } from '~/lib/userHelpers'

const NAMESPACE = 'relationship_leads'
const BASE_URL = `${import.meta.env.VITE_RAILS_URL}${API_PATH}`

// FETCH LEADS

export type LeadsResponse = {
  relationshipLeads: RelationshipLead[]
  unassignedLeads?: RelationshipLead[]
}

export const enterpriseLeadsRequest = async (requesterId: number, accountId: number) => {
  const url = new URL(`${BASE_URL}/${NAMESPACE}?requesterId=${requesterId}&accountId=${accountId}`)
  return await request<null, LeadsResponse>(url, 'GET')
}

const useEnterpriseLeadsRequest = (personId: number, accountId: number) => {
  return useQuery({
    queryKey: ['leads', 'enterprise', `${personId}`, `${accountId}`],
    queryFn: () => enterpriseLeadsRequest(personId, accountId),
    enabled: !!personId && !!accountId
  })
}

export const superConnectorLeadsRequest = async (requesterId: number, membership: Membership) => {
  const { organization } = membership
  const url = new URL(`${BASE_URL}/${NAMESPACE}?requesterId=${requesterId}&organizationId=${organization.id}`)
  return await request<null, LeadsResponse>(url, 'GET')
}

const useSuperConnectorLeadsRequest = (personId: number, membership: Membership) => {
  return useQuery({
    queryKey: ['lead', 'superconnector', `${personId}`, `${membership?.id}`],
    queryFn: () => superConnectorLeadsRequest(personId, membership),
    enabled: !!personId && !!membership
  })
}

export type LeadRequestOptions = {
  membership: Membership
  accountId: number
}

export const useLeadsRequest = (personIdMakingRequest: number, opts: Partial<LeadRequestOptions>) => {
  const { accountId, membership } = opts

  if ((!membership && accountId) || isEnterpriseUser(membership)) {
    return useEnterpriseLeadsRequest(personIdMakingRequest, accountId)
  }

  return useSuperConnectorLeadsRequest(personIdMakingRequest, membership)
}

// Assign Lead
type InstantiateUnassignedLeadRequestOptions = {
  requesterId: number
  accountId: number
  relationshipId: number
  assignedById: number
  state: RelationshipLeadStates
}

export type AssignedLead = {
  id: string
  adminAssigned: boolean
  assignedById: number
  requesterId: number
  requesterFirstName: string
  requesterLastName: string
  relationshipId: number
  prospectId: number
  state: RelationshipLeadStates
}

export type InstantiateUnassignedLeadResponse = {
  data: {
    relationshipLead: AssignedLead
  }
}

const instantiateUnassignedLeadRequest = (options: InstantiateUnassignedLeadRequestOptions) => {
  const url = new URL(`${BASE_URL}/${NAMESPACE}`)
  const requestConfig: RequestConfig<InstantiateUnassignedLeadRequestOptions> = { body: options }
  return request<InstantiateUnassignedLeadRequestOptions, InstantiateUnassignedLeadResponse>(url, 'POST', requestConfig)
}

export type AssignLeadOptions = Omit<InstantiateUnassignedLeadRequestOptions, 'state'>

export const useAssignLeadRequest = () =>
  useMutation((options: AssignLeadOptions) => instantiateUnassignedLeadRequest({ ...options, state: 'queued' }))

// Save Unassigned Lead (same endpoint as assign, but with different state and lacking assignedById)
export type SaveUnassignedLeadOptions = Omit<InstantiateUnassignedLeadRequestOptions, 'state'>

export const useSaveUnassignedLeadRequest = () =>
  useMutation((options: SaveUnassignedLeadOptions) => instantiateUnassignedLeadRequest({ ...options, state: 'saved' }))

// Dismiss Unassigned Lead
type DismissUnassignedLeadRequestOptions = {
  relationshipId: number
  accountId: number
  state: RelationshipLeadStates
}

export type DismissUnassignedLeadOptions = Omit<DismissUnassignedLeadRequestOptions, 'state'>

type DismissUnassignedLeadResponse = {
  success?: string
  err: string
}

const dismissUnassignedLeadRequest = (options: DismissUnassignedLeadRequestOptions) => {
  const url = new URL(`${BASE_URL}/${NAMESPACE}/admin_dismiss`)
  const requestConfig: RequestConfig<DismissUnassignedLeadRequestOptions> = { body: options }
  return request<DismissUnassignedLeadRequestOptions, DismissUnassignedLeadResponse>(url, 'PATCH', requestConfig)
}

export const useDismissUnassignedLeadRequest = () =>
  useMutation((options: DismissUnassignedLeadOptions) =>
    dismissUnassignedLeadRequest({ ...options, state: 'admin_dismissed' })
  )

// Assign All Leads for a Prospect Company
export type AssignAllLeadsForCompanyOptions = {
  requesterId: number
  accountId: number
  prospectCompanyId: number
  assignedById: number
}

type AssignAllLeadsResponseItem = {
  relationshipLead: AssignedLead
  // requester: RequesterStub
}

export type AssignAllLeadsForCompanyResponse = {
  data: {
    relationshipLeads: AssignAllLeadsResponseItem[]
  }
}

const assignAllLeadsForCompanyRequest = (options: AssignAllLeadsForCompanyOptions) => {
  const url = new URL(`${BASE_URL}/${NAMESPACE}/assign_all_for_company`)
  const requestConfig: RequestConfig<AssignAllLeadsForCompanyOptions> = { body: options }
  return request<AssignAllLeadsForCompanyOptions, AssignAllLeadsForCompanyResponse>(url, 'POST', requestConfig)
}

export const useAssignAllLeadsForCompanyRequest = (
  options?: UseMutationOptions<AssignAllLeadsForCompanyResponse, Error, AssignAllLeadsForCompanyOptions>
) =>
  useMutation<AssignAllLeadsForCompanyResponse, Error, AssignAllLeadsForCompanyOptions>(
    assignAllLeadsForCompanyRequest,
    options
  )

// STATE CHANGE

export const stateChangeRequest = async (stateChange: RelationshipLeadStateChange): Promise<void> => {
  const url = new URL(`${BASE_URL}/${NAMESPACE}/${stateChange.id}`)
  const options: RequestConfig<RelationshipLeadStateChange> = { body: stateChange }
  const response = await request<RelationshipLeadStateChange, void>(url, 'PATCH', options)
  return response
}

export const useStateChangeRequest = (options?: UseMutationOptions<void, Error, RelationshipLeadStateChange>) =>
  useMutation<void, Error, RelationshipLeadStateChange>(stateChangeRequest, options)
