import { useEffect, useState } from 'react'
import { Flex, useToast } from '@chakra-ui/react'
import { useMachine } from '@xstate/react'
import { RelationshipRequestModal, RequestModalBodyForm } from '~/components'
import { useAuth } from '~/auth'
import {
  RelationshipLeadsApi,
  AssignLeadOptions,
  AssignAllLeadsForCompanyOptions,
  SaveUnassignedLeadOptions
} from '~/api'
import { RelationshipLeadStore } from '~/stores'
import { LinksContent } from './LinksContent'
import { RelationshipContent } from './RelationshipContent'
import { RequestIntroductionContent } from './RequestIntroductionContent'
import { SaveRelationshipContent } from './SaveRelationshipContent'
import { HideRelationshipContent } from './HideRelationshipContent'
import { AssignContent } from './AssignContent'
import { LeadAssignedContent } from './LeadAssignedContent'
import { RelationshipCardButtons } from './RelationshipCardButtons'
import { IntroductionRequestSubmission, buildRelationshipCardStateMachine } from './relationshipCardState'
import {
  IntroductionRequestFromUnassignedLeadParams,
  createIntroductionRequestFromUnassignedLead
} from '~/api/introductions'
import { InvitedContent } from './InvitedContent'

const makeIntroRequest = (formdata: IntroductionRequestSubmission) => {
  const { accountId, personId, relationshipId, form } = formdata

  const requestParams: IntroductionRequestFromUnassignedLeadParams = {
    relationshipId,
    requesterId: personId,
    accountId,
    assignedById: personId,
    msgForProspect: form.msgForProspect,
    msgForConnector: form.msgForConnector,
    prospectEmail: form.prospectEmail
  }

  return createIntroductionRequestFromUnassignedLead(requestParams)
}

const relationshipCardStateMachine = buildRelationshipCardStateMachine(makeIntroRequest)

type RelationshipCardProps = {
  relationshipLead: RelationshipLead
  requesterMembership?: Membership
  connectorMembership?: Membership
  disableDisplaySave?: boolean
  onRequestIntro?: () => void
}

export const UnassignedRelationshipCard = ({
  relationshipLead,
  requesterMembership,
  connectorMembership,
  disableDisplaySave = false
}: RelationshipCardProps) => {
  const { id, requester, connector, prospect, relationship } = relationshipLead
  const { accountId, personId } = useAuth()

  const [currentState, setCurrentState] = useState<RelationshipLeadStates>(relationshipLead.state)
  const [initialState, setInitialState] = useState<RelationshipLeadStates>(relationshipLead.state)
  const [invitationEmail, setInvitationEmail] = useState('')

  const stateChangeRequest = RelationshipLeadsApi.useStateChangeRequest()
  const assignLeadRequest = RelationshipLeadsApi.useAssignLeadRequest()
  const saveUnassignedLeadRequest = RelationshipLeadsApi.useSaveUnassignedLeadRequest()
  const assignAllLeadsForCompanyRequest = RelationshipLeadsApi.useAssignAllLeadsForCompanyRequest()
  const dismissUnassignedLeadRequest = RelationshipLeadsApi.useDismissUnassignedLeadRequest()

  const leadsStore = RelationshipLeadStore.useStore((store) => store)

  const toast = useToast()

  const [state, send] = useMachine(relationshipCardStateMachine)

  useEffect(() => {
    // On mount, check if the lead was assigned. This is mainly for
    // infinite scrolling
    const isAssigned = leadsStore.isAssigned(relationshipLead)
    if (isAssigned) send('LEAD_ASSIGNED')
  }, [send, leadsStore, relationshipLead])

  useEffect(() => {
    const listener = {
      relationshipLead,
      eventHandler: () => {
        send('LEAD_ASSIGNED')
      }
    }
    leadsStore.subscribeToLeadAssignment(listener)
    return () => {
      leadsStore.unsubscribeFromLeadAssignment(relationshipLead)
    }
  }, [relationshipLead, send])

  const showError = (verb: string) => {
    toast({
      description: `An error occurred while ${verb} the relationship lead. Please try again.`,
      status: 'error',
      duration: 5000,
      isClosable: true
    })
  }

  const handleStateChange = (
    state: RelationshipLeadStates,
    dismissedReason: RelationshipLeadDismissedReasons,
    dismissedAt: Date
  ) => {
    const stateChange: RelationshipLeadStateChange = {
      id,
      state,
      dismissedReason,
      dismissedAt: dismissedAt?.toISOString()
    }

    if (currentState !== stateChange.state) {
      setInitialState(currentState)
      setCurrentState(stateChange.state)
    }

    stateChangeRequest.mutate(stateChange, {
      onSuccess: () => leadsStore.changeState(stateChange)
    })
  }

  const handleAssignLead = (options: AssignLeadOptions) => {
    assignLeadRequest.mutate(options, {
      onSuccess: (response) => {
        leadsStore.assignLeads([response.data.relationshipLead])
      },
      onError: () => {
        showError('assigning')
        send('BACK')
      }
    })
  }

  const saveUnassignedLead = (options: SaveUnassignedLeadOptions) => {
    saveUnassignedLeadRequest.mutate(options, {
      onSuccess: (response) => {
        leadsStore.instantiateUnassignedLead({
          instantiatedLeadId: response.data.relationshipLead.id,
          relationshipId: relationship.id
        })
      },
      onError: () => {
        showError('saving')
        send('BACK')
      }
    })
  }

  const handleSave = () => {
    saveUnassignedLead({
      relationshipId: relationship.id,
      accountId,
      requesterId: personId,
      assignedById: personId
    })
  }

  const handleHide = () => {
    const options = {
      relationshipId: relationship.id,
      accountId
    }
    dismissUnassignedLeadRequest.mutate(options, {
      onSuccess: () => {
        send('ADMIN_DISMISS')
      },
      onError: () => {
        showError('dismissing')
        send('BACK')
      }
    })
  }

  const handleMultipleLeadAssignment = (options: AssignAllLeadsForCompanyOptions) => {
    assignAllLeadsForCompanyRequest.mutate(options, {
      onSuccess: (response) => {
        leadsStore.assignLeads(response.data.relationshipLeads.map((item) => item.relationshipLead))
      }
    })
  }

  let cardContent = null
  let activeBorderColor = 'gray.200'
  let activeBgColor = 'white'

  switch (state.value) {
    case 'displayLinks':
      cardContent = <LinksContent relationshipLead={relationshipLead} onBack={() => send('BACK')} />
      break

    case 'displayHide':
      cardContent = (
        <HideRelationshipContent
          firstName={relationshipLead.prospect.firstName}
          lastName={relationshipLead.prospect.lastName}
        />
      )
      activeBgColor = 'gray.100'
      break

    case 'introRequested':
      cardContent = <RequestIntroductionContent introUrl={state.context.introUrl} />
      activeBorderColor = 'blue.400'
      break

    case 'assigningLead':
      cardContent = (
        <AssignContent
          onBack={() => {
            handleStateChange(initialState, null, null)
            send('CANCEL')
          }}
          onAssign={(assignmentOptions) => {
            handleAssignLead(assignmentOptions)
          }}
          onAssignAll={(assignmentOptions) => {
            handleMultipleLeadAssignment(assignmentOptions)
          }}
          onInvite={(invitationEmail: string) => {
            setInvitationEmail(invitationEmail)
            send('INVITE')
          }}
          relationshipLead={relationshipLead}
        />
      )
      activeBorderColor = 'green.400'
      break

    case 'leadAssigned':
      cardContent = <LeadAssignedContent relationshipLead={relationshipLead} />
      activeBorderColor = 'green.400'
      activeBgColor = 'gray.100'
      break

    case 'requesterInvited':
      cardContent = <InvitedContent invitedEmail={invitationEmail} />
      activeBorderColor = 'blue.400'
      activeBgColor = 'gray.100'
      break

    case 'displaySave':
      if (!disableDisplaySave) {
        cardContent = (
          <SaveRelationshipContent
            firstName={relationshipLead.prospect.firstName}
            lastName={relationshipLead.prospect.lastName}
            savedLeadsUrl={`/saved-leads/${relationshipLead.prospect.company.slug}`}
            onBack={() => {
              handleStateChange(initialState, null, null)
              send('BACK')
            }}
          />
        )
        activeBorderColor = 'yellow.400'
        break
      }
    /* falls through */
    default:
      cardContent = (
        <RelationshipContent onMore={() => send('LINKS')} relationshipLead={relationshipLead}>
          <RelationshipCardButtons
            onHide={() => {
              setInitialState(currentState)
              handleHide()
              send('HIDE')
            }}
            onSave={() => {
              handleSave()
              send('SAVE')
            }}
            onRequest={() => send('REQUEST')}
            onAssign={() => send('ASSIGN')}
          />
        </RelationshipContent>
      )
  }

  const handleCloseModal = () => {
    send('CANCEL')
  }

  const handleOnRequestClick = (formdata: RequestModalBodyForm) => {
    const data = {
      id,
      accountId,
      personId,
      relationshipId: relationship.id,
      connector,
      requesterMembership,
      connectorMembership,
      prospect,
      form: formdata
    } as IntroductionRequestSubmission

    send({ type: 'MAKE_REQUEST', data })

    // The API takes care of setting the state on the associated relationship lead
    // handleStateChange('intro_requested', null, null)
  }

  return (
    <>
      <Flex
        direction="column"
        width={233}
        height={216}
        border="1px"
        mb={2}
        borderColor={activeBorderColor}
        borderRadius={3}
        bg={activeBgColor}
        boxShadow="0 18px 67px rgba(0,0,0,0.1)"
        _hover={{
          boxShadow: '0 18px 67px rgba(0,0,0,.17)'
        }}
      >
        {cardContent}
      </Flex>
      {state.context.requestIntroModalIsOpen && (
        <RelationshipRequestModal
          requester={requester}
          connector={connector}
          prospect={prospect}
          isOpen={state.context.requestIntroModalIsOpen}
          isLoading={state.context.requestInFlight}
          onClose={handleCloseModal}
          onCancelClick={handleCloseModal}
          onRequestClick={handleOnRequestClick}
        />
      )}
    </>
  )
}
