import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Card, Container, Flex, Link, Text, SimpleGrid, Grid, GridItem, Center, Tooltip } from '@chakra-ui/react'
import { StarIcon, BackButton } from '@smallworld-io/pangaea'
import { useLeadsRequest, useLoggedInUserQuery } from '~/api'
import { RelationshipLeadStore as RLS } from '~/stores'
import useDocumentTitle from '~/hooks/useDocumentTitle'

export const SavedRelationshipLeads = () => {
  const navigate = useNavigate()

  const { data } = useLoggedInUserQuery()
  const { accountId, currentMembership, personId } = data

  const leadsRequest = useLeadsRequest(personId, { accountId, membership: currentMembership })

  const leadsStore = RLS.useStore((state) => state)
  const leadsByCompany = RLS.groupLeadsByCompany(RLS.findByState(leadsStore.relationshipLeads, 'saved'))

  useDocumentTitle('Saved Relationship Leads – SmallWorld')

  useEffect(() => {
    if (!leadsRequest.isLoading && leadsRequest.data?.relationshipLeads)
      leadsStore.initialize(leadsRequest.data.relationshipLeads)
  }, [leadsRequest.isLoading, leadsRequest.data?.relationshipLeads, leadsStore.initialize])

  // if (leadsRequest.error) return <Box>{`An error has occurred: ${leadsRequest.error.message}`}</Box>

  return (
    <Container maxW="800px" pt={4}>
      <BackButton onClick={() => navigate('/leads')}>New Leads</BackButton>
      <Text as="h1" fontSize="4xl" textAlign="center" mb={8}>
        Saved Relationship Leads
      </Text>

      <SimpleGrid columns={1} spacing={5} pb={16}>
        {leadsStore.relationshipLeads.length == 0 && (
          <Box
            width="100%"
            textAlign="center"
            borderStyle="dashed"
            borderWidth="2px"
            borderColor="gray.200"
            background="gray.100"
            color="gray.600"
            p={4}
            borderRadius="md"
            alignItems="center"
          >
            <Text fontWeight="bold">You have no Saved Relationships Leads.</Text>
            <Text>
              Visit your New Relationship Leads and click the icon
              <StarIcon fill="none" color="yellow.400" boxSize={5} ml={1} mr={0} /> to Save the Relationship Lead
            </Text>
          </Box>
        )}

        {leadsByCompany.map(([companyName, companySlug, leads]) => (
          <Card
            key={companyName}
            border="1px"
            borderColor="gray.200"
            shadow="0"
            borderRadius={5}
            pt={6}
            pb={5}
            pl={6}
            pr={1}
          >
            <Grid templateRows="repeat(2, 1fr)" templateColumns="repeat(8, 1fr)" gap={1}>
              <GridItem rowSpan={1} colSpan={6}>
                <Text as="h2" fontSize="2xl" lineHeight="1.3rem" fontWeight="bold" color="black">
                  {companyName}
                </Text>
              </GridItem>

              <GridItem rowSpan={2} colSpan={2}>
                <Center h="100%">
                  <Link
                    href={`/saved-leads/${companySlug}`}
                    color="blue.400"
                    fontWeight="bold"
                    display="block"
                    textAlign="center"
                  >
                    View Saved
                  </Link>
                </Center>
              </GridItem>

              <GridItem rowSpan={1} colSpan={6} display="flex" alignItems="center">
                <Tooltip
                  label={`${leads.length} Saved Leads at ${companyName}`}
                  placement="top"
                  color="white"
                  fontSize="xs"
                  bg="black"
                  py={2}
                  hasArrow
                >
                  <Flex alignItems="center">
                    <Text as="h2" fontSize="l" fontWeight="bold" color="gray.600" pl={1} pr={1}>
                      {leads.length}
                    </Text>
                    <StarIcon fill="yellow.400" color="yellow.400" boxSize={5} />
                  </Flex>
                </Tooltip>
              </GridItem>
            </Grid>
          </Card>
        ))}
      </SimpleGrid>
    </Container>
  )
}
