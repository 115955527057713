import { Box, Flex } from '@chakra-ui/react'
import { format, formatDistanceToNow } from 'date-fns'

export const BackAndForth = ({
  introduction,
  messages,
  userId,
  personId,
  anonymous
}: {
  introduction: Introduction
  messages: Message[]
  userId: number
  personId: number
  anonymous: boolean
}) => {
  if (messages.length === 0) return null

  const isRequester = introduction.requesterPersonId === personId

  const isAuthor = (message: Message) => message.userId === userId

  const initials = (msg: Message, anon: boolean) => {
    const authored = isAuthor(msg)

    if (authored) return 'You'
    if (!authored && isRequester && anon) return 'SC'

    const recipient = !authored && isRequester ? introduction.connector : introduction.requester

    return `${recipient.firstName[0]}${recipient.lastName[0]}`
  }

  return (
    <Flex height="200px" overflowY="scroll" direction="column-reverse">
      {messages.map((message) => (
        <Box key={message.id} mb={4}>
          <Flex justifyContent={isAuthor(message) ? 'flex-end' : 'flex-start'} width="100%">
            {!isAuthor(message) && (
              <Box flex="0 0 auto" fontSize="xs" mr={2} alignSelf="flex-end">
                {initials(message, anonymous)}
              </Box>
            )}

            <Box
              fontSize="xs"
              lineHeight="1.125rem"
              borderRadius={8}
              bg={isAuthor(message) ? 'blue.200' : 'gray.100'}
              p={3}
              maxWidth="80%"
            >
              {message.body}
            </Box>

            {isAuthor(message) && (
              <Box flex="0 0 auto" fontSize="xs" ml={2} alignSelf="flex-end">
                {initials(message, anonymous)}
              </Box>
            )}
          </Flex>

          {/* Timestamp */}
          <Box
            as="span"
            display="block"
            pt={1}
            pr={isAuthor(message) ? 8 : 0}
            pl={isAuthor(message) ? 0 : 6}
            textAlign={isAuthor(message) ? 'right' : 'left'}
            fontSize="xs"
            color="gray.400"
          >
            {formatDistanceToNow(new Date(message.createdAt), { addSuffix: true })}
          </Box>
        </Box>
      ))}
    </Flex>
  )
}
