import { Heading, Text } from '@chakra-ui/react'
import { fullname } from '~/lib'

export const Header = ({ connector, prospect }: { connector: Connector; prospect: Person }) => {
  const from = connector.isAnonymous ? (
    <> </>
  ) : (
    <>
      from <Text as="strong">{fullname(connector)}</Text>
    </>
  )

  return (
    <>
      <Heading fontSize="2xl" mb={4}>
        Request an Intro
      </Heading>
      <Text fontSize="sm" fontWeight="normal" pr={10} pl={10}>
        Complete the details below to request an introduction {from} to <Text as="strong">{fullname(prospect)}</Text>.
      </Text>
    </>
  )
}
