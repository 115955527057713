import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { request, RequestConfig, isPositiveNumber } from './utils'
import { API_PATH, API_PATH_SWAG, RAILS_URL } from '~/settings'

const NAMESPACE = 'relationships'

const BASE_URL_SWAG = `${import.meta.env.VITE_API_GW_URL}${API_PATH_SWAG}/${NAMESPACE}`

//region rels to rate
export const relationshipsToRateRequest = async (
  sourceId: number,
  ratedSince: string,
  limit: number
): Promise<Relationships> => {
  if (!isPositiveNumber(sourceId) || !isPositiveNumber(limit) || !ratedSince) {
    console.warn(
      `Rate relationships request called with invalid sourceId: ${sourceId} or invalid ratedSince: ${ratedSince} or invalid limit: ${limit}.`
    )

    return Promise.resolve<Relationships>({
      relationships: []
    })
  }

  const url = new URL(
    `${BASE_URL_SWAG}/relationships-to-rate?sourceId=${sourceId}&ratedSince=${ratedSince}&limit=${limit}`
  )

  const response = await request<null, Relationships>(url, 'GET')

  return response
}

export const useRelationshipsToRateRequest = (
  sourceId: number,
  ratedSince: string,
  limit: number,
  options?: UseQueryOptions<Relationships, Error>
) => {
  return useQuery<Relationships, Error>([], () => relationshipsToRateRequest(sourceId, ratedSince, limit), {
    enabled: isPositiveNumber(sourceId) && ratedSince && isPositiveNumber(limit),
    ...options
  })
}
//endregion rels to rate

//region Rated count relationships request
export const ratedCountRelationshipsRequest = async (sourceId: number): Promise<Count> => {
  if (!isPositiveNumber(sourceId)) {
    console.warn(`Rate relationships request called with invalid sourceId: ${sourceId}`)

    return Promise.resolve<Count>({
      count: 0
    })
  }
  const url = new URL(`${BASE_URL_SWAG}/rated-count/${sourceId}`)
  const response = await request<null, Count>(url, 'GET')
  return response
}

export const useRatedCountRelationshipsRequest = (
  sourceId: number,
  enabled: boolean,
  options?: UseQueryOptions<Count, Error>
) => {
  return useQuery<Count, Error>(['relationshipsRatedCount', sourceId], () => ratedCountRelationshipsRequest(sourceId), {
    enabled: enabled && Boolean(sourceId),
    ...options
  })
}
//endregion Rated count relationships request

//region count relationships
export const countRelationshipsRequest = async (sourceId: number): Promise<Count> => {
  if (typeof sourceId !== 'number' || isNaN(sourceId)) {
    console.warn(`countRelationshipsRequest called with invalid sourceId: ${sourceId}. Defaulting to 0.`)
    sourceId = 0
  }
  const url = new URL(`${BASE_URL_SWAG}/count/${sourceId}`)
  const response = await request<null, Count>(url, 'GET')
  return response
}

export const useCountRelationshipsRequest = (sourceId: number, options?: UseQueryOptions<Count, Error>) => {
  return useQuery<Count, Error>(['relationshipCount', sourceId], () => countRelationshipsRequest(sourceId), {
    enabled: Boolean(sourceId),
    ...options
  })
}
//endregion count relationships

//region matching strong
export const matchingStrongRelationshipsPeopleIdsRequest = async (sourceId: number): Promise<Integers> => {
  if (typeof sourceId !== 'number' || isNaN(sourceId)) {
    console.warn(
      `matchingStrongRelationshipsPeopleIdsRequest called with invalid sourceId: ${sourceId}. Defaulting to 0.`
    )
    sourceId = 0
  }
  const url = new URL(`${BASE_URL_SWAG}/matching-strong-relationships-people-ids/${sourceId}`)
  const response = await request<null, Integers>(url, 'GET')
  return response
}

export const useMatchingStrongRelationshipsPeopleIdsRequest = (
  sourceId: number,
  options?: UseQueryOptions<Integers, Error>
) => {
  return useQuery<Integers, Error>(
    ['anySuperConnectorMatches', sourceId],
    () => matchingStrongRelationshipsPeopleIdsRequest(sourceId),
    {
      enabled: Boolean(sourceId),
      ...options
    }
  )
}
//endregion matching strong

//region super connector ids
export const superConnectorIdsRequest = async (peopleIds: number[]): Promise<SuperConnectorIdsResponse> => {
  const url = new URL(`${RAILS_URL}${API_PATH}/iam/super_connectors/validate`)

  const validateBody: ValidateSuperConnectorsRequestBody = {
    ids: peopleIds,
    type: 'person'
  }

  const options: RequestConfig<ValidateSuperConnectorsRequestBody> = { body: validateBody }
  const response = await request<ValidateSuperConnectorsRequestBody, SuperConnectorIdsResponse>(url, 'POST', options)
  return response
}

export const useSuperConnectorIdsRequest = (
  peopleIds: number[],
  options?: UseQueryOptions<SuperConnectorIdsResponse, Error>
) =>
  useQuery<SuperConnectorIdsResponse, Error>(
    ['superConnectorIdsRequest', peopleIds],
    () => superConnectorIdsRequest(peopleIds),
    {
      enabled: peopleIds.length > 0,
      ...options
    }
  )
//endregion super connector ids

//region create memberships
export const createMembershipsRequest = async (pairsParam: number[][]): Promise<null> => {
  const url = new URL(`${RAILS_URL}${API_PATH}/iam/super_connectors/memberships/pairs`)

  const registerMembershipsBody: RegisterMembershipsRequestBody = {
    pairs: pairsParam,
    type: 'person'
  }
  const options: RequestConfig<RegisterMembershipsRequestBody> = { body: registerMembershipsBody }
  const response = await request<RegisterMembershipsRequestBody, null>(url, 'POST', options)
  return response
}

export const useCreateMembershipsRequest = (pairs: number[][], options?: UseQueryOptions<null, Error>) =>
  useQuery<null, Error>(['superConnectorIdsRequest', pairs], () => createMembershipsRequest(pairs), {
    enabled: pairs.length > 0,
    ...options
  })
//endregion create memberships

//region create first degree memberships
export const createFirstDegreeMembershipsRequest = async (sourceId: number): Promise<null> => {
  const url = new URL(`${BASE_URL_SWAG}/create-first-degree-scs/${sourceId}`)

  const response = await request<null, null>(url, 'GET')

  return response
}

export const useCreateFirstDegreeMembershipsRequest = (sourceId: number, options?: UseQueryOptions<null, Error>) =>
  useQuery<null, Error>(
    ['createFirstDegreeMembershipsRequest', sourceId],
    () => createFirstDegreeMembershipsRequest(sourceId),
    {
      enabled: isPositiveNumber(sourceId),
      ...options
    }
  )
//endregion create memberships

//region strength change
export const strengthChangeRequest = async (stateChange: RelationshipStrengthChange): Promise<void> => {
  const url = new URL(`${BASE_URL_SWAG}/patch-strength`)

  const options: RequestConfig<RelationshipStrengthChange> = { body: stateChange }
  const response = await request<RelationshipStrengthChange, void>(url, 'PATCH', options)

  return response
}

export const useStrengthChangeRequest = (options?: UseMutationOptions<void, Error, RelationshipStrengthChange>) =>
  useMutation<void, Error, RelationshipStrengthChange>(strengthChangeRequest, options)
//endregion strength change
