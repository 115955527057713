import { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  List,
  ListItem,
  Box,
  Divider,
  HStack,
  Text
} from '@chakra-ui/react'
import { VscSearch } from 'react-icons/vsc'
import { PiCheckCircleLight } from 'react-icons/pi'
import { CompanyApi } from '~/api'

type CompanySearchModalProps = {
  isOpen: boolean
  onClose: () => void
  onAdd: (companyId: number, companyName: string) => void
  onDelete: (companyId: number) => void
}

export const CompanySearchModal = ({ isOpen, onClose, onAdd, onDelete }: CompanySearchModalProps) => {
  const [query, setQuery] = useState('')
  const [suggestions, setSuggestions] = useState<CompanySearchResult[]>([])
  const [checked, setChecked] = useState<number[]>([])
  const companySearchResults = CompanyApi.useGetCompanySearchResults(true, query)
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
    setHighlightedIndex(null)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLLIElement>) => {
    if ((event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Enter') && highlightedIndex === null) {
      setHighlightedIndex(0)
    } else if (event.key === 'ArrowDown' && highlightedIndex < suggestions.length - 1) {
      setHighlightedIndex(highlightedIndex + 1)
    } else if (event.key === 'ArrowUp' && highlightedIndex > 0) {
      setHighlightedIndex(highlightedIndex - 1)
    } else if (event.key === 'Enter') {
      event.preventDefault()
      if (highlightedIndex !== null) {
        handleToggle(suggestions[highlightedIndex])
      }
    }
  }

  const handleClose = () => {
    setQuery('')
    onClose()
  }

  const handleToggle = (companySearchRecord: CompanySearchResult) => {
    const isCurrentlyChecked = checked.includes(companySearchRecord.companyId)
    if (isCurrentlyChecked) {
      onDelete(companySearchRecord.companyId)
      setChecked(checked.filter((id) => id !== companySearchRecord.companyId))
    } else {
      onAdd(companySearchRecord.companyId, companySearchRecord.companyName)
      setChecked([...checked, companySearchRecord.companyId])
    }
    setQuery('')
  }
  // If this useEffect goes wrong, consult Target Company component
  useEffect(() => {
    if (query.length > 1) {
      setChecked([])
      if (!companySearchResults.isLoading && companySearchResults.data) setSuggestions(companySearchResults.data)
    } else {
      setSuggestions([])
    }
  }, [query, companySearchResults.isLoading, companySearchResults.data])

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <InputGroup size="lg" borderRadius="md" borderColor="transparent">
          <InputLeftElement pointerEvents="none" _focus={{ borderColor: 'transparent' }}>
            <Icon as={VscSearch} color="gray.300" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Search Companies"
            aria-label="Search Companies"
            borderColor="transparent"
            focusBorderColor="transparent"
            borderRadius="md"
            onChange={handleInputChange}
            value={query}
            onKeyDown={handleKeyDown}
          />
        </InputGroup>
        {suggestions.length > 0 && (
          <Box px={4} borderRadius="md">
            <Divider mb={2} />
            <List my={2}>
              {suggestions.map((csr, index) => (
                <ListItem
                  my={1}
                  p={2}
                  key={`${csr.companyId}-${index}`}
                  _hover={{ bg: 'gray.200', borderRadius: 'md' }}
                  onClick={() => handleToggle(csr)}
                  _focus={{ bg: 'gray.200', borderRadius: 'md' }}
                  onKeyDown={(event) => handleKeyDown(event)}
                  tabIndex={0}
                  aria-selected={highlightedIndex === index}
                  // style={highlightedIndex === index ? { bg: 'gray.200', borderRadius: 'md' } : {}}
                  ref={(el) => {
                    if (highlightedIndex === index && el) {
                      el.focus()
                    }
                  }}
                >
                  <HStack justify="space-between">
                    <Text>{csr.companyName}</Text>
                    {checked.includes(csr.companyId) && <Icon as={PiCheckCircleLight} color="green.300" boxSize={6} />}
                  </HStack>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </ModalContent>
    </Modal>
  )
}
