import { Badge, Box, Flex, Spacer, Text, Tooltip } from '@chakra-ui/react'
import { StrengthButton } from './StrengthButton'

import { motion } from 'framer-motion'
import { ordinal } from './lib'
import { IoRadioButtonOn, IoRadioButtonOff } from 'react-icons/io5'

export type ConnectorCardProps = {
  relationshipId: number
  connector: { [key: string]: string | number }
  strength: Strengths | null
  strengthRequested: boolean
  state: 'none' | 'selected' | 'deselected'
  onSelect: (id: number) => void
}

const config = {
  none: {
    borderColor: 'gray.200',
    bg: 'white',
    opacity: '1.0',
    _hover: {
      borderColor: 'blue.200'
    }
  },
  selected: {
    borderColor: 'blue.400',
    bg: 'white',
    opacity: '1.0'
  },
  deselected: {
    borderColor: 'gray.200',
    bg: 'white',
    opacity: '0.3',
    _hover: {
      opacity: '0.66'
    }
  }
}

const selectedColor = '#3f84e5' // why can't use `blue.400`?
const deselectedColor = '#9DA1A5'

export const ConnectorCard = (props: ConnectorCardProps) => {
  const { connector, onSelect, relationshipId, strength, strengthRequested, state } = props

  return (
    <motion.div
      initial={{
        opacity: 0.25
      }}
      animate={{
        opacity: 1.0
      }}
    >
      <Flex
        bg="white"
        borderRadius="7px"
        borderWidth="3px"
        borderColor="gray.200"
        px={4}
        py={6}
        mb={4}
        cursor="pointer"
        direction={{
          base: 'column',
          sm: 'row'
        }}
        textAlign={{
          base: 'center',
          sm: 'left'
        }}
        onClick={() => onSelect(relationshipId)}
        sx={config[state]}
        transition="all 400ms ease"
      >
        <Box mt={1} mr={3}>
          {state === 'selected' ? (
            <IoRadioButtonOn color={selectedColor} />
          ) : (
            <IoRadioButtonOff color={deselectedColor} />
          )}
        </Box>
        <Box
          mb={{
            base: 4,
            sm: 0
          }}
        >
          <Text
            as="h4"
            fontSize="2xl"
            fontWeight="medium"
            color="black"
            pb={2}
            display="flex"
            alignItems="center"
            lineHeight={1}
          >
            {connector.name === 'Super Connector' ? 'Anonymous' : connector.name}{' '}
            {connector.degree === 1 && (
              <Tooltip label={'You are connected with this Super Connector on LinkedIn.'} placement="top" hasArrow>
                <Text
                  as="span"
                  fontSize="10px"
                  lineHeight="12px"
                  borderColor="gray.300"
                  borderWidth="1px"
                  borderStyle="solid"
                  borderRadius="3px"
                  padding="3px 5px 2px"
                  ml={4}
                >
                  {ordinal(connector.degree)} Degree
                </Text>
              </Tooltip>
            )}
          </Text>
          <Text
            fontSize="xs"
            fontWeight="medium"
            color="gray.500"
            pr={{
              base: 16,
              sm: 8
            }}
          >
            {connector.sharedRelationshipText}
          </Text>
        </Box>
        <Spacer />
        <Box>
          <StrengthButton relationshipId={relationshipId} strength={strength} strengthRequested={strengthRequested} />
        </Box>
      </Flex>
    </motion.div>
  )
}
