import { useQuery } from '@tanstack/react-query'
import { API_PATH } from '~/settings'
import { request } from './utils'

const NAMESPACE = 'requesters'
const BASE_URL = `${import.meta.env.VITE_RAILS_URL}${API_PATH}`

export type RequesterStub = Pick<User, 'id' | 'firstName' | 'lastName'>

export type RequesterStubResponse = {
  data: {
    requesters: RequesterStub[]
  }
}

const requesterStubRequest = async (accountId?: number) => {
  // Account Id can be undefined if not showing enterprise leads
  // on the Relationship Leads page
  if (accountId === undefined) return new Promise<RequesterStub[]>((resolve) => resolve([]))

  const url = new URL(`${BASE_URL}/${NAMESPACE}?accountId=${accountId}`)
  const response = await request<null, RequesterStubResponse>(url)
  return response.data?.requesters
}

export const useRequesterStubRequest = (accountId: number) => {
  return useQuery({
    queryKey: [NAMESPACE, `${accountId}`],
    queryFn: () => requesterStubRequest(accountId),
    enabled: !!accountId
  })
}
