import { useNavigate } from 'react-router-dom'
import { useMeCountsQuery } from '~/api/userCounts'

const RATING_THRESHOLD = 100

enum RedirectRoutes {
  Rate = '/rate-relationships',
  Leads = '/leads',
  Discover = '/discover',
  Sync = '/sign-up/sync'
}

// Presumably we will want to be able to redirect from other places
type RedirectSource = 'login' | 'home'

export default function useRedirection() {
  const navigate = useNavigate()
  const query = useMeCountsQuery()

  const { relationshipCounts, relationshipLeadCounts, networkSize } = query.data

  const hasSyncedRelationships = relationshipCounts?.total > 0
  const meetsRatingThreshold = relationshipCounts?.rated >= RATING_THRESHOLD
  const hasNetworkGrowth = networkSize > 1
  const hasLeads = relationshipLeadCounts?.superConnector > 0

  function fromLogin() {
    if (!query.isFetched) return

    if (query.isError) {
      // TODO: What to do if the query fails?
      return
    }

    if (!hasSyncedRelationships) {
      navigate(RedirectRoutes.Sync)
    } else if (!meetsRatingThreshold) {
      navigate(RedirectRoutes.Rate)
    } else if (!hasNetworkGrowth) {
      navigate(RedirectRoutes.Rate)
    } else if (hasLeads) {
      navigate(RedirectRoutes.Leads)
    } else {
      navigate(RedirectRoutes.Discover)
    }
  }

  const sourceMap: Record<RedirectSource, () => void> = {
    login: fromLogin,
    home: fromLogin
  }

  return {
    from: (source: RedirectSource) => {
      sourceMap[source]()
    }
  }
}
