import { useEffect, useRef, useState } from 'react'
import { Box, Flex, HStack, VStack, Image, Link, Text, useToast, Tooltip } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { useAuth } from '~/auth'
import useExpressStore from '~/stores/express'
import { MyNetworkMetrics } from '~/api'
import ShareModal from '~/components/ShareModal'
import InfoIcon from '~/assets/icons/icon-info.svg'
import loadingImage from '~/assets/loading.gif'

type NetworkStatsCardProps = {
  relationshipsRated: number
  onUpdateMyNetworkStats?: (stats: CountOfConnections) => void
  relationshipRefreshInterval?: number
}

export const NetworkStatsCard = ({
  relationshipsRated,
  onUpdateMyNetworkStats,
  relationshipRefreshInterval = 0
}: NetworkStatsCardProps) => {
  const auth = useAuth()
  const membership = useExpressStore((state) => state.membership)
  const [myNetworkStats, setMyNetworkStats] = useState<CountOfConnections>()
  const [relationships, setRelationships] = useState(0)
  const [ratedRelationships, setRatedRelationships] = useState(0)

  const connectionCounts = MyNetworkMetrics.useGetConnectionCounts(auth.personId, auth.organizationId, membership?.id)
  const relationshipCount = MyNetworkMetrics.useGetRelationshipCount(auth.personId, {
    refetchInterval: relationshipRefreshInterval,
    refetchIntervalInBackground: Boolean(relationshipRefreshInterval)
  })
  const ratedRelationshipCount = MyNetworkMetrics.useGetRatedRelationshipCount(auth.personId)
  const countOfTargetCompanies = MyNetworkMetrics.useGetTargetCompanyCount(membership?.id)
  const prospectsCount = myNetworkStats?.targetCompanyProspects

  // State Variable set in useEffect Line 83-87
  const [targetCompanyCount, setTargetCompanyCount] = useState(0)
  const [hasTargetCompanies, setHasTargetCompanies] = useState(false)

  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  const openShareModal = () => {
    setIsShareModalOpen(true)
  }
  const closeShareModal = () => setIsShareModalOpen(false)

  const calculateProgressWidth = () => {
    let denominator = relationships

    // Use 100 as the denominator if fewer than
    // 100 relationships have been rated
    if (relationshipsRated < 100) {
      denominator = 100
    }

    const progress = (relationshipsRated / denominator) * 100
    return `${progress == 0 ? 1 : progress}%` // min 1% to show _something_
  }

  const toast = useToast()
  const prevNetworkStats = useRef(myNetworkStats)

  useEffect(() => {
    if (
      prevNetworkStats.current &&
      myNetworkStats &&
      (prevNetworkStats.current.firstDegreeConnections === myNetworkStats.firstDegreeConnections ||
        prevNetworkStats.current.secondDegreeConnections === myNetworkStats.secondDegreeConnections)
    ) {
      toast({
        title: 'Your network has grown!',
        description: `Your network now contains ${connectionCounts.data.targetCompanyProspects.toLocaleString()} potential prospects!`,
        status: 'info',
        position: 'top',
        duration: 5000,
        isClosable: true
      })
    }
    prevNetworkStats.current = myNetworkStats
  }, [myNetworkStats, toast])

  useEffect(() => {
    if (!connectionCounts.isLoading && connectionCounts?.data) {
      setMyNetworkStats(connectionCounts.data)
      onUpdateMyNetworkStats(connectionCounts.data)
    }
  }, [connectionCounts.isLoading, connectionCounts.data])

  useEffect(() => {
    if (!countOfTargetCompanies.isLoading && countOfTargetCompanies?.data) {
      setTargetCompanyCount(countOfTargetCompanies?.data)
      setHasTargetCompanies(countOfTargetCompanies?.data > 0)
    }
  }, [countOfTargetCompanies.isLoading, countOfTargetCompanies.data])

  useEffect(() => {
    if (!relationshipCount.isLoading && relationshipCount?.data) {
      setRelationships(relationshipCount?.data)
    }
  }, [relationshipCount.isLoading, relationshipCount.data])

  useEffect(() => {
    if (!ratedRelationshipCount.isLoading && ratedRelationshipCount?.data) {
      setRatedRelationships(ratedRelationshipCount.data)
    }
  }, [ratedRelationshipCount.isLoading, ratedRelationshipCount.data])

  useEffect(() => {
    setRatedRelationships(relationshipsRated)
    connectionCounts.refetch()
  }, [relationshipsRated])

  return (
    <>
      {/* Stats */}
      <HStack
        id="statsModule"
        position="sticky"
        top="0"
        zIndex="sticky"
        bg="#F7FAFC"
        borderTop="1px solid"
        borderLeft="1px solid"
        borderRight="1px solid"
        borderColor="gray.200"
        borderTopLeftRadius="5"
        borderTopRightRadius="5"
        w="100%"
        py={5}
        justify="space-between"
        gap={0}
      >
        <Box
          flex="1"
          textAlign="center"
          borderRight="1px solid"
          borderColor="gray.200"
          className="pendo-relationship-count"
        >
          <Tooltip
            label="This shows the total number of relationships rated in relation to your total relationship count."
            placement="top"
            textAlign={'center'}
            color="white"
            fontSize="xs"
            bg="black"
            py={2}
            hasArrow
          >
            <HStack justify="center" verticalAlign={'center'}>
              <Text fontSize={'xs'} textColor={'gray.600'} fontWeight={500}>
                Relationships Rated
              </Text>
              <Image src={InfoIcon} boxSize="12px" />
            </HStack>
          </Tooltip>
          <Flex justify="center" fontSize={'3xl'} textColor={'gray.400'}>
            {ratedRelationshipCount.isLoading ? (
              <Flex justify="center" verticalAlign={'center'} w={'full'}>
                <Image my={3} src={loadingImage} boxSize="20px" />
              </Flex>
            ) : (
              <>
                <Text as="span" mr={2} textColor={'gray.600'}>
                  {ratedRelationships.toLocaleString()}
                </Text>
                of {relationships.toLocaleString()}
              </>
            )}
          </Flex>
        </Box>

        <Box flex="1" textAlign="center" borderRight="1px solid" borderColor="gray.200" className="pendo-network-size">
          <Tooltip
            label="1st degree Super Connectors are ones where you already share a direct connection on LinkedIn. 2nd degree relationships are ones that you share a trusted relationship."
            placement="top"
            textAlign={'center'}
            color="white"
            fontSize="xs"
            bg="black"
            py={2}
            hasArrow
          >
            <HStack justify="center">
              <Text fontSize={'xs'} textColor={'gray.600'} fontWeight={500}>
                Super Connectors in Your Network
              </Text>
              <Image src={InfoIcon} boxSize="12px" />
            </HStack>
          </Tooltip>

          <HStack w="100%" maxW={270} m="0 auto" justify="space-between">
            {connectionCounts.isLoading ? (
              <Flex justify="center" verticalAlign={'center'} w={'full'}>
                <Image my={3} src={loadingImage} boxSize="20px" />
              </Flex>
            ) : (
              <>
                <HStack justify="center" verticalAlign={'center'}>
                  <Text fontSize={'3xl'} textColor={'gray.600'}>
                    {myNetworkStats?.firstDegreeConnections || 0}
                  </Text>
                  <Text
                    textColor={'gray.600'}
                    px={2}
                    border="1px solid"
                    borderColor={'gray.300'}
                    borderRadius="full"
                    fontSize={10}
                    fontWeight={600}
                  >
                    1st Degree
                  </Text>
                </HStack>

                <HStack justify="center" verticalAlign={'center'}>
                  <Text fontSize={'3xl'} textColor={'gray.600'}>
                    {myNetworkStats?.secondDegreeConnections || 0}
                  </Text>
                  <Text
                    textColor={'gray.600'}
                    px={2}
                    border="1px solid"
                    borderColor={'gray.300'}
                    borderRadius="full"
                    fontSize={10}
                    fontWeight={600}
                  >
                    2nd Degree
                  </Text>
                </HStack>
              </>
            )}
          </HStack>

          {/*<Link onClick={openShareModal} fontSize={'small'} textColor={'gray.600'} textDecoration={'underline'}>
            <HStack w="100%" justify="center">
              <Text>Invite Others</Text>
              <Image src={LinkIcon} boxSize="12px" />
            </HStack>
          </Link>*/}
        </Box>

        <Box flex="1" textAlign="center" className="pendo-prospect-count">
          <HStack>
            {hasTargetCompanies && (
              <VStack flex="1" pl={4}>
                <Tooltip
                  label={
                    targetCompanyCount < 10
                      ? 'Adding more Target Companies will increase the number of Prospects that appear on your Discover page.'
                      : "The number of Target Companies that you add will appear here. Remember: the more Target Companies you add the more Prospects you'll find."
                  }
                  placement="top"
                  textAlign={'center'}
                  color="white"
                  fontSize="xs"
                  bg="black"
                  py={2}
                  hasArrow
                >
                  <HStack justify="center">
                    <Text fontSize={'xs'} textColor={'gray.600'} fontWeight={500}>
                      Target Companies
                    </Text>
                    <Image src={InfoIcon} boxSize="12px" />
                  </HStack>
                </Tooltip>

                {connectionCounts.isLoading && hasTargetCompanies ? (
                  <Flex justify="center" verticalAlign={'center'} w={'full'}>
                    <Image my={3} src={loadingImage} boxSize="20px" />
                  </Flex>
                ) : (
                  <Link
                    as={RouterLink}
                    to={`/target-companies`}
                    mt={-2}
                    textColor={'blue.400'}
                    fontSize={'3xl'}
                    _hover={{ textDecoration: 'none', color: 'blue.500', cursor: 'pointer' }}
                  >
                    {targetCompanyCount}
                  </Link>
                )}
              </VStack>
            )}

            <VStack flex="1">
              <Tooltip
                label={
                  hasTargetCompanies
                    ? 'These are the number of relationships that Super Connectors have with people that work at your Target Companies.'
                    : "You can add Target Companies to see how many of these people work at the companies you're looking for introductions into."
                }
                placement="top"
                textAlign={'center'}
                color="white"
                fontSize="xs"
                bg="black"
                py={2}
                hasArrow
              >
                <HStack justify="center">
                  <Text fontSize={'xs'} textColor={'gray.600'} fontWeight={500}>
                    {hasTargetCompanies ? 'Prospects' : 'Potential Prospects'}
                  </Text>
                  <Image src={InfoIcon} boxSize="12px" />
                </HStack>
              </Tooltip>

              {connectionCounts.isLoading ? (
                <Flex justify="center" verticalAlign={'center'} w={'full'}>
                  <Image my={3} src={loadingImage} boxSize="20px" />
                </Flex>
              ) : (
                <Box mt={-2}>
                  {!isNaN(prospectsCount) && prospectsCount > 0 ? (
                    <Link
                      as={RouterLink}
                      to="/discover"
                      textColor="blue.400"
                      fontSize="3xl"
                      _hover={{ textDecoration: 'none', color: 'blue.500', cursor: 'pointer' }}
                    >
                      {prospectsCount.toLocaleString()}
                    </Link>
                  ) : (
                    <Text textColor="gray.600" fontSize="3xl">
                      0
                    </Text>
                  )}
                </Box>
              )}
            </VStack>
          </HStack>
        </Box>

        <Box
          position="absolute"
          bottom="0"
          left="0"
          w={calculateProgressWidth()}
          h="4px"
          bg="blue.400"
          borderTopRightRadius="full"
          borderBottomRightRadius="full"
        />
      </HStack>

      <ShareModal isOpen={isShareModalOpen} onClose={closeShareModal} />
    </>
  )
}
