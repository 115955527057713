export class HttpError extends Error {
  public statusCode: number
  public detail: string | null

  constructor(statusCode: number, message: string, detail: string | null = null) {
    // Pass the message to the parent (Error) constructor
    super(message)

    // Set the name property to the class name for easier identification
    this.name = this.constructor.name

    // Ensure the proper capture of the stack trace (excluding the HttpError constructor itself)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(message).stack
    }

    // Set the status code and detail
    this.statusCode = statusCode
    this.detail = detail
  }
}
