import { Container, Box, Text, Tag, TagLabel, Flex, Skeleton } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { IntroductionCard } from './IntroductionCard'
import { useExpressStore } from '~/stores'
import { StatusFilter, useIntroductionsQuery, useMembershipConnectorsQuery } from '~/api/introductions'
import { useShallow } from 'zustand/react/shallow'
import { useState } from 'react'

const filters = {
  active: 'Active',
  successful: 'Completed',
  unfulfilled: 'Canceled / Declined'
}

const extractFilter = (params: URLSearchParams): StatusFilter => {
  let key = params.get('filter')
  if (key == null) return 'active'

  key = key.trim().toLowerCase()
  if (Object.keys(filters).includes(key)) return key as StatusFilter
  return 'active'
}

const FilterPill = ({
  filter,
  selected,
  onClick
}: {
  filter: string
  selected: boolean
  onClick: (key: string) => void
}) => {
  const handleOnClick = () => onClick(filter)

  return (
    <Tag
      size="lg"
      key={filter}
      borderRadius="full"
      variant={selected ? 'solid' : 'outline'}
      cursor="pointer"
      bg={selected ? 'blue.400' : 'white'}
      onClick={handleOnClick}
    >
      <TagLabel>{filters[filter]}</TagLabel>
    </Tag>
  )
}

export const IntroductionsList = () => {
  const [searchParams] = useSearchParams()
  const [selectedFilter, setSelectedFilter] = useState<StatusFilter>(extractFilter(searchParams))

  const { user, membership: currentMembership } = useExpressStore(
    useShallow((state) => ({
      membership: state.membership,
      user: state.user
    }))
  )

  const { setReqDashLastProspectPersonIdSeen } = useExpressStore(
    useShallow((state) => ({
      setReqDashLastProspectPersonIdSeen: state.setReqDashLastProspectPersonIdSeen
    }))
  )

  const prospectPersonId = parseInt(searchParams.get('prospectPersonId'), 10)
  if (!isNaN(prospectPersonId)) {
    setReqDashLastProspectPersonIdSeen(prospectPersonId)
  }

  const connectorsQuery = useMembershipConnectorsQuery(currentMembership.organization.id)
  const introsQuery = useIntroductionsQuery({
    personId: user.personId,
    membership: currentMembership,
    status: selectedFilter
  })

  const handleFilterClick = (key: StatusFilter) => {
    setSelectedFilter(key)
  }

  return (
    <>
      <Container maxW="container.lg">
        <Text as="h1" fontSize="3xl" color="black" mb={8}>
          My Introductions
        </Text>
        <Flex gap={2} mb={4}>
          <FilterPill filter="active" selected={selectedFilter === 'active'} onClick={handleFilterClick} />
          <FilterPill filter="successful" selected={selectedFilter === 'successful'} onClick={handleFilterClick} />
          <FilterPill filter="unfulfilled" selected={selectedFilter === 'unfulfilled'} onClick={handleFilterClick} />
        </Flex>
        {(introsQuery.isLoading || connectorsQuery.isLoading) && (
          <Skeleton height="173px" mb={4} startColor="gray.100" endColor="gray.200" width="100%" borderRadius={'5px'} />
        )}
        {introsQuery.isSuccess && connectorsQuery.isSuccess && (
          <Box>
            <AnimatePresence>
              {(introsQuery.data || []).map((intro: Introduction) => (
                <IntroductionCard
                  key={intro.id}
                  introduction={intro}
                  user={user}
                  anonymousSuperConnectorIds={connectorsQuery.data.anonymous}
                />
              ))}
            </AnimatePresence>
          </Box>
        )}
        {introsQuery.isSuccess && connectorsQuery.isSuccess && introsQuery.data.length === 0 && (
          <Box
            borderRadius="3px"
            borderWidth={1}
            borderStyle="dashed"
            borderColor="gray.300"
            textAlign="center"
            padding="5rem"
          >
            <Text fontWeight="semibold">There are no introductions to display.</Text>
          </Box>
        )}
      </Container>
    </>
  )
}
