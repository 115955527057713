import React, { useState } from 'react'
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Text,
  useToast,
  Input
} from '@chakra-ui/react'
import CopyIcon from '~/assets/icons/icon-copy.svg'
import { copyToClipboard } from '~/lib'

function ShareModal({ isOpen, onClose }) {
  const toast = useToast()

  const shareUrl = 'https://smallworld.ai/superconnectors'
  const [isCopied, setIsCopied] = useState(false) // State to track if the URL is copied

  const handleCopyToClipboard = async () => {
    const wasCopied = await copyToClipboard(shareUrl)
      .then(() => true)
      .catch(() => false)

    if (wasCopied) {
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 4000)
      toast({
        position: 'top',
        title: 'Share URL was copied to your clipboard!',
        status: 'success',
        duration: 2000
      })
    } else {
      toast({
        position: 'top',
        title: 'Failed to copy to your clipboard!',
        status: 'error',
        duration: 2000
      })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p={8}>
          <Text fontSize="2xl" textAlign={'center'} pb={1}>
            Invite Your Network
          </Text>
          <Text as="p" textAlign={'center'} pb={4}>
            Share the link below with your network, and they'll be added to your 1st Degree network.
          </Text>

          <Box position="relative" marginBottom="1rem">
            <Input variant="outline" fontSize="sm" value={shareUrl} isReadOnly />

            <Text
              as="button"
              position="absolute"
              top="50%"
              right="1rem"
              transform="translateY(-50%)"
              fontSize="small"
              onClick={handleCopyToClipboard}
              cursor="pointer"
              textColor="blue.400"
              fontWeight={600}
              display="flex"
              alignItems="center"
              zIndex={2}
            >
              {isCopied ? 'Copied!' : 'Copy'} <img src={CopyIcon} alt="Copy icon" style={{ marginLeft: '0.5rem' }} />
            </Text>
          </Box>
          <Text as="p" fontSize="xs" textColor="gray.600" lineHeight={1.3} textAlign="center" pb={4}>
            Remember: You're always in control of when your name is revealed alongside your relationships.
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ShareModal
