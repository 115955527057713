import { Image } from '@chakra-ui/react'
import icon from '~/assets/smallworld-icon.svg'

type SmallWorldIconProps = {
  size: number
  onClick?: () => void
  onLoad?: () => void
  isDisabled?: boolean
}

export const SmallWorldIcon = (props: SmallWorldIconProps) => {
  const handleClick = () => {
    if (props.onClick && !props.isDisabled) props.onClick()
  }

  const handleLoad = () => {
    if (props.onLoad) props.onLoad()
  }

  return (
    <Image
      src={icon}
      alt="SmallWorld Icon"
      maxH={props.size}
      maxW={props.size}
      cursor={props.isDisabled ? 'default' : 'pointer'}
      onClick={handleClick}
      onLoad={handleLoad}
    />
  )
}
