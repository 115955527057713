import { Box, Circle } from '@chakra-ui/react'
import { FiCheck } from 'react-icons/fi'

type CheckBoxCirlceProps = {
  isChecked: boolean
}

export const RequesterDashboardWelcomeBanner = ({ isChecked: isChecked }: CheckBoxCirlceProps) => {
  const circleSize = '1.8rem'
  const checkedStyle = { size: circleSize, bg: 'green.400' }
  const emptyStyle = { size: circleSize, border: '1px', borderColor: 'blue.100' }

  return (
    <>
      {isChecked && (
        <Circle {...checkedStyle} position={'relative'}>
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -45%)"
            height="100%"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FiCheck size="75%" />
          </Box>
        </Circle>
      )}

      {!isChecked && <Circle {...emptyStyle} />}
    </>
  )
}

export default RequesterDashboardWelcomeBanner
