import { Box, Button, Flex, Grid, GridItem, Link, Text, Tooltip, useToast } from '@chakra-ui/react'

import { RateRelationshipsApi } from '~/api'
import { useState, useEffect } from 'react'
import { emojis, getEmoji } from '~/lib'
import {relationshipStrengths} from '~/lib'
import RelationshipStrength from '~/lib/relationshipStrength'

type RateRelationshipCardProps = {
  relationship: Relationship
  onStrengthSelected?: (ratingStage: string) => void
}

export const RateRelationshipCard = ({ relationship, onStrengthSelected }: RateRelationshipCardProps) => {
  const { id, firstName, lastName, companyName, companyPosition, linkedinHeadline } = relationship
  const strengthChangeRequest = RateRelationshipsApi.useStrengthChangeRequest()
  const toast = useToast()

  const getBackgroundColor = (checkIfStrengthMatches: Strengths) => {
    return checkIfStrengthMatches === selectedStrength ? 'gray.100' : 'white'
  }

  const handleUndo = () => {
    // if setting it to null doesn't work, try handleClickRating(selectedStrength)
    // which acts the same way as (un)clicking the same rating again in the previous ui
    handleClickRating(null)
  }

  const handleClickRating = (strengthParam: Strengths) => {
    const strengthOrNull = strengthParam != selectedStrength ? strengthParam : null
    onStrengthSelected('initiated')
    if (cardState === 'rate') {
      setCardState('undo')
    } else {
      setCardState('rate')
    }

    setSelectedStrength(strengthOrNull)

    const strengthChange: RelationshipStrengthChange = {
      id,
      strength: strengthOrNull
    }

    strengthChangeRequest.mutate(strengthChange, {
      onSuccess: () => {
        onStrengthSelected('success')
        // toast.closeAll()

        // // If we want a
        // // confirmation toast
        // toast({
        //   position: 'top',
        //   title: 'Strength Captured!',
        //   status: 'success',
        //   duration: 3_000,
        // })
      },
      onError: (e) => {
        console.log(e)
        onStrengthSelected('error')
        toast({
          position: 'top',
          title: 'There was an error saving the strength of relationship.',
          status: 'error',
          duration: 3_000
        })
      }
    })
  }

  const [selectedStrength, setSelectedStrength] = useState<Strengths>(null)

  // 3 states: rate, undo, done
  const [cardState, setCardState] = useState('rate')

  useEffect(() => {
    let threeSecondsMillis: number = 3000
    let thirtySecondsMillis: number = 30000
    let fiveMinutesMillis: number = 300000
    let timeOutMilliseconds: number = threeSecondsMillis

    let timeoutId: number | undefined
    if (cardState === 'undo') {
      timeoutId = window.setTimeout(() => {
        setCardState('done')
      }, timeOutMilliseconds)
    }

    // Clear timeout if the component is unmounted or the state changes
    return () => window.clearTimeout(timeoutId)
  }, [cardState])

  const renderCompanyInfo = (companyName: string, companyPosition: string, linkedinHeadline: string) => {
    if (!companyName || !companyPosition) {
      return <>{linkedinHeadline}</>
    } else {
      return (
        <>
          <strong>{companyName}</strong> {companyPosition}
        </>
      )
    }
  }

  const selectedRelationshipStrength = RelationshipStrength.fromDatabaseValue(selectedStrength);

  return (
    <>
      <Box
        borderWidth="1px"
        borderColor="gray.200"
        borderRadius="md"
        className="pendo-relationship"
        mb="4"
        bg="white"
        transition="box-shadow 0.3s ease-in-out" // Add a smooth transition effect
        _hover={{
          boxShadow: '0 8px 40px rgba(0,0,0,.09)',
          borderColor: '#ccd1d6'
        }}
      >
        <Grid templateColumns="repeat(12, 1fr)">
          <GridItem rowSpan={1} colSpan={7}>
            <Flex flexDirection="column" justifyContent="center" pt={8} pb={8} pl={8} pr={4}>
              <Text fontWeight="600" fontSize="2xl" lineHeight={1.2}>
                {firstName} {lastName}
              </Text>
              <Text fontSize="sm">{renderCompanyInfo(companyName, companyPosition, linkedinHeadline)}</Text>
            </Flex>
          </GridItem>

          <GridItem colStart={8} colSpan={5}>
            <Flex flexDirection="column" justifyContent="center" pt={5} pr={4} pb={8}>
              <Grid templateColumns="repeat(1, 1fr)">
                <GridItem colSpan={5} pb={1}>
                  <Text w="100%" fontSize="xs" color="gray.600" textAlign="center">
                    {cardState === 'rate' && 'Rate'} Relationship Strength
                  </Text>
                </GridItem>
                {cardState === 'rate' && (
                  <Grid
                    templateColumns="repeat(5, 1fr)"
                    p={2}
                    borderWidth="1px"
                    borderRadius="sm"
                    sx={{
                      border: '1px solid #E1E5E9'
                    }}
                  >
                    {relationshipStrengths.map((relationshipStrength, index) => (
                      <Tooltip
                        label={relationshipStrength.descriptor}
                        key={index}
                        placement="top"
                        py={1}
                        borderRadius={5}
                        px={3}
                        hasArrow
                      >
                        <GridItem
                          colStart={index + 1}
                          colSpan={1}
                          onClick={() => handleClickRating(relationshipStrength.databaseValue)}
                        >
                          <Text
                            textAlign="center"
                            bg={getBackgroundColor(relationshipStrength.databaseValue)}
                            fontSize="xl"
                            sx={{
                              cursor: 'pointer',
                              borderRight: index === emojis.length - 1 ? 'none' : '1px solid #E1E5E9'
                            }}
                          >
                            {relationshipStrength.emoji}
                          </Text>
                        </GridItem>
                      </Tooltip>
                    ))}
                  </Grid>
                )}
                {cardState !== 'rate' && (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    borderWidth="1px"
                    borderRadius="sm"
                    py="14px"
                    fontSize={12}
                    sx={{
                      border: '1px solid #E1E5E9'
                    }}
                  >
                    <Text mr={2}>
                      {selectedRelationshipStrength?.emoji}{' '}
                      <Text fontWeight="bold" as="span">
                        {selectedRelationshipStrength?.descriptor}
                      </Text>
                    </Text>

                    {cardState === 'undo' && (
                      <Link
                        onClick={handleUndo}
                        color="gray.600"
                        sx={{
                          textDecoration: 'underline'
                        }}
                      >
                        Undo
                      </Link>
                    )}
                  </Flex>
                )}{' '}
                {/* close card state undo */}
              </Grid>
            </Flex>
          </GridItem>
        </Grid>
      </Box>
    </>
  )
}
