import { Tooltip, Flex } from '@chakra-ui/react'
import React from 'react'

export type RelationshipCardButtonProps = {
  onClick: () => void
  label: string
  buttonIndex: number
  totalButtons: number
  color: string
  icon: React.ReactElement
}

export const RelationshipCardButton = (props: RelationshipCardButtonProps) => {
  const isLastButton = props.buttonIndex === props.totalButtons - 1
  return (
    <Tooltip label={props.label} placement="top" color="white" fontSize="xs" bg="black" py={2} hasArrow>
      <Flex
        flex="1"
        justifyContent="center"
        alignItems="center"
        borderRightColor={!isLastButton && 'gray.200'}
        borderRightWidth={!isLastButton && '1px'}
        onClick={props.onClick}
        cursor="pointer"
        _hover={{
          svg: { color: props.color }
        }}
      >
        {props.icon}
      </Flex>
    </Tooltip>
  )
}
