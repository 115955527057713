import { Box, Flex, Text } from '@chakra-ui/react'
import { RelationshipLeadStore } from '~/stores'
import { AssignIcon } from './AssignIcon'

type Props = {
  relationshipLead: RelationshipLead
}

export const LeadAssignedContent = (props: Props) => {
  const assignedLeads = RelationshipLeadStore.useStore((state) => state.assignedLeads)
  const assignedLead = assignedLeads.find(
    (assignedLead) =>
      assignedLead.prospectId === props.relationshipLead.prospect.id &&
      assignedLead.relationshipId === props.relationshipLead.relationship.id
  )

  const prospectName = `${props.relationshipLead.prospect.firstName} ${props.relationshipLead.prospect.lastName}`
  const assigneeName = `${assignedLead?.requesterFirstName} ${assignedLead?.requesterLastName}`
  const assigneeText = assignedLead && (
    <>
      <Text as="span">to </Text>
      <Text as="span" fontWeight="bold">
        {assigneeName}
      </Text>
    </>
  )
  return (
    <Flex fontSize="xs" direction="column" flex="2" justifyContent="center" alignItems="center" p={4} width="100%">
      <Box fontWeight="bold" lineHeight=".7rem" mb={1}>
        <AssignIcon boxSize={4} color="green.400" mr={1} mb={1} fill="none" />
        {prospectName}
      </Box>
      <Box fontWeight="normal" whiteSpace="nowrap">
        was Assigned {assigneeText}
      </Box>
    </Flex>
  )
}
