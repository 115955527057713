import { useQuery } from '@tanstack/react-query'
import { BASE_URL } from '~/settings'
import { request } from './utils'

const fetchMembership = (id: number) => {
  const url = new URL(`${BASE_URL}/iam/memberships/${id}`)
  return request<null, { [key: string]: any }>(url) // TODO: Conflicts with Membership in iam.d.ts
}

export const useMembershipQuery = (id: number) => {
  return useQuery({
    queryKey: ['memberships', id],
    queryFn: () => fetchMembership(id),
    enabled: !!id
  })
}
