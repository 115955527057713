import { Box } from '@chakra-ui/react'
import { useAuth } from '~/auth'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import MultiSelectMenuChakra from '~/components/MultiSelectMenuChakra'
import useDiscoverStore from '~/stores/discover'
import useExpressStore from '~/stores/express'
import { useShallow } from 'zustand/react/shallow'
import { PeopleApi, RequesterDashboardApi } from '~/api'

// Define the component
export const ConnectorsFilter = () => {
  const location = useLocation()
  const auth = useAuth()

  // Component (True) Constant Variables
  const REQ_DASH_PREFIX = 'req_dash_'
  const REQ_DASH_EXCLUDE_CONN_IDS = `${REQ_DASH_PREFIX}exclude_conn_ids`
  const REQ_DASH_SHOW_ANON_CONNECTORS = `${REQ_DASH_PREFIX}show_anon_connectors`

  //region Store Values
  const membership = useExpressStore((state) => state.membership)
  const { excludedConnectors, setExcludedConnectors, showAnonFilter, setShowAnonFilter } = useDiscoverStore(
    useShallow((state) => ({
      excludedConnectors: state.excludedConnectors,
      setExcludedConnectors: state.setExcludedConnectors,
      showAnonFilter: state.showAnonFilter,
      setShowAnonFilter: state.setShowAnonFilter
    }))
  )

  //region Query and Mutation Functions
  const saveMemberSettingsRequest = RequesterDashboardApi.useSaveMemberSettingsRequest()
  const getNamedConnectorsRequest = PeopleApi.useGetNamedConnectorsRequest(auth.organizationId)
  const getMemberSettingExcludeConnIdsRequest = RequesterDashboardApi.useGetMemberSettingRequest(
    !excludedConnectors,
    membership.id,
    REQ_DASH_EXCLUDE_CONN_IDS
  )
  const getMemberSettingShowAnonConnsRequest = RequesterDashboardApi.useGetMemberSettingRequest(
    showAnonFilter === null,
    membership.id,
    REQ_DASH_SHOW_ANON_CONNECTORS
  )

  //region Component State
  const [excludeConnIdsParamProcessed, setExcludeConnIdsParamProcessed] = useState(false)
  const [showAnonConnsParamProcessed, setShowAnonConnsParamProcessed] = useState(false)
  const [connectorMenuOptions, setConnectorMenuOptions] = useState<MenuOption[]>([])
  const [showAnonFilterString, setShowAnonFilterString] = useState(null)
  const [connectorSelectedCount, setConnectorSelectedCount] = useState(0)

  // region Functions
  function countSelectedOptions(str: string): number {
    if (!str || typeof str !== 'string') {
      return 0
    }

    // Count non-empty, trimmed words
    return str.split(',').filter((word) => word.trim() !== '').length
  }
  // region Use Effects
  //exclude conn settings use effect
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const excludeConnIdsParam = searchParams.get('excludeConnIds')
    if (!excludeConnIdsParamProcessed && excludeConnIdsParam) {
      setExcludedConnectors(excludeConnIdsParam)
      setExcludeConnIdsParamProcessed(true)
    } else if (
      !getMemberSettingExcludeConnIdsRequest.isFetching &&
      getMemberSettingExcludeConnIdsRequest.data &&
      !excludedConnectors
    ) {
      const filtStringToSet = getMemberSettingExcludeConnIdsRequest.data.value
      setExcludedConnectors(filtStringToSet)
    }
  }, [
    getMemberSettingExcludeConnIdsRequest.isFetching,
    getMemberSettingExcludeConnIdsRequest.data,
    excludeConnIdsParamProcessed,
    location,
    excludedConnectors,
    membership,
    setExcludedConnectors
  ])

  //show anon database settings use effect
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const showAnonConnsParam = searchParams.get('showAnonConns')
    if (!showAnonConnsParamProcessed && showAnonConnsParam) {
      setShowAnonFilter(showAnonConnsParam === 'true')
      setShowAnonConnsParamProcessed(true)
    } else if (
      !getMemberSettingShowAnonConnsRequest.isFetching &&
      getMemberSettingShowAnonConnsRequest.data &&
      !showAnonFilter
    ) {
      const filtStringToSet = getMemberSettingShowAnonConnsRequest.data.value
      setShowAnonFilter(filtStringToSet === 'true')
    }
  }, [
    getMemberSettingShowAnonConnsRequest.isFetching,
    getMemberSettingShowAnonConnsRequest.data,
    showAnonConnsParamProcessed,
    location,
    showAnonFilter,
    membership,
    setShowAnonFilter
  ])

  //named connectors list and Exclude conn ids filter string useEffect
  useEffect(() => {
    if (!getNamedConnectorsRequest.isLoading && getNamedConnectorsRequest.data && excludedConnectors != null) {
      const superConnectorMenuOption: MenuOption = {
        label: 'Super Connectors',
        value: 'Super Connectors',
        isSelected: true
      }

      let menuOptsToAdd: MenuOption[] = []

      if (Boolean(getNamedConnectorsRequest.data?.people)) {
        menuOptsToAdd = getNamedConnectorsRequest.data?.people
          ?.sort((a, b) => {
            let nameA = a.firstName ?? ''
            let nameB = b.firstName ?? ''
            return nameA.localeCompare(nameB)
          })
          .reduce((options, person) => {
            let fullName = ''
            if (person.firstName) fullName += person.firstName
            if (person.firstName && person.lastName) fullName += ' '
            if (person.lastName) fullName += person.lastName

            const menuOption: MenuOption = {
              label: fullName,
              value: person.id.toString(),
              isSelected: true
            }
            //
            options.push(menuOption)
            return options
          }, [])

        let excludeConnIdsStringArr: string[] = []

        if (excludedConnectors?.length > 0) {
          excludeConnIdsStringArr = excludedConnectors.split(',')
        }

        menuOptsToAdd.map((a) => {
          if (excludeConnIdsStringArr.includes(a.value)) {
            a.isSelected = false
          }
        })
      }
      setConnectorMenuOptions([superConnectorMenuOption, ...menuOptsToAdd])
    }
  }, [
    getNamedConnectorsRequest.isLoading,
    getNamedConnectorsRequest.data,
    excludedConnectors,
    setConnectorMenuOptions,
    membership
  ])

  // when connector menu options changes, calculate count selected
  useEffect(() => {
    if (showAnonFilter == null || excludedConnectors == null || connectorMenuOptions.length == 0) {
      // console.log(`cant set connector count pill yet bc required info missing`)
      return
    }

    let countUnselected = showAnonFilter ? 0 : 1

    countUnselected += countSelectedOptions(excludedConnectors)

    const countTotal = connectorMenuOptions.length

    setConnectorSelectedCount(countTotal - countUnselected)
  }, [showAnonFilter, excludedConnectors, connectorMenuOptions, connectorSelectedCount])

  // show anon filter string use effect
  useEffect(() => {
    if (showAnonFilterString?.length > 0 && connectorMenuOptions?.length > 0) {
      const showSupers = showAnonFilterString === 'true'
      connectorMenuOptions.filter((a) => a.value === 'Super Connectors')[0].isSelected = showSupers

      setConnectorMenuOptions(connectorMenuOptions)
      setShowAnonFilter(showSupers)
    }
  }, [showAnonFilterString, connectorMenuOptions, setConnectorMenuOptions, setShowAnonFilter])

  const handleOnCloseConnectors = (menuOptionsParam: MenuOption[]) => {
    const unSelectedNamedConnectorIds: string[] = menuOptionsParam
      .filter((a) => {
        return !a.isSelected && a.label !== 'Super Connectors'
      })
      .map((a) => a.value)
    //

    const unSelectedNamedConnectorIdsCsvString: string = unSelectedNamedConnectorIds.join(',')

    const showAnonymous: string = menuOptionsParam.filter((a) => a.label === 'Super Connectors')[0].isSelected
      ? 'true'
      : 'false'

    if (unSelectedNamedConnectorIdsCsvString !== excludedConnectors || showAnonymous !== showAnonFilterString) {
      setExcludedConnectors(unSelectedNamedConnectorIdsCsvString)
      setShowAnonFilterString(showAnonymous)

      const excludeConnIdsSettingsChange: MemberSetting = {
        organizationMemberId: membership.id,
        value: unSelectedNamedConnectorIdsCsvString,
        name: REQ_DASH_EXCLUDE_CONN_IDS
      }

      saveMemberSettingsRequest.mutate(excludeConnIdsSettingsChange, {
        onSuccess: () => {},
        onError: (e) => {
          console.error(e)
        }
      })

      //save show anon
      const showAnonMemberSettingsChange: MemberSetting = {
        organizationMemberId: membership.id,
        value: showAnonymous,
        name: REQ_DASH_SHOW_ANON_CONNECTORS
      }

      saveMemberSettingsRequest.mutate(showAnonMemberSettingsChange, {
        onSuccess: () => {},
        onError: (e) => {
          console.error(e)
        }
      })
    }
  }

  return connectorMenuOptions.length > 1 ? (
    <Box mr={2}>
      <MultiSelectMenuChakra
        menuOptions={connectorMenuOptions}
        menuTitle="Connectors"
        menuSelectedCount={connectorSelectedCount}
        onClose={handleOnCloseConnectors}
      />
    </Box>
  ) : null
}
