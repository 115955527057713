import { Circle } from '@chakra-ui/react'

type PendoTriggerProps = {
  bgColor: string
}

export const PendoTrigger = ({ bgColor }: PendoTriggerProps) => {
  return (
    <Circle
      bg={bgColor}
      size={20}
      id="pendo-trigger"
      position={'fixed'}
      bottom="1rem"
      right="1rem"
      fontSize={32}
      lineHeight={0}
      fontWeight="bold"
      textColor={'white'}
      zIndex={99999}
      cursor={'pointer'}
    >
      ?
    </Circle>
  )
}
