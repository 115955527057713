import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { request, RequestConfig } from './utils'
import { API_PATH_SWAG } from '~/settings'

const NAMESPACE_SWAG = 'target-companies'

const BASE_URL_SWAG = `${import.meta.env.VITE_API_GW_URL}${API_PATH_SWAG}/${NAMESPACE_SWAG}`

type DeleteConfimation = {
  deleteStatus: string
}

type CreateTargetCompanyRequestBody = {
  organizationMemberId: number
  companyId: number
}

type CreateBulkTargetCompaniesRequestBody = {
  memberId: number
  companyNames: string
  personId: number
}

//region create targ comp
export const createTargetCompany = async (targetCompany: CreateTargetCompanyRequestBody): Promise<ReturnRecordId> => {
  const url = new URL(`${BASE_URL_SWAG}/`)

  const options: RequestConfig<CreateTargetCompanyRequestBody> = { body: targetCompany }
  const result = await request<CreateTargetCompanyRequestBody, ReturnRecordId>(url, 'POST', options)

  return result
}

export const useCreateTargetCompany = (
  options?: UseMutationOptions<ReturnRecordId, Error, CreateTargetCompanyRequestBody>
) => useMutation<ReturnRecordId, Error, CreateTargetCompanyRequestBody>(createTargetCompany, options)
//endregion create targ comp

//region bulk create targ comp
export const createBulkTargetCompanies = async (
  targetCompanies: CreateBulkTargetCompaniesRequestBody
): Promise<ReturnRecordId> => {
  const url = new URL(`${BASE_URL_SWAG}/bulk-upload`)

  const options: RequestConfig<CreateBulkTargetCompaniesRequestBody> = { body: targetCompanies }
  const result = await request<CreateBulkTargetCompaniesRequestBody, ReturnRecordId>(url, 'POST', options)

  return result
}

export const useCreateBulkTargetCompanies = (
  options?: UseMutationOptions<ReturnRecordId, Error, CreateBulkTargetCompaniesRequestBody>
) => useMutation<ReturnRecordId, Error, CreateBulkTargetCompaniesRequestBody>(createBulkTargetCompanies, options)
//endregion bulk create targ comp

//region delete targ comp
export const deleteTargetCompany = async (targetCompanyId: number): Promise<DeleteConfimation> => {
  const url = new URL(`${BASE_URL_SWAG}/${targetCompanyId}`)

  const result = await request<null, DeleteConfimation>(url, 'DELETE')

  return result
}

export const useDeleteTargetCompany = (options?: UseMutationOptions<DeleteConfimation, Error, number>) =>
  useMutation<DeleteConfimation, Error, number>(deleteTargetCompany, options)
//endregion delete targ comp

//region delete all targ comps
export const deleteAllTargetCompanies = async (memberId: number): Promise<DeleteConfimation> => {
  const url = new URL(`${BASE_URL_SWAG}/member/${memberId}`)

  const result = await request<null, DeleteConfimation>(url, 'DELETE')

  return result
}

export const useDeleteAllTargetCompanies = (options?: UseMutationOptions<DeleteConfimation, Error, number>) =>
  useMutation<DeleteConfimation, Error, number>(deleteAllTargetCompanies, options)
//endregion delete all targ comps

//region get targ comps for member
export const getTargetCompaniesForMember = async (memberId: number): Promise<TargetCompany[]> => {
  const url = new URL(`${BASE_URL_SWAG}/${memberId}`)

  const response = await request<null, TargetCompany[]>(url, 'GET')

  // Alphabetize the Target Companies based on companyName
  response.sort((a, b) => {
    const nameA = (a.companyName || '').toLowerCase()
    const nameB = (b.companyName || '').toLowerCase()

    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  })

  return response
}

export const useGetTargetCompaniesForMember = (memberId: number, options?: UseQueryOptions<TargetCompany[], Error>) => {
  return useQuery<TargetCompany[], Error>(
    ['getTargetCompaniesForMember', memberId],
    () => getTargetCompaniesForMember(memberId),
    {
      enabled: Boolean(memberId),
      ...options
    }
  )
}
//endregion get targ comps for member

//region get bulk upload status
export const getBulkUploadStatus = async (memberId: number): Promise<BulkUploadStatus> => {
  const url = new URL(`${BASE_URL_SWAG}/bulk-upload/${memberId}`)

  const response = await request<null, BulkUploadStatus>(url, 'GET')

  return response
}

export const useGetBulkUploadStatus = (memberId: number, options?: UseQueryOptions<BulkUploadStatus, Error>) => {
  return useQuery<BulkUploadStatus, Error>(['getBulkUploadStatus', memberId], () => getBulkUploadStatus(memberId), {
    enabled: Boolean(memberId),
    ...options
  })
}
//endregion get bulk upload status
