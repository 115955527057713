import { useQuery } from '@tanstack/react-query'
import { useAuth } from '~/auth'
import { BASE_URL } from '~/settings'
import { request } from './utils'

type IamMeCountsResponse = {
  data: {
    relationshipCounts: {
      total: number
      rated: number
    }
    relationshipLeadCounts: {
      total: number
      enterprise: number
      superConnector: number
    }
    networkSize: number
  }
}

export function useMeCountsQuery() {
  const url = new URL(`${BASE_URL}/iam/me/counts`)
  const { userId } = useAuth()

  const fetchMeCounts = (): Promise<IamMeCountsResponse> => request(url, 'GET')

  return useQuery({
    queryKey: ['MECounts', userId],
    queryFn: fetchMeCounts,
    enabled: !!userId,
    cacheTime: Infinity,
    staleTime: Infinity,
    placeholderData: {
      data: {
        relationshipCounts: {
          total: 0,
          rated: 0
        },
        relationshipLeadCounts: {
          total: 0,
          enterprise: 0,
          superConnector: 0
        },
        networkSize: 0
      }
    },
    select: (data) => data.data
  })
}
