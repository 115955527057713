import { Box, Button, Flex, Text } from '@chakra-ui/react'

import { StarIcon } from '@smallworld-io/pangaea'

import { UndoButton } from './UndoButton'
import { Link } from 'react-router-dom'

export type SaveRelationshipContentProps = {
  firstName: string
  lastName: string
  savedLeadsUrl: string
  onBack: () => void
}

// TODO: Fix hover of button

export const SaveRelationshipContent = ({
  firstName,
  lastName,
  savedLeadsUrl,
  onBack
}: SaveRelationshipContentProps) => {
  return (
    <Flex direction="column" flex="1" justify="center" align="center" width="100%" position="relative">
      <UndoButton onClick={onBack} />
      <Flex direction="column" flex="2" justifyContent="center" alignItems="center" p={4} width="100%">
        <Text fontSize="xs" align="center" fontWeight="bold" lineHeight=".7rem" mb={1}>
          <StarIcon boxSize={4} mr={1} mb={1} mt={0.5} color="yellow.400" fill="yellow.400" fillOpacity={0.22} />
          {firstName} {lastName}{' '}
          <Box as="span" fontWeight="normal" whiteSpace="nowrap">
            was Saved.
          </Box>
        </Text>
        <Button
          as={Link}
          to={savedLeadsUrl}
          state={{ from: location.pathname }}
          fontSize="xs"
          bg="yellow.400"
          color="white"
          fontWeight="bold"
        >
          View Saved Leads
        </Button>
      </Flex>
    </Flex>
  )
}
