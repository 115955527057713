import { Button, Checkbox, Flex, FormControl, Select, Text, useToast } from '@chakra-ui/react'
import { UndoButton } from './UndoButton'
import { useState } from 'react'
import { AssignIcon } from './AssignIcon'
import { RelationshipLeadStore } from '~/stores'
import { InviteRequesterModal } from './InviteRequesterModal'
import { AssignAllLeadsForCompanyOptions, AssignLeadOptions } from '~/api'
import { useAuth } from '~/auth'

const NEW_REQUESTER_ID = 'new_requester'

type AssignContentProps = {
  onBack: () => void
  onAssign: (assignmentOptions: AssignLeadOptions) => void
  onAssignAll: (assignmentOptions: AssignAllLeadsForCompanyOptions) => void
  onInvite: (invitationEmail: string) => void
  relationshipLead: RelationshipLead
}

export const AssignContent = (props: AssignContentProps) => {
  const prospectCompany = props.relationshipLead.prospect.company
  const { prospectCompanyLeadCount, requesters } = RelationshipLeadStore.useStore((state) => ({
    prospectCompanyLeadCount: state.prospectCompanyCounts[prospectCompany.id],
    requesters: state.accountRequesters
  }))
  const { personId, accountId } = useAuth()
  const toast = useToast()

  const [isInvalid, setIsInvalid] = useState(false)
  const [selectedRequesterId, setSelectedRequesterId] = useState('')
  const [assignAllSelected, setAssignAllSelected] = useState(false)
  const [inviteModalOpen, setInviteModalOpen] = useState(false)

  function handleAssign() {
    setIsInvalid(false)
    if (!selectedRequesterId) {
      setIsInvalid(true)
      return
    }

    const selectedRequester = requesters.find((requester) => requester.id === Number(selectedRequesterId))
    if (!selectedRequester) {
      setSelectedRequesterId('')
      return
    }

    const relationshipId = props.relationshipLead.relationship.id
    if (!accountId) {
      toast({
        position: 'top',
        title: 'Error: no account Id found for the current user',
        status: 'error',
        duration: 10000,
        isClosable: true
      })
      return
    }

    if (!personId) {
      toast({
        position: 'top',
        title: 'Error: No user Id found for the current user',
        status: 'error',
        duration: 10000,
        isClosable: true
      })
      return
    }

    if (assignAllSelected) {
      const assignmentOptions: AssignAllLeadsForCompanyOptions = {
        requesterId: selectedRequester.id,
        accountId,
        prospectCompanyId: prospectCompany.id,
        assignedById: personId
      }
      props.onAssignAll(assignmentOptions)
    } else {
      const assignmentOptions: AssignLeadOptions = {
        requesterId: selectedRequester.id,
        accountId,
        relationshipId,
        assignedById: personId
      }
      props.onAssign(assignmentOptions)
    }
  }

  function handleOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const selectedRequesterId = e.target.value
    if (selectedRequesterId === NEW_REQUESTER_ID) {
      setInviteModalOpen(true)
    } else {
      setSelectedRequesterId(selectedRequesterId)
    }
  }

  return (
    <>
      <Flex direction="column" flex="1" justify="center" align="center" width="100%" position="relative" p={4} gap={2}>
        <UndoButton onClick={props.onBack} label="Cancel" />
        <Text fontSize="md" align="center" lineHeight=".7rem" mb={2}>
          <AssignIcon boxSize={4} mr={2} mt={-1} color="green.400" fill="none" />
          Assign Lead
        </Text>
        <FormControl isInvalid={isInvalid}>
          <Select placeholder="Choose Requester" onChange={handleOnChange} value={selectedRequesterId}>
            {requesters.map((requester) => (
              <option key={requester.id} value={requester.id}>
                {`${requester.firstName} ${requester.lastName}`}
              </option>
            ))}
            <option disabled>— — —</option>
            <option value={NEW_REQUESTER_ID}>Invite New Requester</option>
          </Select>
        </FormControl>
        {prospectCompanyLeadCount && prospectCompanyLeadCount > 1 && (
          <FormControl>
            <Checkbox checked={assignAllSelected} onChange={(e) => setAssignAllSelected(e.target.checked)} ml={2}>
              <Text fontSize="xs" color="black">
                Assign all Leads at
                <Text as="span" ml={1} display="inline" whiteSpace="nowrap">
                  {prospectCompany.name} ({prospectCompanyLeadCount})
                </Text>
              </Text>
            </Checkbox>
          </FormControl>
        )}
        <Button onClick={handleAssign} disabled={!selectedRequesterId} size="sm">
          Assign
        </Button>
      </Flex>

      <InviteRequesterModal
        isOpen={inviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
        onSuccess={props.onInvite}
        prospectCompanyName={prospectCompany.name}
        relationshipStrength={props.relationshipLead.relationship.strength}
        accountId={accountId}
        adminPersonId={personId}
      />
    </>
  )
}
