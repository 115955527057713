import { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Flex, Text } from '@chakra-ui/react'

import { UndoIcon } from '@smallworld-io/pangaea'

export const UndoButton = ({ onClick, label }: { onClick: () => void; label?: string }) => {
  const [hovering, setHovering] = useState(false)

  return (
    <Flex align="baseline" position="absolute" top={1} left={1}>
      <motion.div onHoverStart={() => setHovering(true)} onHoverEnd={() => setHovering(false)}>
        <UndoIcon onClick={onClick} cursor="pointer" color="gray.300" _hover={{ color: 'gray.600' }} />
      </motion.div>
      <AnimatePresence>
        {hovering && (
          <motion.span
            initial={{ opacity: 0, x: 4 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{
              duration: 0.2
            }}
          >
            <Text color="gray.600" bgColor="white" cursor="pointer" casing="uppercase" fontWeight={700} fontSize="11px">
              {label || 'Undo'}
            </Text>
          </motion.span>
        )}
      </AnimatePresence>
    </Flex>
  )
}
