import { useState } from 'react'
import {
  Box,
  Button,
  Flex,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text
} from '@chakra-ui/react'
import { BaseCard } from './BaseCard'
import { isCanceled, isCompleted, isDeclined, isInProgress, statusColors } from './lib'
import { useUserRoleIn } from './hooks'
import { useCancelIntroductionMutation } from '~/api/introductions'
import { fullname } from '~/lib/fullname'
import { ConnectorCallToActionCard } from './ConnectorCallToActionCard'
import { useCompleteIntroductionMutation } from '~/api/introductions'

type CallToActionProps = {
  introduction: Introduction
  user: User
  currentMembership: Membership
  onAccept: () => void
}

const CallToActionRequester = ({
  introduction,
  onCancelClick,
  onCompleteClick
}: {
  introduction: Introduction
  onCancelClick: () => void
  onCompleteClick: () => void
}) => {
  let content = "You'll be notified when the Super Connector responds to your introduction request."

  if (isCanceled(introduction)) {
    content = `Your introduction request to ${fullname(introduction.prospect)} was canceled.`
  }

  if (isDeclined(introduction)) {
    content = `Your introduction request to ${fullname(introduction.prospect)} was declined.`
  }

  if (isInProgress(introduction)) {
    content = `Your introduction request to ${fullname(introduction.prospect)} was accepted.`
  }

  if (isCompleted(introduction)) {
    content = `Your introduction request to ${fullname(introduction.prospect)} was completed.`
  }

  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false)

  const openCancelModal = () => setIsCancelModalOpen(true)
  const closeCancelModal = () => setIsCancelModalOpen(false)

  const openCompleteModal = () => setIsCompleteModalOpen(true)
  const closeCompleteModal = () => setIsCompleteModalOpen(false)

  return (
    <>
      <Box px={4} pb={5} textAlign="center">
        <Text fontSize="md" pb={2}>
          {content}
        </Text>

        <Flex align="center" justifyContent="center" gap=".5rem" width="full">
          {isInProgress(introduction) && (
            <Link
              fontSize="xs"
              color="blue.400"
              pr={2}
              _hover={{ textDecoration: 'underline' }}
              cursor="pointer"
              fontWeight="medium"
              borderRightColor="gray.300"
              borderRightWidth="1px"
              borderRightStyle="solid"
              onClick={openCompleteModal}
            >
              Mark Completed
            </Link>
          )}
          {!(isCompleted(introduction) || isDeclined(introduction) || isCanceled(introduction)) && (
            <Link
              fontSize="xs"
              textDecoration="none"
              color="blue.400"
              fontWeight="medium"
              _hover={{ textDecoration: 'underline' }}
              onClick={openCancelModal}
              cursor="pointer"
            >
              Cancel Introduction
            </Link>
          )}
        </Flex>
      </Box>

      <Modal isOpen={isCancelModalOpen} onClose={closeCancelModal} size="lg">
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader>Are you sure you want to cancel this introduction?</ModalHeader>
          <ModalFooter>
            <Button
              bg="red"
              mr={3}
              onClick={() => {
                onCancelClick()
                closeCancelModal()
              }}
            >
              Cancel
            </Button>
            <Button variant="ghost" onClick={closeCancelModal}>
              Dismiss
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isCompleteModalOpen} onClose={closeCompleteModal} size="lg">
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader>Are you sure you want to mark this introduction as completed?</ModalHeader>
          <ModalBody>
            Please only mark the introduction completed if you've received the introduction to the Prospect.
          </ModalBody>
          <ModalFooter>
            <Button
              bg="green.400"
              mr={3}
              onClick={() => {
                onCompleteClick()
                closeCompleteModal()
              }}
            >
              Yes
            </Button>
            <Button onClick={closeCompleteModal} variant="ghost">
              Dismiss
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export const CallToAction = ({ introduction, user, currentMembership, onAccept }: CallToActionProps) => {
  const { status } = introduction
  const { bg, color } = statusColors(status)

  const { isConnector, isRequester } = useUserRoleIn(introduction, user, currentMembership)

  if (!introduction) {
    return <BaseCard mb={4}>Loading...</BaseCard>
  }

  const completeMutation = useCompleteIntroductionMutation(introduction)

  const cancelMutation = useCancelIntroductionMutation({
    membershipId: currentMembership.id,
    introId: introduction.id
  })

  const handleOnCancelClick = () => {
    cancelMutation.mutate()
  }

  const handleOnCompleteClick = () => {
    completeMutation.mutate({
      membership: currentMembership,
      personId: user.personId,
      note: ''
    })
  }

  return (
    <BaseCard mb={4}>
      <Box px={8} py={4} textAlign="center">
        <Text
          as="span"
          borderColor={status === 'Completed' ? 'green.400' : 'gray.300'}
          borderStyle="solid"
          borderWidth={1}
          bg={bg}
          color={color}
          borderRadius="full"
          fontSize="2xs"
          fontWeight="bold"
          textTransform="uppercase"
          px={2}
          py={1}
        >
          {status}
        </Text>
      </Box>
      {isConnector && (
        <ConnectorCallToActionCard
          introduction={introduction}
          user={user}
          currentMembership={currentMembership}
          onAccept={onAccept}
        />
      )}
      {isRequester && (
        <CallToActionRequester
          introduction={introduction}
          onCancelClick={handleOnCancelClick}
          onCompleteClick={handleOnCompleteClick}
        />
      )}
    </BaseCard>
  )
}
