import { Box, Button, Container, Flex, Text, useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { BASE_URL, PAGE_WIDTH } from '~/settings'
import { useSignUpStore } from '~/stores/signUp'

import { LabeledInput } from '@smallworld-io/pangaea'
import { useNavigate } from 'react-router-dom'

const VALID_EMAIL_PATTERN = /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/i
const VALID_PASSWORD_PATTERN = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])/

type Form = {
  email: string
  password: string
  passwordConfirmation: string
}

const credentialsUrl = `${BASE_URL}/sign_up/credentials`

export const CollectCredentials = () => {
  const toast = useToast()
  const navigate = useNavigate()

  const isVerified = useSignUpStore((state) => state.verified)
  const phone = useSignUpStore((state) => state.phone)
  const updateEmail = useSignUpStore((state) => state.updateEmail)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const { control, handleSubmit } = useForm<Form>()

  useEffect(() => {
    if (isVerified) {
      toast({
        position: 'top',
        title: 'Phone Number Verified',
        description: 'Your phone number has previously been verified, thank you.',
        status: 'info',
        duration: 10_000,
        isClosable: true
      })
    }
  }, [isVerified])

  const submitHandler: SubmitHandler<Form> = useCallback(
    async ({ email, password }) => {
      setIsSubmitting(true)
      updateEmail(email)
      try {
        const response = await fetch(credentialsUrl, {
          method: 'post',
          credentials: 'omit',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ phonenumber: phone, email, password })
        })
        const data = await response.json()
        if (response.ok && data.ok) {
          // Handle Successful Credentials Confirmation
          navigate('../sync')
        } else if (data.errors && data.errors.user === 'enterprise') {
          // Handle Enterprise User Sign-Up
          toast({
            position: 'top',
            title: 'Enterprise Email Detected',
            description:
              'SmallWorld Enterprise emails cannot be used for sign-up. Please use a different email address.',
            status: 'error',
            duration: 9000,
            isClosable: true
          })
        } else if (data.errors && data.errors.user === 'existing') {
          // Redirect to Login Screen and Show Toast for Existing User
          toast({
            position: 'top',
            title: 'Account Already Exists',
            description: 'An account with this email already exists. Please sign in or reset your password.',
            status: 'info',
            duration: 9000,
            isClosable: true
          })
          navigate('/login')
        } else {
          // Handle Generic Errors
          toast({
            position: 'top',
            title: 'Error',
            description: 'There was an error submitting your request. Please try again.',
            status: 'error',
            duration: 9000,
            isClosable: true
          })
        }
      } catch (err) {
        // Catch Network Error
        toast({
          position: 'top',
          title: 'Network Error',
          description: 'Unable to connect to the server. Please check your internet connection and try again.',
          status: 'error',
          duration: 9000,
          isClosable: true
        })
        console.log('🧨 '.repeat(20))
        console.error('Network Error During Form Submission:', err)
      } finally {
        setIsSubmitting(false)
      }
    },
    [navigate, phone, setIsSubmitting, toast, updateEmail]
  )

  return (
    <Flex direction="column" align="center" maxW={PAGE_WIDTH} px={4}>
      <Container maxW={600} textAlign="center" py={8}>
        <Text as="h1" fontSize="4xl" lineHeight={1.2} pb={4}>
          Create Your Account
        </Text>
        <Text>
          Your password should be at least 8 characters and include at least one uppercase letter, one lowercase letter,
          and one number.
        </Text>
      </Container>

      <Box w="full" maxW={400}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <Box pb={4}>
            <Controller
              name="email"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                pattern: VALID_EMAIL_PATTERN
              }}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => (
                <LabeledInput
                  label="Email Address"
                  placeholder="email@address.com"
                  type="email"
                  ref={ref}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  isInvalid={invalid}
                  tooltip="This email address will serve as the primary means of communication between you and SmallWorld, as well as for interactions with other Super Connectors."
                  data-hj-allow
                />
              )}
            />
          </Box>
          <Box pb={4}>
            <Controller
              name="password"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                minLength: 8,
                pattern: VALID_PASSWORD_PATTERN
              }}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => (
                <LabeledInput
                  label="Password"
                  type="password"
                  ref={ref}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  isInvalid={invalid}
                  autoComplete="new-password"
                />
              )}
            />
          </Box>
          <Box pb={4}>
            <Controller
              name="passwordConfirmation"
              defaultValue=""
              control={control}
              rules={{
                required: true,
                validate: (value, formValues) => {
                  return value === formValues['password']
                }
              }}
              render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => (
                <LabeledInput
                  label="Password Confirmation"
                  type="password"
                  ref={ref}
                  name={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  isInvalid={invalid}
                  autoComplete="new-password"
                />
              )}
            />
          </Box>
          <Box pb={4}>
            <Button type="submit" isLoading={isSubmitting}>
              Continue
            </Button>
          </Box>
        </form>
      </Box>
    </Flex>
  )
}
