export const uriToObject = (uri: string) => {
  const url = uri.split('?')[0]
  const params = new URLSearchParams(uri.split('?')[1])
  const paramsObj = Object.fromEntries(params.entries())
  return {
    uri: uri,
    url: url,
    params: paramsObj
  }
}

export const convertStringToNumberArray = (input: string): number[] => {
  // handles null undefined, and if any aren't numbers
  return (
    input
      ?.split(',')
      .map(Number)
      .filter((n) => !isNaN(n)) ?? []
  )
}

export const isNumber = (value: any): boolean => {
  if (value === null) {
    return false
  }

  return typeof value === 'number'
}

export const isPositiveNumber = (value: any): boolean => {
  return isNumber(value) && value > 0
}
