import { Box, Container, Flex, Text, useDisclosure } from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'

import {
  useConnectorsForProspectQuery,
  useIntroductionForPersonQuery,
  useMembershipConnectorsQuery
} from '~/api/introductions'

import { CallToAction } from './CallToAction'
import { HowIntrosWork } from './HowIntrosWork'
import { Details } from './Details'
import { useRelationshipQuery } from '~/api/relationships'
import { useMemo } from 'react'
import { AcceptIntroRequestModal } from './AcceptIntroRequestModal'
import { useMembershipQuery } from '~/api/memberships'
import { useExpressStore } from '~/stores'

export const IntroductionDetails = () => {
  const { id } = useParams()
  const introId = parseInt(id, 10)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { user, personId, membership: currentMembership } = useExpressStore(
    useShallow((state) => ({
      personId: state.personId,
      membership: state.membership,
      user: state.user
    }))
  )

  const introQuery = useIntroductionForPersonQuery(introId, personId, currentMembership)
  const relQuery = useRelationshipQuery(introQuery.data?.relationshipId)
  const membershipQuery = useMembershipQuery(introQuery.data?.requesterMembershipId)
  const orgConnectorsQuery = useMembershipConnectorsQuery(membershipQuery.data?.organization_id)
  const prospectConnectorsQuery = useConnectorsForProspectQuery({
    prospectId: introQuery.data?.prospectPersonId,
    requesterPersonId: introQuery.data?.requesterPersonId,
    organizationId: membershipQuery.data?.organization_id // <-- mutherfukken ruby!!
  })

  const isAnonymous = useMemo(() => {
    if (!introQuery?.isSuccess || !orgConnectorsQuery.isSuccess) return true
    if (introQuery.data.isWilling) return false

    const connectorId = introQuery.data.connectorPersonId
    const anonymous = orgConnectorsQuery.data.anonymous || []
    return anonymous.includes(connectorId)
  }, [introQuery.data, orgConnectorsQuery.data])

  const isConnector = personId === introQuery.data?.connectorPersonId
  const isRequester = personId === introQuery.data?.requesterPersonId

  const userRole = (() => {
    if (isConnector) return 'Connector'
    if (isRequester) return 'Requester'
    return null // or 'Unknown' or any other default value you wish to use
  })()

  if (isNaN(introId)) {
    return (
      <Container maxW="container.lg" background="gray.100" centerContent={true}>
        <Text as="h1" fontSize="xl">
          Introduction Not Found
        </Text>
        <Link to="/introductions">Return to your Introductions</Link>
      </Container>
    )
  }

  if (introQuery.isError) {
    return (
      <Container maxW="container.lg" background="gray.100" centerContent={true}>
        <Text as="h1" fontSize="xl">
          Introduction Not Found
        </Text>
        <Link to="/introductions">Return to your Introductions</Link>
      </Container>
    )
  }

  if (introQuery.isLoading || relQuery.isLoading || prospectConnectorsQuery.isLoading || membershipQuery.isLoading) {
    return <Container maxW="container.lg"></Container> // Keep the loading in view
  }

  const onConnectorAccept = () => {
    onOpen()
  }

  const intro = {
    ...introQuery.data,
    ...{ relationship: relQuery.data },
    ...{ requesterMembership: membershipQuery.data }
  }

  return (
    <Container maxW="container.lg" background="gray.100">
      <AcceptIntroRequestModal isOpen={isOpen} onClose={onClose} introduction={intro} />
      <Flex gap={4}>
        <Box w="66%" mt={8}>
          <Details
            introduction={intro}
            anonymous={isAnonymous}
            connectors={prospectConnectorsQuery.data}
            user={user}
            currentMembership={currentMembership}
          />
        </Box>
        <Box w="33%" mt={8}>
          <CallToAction
            introduction={introQuery.data}
            onAccept={onConnectorAccept}
            user={user}
            currentMembership={currentMembership}
          />
          {userRole && <HowIntrosWork role={userRole} />}
        </Box>
      </Flex>
    </Container>
  )
}
