import { Box, Button, Container, Flex, Link, Text, Textarea, useToast } from '@chakra-ui/react'
import { SectionHeader } from '@smallworld-io/pangaea'
import { ConnectorCard } from './ConnectorCard'
import { PersonAndPositionHeading } from './PersonAndPositionHeading'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useConnectorsForProspectQuery, useRequestIntroduction } from '~/api/introductions'
import { usePersonQuery } from '~/api/people'
import { HttpError, useUserForPersonQuery } from '~/api'
import { fullname } from '~/lib/fullname'
import { DotContainer } from './DotContainer'
import { writeIntroductionRequestDetails } from '~/lib/prefill'
import { AnimatePresence } from 'framer-motion'
import DOMPurify from 'dompurify'
import { useOrganizationQuery } from '~/api/organizations'
import { useExpressStore } from '~/stores'
import { useShallow } from 'zustand/react/shallow'

type CardState = 'none' | 'selected' | 'deselected'
type CardData = {
  state: CardState
  relationshipId: number
  strength: Strengths | null
  strengthRequested: boolean
  connector: { [key: string]: string | number | null }
}

type Connection = {
  relationshipId: number
  connectorPersonId: number
}

const combineForCardData = (loggedInPersonId: number, query: { [key: string]: any }) => {
  if (query.isLoading || query.isError) return []

  const { data } = query
  const { connectors = [] } = data
  const relationshipIds = new Set()

  const uniqueConnectors = connectors.reduce((acc: CardData[], c: { [key: string]: string | number | boolean }) => {
    if (c['connector_person_id'] === loggedInPersonId) return acc
    if (relationshipIds.has(c['relationship_id'])) return acc

    relationshipIds.add(c['relationship_id'])
    acc.push({
      state: 'none',
      relationshipId: parseInt(String(c['relationship_id']), 0),
      strength: c['relationship_strength'] as Strengths,
      strengthRequested: Boolean(c['relationship_strength_requested']),
      connector: {
        name: String(c['connector_name']),
        degree: Number(c['degree_num']),
        sharedRelationshipText: String(c['hover_text'])
      }
    })

    return acc
  }, [])

  return uniqueConnectors
}

const placeholder = 'Add request details to your introduction request.'

export const IntroductionRequest = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const { membership, user } = useExpressStore(
    useShallow((state) => ({
      membership: state.membership,
      user: state.user
    }))
  )
  const { organization } = membership
  const { personId } = user

  const { id } = useParams()
  const prospectId = parseInt(id, 10)

  if (isNaN(prospectId)) {
    return (
      <Container maxW="container.lg" bg="gray.100">
        <Text as="h1" fontSize="xl">
          Prospect Not Found
        </Text>
      </Container>
    )
  }

  const prospectQuery = usePersonQuery({ id: prospectId })
  const requestIntroduction = useRequestIntroduction()
  const connectorsQuery = useConnectorsForProspectQuery({
    prospectId,
    requesterPersonId: personId,
    organizationId: organization.id
  })

  const cardData = useMemo(() => combineForCardData(personId, connectorsQuery), [connectorsQuery])

  const [details, setDetails] = useState('')
  const [selectedConnection, setSelectedConnection] = useState<Connection | null>(null)

  const [showAllConnectors, setShowAllConnectors] = useState(false)
  const visibleCardData = showAllConnectors ? cardData : cardData.slice(0, 3)
  const hiddenConnectorCount = cardData.length - 3

  const [isDetailsManuallyChanged, setIsDetailsManuallyChanged] = useState(false)

  const connectorUserQuery = useUserForPersonQuery(selectedConnection?.connectorPersonId)
  const requesterOrganizationQuery = useOrganizationQuery(organization.id)

  useEffect(() => {
    if (cardData.length === 1) {
      handleOnSelectConnector(cardData[0].relationshipId)
    }
  }, [cardData])

  if (prospectQuery.isError) {
    return (
      <Container maxW="container.lg" bg="gray.100">
        <Text as="h1" fontSize="xl">
          Prospect Not Found
        </Text>
      </Container>
    )
  }

  const getCardState = (id: number) => {
    if (selectedConnection === null) return 'none'
    return selectedConnection.relationshipId === id ? 'selected' : 'deselected'
  }

  const canMakeRequest = selectedConnection !== null && details.trim().length > 0 && prospectQuery.isSuccess

  const handleDetailsChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDetails(event.currentTarget.value)
    setIsDetailsManuallyChanged(true)
  }

  const handleOnSelectConnector = (id: number) => {
    if (selectedConnection?.relationshipId === id) return

    const {
      data: { connectors = [] }
    } = connectorsQuery
    const connection = connectors.find((c: { [key: string]: string | number }) => id === c['relationship_id'])
    const prospect = prospectQuery.data

    setSelectedConnection({
      connectorPersonId: connection['connector_person_id'],
      relationshipId: connection['relationship_id']
    })

    if (!isDetailsManuallyChanged) {
      setDetails(writeIntroductionRequestDetails({ user, connection, prospect }))
    }
  }

  const handleRequestOnClick = () => {
    const placeholderText = 'Include additional context here'
    if (details.includes(placeholderText)) {
      window.alert('Please remove the placeholder text from the details of the introduction request and submit again.')
      return false
    }

    const showExistingRequestToast = (desc: React.ReactNode) => {
      toast({
        status: 'warning',
        title: 'Existing Introduction Request',
        description: desc,
        duration: null,
        isClosable: true,
        position: 'top'
      })
    }

    const showExistingRequesterRequestToast = (id: number) => {
      const desc = (
        <Box>
          You have an existing introduction request to {fullname(prospectQuery.data)}. You can see it{' '}
          <Box
            cursor="pointer"
            textDecoration="underline"
            color="blue.400"
            as="span"
            onClick={() => navigate(`/introductions/${id}`)}
          >
            here
          </Box>
          .
        </Box>
      )
      showExistingRequestToast(desc)
    }

    const showExistingConnectorRequestToast = () => {
      const desc = (
        <Box>
          There&apos;s already an outstanding Introduction Request to this Super Connector for{' '}
          {fullname(prospectQuery.data)}. Please try again.
        </Box>
      )
      showExistingRequestToast(desc)
    }

    //
    // Look through the memberships of the requester's organization and find the connector's
    // user and associate that membership with the introduction request.
    //
    const {
      data: { memberships: requesterMemberships }
    } = requesterOrganizationQuery
    const {
      data: { id: connectorUserId }
    } = connectorUserQuery
    const connectorMembership = requesterMemberships.find((membership) => membership.userId === connectorUserId)

    requestIntroduction.mutate(
      {
        companyId: prospectQuery.data.companyId,
        relationshipId: selectedConnection.relationshipId,
        requesterId: personId,
        requesterMembershipId: membership.id,
        connectorMembershipId: connectorMembership.id,
        prospectEmail: '',
        details: DOMPurify.sanitize(details, { ALLOWED_TAGS: [] })
      },
      {
        onSuccess: (response) => {
          const { introduction } = response
          navigate(`/introductions/${introduction.id}`)
          toast({
            status: 'success',
            title: 'Success',
            description: "We've sent your introduction request to the Super Connector.",
            position: 'top',
            isClosable: true
          })
        },
        onError: (err: HttpError) => {
          if (err.statusCode === 409) {
            const { id, conflictSource } = JSON.parse(err.detail)
            if (conflictSource.toLowerCase() === 'connector') {
              showExistingConnectorRequestToast()
            } else {
              showExistingRequesterRequestToast(id)
            }
          } else {
            toast({
              status: 'error',
              title: 'An Error Occurred',
              description: 'There was a problem making your introduction request, please try again.',
              position: 'top',
              isClosable: true
            })
          }
        }
      }
    )
  }

  if (connectorsQuery.isLoading) {
    return <div></div>
  }

  return (
    <Container maxW="container.lg" bg="gray.100" pt={4}>
      <PersonAndPositionHeading
        isLoading={prospectQuery.isLoading}
        isError={prospectQuery.isError}
        person={prospectQuery.data}
        company={connectorsQuery.data?.prospect_company_name}
      />
      <DotContainer>
        <SectionHeader mb={4}>Choose a Super Connector</SectionHeader>
        <AnimatePresence>
          {visibleCardData.length > 0 &&
            visibleCardData.map((card) => (
              <ConnectorCard
                key={card.relationshipId}
                relationshipId={card.relationshipId}
                connector={card.connector}
                strength={card.strength as Strengths}
                strengthRequested={card.strengthRequested}
                state={getCardState(card.relationshipId)}
                onSelect={handleOnSelectConnector}
              />
            ))}
        </AnimatePresence>

        {hiddenConnectorCount > 0 && !showAllConnectors && (
          <Link mt={4} onClick={() => setShowAllConnectors(true)}>
            Show {hiddenConnectorCount} more Super Connector{hiddenConnectorCount !== 1 ? 's' : ''}
          </Link>
        )}
      </DotContainer>
      <Flex
        direction={{
          base: 'column-reverse',
          sm: 'row'
        }}
      >
        <Box flex="0 0 66%">
          <DotContainer>
            <SectionHeader mb={4}>Details of your introduction request</SectionHeader>
            <Textarea
              value={details}
              onChange={handleDetailsChange}
              fontSize="md"
              lineHeight="short"
              placeholder={placeholder}
              borderRadius="3px"
              borderWidth="1px"
              borderStyle="solid"
              borderColor="gray.300"
              color="#222"
              bg="white"
              outline="none"
              p={4}
              mb={4}
              mr={{
                base: 0,
                sm: 4
              }}
              minHeight={{
                base: 'sm',
                sm: 'md'
              }}
              _focus={{
                borderColor: 'blue.400',
                borderStyle: 'solid',
                borderWidth: '1px'
              }}
              isDisabled={selectedConnection === null || prospectQuery.isLoading || prospectQuery.isError}
              data-hj-allow
            />
          </DotContainer>
        </Box>
        <Box flex="1" ml={8}>
          <SectionHeader mb={4}>Tips</SectionHeader>
          <ul>
            <li>
              <Text fontWeight="400" fontSize="13px" mb={4}>
                Be sure to include relevant details (e.g. <strong>intent data, sales stage</strong>, etc.) to maximize
                the chances of getting your request accepted
              </Text>
            </li>
            <li>
              <Text fontWeight="400" fontSize="13px" mb={4}>
                Use the relationship strength data and any details about your trusted relationship to help bring more
                context to your request
              </Text>
            </li>
            <li>
              <Text fontWeight="400" fontSize="13px" mb={4}>
                Include the <strong>email address</strong> of the prospect to make it easier for your fellow Super
                Connector to make the intro once they accept
              </Text>
            </li>
          </ul>
        </Box>
      </Flex>
      <Flex
        mb={4}
        direction={{
          base: 'column',
          sm: 'row'
        }}
      >
        <Button
          variant="secondary"
          onClick={handleRequestOnClick}
          isDisabled={!canMakeRequest}
          isActive={!canMakeRequest}
          maxW={405}
          ml={'3rem'}
        >
          Request Introduction
        </Button>
      </Flex>
    </Container>
  )
}
