import { Helmet as ReactHelmet } from 'react-helmet-async'
import { useEffect } from 'react'
import { APP_NAME } from '~/settings'
import { useShallow } from 'zustand/react/shallow'
import useExpressStore from '~/stores/express'
import ReactGA from 'react-ga';

const HEAP_ID = import.meta.env.VITE_HEAP_ID
const HOTJAR_ID = import.meta.env.VITE_HOTJAR_ID
const PENDO_ID = import.meta.env.VITE_PENDO_ID
const GA_ID = import.meta.env.GA_ID

export const Helmet = () => {
  const { membership, organization, user } = useExpressStore(
    useShallow((state) => ({
      membership: state.membership,
      organization: state.organization,
      user: state.user
    }))
  )

  useEffect(() => {
    ReactGA.initialize(GA_ID)

    if (user !== null) {
      ReactGA.set({
        'UserName': `${user.firstName} ${user.lastName}`,
        'UserType': membership !== null ? 'Super Connector' : 'Requester'
      })
    }

    const injectAnalyticsScripts = (content) => {
      const script = document.createElement('script')
      script.textContent = content
      document.body.appendChild(script)
    }

    const baseAnalytics = `
      // Heap base script
      window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};
        var r=document.createElement("script");
        r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";
        var a=document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(r,a);
        for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;
        o<p.length;
        o++)heap[p[o]]=n(p[o])};
        heap.load("${HEAP_ID}");

      // Hotjar base script
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${HOTJAR_ID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');

      // Pendo base script
      (function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){ 
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]); 
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js'; 
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo'); 
      })('${PENDO_ID}');
    `
    injectAnalyticsScripts(baseAnalytics)

    if (user !== null) {
      const analyticsUserAttributes = `
        // // Heap User Attributes
        // heap.identify('${user.id}');
        // heap.addUserProperties({
        //   'Person ID': '${user.personId}',
        //   'Name': '${user.firstName} ${user.lastName}',
        //   'User ID': '${user.id}',
        //   'Email': '${user.email}',
        //   'Membership ID': '${membership.id}',
        //   'Organization ID': '${organization.id}',
        // });

        // Hotjar user attributes
        window.hj('identify', '${user.id}', {
          'Person ID': '${user.personId}',
          'Name': '${user.firstName} ${user.lastName}',
          'User ID': '${user.id}',
          'Email': '${user.email}',
          'Membership ID': '${membership.id}',
          'Organization ID': '${organization.id}',
        });
      `
      injectAnalyticsScripts(analyticsUserAttributes)
    }
  }, [user])

  return (
    <ReactHelmet>
      <meta name="description" content={APP_NAME} />
      <meta name="application-name" content={APP_NAME} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content={APP_NAME} />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="theme-color" content="#FFFFFF" />
      <link rel="shortcut icon" href="/assets/favicon.ico" />
    </ReactHelmet>
  )
}
