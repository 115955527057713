import { createContext } from 'react'
import { AuthApi, HttpError } from '~/api'

export type AuthContextData = {
  userId: number
  organizationId: number
  personId: number
  accountId: number
  isLoggedIn: boolean
  isLoading: boolean
  onAuth: () => void
  onSignup: (credentials: AuthApi.Credentials) => void
  onLogin: (credentials: AuthApi.Credentials) => void
  onLogout: () => void
  signupError: HttpError
  loginError: HttpError
  logoutError: HttpError
}

export const AuthContext = createContext<AuthContextData>({
  userId: null,
  organizationId: null,
  personId: null,
  accountId: null,
  isLoggedIn: false,
  isLoading: true,
  onAuth: () => {},
  onSignup: () => {},
  onLogin: () => {},
  onLogout: () => {},
  signupError: null,
  loginError: null,
  logoutError: null
})
