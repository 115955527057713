import { useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Flex,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  useToast
} from '@chakra-ui/react'
import {
  useAcceptIntroductionMutation,
  useCompleteIntroductionMutation,
  useDeclineIntroductionMutation
} from '~/api/introductions'
import { useMembershipQuery } from '~/api/memberships'
import { isCompleted, isDeclined, isInProgress } from './lib'
import { fullname } from '~/lib/fullname'
import { RECIPOCATE_URL } from '~/settings'
import { copyToClipboard } from '~/lib/copyToClipboard'

type CardProps = {
  introduction: Introduction
  membership: Membership
  onAccept?: () => void
  personId: number
}

export const CompletedState = ({ introduction }: { introduction: Introduction }) => {
  const { requester, prospect } = introduction

  return (
    <Box mb={2} px={4} pb={4} textAlign="center">
      <Text mb={4}>
        Your introduction of {fullname(requester)} to {fullname(prospect)} has been completed.
      </Text>

      <Text fontWeight={'bold'} fontSize={'.75rem'}>
        {requester.firstName} has now been added to your network to reciprocate the request.{' '}
        <Link href={`${RECIPOCATE_URL}`} whiteSpace="nowrap">
          Click here
        </Link>{' '}
        to view his relationships into your target prospects.
      </Text>
    </Box>
  )
}

export const DeclinedState = () => {
  return (
    <Box px={8} pb={5} textAlign="center">
      <Text>This introduction request was declined.</Text>
    </Box>
  )
}

export const InProgressState = ({ introduction, membership, personId }: CardProps) => {
  const { prospect, requester, requesterMembershipId } = introduction
  const { data: requesterMembership } = useMembershipQuery(requesterMembershipId)

  const declineMutation = useDeclineIntroductionMutation(introduction)
  const completeMutation = useCompleteIntroductionMutation(introduction)

  const handleOnDeclineClick = () => {
    declineMutation.mutate({ membership, personId, declineReason: 'The prospect declined.' })
  }

  const handleOnCompleteClick = () => {
    completeMutation.mutate({ membership, personId, note: '' })
  }

  const [isCompletedModalOpen, setIsCompletedModalOpen] = useState(false)
  const openCompletedModal = () => setIsCompletedModalOpen(true)
  const closeCompletedModal = () => setIsCompletedModalOpen(false)

  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false)
  const openDeclineModal = () => setIsDeclineModalOpen(true)
  const closeDeclineModal = () => setIsDeclineModalOpen(false)

  const [isCopied, setIsCopied] = useState(false)
  const toast = useToast()

  const handleEmailCopy = async () => {
    try {
      await copyToClipboard(requesterMembership?.email)
      setIsCopied(true)
      setTimeout(() => setIsCopied(false), 4000)
      toast({
        title: 'Copied!',
        description: `You've copied ${requesterMembership?.email} to your clipboard.`,
        status: 'success',
        duration: 2000,
        position: 'top',
        isClosable: true
      })
    } catch (err) {
      toast({
        title: 'Failed to copy email address.',
        description: 'Please try again.',
        status: 'error',
        position: 'top',
        duration: 2000,
        isClosable: true
      })
    }
  }

  return (
    <>
      <Box px={8} pb={4} textAlign="center">
        <Text fontWeight="bold" fontSize="xl" lineHeight={1.2}>
          Was the intro to {fullname(prospect)} completed?
        </Text>
        <Flex justify="center" gap={4} p={4} mb={4}>
          <Button
            bg="green.400"
            color="blue.100"
            fontWeight="bold"
            fontSize="sm"
            textDecoration="uppercase"
            onClick={openCompletedModal}
          >
            Yes
          </Button>
          <Button
            bg="red.400"
            color="white"
            fontWeight="bold"
            fontSize="sm"
            textDecoration="uppercase"
            onClick={openDeclineModal}
          >
            No
          </Button>
        </Flex>

        <Text fontSize="sm" lineHeight={1.2} mb={2}>
          When you're ready to make the intro, here is {requester?.firstName}'s email address to include them on the
          email:
        </Text>

        <Box>
          <Text as="span" fontWeight={'bold'} fontSize="sm" mr={2} lineHeight={1.2}>
            {requesterMembership?.email}
          </Text>
          <Text as="span" fontSize="sm" textColor={'gray.500'} mr={2} lineHeight={1.2} whiteSpace="nowrap">
            (
            <Link as="span" fontSize="sm" sx={{ cursor: 'pointer' }} onClick={handleEmailCopy}>
              {isCopied ? 'copied!' : 'copy'}
            </Link>
            )
          </Text>
        </Box>
      </Box>

      <Modal isOpen={isCompletedModalOpen} onClose={closeCompletedModal} size="lg">
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader>Are you sure you want to mark this introduction as completed?</ModalHeader>
          <ModalBody>
            Please only mark the introduction completed if you've introduced the Requester to the Prospect.
          </ModalBody>
          <ModalFooter>
            <Button bg="green.400" mr={3} onClick={handleOnCompleteClick}>
              Yes
            </Button>
            <Button variant="ghost" onClick={closeCompletedModal}>
              Dismiss
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDeclineModalOpen} onClose={closeDeclineModal} size="lg">
        <ModalOverlay />
        <ModalContent p={4}>
          <ModalHeader>Do you plan on making the intro?</ModalHeader>
          <ModalBody>
            If you plan to make the introduction soon, simply dismiss this and update us later. Choose "No" only if
            you're certain the introduction won't happen.
          </ModalBody>
          <ModalFooter>
            <Button bg="red.400" mr={3} onClick={handleOnDeclineClick}>
              No
            </Button>
            <Button variant="ghost" onClick={closeDeclineModal}>
              Dismiss
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export const RequestedState = ({ introduction, membership, onAccept, personId }: CardProps) => {
  const { prospect, requester } = introduction

  const acceptMutation = useAcceptIntroductionMutation(introduction)
  const declineMutation = useDeclineIntroductionMutation(introduction)

  const handleOnYesClick = () => {
    acceptMutation.mutate(
      { membership, personId },
      {
        onSuccess: () => onAccept()
      }
    )
  }

  const handleOnNoClick = () => {
    declineMutation.mutate({ membership, personId, declineReason: '' })
  }

  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false)
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false)

  const openAcceptModal = () => setIsAcceptModalOpen(true)
  const closeAcceptModal = () => setIsAcceptModalOpen(false)

  const openDeclineModal = () => setIsDeclineModalOpen(true)
  const closeDeclineModal = () => setIsDeclineModalOpen(false)

  const askQuestion = () => {
    const inputElement = document.querySelector('input') // This is gross

    if (inputElement) {
      inputElement.scrollIntoView({ behavior: 'smooth', block: 'center' })

      setTimeout(() => {
        inputElement.focus()
      }, 750)
    }
  }

  return (
    <>
      <Box px={8} pb={0} textAlign="center">
        <Text fontWeight="bold" fontSize="xl" lineHeight={1.3}>
          Can you make the intro to {prospect?.firstName}?
        </Text>
        <Flex justify="center" gap={4} p={4}>
          <Button
            bg="green.400"
            color="blue.100"
            fontWeight="bold"
            fontSize="sm"
            textDecoration="uppercase"
            onClick={openAcceptModal}
          >
            Yes
          </Button>
          <Button
            bg="red.400"
            color="white"
            fontWeight="bold"
            fontSize="sm"
            textDecoration="uppercase"
            onClick={openDeclineModal}
          >
            No
          </Button>
        </Flex>
      </Box>

      <Text fontSize="xs" color="gray.600" textAlign="center" px={4} pb={4}>
        Or{' '}
        <Link color="blue.400" onClick={() => askQuestion()}>
          ask {requester?.firstName} a question
        </Link>
      </Text>

      <Divider color="gray.200" />
      <Box bg="gray.050" fontSize="xs" textAlign="center" px={4} py={4}>
        <Text as="span" fontWeight="bold">
          Note:
        </Text>{' '}
        If you accept this introduction, your identity will be revealed to the Requester.
      </Box>

      <Modal isOpen={isAcceptModalOpen} onClose={closeAcceptModal} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure you want to accept this introduction?</ModalHeader>
          <ModalBody>
            Once accepted, your name will be revealed to the Super Connector who made the introduction request so you’re
            able to correspond directly about the request through messages in SmallWorld.
          </ModalBody>
          <ModalFooter>
            <Button bg="green.400" mr={3} onClick={handleOnYesClick}>
              Accept
            </Button>
            <Button variant="ghost" onClick={closeAcceptModal}>
              Dismiss
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDeclineModalOpen} onClose={closeDeclineModal} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Are you sure you want to decline this introduction?</ModalHeader>
          <ModalBody>
            By declining this introduction request, you won't receive future requests from this Super Connector. Should
            you have a question about the request, message the Super Connector under the introduction request.
          </ModalBody>
          <ModalFooter>
            <Button bg="red.400" mr={3} onClick={handleOnNoClick}>
              Decline
            </Button>
            <Button variant="ghost" onClick={closeDeclineModal}>
              Dismiss
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export const ConnectorCallToActionCard = ({
  introduction,
  user,
  currentMembership,
  onAccept
}: {
  introduction: Introduction
  user: User
  currentMembership: Membership
  onAccept: () => void
}) => {
  const { personId } = user

  if (isDeclined(introduction)) {
    return <DeclinedState />
  }

  if (isInProgress(introduction)) {
    return <InProgressState introduction={introduction} membership={currentMembership} personId={personId} />
  }

  if (isCompleted(introduction)) {
    return <CompletedState introduction={introduction} />
  }

  return (
    <RequestedState
      introduction={introduction}
      membership={currentMembership}
      personId={personId}
      onAccept={onAccept}
    />
  )
}
