import { MachineConfig, AnyEventObject, assign, createMachine, EventObject } from 'xstate'
import { RequestModalBodyForm } from '../RelationshipRequestModal'

type Context = {
  requestInFlight: boolean
  requestIntroModalIsOpen: boolean
  introRequestErrors: Record<string, string>
  introId: number | null
  introUrl: string | null
  form: RequestModalBodyForm | object
  lead: RelationshipLead
}

const defaultContext = {
  requestInFlight: false,
  requestIntroModalIsOpen: false,
  introRequestErrors: {},
  introId: null,
  introUrl: null,
  form: {},
  lead: null
}

export type IntroductionRequestSubmission = {
  id: string
  personId: number
  relationshipId: number
  connector: Connector
  prospect: Person
  form: RequestModalBodyForm
  accountId?: number
  requesterMembership?: Membership
  connectorMembership?: Membership
}

type Event = { type: 'MAKE_REQUEST'; data: IntroductionRequestSubmission } | EventObject

// CR: This is an ugly generic, but it has to be able to handle the classic or new style intro request
// As well as the create_intro_from_unassigned_lead
export function buildRelationshipCardStateMachine<T, U>(
  makeIntroRequest: (formdata: IntroductionRequestSubmission) => Promise<T> | Promise<U>
) {
  const state: MachineConfig<Context, XStateSchema, Event> = {
    id: 'relationshipCard', // should append a random id
    predictableActionArguments: true,
    schema: {
      // todo: extract this to interfaces Typestates:
      // https://xstate.js.org/docs/guides/typescript.html#typestates
      context: {} as Context,
      events: {} as Event
    },
    context: defaultContext,
    initial: 'displayRelationship',
    states: {
      displayRelationship: {
        on: {
          LINKS: { target: 'displayLinks' },
          SAVE: { target: 'displaySave' },
          REQUEST: {
            target: 'requestingIntro',
            actions: ['openRequestIntroModal']
          },
          HIDE: { target: 'displayHide' },
          ASSIGN: { target: 'assigningLead' }
        }
      },
      displayLinks: {
        on: {
          BACK: { target: 'displayRelationship' }
        }
      },
      displayHide: {
        on: {
          BACK: { target: 'displayRelationship' }
        }
      },
      displaySave: {
        on: {
          BACK: { target: 'displayRelationship' }
        }
      },
      requestingIntro: {
        on: {
          CANCEL: {
            target: 'displayRelationship',
            actions: ['closeRequestIntroModal']
          },
          MAKE_REQUEST: {
            target: 'makingRequest'
          },
          REQUEST_SUCCESS: {
            target: 'introRequested',
            actions: ['toggleRequestSpinner', 'closeRequestIntroModal']
          }
        }
      },
      makingRequest: {
        entry: ['toggleRequestSpinner'],
        exit: ['toggleRequestSpinner'],
        invoke: {
          id: 'makeIntroRequestApiCall',
          src: (_, event: AnyEventObject) => {
            const { data } = event
            return makeIntroRequest(data)
          },
          onDone: {
            target: 'introRequested',
            actions: ['setIntroId', 'closeRequestIntroModal']
          },
          onError: {
            target: 'requestingIntro', // todo: whats a bettertype for this?
            actions: ['setIntroductionRequestErrors']
          }
        }
      },
      introRequested: {
        on: {
          BACK: {
            // undo button
            target: 'displayRelationship'
          }
        }
      },
      assigningLead: {
        on: {
          CANCEL: {
            target: 'displayRelationship'
          },
          INVITE: {
            target: 'requesterInvited'
          }
        }
      },
      leadAssigned: {},
      requesterInvited: {}
    },
    on: {
      // Internal transition from any other state
      LEAD_ASSIGNED: {
        target: '.leadAssigned'
      }
    }
  }

  const actions = {
    openRequestIntroModal: assign(() => {
      return {
        requestIntroModalIsOpen: true
      }
    }),
    closeRequestIntroModal: assign(() => {
      return {
        requestIntroModalIsOpen: false
      }
    }),
    toggleRequestSpinner: assign((context: Context) => {
      return {
        requestInFlight: !context.requestInFlight
      }
    }),
    setIntroductionRequestErrors: assign((_, event: AnyEventObject) => {
      // TODO: Handle errors
      return {
        introRequestErrors: event.data.errors
      }
    }),
    setIntroId: assign((_, event: AnyEventObject) => {
      const { data } = event

      // eslint-disable-next-line no-prototype-builtins
      if (data.hasOwnProperty('introduction')) {
        // Swag response
        return {
          introId: data.introduction.id,
          introUrl: `/introductions/${data.introduction.id}`
        }
      }

      return {
        // Rails app response
        introId: data.id,
        introUrl: data.url
      }
    })
  }
  return createMachine(state, { actions })
}
