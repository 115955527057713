import { create } from 'zustand'

export type Screen = 'phonenumber' | 'verification' | 'credentials' | 'sync'

export type SignUpState = {
  screen: Screen
  verified: boolean
  optIn: boolean
  phone: string
  email: string
}

export type SignUpAction = {
  setScreen: (step: SignUpState['screen']) => void
  setVerified: (tf: SignUpState['verified']) => void
  updateOptIn: (tf: SignUpState['optIn']) => void
  updatePhone: (phone: SignUpState['phone']) => void
  updateEmail: (email: SignUpState['email']) => void
}

export type SignUpStore = SignUpState & SignUpAction

export const useSignUpStore = create<SignUpStore>()((set) => {
  return {
    screen: 'phonenumber',
    optIn: true,
    phone: '',
    email: '',
    verified: false,

    setScreen: (name: Screen) => set(() => ({ screen: name })),
    setVerified: (tf) => set(() => ({ verified: tf })),
    updateOptIn: (tf) => set(() => ({ optIn: tf })),
    updatePhone: (phone) => set(() => ({ phone })),
    updateEmail: (email) => set(() => ({ email }))
  }
})
