import { Outlet } from 'react-router-dom'
import { Box, Grid, GridItem } from '@chakra-ui/react'
import { Helmet } from '~/components'

export const UnprotectedLayout = () => {
  return (
    <Box>
      <Helmet />
      <Grid templateRows={['min-content', 'auto']} h="100vh">
        <GridItem h="full">
          <Outlet />
        </GridItem>
      </Grid>
    </Box>
  )
}
