import { useEffect, useState, useRef } from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  GridItem,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Skeleton,
  useToast,
  useMediaQuery
} from '@chakra-ui/react'
import { useAuth } from '~/auth'
import { isPositiveNumber, RateRelationshipsApi } from '~/api'
import { createPairs } from '~/lib'
import { RateRelationshipCard } from '~/components/RateRelationshipCard/RateRelationshipCard'
import useDocumentTitle from '~/hooks/useDocumentTitle'
import { SUPPORT_URL } from '~/settings'
import { useNavigate } from 'react-router-dom'
import RelationshipRatingKey from '~/components/RelationshipRatingKey'
import Footer from '~/components/Footer'
import LockIcon from '~/assets/icons/icon-lock.svg'
import Particles from 'react-tsparticles'
import particlesConfettiOptions from '~/lib/particles/ConfettiOptions.json'
import { NetworkStatsCard } from '~/components/NetworkStatsCard'
import { IntroductionScorecard } from '~/components/IntroductionScorecard'
import ShareModal from '~/components/ShareModal'

export const RatingModule = ({ onboarding }) => {
  const ratingRequirement = 100
  const loadMoreIncrement = 200

  const navigate = useNavigate()
  const auth = useAuth()

  const loadDateRef = useRef(null)
  if (loadDateRef.current === null) {
    loadDateRef.current = new Date().toUTCString()
  }

  const [relationshipDisplayLimit, setRelationshipDisplayLimit] = useState(loadMoreIncrement)

  const [ratedOnPage, setRatedOnPage] = useState(0)

  const countRelationshipsRequest = RateRelationshipsApi.useCountRelationshipsRequest(auth.personId, {
    refetchInterval: 60000
  })

  const relationshipsToRateRequest = RateRelationshipsApi.useRelationshipsToRateRequest(
    auth.personId,
    loadDateRef.current,
    relationshipDisplayLimit
  )
  const ratedCountRequest = RateRelationshipsApi.useRatedCountRelationshipsRequest(auth.personId, true)
  const createFirstDegreeMembershipsRequest = RateRelationshipsApi.useCreateFirstDegreeMembershipsRequest(auth.personId)
  const matchingStrongRelationshipsPeopleIdsRequest =
    RateRelationshipsApi.useMatchingStrongRelationshipsPeopleIdsRequest(auth.personId)

  // TODO: distruibute this new code to the proper section(s) once complete
  const toast = useToast()

  const [relationshipsToRate, setRelationshipsToRate] = useState(null)

  const [lastUserRefresh, setLastUserRefresh] = useState('0000-01-01T00:00:00.000Z')

  const [showToast, setShowToast] = useState(true)

  const refreshRelationships = () => {
    toast.closeAll()
    if (!relationshipsToRateRequest.isFetching && relationshipsToRateRequest.data?.relationships) {
      setRelationshipsToRate(relationshipsToRateRequest.data?.relationships)
      const currentDatetime = new Date()
      setLastUserRefresh(currentDatetime.toISOString())
      setShowToast(true)
    }
  }

  //relationships to rate ready?
  useEffect(() => {
    if (!relationshipsToRateRequest.isFetching && relationshipsToRateRequest.data?.relationships) {
      const relationships = relationshipsToRateRequest.data.relationships

      let isAnyRelationshipUpdatedAfterLastRefresh
      if (!relationshipsToRate) {
        setRelationshipsToRate(relationships)
        const currentDatetime = new Date()
        setLastUserRefresh(currentDatetime.toISOString())
        isAnyRelationshipUpdatedAfterLastRefresh = false
      } else {
        isAnyRelationshipUpdatedAfterLastRefresh = relationships.some(
          (rel) => new Date(rel.lastUpdated) > new Date(lastUserRefresh)
        )
        setRelationshipsToRate(relationships)
      }

      if (isAnyRelationshipUpdatedAfterLastRefresh && showToast) {
        setShowToast(false)
        toast({
          position: 'bottom',
          title: 'Relationships Updated',
          description: (
            <>
              New relationships have been processed. Refresh the page, or{' '}
              <Link color="white" textDecoration={'underline'} onClick={refreshRelationships}>
                click here
              </Link>{' '}
              to update your relationships.
            </>
          ),
          status: 'info',
          duration: null,
          isClosable: true
        })
      }
    }
    // Refetch every 10 sec
    const intervalId = setInterval(() => {
      relationshipsToRateRequest.refetch()
    }, 15000)

    // Cleanup function to clear interval when component unmounts or when dependencies change
    return () => {
      clearInterval(intervalId)
    }
  }, [
    relationshipsToRateRequest.isFetching,
    relationshipsToRateRequest.data,
    lastUserRefresh,
    relationshipsToRate,
    relationshipsToRateRequest
  ])
  // end new code

  const [twoColumnLayout] = useMediaQuery('(min-width: 48em)')

  const [successModalIsOpen, setSuccessModalIsOpen] = useState(true)

  const [strongOtherSourceIds, setStrongOtherSourceIds] = useState([])

  const [superConnectorIds, setSuperConnectorIds] = useState([])

  const [membershipPairs, setMembershipPairs] = useState([])

  const [networkStats, setNetworkStats] = useState<CountOfConnections>()

  const [connectorsPresent, setConnectorsPresent] = useState(undefined)

  const createMembershipsRequest = RateRelationshipsApi.useCreateMembershipsRequest(membershipPairs)

  const superConnectorIdsRequest = RateRelationshipsApi.useSuperConnectorIdsRequest(strongOtherSourceIds)

  const [loadMoreButtonVisible, setLoadMoreButtonVisible] = useState(true)

  const [relationshipsRated, setRelationshipsRated] = useState(0)

  const [totalRelationshipCount, setTotalRelationshipCount] = useState(0)

  const [isRatingKeyModalOpen, setIsRatingKeyModalOpen] = useState(false)
  const openRatingKeyModal = () => setIsRatingKeyModalOpen(true)
  const closeRatingKeyModal = () => setIsRatingKeyModalOpen(false)

  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  const openShareModal = () => {
    setIsShareModalOpen(true)
  }
  const closeShareModal = () => setIsShareModalOpen(false)

  // on personId avail, refetch rels request
  useEffect(() => {
    if (!auth.personId || !isPositiveNumber(relationshipDisplayLimit)) {
      return
    }

    relationshipsToRateRequest.refetch()
  }, [auth.personId, relationshipDisplayLimit])


  useEffect(() => {
    if (auth.personId && isPositiveNumber(auth.personId)) {
      createFirstDegreeMembershipsRequest.refetch()
    }
  }, [auth.personId])

  useEffect(() => {
    if (strongOtherSourceIds.length > 0) {
      superConnectorIdsRequest.refetch()
    }
  }, [strongOtherSourceIds, superConnectorIdsRequest])

  useEffect(() => {
    if (superConnectorIds.length > 0) {
      let pairs: number[][] = createPairs(auth.personId, superConnectorIds)
      setMembershipPairs(pairs)
    }
  }, [auth.personId, superConnectorIds, setMembershipPairs])

  useEffect(() => {
    if (membershipPairs.length > 0) {
      createMembershipsRequest.refetch()
    }
  }, [membershipPairs, createMembershipsRequest])

  useEffect(() => {
    let countRatedBeforePage = relationshipsRated - ratedOnPage

    let offPageLeftToRate = totalRelationshipCount - countRatedBeforePage - relationshipDisplayLimit

    let showLoadMoreBtn = offPageLeftToRate > 0 || totalRelationshipCount < loadMoreIncrement

    setLoadMoreButtonVisible(showLoadMoreBtn)
  }, [totalRelationshipCount, relationshipsRated, ratedOnPage, relationshipDisplayLimit])

  useEffect(() => {
    if (!superConnectorIdsRequest.isLoading && superConnectorIdsRequest?.data?.matched_ids?.length) {
      setSuperConnectorIds(superConnectorIdsRequest.data.matched_ids)
    }
  }, [superConnectorIdsRequest.data, superConnectorIdsRequest.isLoading, setSuperConnectorIds])

  useEffect(() => {
    if (!ratedCountRequest.isLoading) {
      let countFromDb = ratedCountRequest.data.count
      setRelationshipsRated(countFromDb)
    }
  }, [ratedCountRequest.data, setRelationshipsRated])

  useEffect(() => {
    if (!countRelationshipsRequest.isLoading) {
      let countFromDb = countRelationshipsRequest.data.count

      setTotalRelationshipCount(countFromDb)
    }
  }, [countRelationshipsRequest.isLoading, countRelationshipsRequest.data, setTotalRelationshipCount])

  useEffect(() => {
    if (
      !matchingStrongRelationshipsPeopleIdsRequest.isLoading &&
      !ratedCountRequest.isLoading &&
      matchingStrongRelationshipsPeopleIdsRequest.data?.integers?.length > 0
    ) {
      setStrongOtherSourceIds(matchingStrongRelationshipsPeopleIdsRequest?.data?.integers)
    }
  }, [
    ratedCountRequest.isLoading,
    ratedCountRequest.data,
    matchingStrongRelationshipsPeopleIdsRequest.isLoading,
    matchingStrongRelationshipsPeopleIdsRequest.data,
    setStrongOtherSourceIds
  ])

  useEffect(() => {
    if (!countRelationshipsRequest.isLoading) {
      let countFromDb = countRelationshipsRequest.data.count

      // Check if `totalRelationshipCount`
      // is 0 and redirect to sync if so
      if (countFromDb === 0) {
        navigate('/sign-up/sync')
      }

      setTotalRelationshipCount(countFromDb)
    }
  }, [countRelationshipsRequest.isLoading, countRelationshipsRequest.data, setTotalRelationshipCount])

  useDocumentTitle('Get Access to Super Connectors – SmallWorld')

  const navigateToDiscover = () => {
    navigate('/discover')
  }

  const handleOnSelectedStrength = (ratingStage) => {
    if (ratingStage == 'initiated') {
      setRatedOnPage((prev) => prev + 1)
    } else if (ratingStage == 'success') {
      // refetch hits a useEffect
      ratedCountRequest.refetch()
      matchingStrongRelationshipsPeopleIdsRequest.refetch()
    } else if (ratingStage == 'error') {
      setRatedOnPage((prev) => prev - 1)
    }
  }

  // Manage the connector count that is passed from the NetworkStatsCard
  const handleMyNetworkStatsUpdate = (stats) => {
    setNetworkStats(stats)
  }
  useEffect(() => {
    const connectors = (networkStats?.firstDegreeConnections ?? 0) + (networkStats?.secondDegreeConnections ?? 0)

    if (networkStats != undefined) {
      setConnectorsPresent(connectors > 0)
    }
  }, [networkStats])
  // END Manage the connector count that is passed from the NetworkStatsCard

  const loadMoreRelationships = () => {
    setRelationshipDisplayLimit((prevCount) => prevCount + loadMoreIncrement)
  }

  return (
    <>
      <Container maxW="1100px" p={0}>
        {/* 
          * * * * 
          TOP STATS AREA
          * * * * 
        */}

        {import.meta.env.VITE_FF_NETWORK_STATS_HIDDEN != '1' && (
          <NetworkStatsCard
            relationshipsRated={relationshipsRated}
            onUpdateMyNetworkStats={handleMyNetworkStatsUpdate}
            relationshipRefreshInterval={onboarding ? 5000 : 0}
          ></NetworkStatsCard>
        )}

        <Box
          bg="white"
          borderTop="1px solid"
          borderRight="1px solid"
          borderLeft="1px solid"
          borderColor="gray.200"
          p={8}
        >
          <Grid templateColumns="repeat(12, 1fr)" gap="8">
            <GridItem colSpan={twoColumnLayout ? 8 : 12}>
              {relationshipsToRate && relationshipsToRate.length === 0 && (
                <Text color="gray.500" fontSize="lg" textAlign="center" my={10}>
                  You've rated all your relationships.
                </Text>
              )}

              {relationshipsToRate &&
                relationshipsToRate.map((relationship: Relationship) => {
                  return (
                    <Box key={relationship.id}>
                      <RateRelationshipCard relationship={relationship} onStrengthSelected={handleOnSelectedStrength} />
                    </Box>
                  )
                })}

              {relationshipsToRate && loadMoreButtonVisible && (
                <Button colorScheme="blue" onClick={() => loadMoreRelationships()} mr={3} mb={5} mt={1}>
                  Load More
                </Button>
              )}

              {!relationshipsToRate && (
                <Box>
                  <Skeleton
                    height="125px"
                    mb={4}
                    startColor="gray.100"
                    endColor="gray.200"
                    width="100%"
                    borderRadius={'5px'}
                  />

                  <Skeleton
                    height="125px"
                    mb={4}
                    startColor="gray.100"
                    endColor="gray.200"
                    width="100%"
                    borderRadius={'5px'}
                  />

                  <Skeleton
                    height="125px"
                    startColor="gray.100"
                    endColor="gray.200"
                    width="100%"
                    borderRadius={'5px'}
                  />
                </Box>
              )}
            </GridItem>

            {/* 
              * * * * 
              SIDEBAR FOR SUPER CONNECTOR LAUNCH
              * * * * 
            */}

            {import.meta.env.VITE_FF_NETWORK_STATS_HIDDEN != '1' && (
              <GridItem colSpan={twoColumnLayout ? 4 : 12}>
                <Box position="sticky" top="17vh" zIndex="sticky" mb={4}>
                  {!onboarding && (
                    <>
                      <IntroductionScorecard />
                    </>
                  )}

                  {onboarding && (
                    <>
                      {connectorsPresent === undefined ? (
                        <>
                          {/* Skeleton for the button */}
                          <Skeleton
                            height="48px"
                            mb={2}
                            startColor="gray.100"
                            endColor="gray.200"
                            borderRadius={'5px'}
                          />

                          {/* Skeleton for the text */}
                          <Skeleton
                            height="20px"
                            width="80%"
                            margin="0 auto 2rem"
                            startColor="gray.100"
                            endColor="gray.200"
                            borderRadius={'5px'}
                          />
                        </>
                      ) : (
                        <>
                          {/* Rated 100, AND has network growth */}
                          {relationshipsRated >= ratingRequirement && connectorsPresent && (
                            <>
                              <Button
                                textColor="white"
                                fontSize={'.875rem'}
                                bg="green.400"
                                cursor="pointer"
                                disabled={true}
                                mb={2}
                                py={6}
                                onClick={navigateToDiscover}
                              >
                                Discover Prospects
                              </Button>

                              <Text
                                width="80%"
                                margin="0 auto 2rem"
                                fontSize={'small'}
                                lineHeight={'1rem'}
                                textAlign={'center'}
                              >
                                Congratulations, you've unlocked access to Super Connectors!
                              </Text>
                            </>
                          )}

                          {/* Not rated 100, OR has NO network growth */}
                          {(relationshipsRated < ratingRequirement || connectorsPresent === false) && (
                            <>
                              <Button
                                textColor="gray.400"
                                fontSize={'.875rem'}
                                bg="gray.300"
                                cursor="not-allowed"
                                disabled={true}
                                mb={2}
                                py={6}
                              >
                                <Image src={LockIcon} boxSize="16px" mr={2} />
                                Discover Prospects
                              </Button>

                              <Text
                                width="80%"
                                margin="0 auto 2rem"
                                fontSize={'small'}
                                lineHeight={'1rem'}
                                textAlign={'center'}
                              >
                                {/* Rated 100, AND has NO network growth */}
                                {relationshipsRated < ratingRequirement
                                  ? 'Rate at least 100 of your relationships to unlock access.'
                                  : 'We’ll notify you when your network grows to include at least 1 Super Connector.'}
                              </Text>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}

                  <Box w="full">
                    <Text color="gray.600" fontSize="sm" fontWeight="700">
                      Other Links
                    </Text>

                    <Box w="full">
                      <Link color="blue.400" fontSize="sm" onClick={openRatingKeyModal}>
                        How to Measure Relationship Strength
                      </Link>
                    </Box>

                    <Box w="full">
                      <Link
                        color="blue.400"
                        fontSize="sm"
                        href={`${SUPPORT_URL}/article/super-connector-faq`}
                        target="_blank"
                      >
                        Frequently Asked Questions
                      </Link>
                    </Box>

                    <Box w="full">
                      <Link color="blue.400" fontSize="sm" onClick={openShareModal}>
                        Invite Your Network
                      </Link>
                    </Box>
                  </Box>

                  {/* 
                      When a user rates their 100th relationship
                      AND they have network expansion 
                  */}
                  {relationshipsRated === ratingRequirement && connectorsPresent && (
                    <Modal size="xl" isOpen={successModalIsOpen} onClose={() => setSuccessModalIsOpen(false)}>
                      <ModalOverlay />
                      <ModalContent textAlign="center" p={2}>
                        <ModalBody>
                          <Text fontSize="2xl" mb={4} fontWeight="bold">
                            You’ve Unlocked Access
                          </Text>
                          <Text>
                            Now that you've rated 100 relationships you can access SmallWorld to see if there are any
                            Super Connectors who know your target prospects. Keep rating to expand your network and
                            increase the number of matches as new users join SmallWorld.
                          </Text>
                        </ModalBody>
                        <ModalFooter>
                          <Button colorScheme="blue" onClick={() => setSuccessModalIsOpen(false)} mr={3}>
                            Keep Rating
                          </Button>

                          <Button bg="green.400" onClick={navigateToDiscover} mr={3}>
                            Discover Prospects
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  )}

                  {/* 
                      When a user rates their 100th relationship
                      AND they have ZERO network expansion 
                  */}

                  {relationshipsRated === ratingRequirement && connectorsPresent === false && (
                    <Modal size="xl" isOpen={successModalIsOpen} onClose={() => setSuccessModalIsOpen(false)}>
                      <ModalOverlay />
                      <ModalContent textAlign="center" p={4}>
                        <ModalBody>
                          <Text fontSize="2xl" mb={2} fontWeight="bold">
                            No Network Growth
                          </Text>
                          <Text mb={4}>
                            Unfortunately, there are no new Super Connectors in your network right now. Keep rating and
                            invite your network to increase the chances of securing matches with your target prospects.
                            We will notify you as new Super Connectors join that become part of your network in
                            SmallWorld.
                          </Text>

                          <Text>
                            <Text as="span" fontWeight="bold">
                              Want invite your 1st degree network to SmallWorld?
                            </Text>{' '}
                            <Link
                              onClick={() => {
                                openShareModal()
                                setSuccessModalIsOpen(false)
                              }}
                            >
                              Invite them to SmallWorld
                            </Link>{' '}
                            and they'll be added to your network.
                          </Text>
                        </ModalBody>
                        <ModalFooter>
                          <Button colorScheme="blue" onClick={() => setSuccessModalIsOpen(false)} mr={0}>
                            Keep Rating
                          </Button>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  )}
                </Box>
              </GridItem>
            )}

            {/* 
              * * * * 
              SIDEBAR FOR SUPER CONNECTOR PRE-REGISTRATION
              * * * * 
            */}

            {import.meta.env.VITE_FF_NETWORK_STATS_HIDDEN == '1' && (
              <GridItem colSpan={twoColumnLayout ? 4 : 12}>
                <Box position="sticky" top="20vh" zIndex="sticky">
                  <Box mb={4} p={4} borderRadius="md">
                    {/* When a user hasn't rated 100 yet */}
                    {relationshipsRated < ratingRequirement && (
                      <Box>
                        <Text fontSize={16} mb={0} color="gray.600">
                          <Text as="span" color="blue.400" fontWeight={600} mr={1} fontSize={24}>
                            {relationshipsRated}
                          </Text>
                          of
                          <Text as="span" fontWeight={500} mx={1.5} fontSize={24}>
                            {ratingRequirement}
                          </Text>
                          Ratings
                        </Text>

                        <Text color="gray.600" fontSize="sm" lineHeight={1.7} mb={4}>
                          Rate {ratingRequirement} of your Relationships to secure warm introductions to target
                          prospects through trusted sources. Pre-register to receive free access to our platform when we
                          launch in January!
                        </Text>
                      </Box>
                    )}
                    {/* When a user has rated at least 100 */}
                    {relationshipsRated >= ratingRequirement && (
                      <Box>
                        <Text fontSize={16} mb={0} color="gray.600">
                          <Text as="span" color="blue.400" fontWeight={600} mr={1} fontSize={24}>
                            {relationshipsRated}
                          </Text>
                          of
                          <Text as="span" fontWeight={500} mx={1.5} fontSize={24}>
                            {totalRelationshipCount.toLocaleString()}
                          </Text>
                          Ratings
                        </Text>

                        <Text color="gray.600" fontSize="sm" lineHeight={1.7} mb={4}>
                          <strong>Congratulations, you’ve secured your invite.</strong> But remember, the more
                          relationships you rate, the larger your trusted network becomes.
                        </Text>
                      </Box>
                    )}
                    <Box w="full">
                      <Text color="gray.600" fontSize="sm" fontWeight="700">
                        Other Links
                      </Text>

                      <Box w="full">
                        <Link color="blue.400" fontSize="sm" onClick={openRatingKeyModal}>
                          How to Measure Relationship Strength
                        </Link>
                      </Box>

                      <Box w="full">
                        <Link
                          color="blue.400"
                          fontSize="sm"
                          href={`${SUPPORT_URL}/article/super-connector-faq`}
                          target="_blank"
                        >
                          Frequently Asked Questions
                        </Link>
                      </Box>
                    </Box>
                    {/* When a user rates their 100th relationship */}
                    {relationshipsRated === ratingRequirement && (
                      <Box>
                        <Particles
                          id="confetti"
                          options={particlesConfettiOptions as any} // TODO: Fix this typing
                        />
                        <Modal size="lg" isOpen={successModalIsOpen} onClose={() => setSuccessModalIsOpen(false)}>
                          <ModalOverlay />
                          <ModalContent textAlign="center" p={2}>
                            <ModalBody>
                              <Text fontSize="2xl" mb={4} fontWeight="bold">
                                Congrats!
                              </Text>
                              <Text>
                                You’ve now rated {ratingRequirement} relationships and unlocked access to Super
                                Connectors when we launch! Stay tuned for updates and remember, the more relationships
                                you rate, the more warm intros you’ll access once we’re live.
                              </Text>
                            </ModalBody>
                            <ModalFooter>
                              <Button colorScheme="blue" onClick={() => setSuccessModalIsOpen(false)} mr={3}>
                                Keep Rating
                              </Button>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </Box>
                    )}
                  </Box>
                </Box>
              </GridItem>
            )}
          </Grid>
        </Box>
      </Container>

      <Footer />

      <Modal isOpen={isRatingKeyModalOpen} onClose={closeRatingKeyModal} size="lg">
        <ModalOverlay />
        <ModalContent textAlign="left">
          <ModalCloseButton />
          <ModalBody>
            <RelationshipRatingKey />
          </ModalBody>
        </ModalContent>
      </Modal>

      <ShareModal isOpen={isShareModalOpen} onClose={closeShareModal} />
    </>
  )
}
