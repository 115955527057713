import { AuthApi, HttpError } from '~/api'

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export type RequestConfig<T> = {
  method?: HttpMethod
  headers?: HeadersInit
  body?: T | null
  defaultResponse?: T
  signal?: AbortSignal
}

export async function request<TRequest = unknown, TResponse = unknown>(
  url: URL,
  httpMethod: HttpMethod = 'GET',
  requestOptions?: RequestConfig<TRequest>,
  defaultResponse?: TResponse,
  timeout: number = 5000
): Promise<TResponse | null> {
  const { method = httpMethod, headers = {}, body = null, signal = null } = requestOptions || {}

  const abortController = new AbortController()
  const timeoutId = setTimeout(() => abortController.abort(), timeout)

  const fetchOptions: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    signal,
    mode: 'cors',
    credentials: 'include',
    body: body ? JSON.stringify(body) : null
  }

  try {
    let response = await fetch(url, fetchOptions)
    let accessTokenRetryFailed = false

    // Clear the timeout if the fetch request resolves in time
    clearTimeout(timeoutId)

    // If 400 BadRequest, send error to requesting function to indicate failed request
    if (response.status === 400 || response.status === 404) {
      throw new HttpError(response.status, response.statusText)
    }

    if (response.status === 404) {
      throw new HttpError(response.status, response.statusText)
    }

    // If 401 Unauthorized, try to acquire a new access token and retry the request
    if (response.status === 401) {
      const accessTokenData = await AuthApi.accessTokenRequest()

      if (accessTokenData.issued) {
        response = await fetch(url, fetchOptions)
      } else {
        accessTokenRetryFailed = true
      }
    }

    // Catch-all for all other errors (including from the request retry)
    const authFailed = response.status === 401 && accessTokenRetryFailed

    if (!response.ok && authFailed) {
      // Failed login, 401 & no token
      throw new HttpError(response.status, response.statusText)
    }

    if (!response.ok && !authFailed) {
      const data = await response.text()
      throw new HttpError(response.status, response.statusText, data)
    }

    if (response.status === 204) {
      // We check explicitly for 204 because we try to parse the JSON payload
      // in below steps. If there's no content returned, then the JSON parsing
      // step throws an exception (which we now avoid with this step).
      return null
    } else {
      try {
        const clonedResponse = response.clone()
        return await clonedResponse.json()
      } catch (error) {
        const clonedResponse = response.clone()
        const text = await clonedResponse.text()
        const message = error.message.concat('\n\n').concat(text)
        throw new HttpError(response.status, response.statusText, message)
      }
    }
  } catch (error) {
    clearTimeout(timeoutId) // clear the timeout if an error occurred

    if (defaultResponse) {
      return defaultResponse
    } else {
      throw error
    }
  }
}
