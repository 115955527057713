// /lib/copyToClipboard.ts

/**
 * Copies the given text to the clipboard.
 */
export async function copyToClipboard(text: string): Promise<void> {
  try {
    await navigator.clipboard.writeText(text)
    return Promise.resolve()
  } catch (err) {
    return Promise.reject(err)
  }
}
