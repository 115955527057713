import { Button } from '@chakra-ui/react'

export const Footer = ({
  isLoading,
  onCancelClick,
  onRequestClick
}: {
  isLoading: boolean
  onCancelClick: () => void
  onRequestClick: () => void
}) => {
  return (
    <>
      <Button size="sm" variant="tertiary" onClick={onCancelClick}>
        Cancel
      </Button>
      <Button
        variant="secondary"
        size="sm"
        onClick={onRequestClick}
        isLoading={isLoading}
        loadingText="REQUESTING"
        isDisabled={isLoading}
      >
        Request
      </Button>
    </>
  )
}
