import { create } from 'zustand'

export type DiscoverStoreState = {
  relationshipStrengths: string | null
  levels: string | null
  buyingCenters: string | null
  targetCompanies: string | null
  targetCompaniesInclude: boolean | null
  excludedConnectors: string | null
  showAnonFilter: boolean | null
  isBeastMode: boolean | null
  hasTargetCompanies: boolean | null
  prospectMetasCount: number | null
}

export type DiscoverStoreActions = {
  setRelationshipStrengths: (ids: string) => void
  setLevels: (levels: string) => void
  setBuyingCenters: (ids: string) => void
  setTargetCompanies: (ids: string) => void
  setTargetCompaniesInclude: (value: boolean) => void
  setExcludedConnectors: (ids: string) => void
  setShowAnonFilter: (value: boolean) => void
  setIsBeastMode: (value: boolean) => void
  setHasTargetCompanies: (value: boolean) => void
  setProspectMetasCount: (count: number) => void
}

export type DiscoverStore = DiscoverStoreState & DiscoverStoreActions

const defaults: DiscoverStoreState = {
  relationshipStrengths: null,
  levels: null,
  buyingCenters: null,
  targetCompanies: null,
  targetCompaniesInclude: null,
  excludedConnectors: null,
  showAnonFilter: null,
  isBeastMode: null,
  hasTargetCompanies: null,
  prospectMetasCount: null
}

const useDiscoverStore = create<DiscoverStore>()((set) => ({
  ...defaults,
  setRelationshipStrengths: (ids) => set(() => ({ relationshipStrengths: ids })),
  setLevels: (levels) => set(() => ({ levels: levels })),
  setBuyingCenters: (ids) => set(() => ({ buyingCenters: ids })),
  setTargetCompanies: (ids) => set(() => ({ targetCompanies: ids })),
  setTargetCompaniesInclude: (value) => set(() => ({ targetCompaniesInclude: value })),
  setExcludedConnectors: (ids) => set(() => ({ excludedConnectors: ids })),
  setShowAnonFilter: (value) => set(() => ({ showAnonFilter: value })),
  setIsBeastMode: (value) => set(() => ({ isBeastMode: value })),
  setHasTargetCompanies: (value) => set(() => ({ hasTargetCompanies: value })),
  setProspectMetasCount: (count) => set(() => ({ prospectMetasCount: count })),
}))

export default useDiscoverStore
