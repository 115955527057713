import { useQuery } from '@tanstack/react-query'
import { request } from './utils'
import { BASE_URL } from '~/settings'

// TODO: Should this use connector_url instead?

type AccountResponse = {
  account: Account
}

const NAMESPACE = `${BASE_URL}/accounts`
const loadAccountUrl = (id: number) => new URL(`${NAMESPACE}/${id}`)

export const useAccount = (id: number) => {
  return useQuery({
    queryKey: ['accounts', id],
    queryFn: () => request<number, AccountResponse>(loadAccountUrl(id)),
    select: (data) => data?.account,
    staleTime: 16 * 60 * 1000,
    enabled: !!id
  })
}
