import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { request, RequestConfig } from './utils'
import { API_PATH_SWAG } from '~/settings'

const NAMESPACE_SWAG = 'competitors'

const BASE_URL_SWAG = `${import.meta.env.VITE_API_GW_URL}${API_PATH_SWAG}/${NAMESPACE_SWAG}`

export type Credentials = {
  email: string
  password: string
}

export type DeleteConfimation = {
  deleteStatus: string
}

export const createCompetitor = async (competitor: CreateCompetitorRequestBody): Promise<ReturnRecordId> => {
  const url = new URL(`${BASE_URL_SWAG}/create`)

  const options: RequestConfig<CreateCompetitorRequestBody> = { body: competitor }
  const result = await request<CreateCompetitorRequestBody, ReturnRecordId>(url, 'POST', options)

  return result
}

export const useCreateCompetitor = (options?: UseMutationOptions<ReturnRecordId, Error, CreateCompetitorRequestBody>) =>
  useMutation<ReturnRecordId, Error, CreateCompetitorRequestBody>(createCompetitor, options)

export const deleteCompetitor = async (competitorId: number): Promise<DeleteConfimation> => {
  const url = new URL(`${BASE_URL_SWAG}/delete/${competitorId}`)

  const result = await request<null, DeleteConfimation>(url, 'DELETE')

  return result
}

export const useDeleteCompetitor = (options?: UseMutationOptions<DeleteConfimation, Error, number>) =>
  useMutation<DeleteConfimation, Error, number>(deleteCompetitor, options)

export const deleteAllCompetitors = async (memberId: number): Promise<DeleteConfimation> => {
  const url = new URL(`${BASE_URL_SWAG}/delete-all/${memberId}`)

  const result = await request<null, DeleteConfimation>(url, 'DELETE')

  return result
}

export const useDeleteAllCompetitors = (options?: UseMutationOptions<DeleteConfimation, Error, number>) =>
  useMutation<DeleteConfimation, Error, number>(deleteAllCompetitors, options)

export const getCompetitorsForMember = async (memberId: number): Promise<Competitors> => {
  const url = new URL(`${BASE_URL_SWAG}/${memberId}`)

  const response = await request<null, Competitors>(url, 'GET')

  // Alphabetize the Competitor based on companyName
  response.competitors.sort((a, b) => {
    const nameA = (a.companyName || '').toLowerCase()
    const nameB = (b.companyName || '').toLowerCase()

    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  })

  return response
}

export const useGetCompetitorsForMember = (memberId: number, options?: UseQueryOptions<Competitors, Error>) => {
  return useQuery<Competitors, Error>(['getCompetitorsForMember', memberId], () => getCompetitorsForMember(memberId), {
    enabled: Boolean(memberId),
    ...options
  })
}
