import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Spinner } from '@chakra-ui/react'
import { useAuth } from '~/auth'

export const AuthCallback = () => {
  const auth = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (auth.isLoggedIn) navigate('/')
  }, [navigate, auth.isLoggedIn])

  return (
    <Box w="full" h="100%">
      <Spinner />
    </Box>
  )
}
