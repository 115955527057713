import { Box, Center, Flex, Text, Tooltip, useToast } from '@chakra-ui/react'
import { InfoOutlineIcon } from '@chakra-ui/icons'
import { MouseEvent, useState } from 'react'
import { useRequestStrengthMutation } from '~/api/relationships'
import RelationshipStrength from '~/lib/relationshipStrength'
import { StrengthDisplay } from '~/components/RatingModule/StrengthDisplay'

export type StrengthButtonProps = {
  relationshipId: number
  strength: Strengths | null
  strengthRequested: boolean
}

const unratedToolTip =
  'This relationship is unrated, but you can still request an introduction. If you request strength, we’ll notify you when the Connector responds.'

const RequestStrength = ({
  requested,
  handleOnClick
}: {
  requested: boolean
  handleOnClick?: (event: MouseEvent<HTMLDivElement>) => void
}) => {
  const styles = requested
    ? { color: 'black', fontStyle: 'italic' }
    : { color: 'blue.400', textDecoration: 'underline' }

  return (
    <Flex align="center" justify="center">
      <Text fontSize="xs" mr={2} sx={styles} onClick={handleOnClick && !requested ? handleOnClick : undefined}>
        {requested ? 'Requested' : 'Request Strength'}
      </Text>
      {!requested && (
        <Tooltip hasArrow label={unratedToolTip} placement="top">
          <InfoOutlineIcon />
        </Tooltip>
      )}
    </Flex>
  )
}

const WithStrength = ({ relationshipStrength }: { relationshipStrength: RelationshipStrength }) => {
  return (
    <>
      <StrengthDisplay rating={`${relationshipStrength.databaseValue}`} fontSize="xs" fontWeight="normal" mr={2} />
      <Tooltip
        hasArrow
        label={`This Super Connector has ${relationshipStrength.fullPhraseWithArticle} with the prospect.`}
        placement="top"
      >
        <InfoOutlineIcon />
      </Tooltip>
    </>
  )
}

export const StrengthButton = (props: StrengthButtonProps) => {
  const { relationshipId, strength, strengthRequested } = props

  const toast = useToast()
  const [requested, setRequested] = useState(strengthRequested)
  const mutation = useRequestStrengthMutation()

  const handleOnClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()

    if (strength !== null || requested) return
    setRequested(true)

    mutation.mutate(relationshipId, {
      onSettled: () => {
        toast({
          status: 'info',
          title:
            "We sent a notification to the Super Connector asking about the strength of their relationship with this Prospect. You'll get notified when they respond.",
          position: 'top',
          duration: 5000,
          isClosable: true
        })
      }
    })
  }

  const relationshipStrength = RelationshipStrength.fromDatabaseValue(strength)

  return (
    <Box
      w={{
        base: '100%',
        sm: '300px'
      }}
      borderRadius="3px"
      borderWidth="1px"
      borderColor="gray.200"
      px={7}
      py={4}
      display="flex"
      justifyContent="center"
    >
      <Center>
        <Box textAlign="center">
          {strength !== null ? (
            <WithStrength relationshipStrength={relationshipStrength} />
          ) : (
            <RequestStrength requested={requested} handleOnClick={handleOnClick} />
          )}
        </Box>
      </Center>
    </Box>
  )
}
