import { Flex } from '@chakra-ui/react'
import { Outlet, Route, Routes } from 'react-router-dom'

import { CollectPhoneNumber } from './CollectPhoneNumber'
import { VerifyPhoneNumber } from './VerifyPhoneNumber'
import { CollectCredentials } from './CollectCredentials'
import { Sync } from './Sync'
import { Confirmation } from './Confirmation'

export const SignUp = () => {
  return (
    <Flex height="100vh" justify="center" align="center">
      <Routes>
        <Route index path="" element={<CollectPhoneNumber />} />
        <Route path="verify" element={<VerifyPhoneNumber />} />
        <Route path="credentials" element={<CollectCredentials />} />
        <Route path="sync" element={<Sync />} />
        <Route path="confirmation/:userId" element={<Confirmation />} />
      </Routes>

      <Outlet />
    </Flex>
  )
}
