/* stores/user.ts */

import { create } from 'zustand'
import { produce } from 'immer'

export type Store = {
  setUser: (user: User) => void

  clearUser: () => void

  id: string
  setId: (id: string) => void

  email: string
  setEmail: (id: string) => void

  isSuperuser: boolean
  setIsSuperuser: (isSuperuser: boolean) => void

  createdAt: Date
  setCreatedAt: (createdAt: Date) => void

  updatedAt: Date
  setUpdatedAt: (updatedAt: Date) => void
}

export const useStore = create<Store>((set) => ({
  setUser: (user) =>
    set(
      produce((draft) => {
        draft.id = user.id
        draft.email = user.email
        draft.createdAt = user.createdAt
        draft.updatedAt = user.updatedAt
      })
    ),

  clearUser: () =>
    set(
      produce((draft) => {
        draft.id = null
        draft.email = null
        draft.isSuperuser = null
        draft.createdAt = null
        draft.updatedAt = null
      })
    ),

  id: null,
  setId: (id) => set({ id }),

  email: null,
  setEmail: (email) => set({ email }),

  isSuperuser: false,
  setIsSuperuser: (isSuperuser) => set({ isSuperuser }),

  createdAt: null,
  setCreatedAt: (createdAt) => set({ createdAt }),

  updatedAt: null,
  setUpdatedAt: (updatedAt) => set({ updatedAt })
}))
