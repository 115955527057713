import { useState, useMemo, useCallback, memo } from 'react'
import { chakra, Table, Thead, Tbody, Tr, Th, Td, TableContainer, VStack, Center } from '@chakra-ui/react'
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  SortingState,
  getSortedRowModel,
  createColumnHelper
} from '@tanstack/react-table'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import Pagination from '@choc-ui/paginator'
import { ActionCell } from './ActionCell'

type DataTableProps = {
  data: Competitor[]
  onDelete: (companyId: number) => void
}

export const DataTable = memo(({ data, onDelete }: DataTableProps) => {
  const recordsPerPage = 10
  const [sorting, setSorting] = useState<SortingState>([])
  const [currentPage, setCurrentPage] = useState(1)

  const handlePageChange = useCallback((pageNumber) => setCurrentPage(pageNumber), [])

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<CompetitorCompanyRecord>()

    return [
      columnHelper.accessor('companyName', {
        header: 'Company',
        cell: (info) => info.getValue(),
        sortingFn: 'text',
        minSize: 150
      }),
      columnHelper.accessor('actions', {
        header: () => 'Actions',
        cell: (info) => <ActionCell row={info.row} onDelete={onDelete} />,
        size: 50,
        meta: {
          isActionsColumn: true
        }
      })
    ]
  }, [])

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    // Enable manual pagination since we're now handling sorted data
    manualPagination: true,
    // Provide the current page and pageSize for the manual pagination
    pageCount: Math.ceil(data.length / recordsPerPage),
    state: {
      sorting,
      pagination: {
        pageIndex: currentPage - 1,
        pageSize: recordsPerPage
      }
    }
  })

  const startIndex = (currentPage - 1) * recordsPerPage
  const endIndex = currentPage * recordsPerPage
  const paginatedRows = useMemo(
    () => table.getRowModel().rows.slice(startIndex, endIndex),
    [table.getRowModel().rows, startIndex, endIndex]
  )

  return (
    <VStack w="full" gap={8}>
      <TableContainer w="full" border="1px" borderColor="gray.200" borderRadius="md" px={2} pb={6}>
        <Table size="sm" variant="simple" colorScheme="facebook" sx={{ tableLayout: 'fixed' }}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const colSize: number = header.column.getSize()
                  const meta: any = header.column.columnDef.meta
                  const isSortingEnabled = header.id === 'company' // Only enable sorting on the 'Company' column
                  return (
                    <Th
                      key={header.id}
                      w={colSize}
                      isNumeric={meta?.isNumeric}
                      style={meta?.isActionsColumn ? { textAlign: 'right' } : undefined}
                      {...(isSortingEnabled && { onClick: header.column.getToggleSortingHandler() })}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {isSortingEnabled && (
                        <chakra.span pl="4">
                          {header.column.getIsSorted() ? (
                            header.column.getIsSorted() === 'desc' ? (
                              <TriangleDownIcon aria-label="sorted descending" />
                            ) : (
                              <TriangleUpIcon aria-label="sorted ascending" />
                            )
                          ) : null}
                        </chakra.span>
                      )}
                    </Th>
                  )
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {paginatedRows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                  const meta: any = cell.column.columnDef.meta
                  return (
                    <Td key={cell.id} isNumeric={meta?.isNumeric}>
                      {cell.column.id === 'actions' && <ActionCell row={row} onDelete={onDelete} />}
                      {!['type', 'actions'].includes(cell.column.id) &&
                        flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Td>
                  )
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
        {!Boolean(data?.length > 0) && <Center mt={8}>You haven't added any Competitors.</Center>}
      </TableContainer>
      {data?.length > 0 && (
        <Pagination
          // This value should be controlled by state
          currentPage={currentPage}
          // The total number of records divided by records per page
          pageSize={recordsPerPage}
          // Total number of records for pagination
          total={data?.length || 0}
          // onChange method to set the current page
          onChange={handlePageChange}
          paginationProps={{
            display: 'flex'
          }}
          pageNeighbours={2}
        />
      )}
    </VStack>
  )
})
