// src/pages/RateRelationships.tsx
import React from 'react'
import { Box, Circle, Container, Link, Text } from '@chakra-ui/react'
import { Header, Footer, RatingModule, PendoTrigger } from '~/components'

export const RateRelationships = () => {
  return (
    <>
      <Container maxW="container.lg" minH={400}>
        <Text as="h1" fontSize="4xl" mt={8} textAlign="center">
          Rate Your Relationships
        </Text>

        <Text width="60%" margin="0 auto" mb={8} textAlign="center">
          As you rate relationships, you’ll add Super Connectors to your network with whom you share trusted
          relationships.
        </Text>

        <RatingModule onboarding={true} />
      </Container>

      <PendoTrigger bgColor="blue.400" />

      <Footer />
    </>
  )
}

export default RateRelationships
