import { Text, Box, Flex, Heading, List, ListItem, Link as ChakraLink, HStack } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'

import CheckBoxCircle from './CheckBoxCircle'
import { Trademark } from '~/components'

import welcomeBannerBg from '~/assets/bg-welcome-banner.png'

type DiscoverWelcomeBannerProps = {
  hasTargetCompanies: boolean
  hasCompetitorCompanies: boolean
}

export const DiscoverWelcomeBanner = ({
  hasTargetCompanies: hasTargetCompanies,
  hasCompetitorCompanies: hasCompetitorCompanies
}: DiscoverWelcomeBannerProps) => {
  const linkStyle = { marginRight: '4px', color: 'white', textDecoration: 'underline', fontWeight: 'bold' }

  return (
    <>
      <Box
        bg="blue.400"
        w="100%"
        my={8}
        p={6}
        pr={16}
        color="white"
        borderRadius="lg"
        bgImage={welcomeBannerBg}
        bgPosition="bottom right"
        bgRepeat="no-repeat"
        bgSize="50%"
      >
        <Heading mb={5} fontSize={'2xl'} fontWeight={600}>
          Welcome to Super Connectors<sup style={{ color: 'white', fontSize: '.95rem', opacity: 0.5 }}>™</sup> from
          SmallWorld
        </Heading>
        <List spacing={3}>
          <ListItem mb={6}>
            <HStack>
              <CheckBoxCircle isChecked={true} />
              <Text fontSize="1rem" alignItems="baseline" pl={2}>
                <ChakraLink as={RouterLink} to={'../rate-relationships'} {...linkStyle}>
                  Rate
                </ChakraLink>
                your first 100 Relationships. But don’t stop there – the more relationships you rate, the more access
                you’ll unlock into target prospects through other Super Connectors.
              </Text>
            </HStack>
          </ListItem>
          <ListItem mb={6}>
            <HStack>
              <CheckBoxCircle isChecked={hasTargetCompanies} />
              <Text fontSize="1rem" alignItems="baseline" pl={2}>
                <ChakraLink as={RouterLink} to={'../target-companies'} {...linkStyle}>
                  Add Target Companies
                </ChakraLink>
                (optional) to see where other Super Connectors have relationships into your accounts
              </Text>
            </HStack>
          </ListItem>
          <ListItem mb={6}>
            <HStack>
              <CheckBoxCircle isChecked={hasCompetitorCompanies} />
              <Text fontSize="1rem" alignItems="baseline" pl={2}>
                <ChakraLink as={RouterLink} to={'../competitor-companies'} {...linkStyle}>
                  Add Competitors
                </ChakraLink>
                (optional) to suppress any matches with Super Connectors who may work at competing companies.
              </Text>
            </HStack>
          </ListItem>
          <ListItem mb={2}>
            <HStack>
              <CheckBoxCircle isChecked={false} />
              <Text fontSize="1rem" alignItems="baseline" pl={2}>
                <Text as="span" fontWeight={'bold'} mr={'4px'}>
                  Request an Introduction
                </Text>
                from another Super Connector through the dashboard or by searching on a specific company or prospect.
              </Text>
            </HStack>
          </ListItem>
        </List>
      </Box>
    </>
  )
}

export default DiscoverWelcomeBanner
