import { Box, Flex, Link, Text } from '@chakra-ui/react'
import { CloseIcon } from '@smallworld-io/pangaea'

import { fullname, railsUrl } from '~/lib'

export type LinksContentProps = {
  relationshipLead: RelationshipLead
  onBack: () => void
}

export const LinksContent = ({ relationshipLead, onBack }: LinksContentProps) => {
  const { prospect, connector } = relationshipLead
  const { company, linkedinUrl } = prospect

  const connectorReqDashLink = railsUrl(`/v2/dashboard/rd?connectors=${connector.id}`)

  let viewOtherLink = ''
  if (connector.isAnonymous) {
    viewOtherLink = `/search?companyId=${company.id}`
  } else if (!company.isTargetCompany) {
    viewOtherLink = railsUrl(`/searches?query[company_name]=${company.name}&commit=Search`)
  } else {
    viewOtherLink = railsUrl(`/v2/dashboard/rd?target_companies=${company.id}`)
  }

  const prospectLink = connector.isAnonymous
    ? `/introduction-request/${prospect.id}`
    : railsUrl(`/people/${prospect.id}`)

  return (
    <Flex direction="column" flex="2" p={2} height="100%" position="relative">
      <Flex justify="end">
        <CloseIcon boxSize="0.625em" color="black" onClick={onBack} cursor="pointer" />
      </Flex>
      <Box mb={2} pt={2}>
        <Text fontSize="xs" fontWeight="bold">
          {company.name}
        </Text>
        <Text mt={-1}>
          <Link fontSize="xs" href={viewOtherLink} isExternal={!viewOtherLink.startsWith('/')}>
            View Other Relationships
          </Link>
        </Text>
      </Box>
      <Box mb={2}>
        <Text fontSize="xs" fontWeight="bold">
          {fullname(prospect)} (Prospect)
        </Text>
        {linkedinUrl && (
          <Text mt={-1}>
            <Link fontSize="xs" href={linkedinUrl} isExternal>
              Visit LinkedIn Profile
            </Link>
          </Text>
        )}
        {!connector.isAnonymous && (
          <Text mt={-1}>
            <Link fontSize="xs" href={prospectLink} isExternal>
              View All Connectors Who Know {prospect.firstName}
            </Link>
          </Text>
        )}
      </Box>
      <Box mb={2}>
        {!connector.isAnonymous && (
          <>
            <Text fontSize="xs" fontWeight="bold">
              {fullname(connector)} (Connector)
            </Text>
            <Text mt={-1}>
              <Link fontSize="xs" href={connectorReqDashLink} isExternal>
                View {connector.firstName}&apos;s Other Relationships
              </Link>
            </Text>
          </>
        )}
      </Box>
    </Flex>
  )
}
