import { Box, Center, Container, Link, Text, VStack } from "@chakra-ui/react"
import { SmallWorldIcon } from "~/components/SmallWorldIcon"
import { useAuth } from "~/auth"

export const ErrorPage = () => {
  return (
    <Container maxW="800px" my={8}>
      <VStack>
        <Center my={10}>
          <SmallWorldIcon size={20} isDisabled />
        </Center>
        <Box textAlign="center">
          <Text as="h1" fontSize="3xl" fontWeight="bold">
            Sorry, Something Seems To Have Gone Wrong!
          </Text>
        </Box>
        <Box textAlign="center" mb={4} px={10}>
          <Text as="h2" fontSize="xl">
            We Apologize For The Inconvenience &amp; Will Try To Resolve The Issue As Soon As Possible
          </Text>
        </Box>
        <Box textAlign="center" px={10}>
          <Text>
            Please Feel Welcome To Contact{' '}
            <Link href="mailto:support@smallworld.ai" isExternal>
              SmallWorld Support
            </Link>{' '}
            For Guidance &amp; Updates Regarding Any Issues You Are Encountering
          </Text>
        </Box>
      </VStack>
    </Container>
  )
}
