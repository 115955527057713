import { Box } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import MultiSelectMenuChakra from '~/components/MultiSelectMenuChakra'
import useDiscoverStore from '~/stores/discover'
import useExpressStore from '~/stores/express'
import { relationshipStrengths } from '~/lib'
import { useShallow } from 'zustand/react/shallow'
import { RequesterDashboardApi } from '~/api'

// Define the component
export const StrengthsFilter = () => {
  const location = useLocation()

  // Component (True) Constant Variables
  const REQ_DASH_PREFIX = 'req_dash_'
  const REQ_DASH_STRENGTHS_NAME = `${REQ_DASH_PREFIX}strengths`

  //region Store Values
  const membership = useExpressStore((state) => state.membership)
  const { strengthsFilterString, setRelationshipStrengths } = useDiscoverStore(
    useShallow((state) => ({
      strengthsFilterString: state.relationshipStrengths,
      setRelationshipStrengths: state.setRelationshipStrengths
    }))
  )

  //region Query and Mutation Functions
  const saveMemberSettingsRequest = RequesterDashboardApi.useSaveMemberSettingsRequest()
  const getMemberSettingStrengthsRequest = RequesterDashboardApi.useGetMemberSettingRequest(
    !strengthsFilterString,
    membership.id,
    REQ_DASH_STRENGTHS_NAME
  )

  // region Component State
  const [strengthsParamProcessed, setStrengthsParamProcessed] = useState(false)

  const strengthsDescending = [...relationshipStrengths].reverse()
  const selectedStrengthFilters = strengthsFilterString?.split(',').filter((word) => word.trim() !== '') ?? []
  const strengthMenuOptions: MenuOption[] = strengthsDescending.map((strength) => ({
    label: strength.emojiPlusText,
    value: strength.databaseValue,
    isSelected: selectedStrengthFilters.includes(strength.databaseValue)
  }))
  strengthMenuOptions.push({
    label: 'Unrated',
    value: 'Unrated',
    isSelected: selectedStrengthFilters.includes('Unrated')
  })

  // region Use Effects
  // Get & Set Users Levels Settings
  useEffect(() => {
    // Read Buying Center Params if present
    const searchParams = new URLSearchParams(location.search)
    const strengthsParam = searchParams.get('strengths')
    if (!strengthsParamProcessed && strengthsParam) {
      setRelationshipStrengths(strengthsParam)
      setStrengthsParamProcessed(true)
    } else if (
      !getMemberSettingStrengthsRequest.isFetching &&
      getMemberSettingStrengthsRequest.data &&
      !strengthsFilterString
    ) {
      const filtStringToSet = getMemberSettingStrengthsRequest.data.value
      setRelationshipStrengths(filtStringToSet)
    }
  }, [
    getMemberSettingStrengthsRequest.isFetching,
    getMemberSettingStrengthsRequest.data,
    strengthsParamProcessed,
    strengthsFilterString,
    membership,
    setRelationshipStrengths,
    location
  ])

  // region Btn Handlers
  const handleOnCloseStrengths = (menuOptionsParam: MenuOption[]) => {
    const selectedMenuOptions: string[] = menuOptionsParam.filter((a) => a.isSelected).map((a) => a.value)

    const selectedMenuOptionsString: string = selectedMenuOptions.join(',')

    // if deep link, won't get here - see function above for details
    setRelationshipStrengths(selectedMenuOptionsString)

    const memberSettingChange: MemberSetting = {
      organizationMemberId: membership.id,
      value: selectedMenuOptionsString,
      name: REQ_DASH_STRENGTHS_NAME
    }

    saveMemberSettingsRequest.mutate(memberSettingChange, {
      onSuccess: () => {},
      onError: (e) => {
        console.error(e)
      }
    })
  }

  return (
    <Box mr={2}>
      <MultiSelectMenuChakra
        menuOptions={strengthMenuOptions}
        menuTitle="Relationship Strength"
        menuSelectedCount={selectedStrengthFilters.length}
        onClose={handleOnCloseStrengths}
      />
    </Box>
  )
}
