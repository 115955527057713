import { useState, useMemo, useCallback, useEffect } from 'react'
import { AuthApi, HttpError, useLoggedInUserQuery } from '~/api'
import { AuthContext, AuthError } from '~/auth'
import { useAccessTokenRequest } from '~/api/auth'
import { honeybadger } from '~/settings'

type AuthProviderProps = {
  children: React.ReactNode | React.ReactNode[]
}

export const AuthProvider = (props: AuthProviderProps) => {
  const accessTokenRequest = useAccessTokenRequest()
  const loggedInUser = useLoggedInUserQuery()

  const [userId, setUserId] = useState<number>(null)
  const [organizationId, setOrganizationId] = useState<number>(null)

  const [personId, setPersonId] = useState<number>(null)
  const [accountId, setAccountId] = useState<number>(null)

  const [isLoading, setIsLoading] = useState(true)

  const [signupError, setSignupError] = useState(null)
  const [loginError, setLoginError] = useState(null)
  const [logoutError, setLogoutError] = useState(null)

  const signupRequest = AuthApi.useSignupRequest()
  const loginRequest = AuthApi.useLoginRequest()
  const logoutRequest = AuthApi.useLogoutRequest()

  const handleAuth = useCallback(async () => {
    setIsLoading(true)
    accessTokenRequest.refetch()
  }, [accessTokenRequest, setIsLoading])

  const handleSignup = useCallback(
    async (credentials: AuthApi.Credentials) => {
      setSignupError(null)
      signupRequest.mutate(credentials, {
        onSuccess: () => {
          accessTokenRequest.refetch()
          setSignupError(null)
        },
        onError: (e: HttpError) => {
          const authError: AuthError = {
            statusCode: e.statusCode,
            reason: JSON.parse(e.detail)?.detail
          }
          setSignupError(authError)
        }
      })
    },
    [signupRequest, accessTokenRequest]
  )

  const handleLogin = useCallback(
    async (credentials: AuthApi.Credentials) => {
      setLoginError(null)
      loginRequest.mutate(credentials, {
        onSuccess: () => {
          accessTokenRequest.refetch()
          setLoginError(null)
        },
        onError: (error: HttpError) => {
          setLoginError(error)
        }
      })
    },
    [accessTokenRequest, loginRequest]
  )

  const handleLogout = useCallback(async () => {
    setLogoutError(null)
    logoutRequest.mutate(null, {
      onSuccess: () => {
        setLogoutError(null)
      },
      onError: (e: HttpError) => {
        const authError: AuthError = {
          statusCode: e.statusCode,
          reason: JSON.parse(e.detail)?.detail
        }
        setLogoutError(authError)
      },
      onSettled: () => {
        setUserId(null)
        setAccountId(null)
      }
    })
  }, [logoutRequest])


  useEffect(() => {
    if (accessTokenRequest.isLoading || !accessTokenRequest.data) return
    if (loggedInUser.isLoading) return

    const data = accessTokenRequest.data

    if (data.userId) setUserId(data.userId)
    if (data.organizationId) setOrganizationId(data.organizationId)

    if (data.personId) setPersonId(data.personId)
    if (data.accountId) setAccountId(data.accountId)

    honeybadger.setContext({
      user_id: data.userId || 'X',
      organization_id: data.organizationId || 'X',
      person_id: data.personId || 'X',
      account_id: data.accountId || 'X'
    })

    setIsLoading(accessTokenRequest.isLoading || loggedInUser.isLoading)
  }, [
    accessTokenRequest.isLoading,
    accessTokenRequest.data?.userId,
    accessTokenRequest.data?.organizationId,
    accessTokenRequest.data?.personId,
    accessTokenRequest.data?.accountId,
    loggedInUser.isLoading,
    loggedInUser.data,
    setUserId,
    setOrganizationId,
    setPersonId,
    setAccountId,
    setIsLoading
  ])

  const authContextData = useMemo(
    () => ({
      userId: userId,
      organizationId: organizationId,
      personId: personId,
      accountId: accountId,
      isLoggedIn: (Boolean(userId) && Boolean(organizationId)) || (Boolean(personId) && Boolean(accountId)),
      isLoading: isLoading,
      onAuth: handleAuth,
      onSignup: handleSignup,
      onLogin: handleLogin,
      onLogout: handleLogout,
      signupError,
      loginError,
      logoutError
    }),
    [
      userId,
      organizationId,
      personId,
      accountId,
      isLoading,
      handleAuth,
      handleSignup,
      handleLogin,
      handleLogout,
      signupError,
      loginError,
      logoutError
    ]
  )

  /*
    This is very noisy as it tries to fire on each render of the AuthProvider. Until we figure out a better way,
    only uncomment the following useEffect block when you need to actively diagnose.
  */
  // useEffect(() => {
  //   if (authContextData.isLoggedIn) {
  //     const context = `\`isLoggedIn=${authContextData.isLoggedIn}\` \`userId=${authContextData.userId}\` \`organizationId=${authContextData.organizationId}\` \`personId=${authContextData.personId}\` \`accountId=${authContextData.accountId}\``
  //     if (Boolean(userId) && Boolean(organizationId)) {
  //       postToAuthActivity(`USER is logged in ${context}`)
  //     } else if (Boolean(personId) && Boolean(accountId)) {
  //       postToAuthActivity(`LEGACY PERSON is logged in ${context}`)
  //     } else {
  //       postToAuthActivity(`UNKNOWN ENTITY is logged in ${context}`)
  //     }
  //   }
  // }, [
  //   authContextData.isLoggedIn,
  //   authContextData.userId,
  //   authContextData.organizationId,
  //   authContextData.personId,
  //   authContextData.accountId
  // ])

  return <AuthContext.Provider value={authContextData}>{props.children}</AuthContext.Provider>
}
