import { Box, Flex, Heading, Text, VStack } from '@chakra-ui/react'
import RelationshipStrength, { relationshipStrengths } from '~/lib/relationshipStrength'

function renderRelationshipRatingKeyEntry(strength: RelationshipStrength, idx: number) {
  return (
    <Box>
      {idx === 0 && (
        <Heading size="md" py={4} textAlign={'center'}>
          How to Rate the Strength of Your Relationships
        </Heading>
      )}

      <Heading size="xs" mb={1}>
        {strength.emojiPlusText}
      </Heading>
      <Text color="gray.700" fontSize={14} mb={2}>
        {strength.proseDescription}
      </Text>
    </Box>
  )
}

const RelationshipRatingKey: React.FC = () => {
  const relationshipStrengthsDescending = [...relationshipStrengths].reverse()
  return (
    <Box py={0}>
      <Flex justify="center">
        <VStack spacing={2} pl={0}>
          {relationshipStrengthsDescending.map(renderRelationshipRatingKeyEntry)}
        </VStack>
      </Flex>
    </Box>
  )
}

export default RelationshipRatingKey
