//region imports
import { useEffect, useState } from 'react'
import { Text, Image, Container } from '@chakra-ui/react'
import { useAuth } from '~/auth'
import { CompetitorsApi, IntroductionsApi } from '~/api'
import useDocumentTitle from '~/hooks/useDocumentTitle'
import useExpressStore from '~/stores/express'
import { PendoTrigger } from '~/components'
import { DiscoverWelcomeBanner } from '~/components/Discover'
import { useShallow } from 'zustand/react/shallow'
import Header from '~/components/Header'
import Footer from '~/components/Footer'
import loadingImage from '~/assets/loading.gif'
import { FilterMenus } from './FilterMenus'
import { ProspectList } from './ProspectList'
import useDiscoverStore from '~/stores/discover'

export const Discover = () => {
  useDocumentTitle('Discover Prospects – SmallWorld')

  const auth = useAuth()

  //region stores
  const membership = useExpressStore((state) => state.membership)

  const { hasTargetCompanies, prospectMetasCount } = useDiscoverStore(
    useShallow((state) => ({
      hasTargetCompanies: state.hasTargetCompanies,
      prospectMetasCount: state.prospectMetasCount
    }))
  )

  //region useStates

  const [hasCompetitorCompanies, setHasCompetitorCompanies] = useState<boolean>(false)
  const [hasIntro, setHasIntro] = useState<boolean>(true)
  const getCompetitorsRequest = CompetitorsApi.useGetCompetitorsForMember(membership?.id)

  const getIntroductionsRequest = IntroductionsApi.useGetIntroductionsForPersonRequest(auth.personId)

  // region Use Effects
  // Set has intro
  useEffect(() => {
    if (!getIntroductionsRequest.isLoading) {
      setHasIntro(
        getIntroductionsRequest.data.introductions?.filter((a) => a.requesterPersonId == auth.personId).length > 0
      )
    }
  }, [getIntroductionsRequest.isLoading, getIntroductionsRequest.data, setHasIntro, auth])

  // Set has competitor companies
  useEffect(() => {
    if (!getCompetitorsRequest.isLoading) {
      setHasCompetitorCompanies(getCompetitorsRequest.data?.competitors?.length > 0)
    }
  }, [getCompetitorsRequest.isLoading, getCompetitorsRequest.data, setHasCompetitorCompanies])

  return (
    <>
      <Header />
      <Container maxW="container.lg">
        {/* welcome banner */}
        {!hasIntro && !getIntroductionsRequest.isLoading && (
          <DiscoverWelcomeBanner
            hasTargetCompanies={hasTargetCompanies}
            hasCompetitorCompanies={hasCompetitorCompanies}
          />
        )}
        {/* discover prospects */}

        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <Text as="h1" fontSize="3xl" color="black" mr={4} mb={4}>
            Discover Prospects
          </Text>

          {prospectMetasCount == null ? (
            <Image src={loadingImage} w={5} alt="Loading" />
          ) : (
            prospectMetasCount > 0 && (
              <Text as="span" fontSize="medium" color="gray.400">
                ({prospectMetasCount.toLocaleString()})
              </Text>
            )
          )}
        </div>

        {/* menus below */}
        <FilterMenus />
        {/* Prospect List Below */}
        <ProspectList />
      </Container>

      <PendoTrigger bgColor="blue.400" />

      <Footer />
    </>
  )
}
