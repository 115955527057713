export const emojis = [
  { emoji: '❓', value: 'Very Weak' },
  { emoji: '👎', value: 'Weak' },
  { emoji: '🤝', value: 'Average' },
  { emoji: '💪', value: 'Strong' },
  { emoji: '🔥', value: 'Very Strong' }
]

export const getEmoji = (strength: string) => {
  if (!strength) {
    return null
  }
  const normalizedStrength = normalizeStrength(strength)
  return emojis.find((e) => e.value === normalizedStrength)
}

export const getEmojiPlusText = (strength: string): string => {
  if (!strength) {
    return null
  }
  const normalizedStrength = normalizeStrength(strength)

  return emojis.find((e) => e.value === normalizedStrength).emoji + ' ' + normalizedStrength
}

export const normalizeStrength = (strength: string) => {
  return strength.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
}
