import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Box, Grid, GridItem } from '@chakra-ui/react'
import { Helmet } from '~/components'
import { useAuth } from 'auth'
import useExpressStore from '~/stores/express'
import { useShallow } from 'zustand/react/shallow'

export const ProtectedLayout = () => {
  const auth = useAuth()
  const location = useLocation()

  const { setPreviousLocations } = useExpressStore(
    useShallow((state) => ({
      setPreviousLocations: state.setPreviousLocations
    }))
  )

  useEffect(() => {
    window.pendo?.initialize({ visitor: { id: auth.personId }, account: { id: auth.accountId } })
  }, [auth.personId, auth.accountId])

  useEffect(() => {
    setPreviousLocations(location)
  }, [location, setPreviousLocations])

  return (
    <Box>
      <Helmet />
      <Grid templateRows={['min-content', 'auto']} h="100vh">
        <GridItem h="full">
          <Outlet />
        </GridItem>
      </Grid>
    </Box>
  )
}
