import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Link,
  Text,
  Tooltip,
  useToast
} from '@chakra-ui/react'

import { useNavigate } from 'react-router-dom'
import { topXMax, getEmoji, getEmojiPlusText } from '~/lib'
import iconGroup from '~/assets/icons/icon-group.svg'
import iconLevel from '~/assets/icons/icon-level.svg'
import RelationshipStrength from '~/lib/relationshipStrength'

type ProspectCardProps = {
  prospect: Prospect
  index: number
  onHide: (prospect: Prospect) => void
}

export const ProspectCard = ({ prospect: prospect, onHide }: ProspectCardProps) => {
  const { prospectName, companyName, title, buyingCenter, buyingCenterName, level, connections } = prospect

  const countMoreThan3 = connections.length > 3 ? connections.length - 3 : 0

  const navigate = useNavigate()

  const top3Conns = topXMax<Connection>(connections, 3)

  const toast = useToast()

  const handleViewDetails = () => {
    navigate(`/introduction-request/${prospect.prospectPersonId}`)
  }

  return (
    <>
      <Box borderWidth="1px" borderColor="gray.200" borderRadius="md" mb="4" bg="white" className="pendo-prospect-card">
        {/* whole thing */}

        {/* top row with majority of stuff */}
        <Flex direction="row" alignItems="center" justifyContent="space-between" p={8}>
          {/* Name and company info */}
          <Box className="pendo-prospect-name">
            <Text fontSize="3xl">{prospectName}</Text>
            <Text fontSize="l">
              <Text as="span" mr={1} fontWeight={700}>
                {companyName}
              </Text>{' '}
              {title}
            </Text>
          </Box>
          {/* Hide and view details */}
          <HStack>
            <Link color="gray.500" fontSize="xs" mr={4} onClick={() => onHide(prospect)}>
              Hide
            </Link>
            <Button
              textColor="blue.400"
              borderColor="blue.400"
              borderWidth="2px"
              padding={5}
              minW={190}
              className="pendo-view-prospect-details"
              variant="outline"
              bg="white"
              onClick={handleViewDetails}
              _hover={{
                boxShadow: '0 8px 11px rgba(0,0,0,.09)'
              }}
            >
              View Details
            </Button>
          </HStack>
        </Flex>

        {/* second row with the connector details */}
        <Box bg="gray.100" borderBottomLeftRadius="md" borderBottomRightRadius="md" pr={8} pl={6} py={2}>
          <Flex justifyContent="space-between">
            {/* connections */}
            <HStack className="pendo-connector-names">
              {top3Conns.map((connection: Connection, idx: number) => {
                const relationshipStrength = RelationshipStrength.fromDatabaseValue(connection.strength as Strengths)

                return (
                  <Tooltip
                    lineHeight={1.2}
                    fontWeight={400}
                    key={`${connection.personId}-${idx}`}
                    label={
                      <>
                        {connection.hoverText}
                        {connection.strength != '' && (
                          <>
                            <br />
                            <br />
                            {`This Super Connector has ${relationshipStrength.fullPhraseWithArticle} with the Prospect.`}
                          </>
                        )}
                      </>
                    }
                    textAlign="center"
                    borderRadius={5}
                    p={2}
                    hasArrow
                  >
                    <HStack>
                      <HStack>
                        <Text fontSize={12} pl={2} fontWeight="bold" textColor="black">
                          {connection.name === 'Super Connector' ? 'Anonymous' : connection.name}{' '}
                        </Text>
                        {connection.relDegree === '1' && (
                          <Box
                            height="20px"
                            color="gray.600"
                            fontSize="xs"
                            px={1}
                            borderColor="gray.300"
                            borderWidth="1px"
                            borderStyle="solid"
                            borderRadius="3px"
                          >
                            1st
                          </Box>
                        )}
                      </HStack>
                      <HStack>
                        <Text fontSize={12}> {getEmoji(connection.strength)?.emoji}</Text>
                        <Text textColor="gray.300"> {idx === top3Conns.length - 1 ? '' : '|'}</Text>
                      </HStack>
                    </HStack>
                  </Tooltip>
                )
              })}
              {countMoreThan3 && (
                <HStack>
                  <Text textColor="gray.300">|</Text>
                  <Text fontSize={12} textColor="gray.600" ml={2}>
                    {' '}
                    + {countMoreThan3} more
                  </Text>
                </HStack>
              )}
            </HStack>

            {/* prospect buying center and level */}
            <HStack spacing={10}>
              {buyingCenterName !== 'Unknown' && (
                <HStack spacing={1}>
                  <Image src={iconGroup} alt="buying center icon" />
                  <Text fontSize={12} ml={2} textColor="black">
                    {buyingCenterName}
                  </Text>
                </HStack>
              )}
              <HStack spacing={1}>
                <Image src={iconLevel} alt="Level Icon" />
                <Text fontSize={12} ml={2} textColor="black">
                  {level}-level
                </Text>
              </HStack>
            </HStack>
          </Flex>
        </Box>
      </Box>
    </>
  )
}
