import { Box, HStack, Image, Text, Tooltip, VStack } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useIntroductionsQuery } from '~/api/introductions'

import InfoIcon from '~/assets/icons/icon-info.svg'
import { useLoggedInUserQuery } from '~/api/user'

export const IntroductionScorecard = () => {
  const {
    data: { user, currentMembership }
  } = useLoggedInUserQuery()

  const introsQuery = useIntroductionsQuery({ personId: user?.personId, membership: currentMembership })

  let counts = useMemo(() => {
    const { data: intros = [] } = introsQuery
    if (!user?.personId) return { requestedCount: 0, acceptedCount: 0 }

    let requestedCount = 0
    let acceptedCount = 0

    for (const intro of intros) {
      if (intro.requesterPersonId === user.personId) {
        requestedCount++
      }
      if (intro.connectorPersonId === user.personId && intro.isWilling === true) {
        acceptedCount++
      }
    }

    return { requestedCount, acceptedCount }
  }, [user?.personId, introsQuery.data])

  let requestedCount = counts.requestedCount
  let acceptedCount = counts.acceptedCount

  return (
    <Box borderRadius={5} border="1px solid" borderColor="gray.200" mb={8}>
      {' '}
      <Text
        bg="gray.050"
        textColor={'blue.400'}
        fontWeight="bold"
        fontSize={'.75rem'}
        textTransform={'uppercase'}
        borderBottom="1px solid"
        borderColor="gray.200"
        width="full"
        textAlign="center"
        p={2}
      >
        Introduction Tally
      </Text>{' '}
      <HStack width="full" spacing={0} m={0} py={2}>
        <VStack w="50%" spacing={0}>
          <Text m={0} fontSize={20} fontWeight={'bold'} textColor={'gray.600'}>
            {requestedCount}
          </Text>
          <HStack>
            <Text fontSize={11} m={0} textColor={'gray.600'}>
              Requested
            </Text>
            <Tooltip
              placement="bottom"
              label="This is the number of introductions that have been requested by you."
              hasArrow
            >
              <Image src={InfoIcon} boxSize="12px" />
            </Tooltip>
          </HStack>
        </VStack>

        <VStack w="50%" spacing={0}>
          <Text m={0} fontSize={20} fontWeight={'bold'} textColor={'gray.600'}>
            {acceptedCount}
          </Text>
          <HStack>
            <Text fontSize={11} m={0} textColor={'gray.600'}>
              Accepted
            </Text>
            <Tooltip
              placement="bottom"
              label="This is the number of introductions that have been accepted by you."
              hasArrow
            >
              <Image src={InfoIcon} boxSize="12px" />
            </Tooltip>
          </HStack>
        </VStack>
      </HStack>
    </Box>
  )
}
