import { Badge, Box, Container, Flex, Image, Link as ChakraLink } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import SmallWorldIcon from '../assets/smallworld-icon.svg'
import SettingsIcon from '../assets/icons/icon-settings.svg'
import { useIntroductionsQuery } from '~/api/introductions'
import { isInProgress, isRequested } from '~/pages/Introductions/lib'
import { useMemo } from 'react'
import { RelationshipLeadStore as RLS, useExpressStore } from '~/stores'
import { useAuth } from '~/auth'
import { useLeadsRequest } from '~/api'

const Header = () => {
  const { personId, accountId } = useAuth()

  const membership = useExpressStore((state) => state.membership)
  const leadsStore = RLS.useStore((state) => state)

  const introsQuery = useIntroductionsQuery({ personId, membership: membership })
  const leadsQuery = useLeadsRequest(personId, {
    accountId: leadsStore.showingEnterpriseLeads !== undefined && leadsStore.showingEnterpriseLeads ? accountId : null,
    membership: leadsStore.showingEnterpriseLeads !== undefined && leadsStore.showingEnterpriseLeads ? null : membership
  })

  const introCount = useMemo(() => {
    const { data: intros = [] } = introsQuery
    return intros.reduce((acc, intro) => {
      if (intro.connectorPersonId === personId && (isRequested(intro) || isInProgress(intro))) {
        return acc + 1
      }
      return acc
    }, 0)
  }, [personId, introsQuery.data])

  let relationshipLeads = []
  let unassignedLeads = []

  if (leadsStore.isInitialized) {
    relationshipLeads = leadsStore.relationshipLeads
    unassignedLeads = leadsStore.unassignedLeads
  } else {
    relationshipLeads = (leadsQuery.isSuccess && leadsQuery.data.relationshipLeads) || []
    unassignedLeads = (leadsQuery.isSuccess && leadsQuery.data.unassignedLeads) || []
  }

  const totalLeadsCount =
    RLS.findByState(relationshipLeads, 'queued').length + RLS.findByState(unassignedLeads, 'queued').length

  return (
    <Box as="header" py={4} mb={5}>
      <Container maxW="container.lg">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center" flexGrow={1}>
            <Image src={SmallWorldIcon} boxSize="48px" mr={8} />

            <Box as="nav" display="flex" flexGrow={1}>
              <ChakraLink
                as={RouterLink}
                to="/discover"
                color="gray.700"
                mr={10}
                _hover={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}
                fontWeight="500"
                fontSize=".875rem"
              >
                Discover Prospects
              </ChakraLink>
              <ChakraLink
                as={RouterLink}
                to="/search"
                color="gray.700"
                mr={10}
                _hover={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}
                fontWeight="500"
                fontSize=".875rem"
              >
                Search Prospects
              </ChakraLink>
              <Flex alignItems="center" mr={10}>
                <ChakraLink
                  as={RouterLink}
                  to="/leads"
                  color="gray.700"
                  mr={2}
                  _hover={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}
                  fontWeight="500"
                  fontSize=".875rem"
                >
                  Relationship Leads
                </ChakraLink>
                {totalLeadsCount > 0 && (
                  <Badge
                    backgroundColor="red.400"
                    color="white"
                    borderRadius="full"
                    px={2}
                    fontSize="0.675em"
                    fontWeight="bold"
                    fontFamily="Arial, sans-serif"
                  >
                    {totalLeadsCount > 99 ? '99+' : totalLeadsCount}
                  </Badge>
                )}
              </Flex>
              <ChakraLink
                as={RouterLink}
                to="/my-network"
                color="gray.700"
                mr={10}
                _hover={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}
                fontWeight="500"
                fontSize=".875rem"
              >
                My Network
              </ChakraLink>
              <Flex alignItems="center">
                <ChakraLink
                  as={RouterLink}
                  to="/introductions"
                  color="gray.700"
                  mr={2}
                  _hover={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}
                  fontWeight="500"
                  fontSize=".875rem"
                >
                  My Introductions
                </ChakraLink>
                {introCount > 0 && (
                  <Badge
                    backgroundColor="red.400"
                    color="white"
                    borderRadius="full"
                    px={2}
                    fontSize="0.675em"
                    fontWeight="bold"
                    fontFamily="Arial, sans-serif"
                  >
                    {introCount}
                  </Badge>
                )}
              </Flex>
            </Box>
          </Flex>

          <Box>
            <ChakraLink as={RouterLink} to="/settings" color="gray.600" _hover={{ textDecoration: 'none' }}>
              <Image src={SettingsIcon} boxSize="24px" />
            </ChakraLink>
          </Box>
        </Flex>
      </Container>
    </Box>
  )
}

export default Header
