import { useState, useEffect } from 'react'
import { VStack, Heading, HStack, Button, Icon, Text, useDisclosure, useToast } from '@chakra-ui/react'
import { PiTrash, PiPlusCircle } from 'react-icons/pi'
import { DataTable } from './DataTable'
import { DeleteAllModal } from './DeleteAllModal'
import { CompanySearchModal } from './CompanySearchModal'
import { CompetitorsApi, HttpError } from '~/api'
import useExpressStore from '~/stores/express'
import Header from '~/components/Header'
import Footer from '~/components/Footer'

export const CompetitorCompanies = () => {
  // May need to reconfigure once the final form of the user object is determined
  const membership: Membership = useExpressStore((state) => state.membership)
  const toast = useToast()
  // API Request Definitions
  const getCompetitorsRequest = CompetitorsApi.useGetCompetitorsForMember(membership?.id)
  const createCompetitorRequest = CompetitorsApi.useCreateCompetitor()
  const deleteCompetitorRequest = CompetitorsApi.useDeleteCompetitor()
  const deleteAllCompetitorsRequest = CompetitorsApi.useDeleteAllCompetitors()

  const { isOpen: isOpenAdd, onOpen: onOpenAdd, onClose: onCloseAdd } = useDisclosure()
  const { isOpen: isOpenDeleteAll, onOpen: onOpenDeleteAll, onClose: onCloseDeleteAll } = useDisclosure()

  const [competitorCompanies, setCompetitorCompanies] = useState<Competitor[]>([])

  useEffect(() => {
    if (!getCompetitorsRequest.isLoading && getCompetitorsRequest.data?.competitors) {
      setCompetitorCompanies(getCompetitorsRequest.data.competitors)
    }
  }, [getCompetitorsRequest.isLoading, getCompetitorsRequest.data?.competitors])

  const handleAdd = (id: number, companyName: string) => {
    const newCompetitorCompany: Competitor = {
      companyId: id,
      companyName: companyName,
      organizationMemberId: membership.id
    }
    const requestCompetitorCompany: CreateCompetitorRequestBody = {
      companyId: id,
      organizationMemberId: membership.id,
      companyName: companyName
    }
    createCompetitorRequest.mutate(requestCompetitorCompany, {
      onSuccess: (data) => {
        newCompetitorCompany.id = data.id
        setCompetitorCompanies(() => [...competitorCompanies, newCompetitorCompany])
        onCloseAdd()
        toast({
          position: 'top',
          title: 'Success',
          description: `${companyName} was added to your list of Competitors.`,
          status: 'success',
          duration: 3_000,
          isClosable: true
        })
      },
      onError: (error: HttpError) => {
        if (error.statusCode === 409) {
          // Handle status 409 (Conflict) error
          toast({
            position: 'top',
            title: 'Error',
            description: `${companyName} already exists in your list of Competitors.`,
            status: 'error',
            duration: 3_000,
            isClosable: true
          })
        } else {
          // Handle other errors
          toast({
            position: 'top',
            title: 'An Error Occurred',
            description: 'There was a problem adding that Competitor. Please try again.',
            status: 'error',
            duration: 3_000,
            isClosable: true
          })
        }
        return
      }
    })
  }

  const handleDelete = (competitorId: number) => {
    deleteCompetitorRequest.mutate(competitorId, {
      onSuccess: () => {
        setCompetitorCompanies((current) => current.filter((company) => company.id !== competitorId))
        toast({
          position: 'top',
          title: 'Success',
          description: 'Company was removed from your list of Competitors.',
          status: 'success',
          duration: 3_000,
          isClosable: true
        })
      },
      onError: () => {
        toast({
          position: 'top',
          title: 'An Error Occurred',
          description: 'There was a problem deleting the Competitor Company, please try again.',
          status: 'error',
          duration: 3_000,
          isClosable: true
        })
        return
      }
    })
  }

  const handleDeleteAll = () => {
    deleteAllCompetitorsRequest.mutate(membership.id, {
      onSuccess: () => {
        setCompetitorCompanies(() => [])
        toast({
          position: 'top',
          title: 'Success',
          description: `We've removed all of your Competitors.`,
          status: 'success',
          duration: 3_000,
          isClosable: true
        })
      },
      onError: () => {
        toast({
          position: 'top',
          title: 'An Error Occurred',
          description: 'There was a problem deleting all Competitors, please try again.',
          status: 'error',
          duration: 3_000,
          isClosable: true
        })
        return
      }
    })
  }

  return (
    <>
      <Header />
      <VStack maxW="container.lg" mx="auto" px={{ base: 2, sm: 20, lg: 40 }} mb={8} gap={10}>
        <Heading as="h1" textAlign="center" fontWeight="400">
          Competitor Companies
        </Heading>
        <Text textAlign={'center'}>
          Add your main competitor companies to this list. It'll make sure you don't request introductions, or get
          introduction requests, from anyone working at those companies.
        </Text>
        <CompanySearchModal isOpen={isOpenAdd} onClose={onCloseAdd} onAdd={handleAdd} onDelete={handleDelete} />
        <DeleteAllModal isOpen={isOpenDeleteAll} onClose={onCloseDeleteAll} onDelete={handleDeleteAll} />
        <VStack w="full" gap={4}>
          <HStack w="full" justify="space-between">
            <Button
              w={32}
              size="sm"
              borderRadius="md"
              fontWeight="semibold"
              bg="green.400"
              _hover={{ bg: 'green.500' }}
              onClick={onOpenAdd}
              leftIcon={<Icon as={PiPlusCircle} boxSize="16px" />}
            >
              Add New
            </Button>
            <Button
              w={32}
              size="sm"
              borderRadius="md"
              fontWeight="semibold"
              bg="red.500"
              _hover={{ bg: 'red.600' }}
              onClick={onOpenDeleteAll}
              leftIcon={<Icon as={PiTrash} boxSize="16px" />}
            >
              Delete All
            </Button>
          </HStack>
          <DataTable data={competitorCompanies} onDelete={handleDelete} />
        </VStack>
      </VStack>

      <Footer />
    </>
  )
}
