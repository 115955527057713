import { useDisclosure } from '@chakra-ui/react'
import { Row } from '@tanstack/react-table'
import { DeleteModal } from './DeleteModal'
import { DeleteIcon } from './DeleteIcon'

type ActionCellProps = {
  row: Row<CompetitorCompanyRecord>
  onDelete: (id: number) => void
}

export const ActionCell: React.FC<ActionCellProps> = ({ row, onDelete }: ActionCellProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const companyId = row.original.id
  const companyName = row.original.companyName

  return (
    <div style={{ textAlign: 'right' }}>
      <DeleteIcon onOpen={onOpen} companyName={companyName} />
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        companyId={companyId}
        companyName={companyName}
        onDelete={() => onDelete(companyId)}
      />
    </div>
  )
}
