import { Badge } from '@chakra-ui/react'

type CircularBadgeProps = {
  height: string
  width: string
  bgColor: string
  textColor: string
  text: string
}

export const CircularBadge = ({ height, width, bgColor, textColor, text }: CircularBadgeProps) => {
  return (
    <Badge
      width={width}
      height={height}
      borderRadius="50%"
      bg={bgColor}
      color={textColor}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {text}
    </Badge>
  )
}
