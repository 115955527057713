import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { request, isPositiveNumber } from './utils'
import { API_PATH_SWAG } from '~/settings'

const METRICS_NAMESPACE_SWAG = 'metrics'
const RELATIONSHIPS_NAMESPACE_SWAG = 'relationships'
const TARGET_COMPANY_NAMESPACE_SWAG = 'target-companies'

const BASE_URL_METRICS_SWAG = `${import.meta.env.VITE_API_GW_URL}${API_PATH_SWAG}/${METRICS_NAMESPACE_SWAG}`
const BASE_URL_RELATIONSHIPS_SWAG = `${import.meta.env.VITE_API_GW_URL}${API_PATH_SWAG}/${RELATIONSHIPS_NAMESPACE_SWAG}`
const BASE_URL_TARGET_COMPANIES_SWAG = `${
  import.meta.env.VITE_API_GW_URL
}${API_PATH_SWAG}/${TARGET_COMPANY_NAMESPACE_SWAG}`

export const getConnectionCounts = async (
  sourceId: number,
  organizationId: number,
  organizationMemberId: number
): Promise<CountOfConnections> => {
  const url = new URL(
    `${BASE_URL_METRICS_SWAG}/counts-of-connections?sourceId=${sourceId}&organizationId=${organizationId}&organizationMemberId=${organizationMemberId}`
  )

  const result = await request<null, CountOfConnections>(url, 'GET')

  return result
}

export const useGetConnectionCounts = (
  sourceId: number,
  organizationId: number,
  organizationMemberId: number,
  options?: UseQueryOptions<CountOfConnections, Error>
) => {
  return useQuery<CountOfConnections, Error>(
    ['getConnectionCounts', sourceId, organizationId, organizationMemberId],
    () => getConnectionCounts(sourceId, organizationId, organizationMemberId),
    {
      enabled: isPositiveNumber(sourceId) && isPositiveNumber(organizationId) && isPositiveNumber(organizationMemberId),
      ...options
    }
  )
}

export const getRelationshipCount = async (sourceId: number): Promise<number> => {
  const url = new URL(`${BASE_URL_RELATIONSHIPS_SWAG}/count/${sourceId}`)

  const result = await request<null, Count>(url, 'GET')

  return result.count
}

export const useGetRelationshipCount = (sourceId: number, options?: UseQueryOptions<number, Error>) => {
  return useQuery<number, Error>(['getRelationshipCount', sourceId], () => getRelationshipCount(sourceId), {
    enabled: isPositiveNumber(sourceId),
    ...options
  })
}

export const getRatedRelationshipCount = async (sourceId: number): Promise<number> => {
  const url = new URL(`${BASE_URL_RELATIONSHIPS_SWAG}/rated-count/${sourceId}`)

  const result = await request<null, Count>(url, 'GET')

  return result.count
}

export const useGetRatedRelationshipCount = (sourceId: number, options?: UseQueryOptions<number, Error>) => {
  return useQuery<number, Error>(['getRatedRelationshipCount', sourceId], () => getRatedRelationshipCount(sourceId), {
    enabled: isPositiveNumber(sourceId),
    ...options
  })
}

export const getTargetCompanyCount = async (sourceId: number): Promise<number> => {
  const url = new URL(`${BASE_URL_TARGET_COMPANIES_SWAG}/count/${sourceId}`)

  const result = await request<null, Count>(url, 'GET')

  return result.count
}

export const useGetTargetCompanyCount = (sourceId: number, options?: UseQueryOptions<number, Error>) => {
  return useQuery<number, Error>(['getTargetCompanyCount', sourceId], () => getTargetCompanyCount(sourceId), {
    enabled: isPositiveNumber(sourceId),
    ...options
  })
}
