import { Flex, Box, Button, Text, Link } from '@chakra-ui/react'
import { railsUrl } from '~/lib'

const LinkToTargetCompanies = ({ accountId }: { accountId?: number }) => {
  const url = accountId
    ? railsUrl(`/accounts/${accountId}/onboarding_step?page=settings&step=onboarding_targeted_companies`)
    : '/target-companies'

  return (
    <Link color="blue.400" fontWeight="bold" ml={1} mr={1} href={url}>
      Target Companies
    </Link>
  )
}

const LinkToDiscover = ({ accountId }: { accountId?: number }) => {
  const url = accountId ? railsUrl() : '/discover'

  return (
    <Link color="blue.400" fontWeight="bold" ml={1} mr={1} href={url}>
      Discover Prospects
    </Link>
  )
}

interface Props {
  accountId: number | undefined
  windowCanSupportOverlay: boolean
  toggleOverlay: () => void
}

export default function RelationshipLeadsFooter(props: Props) {
  return (
    <>
      <Flex direction="column">
        <Box textAlign="center">
          <Text as="h2" fontSize="2xl">
            Want to see more Relationship Leads?
          </Text>
          <Text color="gray.600">
            Add more
            <LinkToTargetCompanies accountId={props.accountId} />
            or request Relationship strength from Connectors on your
            <LinkToDiscover accountId={props.accountId} />
            page.
          </Text>
        </Box>
      </Flex>

      <Button
        borderRadius="full"
        display={props.windowCanSupportOverlay ? '' : 'none'}
        p="2"
        position="fixed"
        bottom="4"
        right="4"
        width="12"
        textAlign="center"
        textColor="white"
        size="lg"
        onClick={props.toggleOverlay}
        zIndex="1000"
      >
        ?
      </Button>
    </>
  )
}
