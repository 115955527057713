import { useMutation, useQuery } from '@tanstack/react-query'
import { API_PATH, API_PATH_SWAG, RAILS_URL, SWAG_URL } from '~/settings'
import { request } from './utils'

// todo: type when the json payload is solidified
const fetchProspectConnectors = async (id: number) => {
  const { relationships } = await request<null, { [key: string]: any }>(
    new URL(`${API_PATH_SWAG}/relationships/of-prospect/${id}`, SWAG_URL)
  )
  return relationships
}

export const useProspectConnectorsQuery = ({ id }: { id: number }) => {
  return useQuery({
    queryKey: ['swag', 'prospect-connectors', id],
    queryFn: () => fetchProspectConnectors(id),
    enabled: !!id
  })
}

export const useRequestStrengthMutation = () => {
  return useMutation({
    mutationFn: (id: number) => {
      const requestStrengthUrl = new URL(`${RAILS_URL}${API_PATH}/classic/relationships/${id}/request_strength`)
      return request<number>(requestStrengthUrl, 'POST', {})
    }
  })
}

const fetchRelationship = (id: number) => {
  const url = new URL(`${API_PATH_SWAG}/relationships/${id}`, SWAG_URL)
  return request<null, { [key: string]: Relationship }>(url)
}

export const useRelationshipQuery = (id: number | undefined) => {
  return useQuery({
    queryKey: ['swag', 'relationships', id],
    queryFn: () => fetchRelationship(id),
    select: (data) => data.relationship,
    enabled: !!id
  })
}
