import { Box, Flex, Text } from '@chakra-ui/react'
import { fullname } from '~/lib/fullname'
import { useUserRoleIn } from './hooks'
import { connectorName, connectorType } from './lib'
import LinkedInIcon from '~/components/LinkedInIcon'
import { hasLinkedIn, openLinkedIn } from '~/lib/linkedinUtils'
import { StrengthDisplay } from '~/components/RatingModule/StrengthDisplay'

type ParticipantsProps = {
  introduction: Introduction
  user: User
  currentMembership: Membership
  anonymous: boolean
  connectors: { [key: string]: any }
}

export const Participants = ({ introduction, user, currentMembership, anonymous, connectors }: ParticipantsProps) => {
  const { connector, prospect, relationship, requester } = introduction
  const { isConnector, isSuperConnector } = useUserRoleIn(introduction, user, currentMembership)

  if (import.meta.env.MODE === 'development' && anonymous) {
    console.log(
      `The anonymized Super Connector on this introduction is ${connector.firstName} ${connector.lastName} (Person No.${connector.id})`
    )
  }
  if (!introduction?.prospect || !introduction?.connector) {
    return <Box /> // skeleton me!
  }

  //
  // This is not ideal
  const connection = connectors.connectors.find((c) => c['connector_person_id'] == connector.id)

  let trusted: string
  const trustedCount = Number(connection['total_trusted_friends'])

  if (trustedCount > 1) {
    const hovers = connection['hover_text'].split('with')
    trusted = hovers.at(-1).trim().slice(0, -1)
  } else {
    trusted = connection['trusted_friend_name']
  }

  const ratedRelationshipSentence = isConnector
    ? 'You rated your relationship'
    : 'The Super Connector rated their relationship as'
  let sharedRelationshipSentence = 'You both share a trusted relationship with'

  return (
    <Flex>
      <Box flex="1" textAlign="center" borderRightWidth="1px" borderStyle="solid" borderColor="gray.100" py={8}>
        <Text fontWeight="bold" fontSize="2xs" color="blue.400" textTransform="uppercase" pb={1}>
          Prospect
        </Text>
        <Flex alignItems="baseline" justifyContent="center" w={'100%'}>
          <Text fontSize="xl" mr={2}>
            {fullname(prospect)}
          </Text>
          {hasLinkedIn(prospect.linkedinUrl) && (
            <LinkedInIcon
              cursor="pointer"
              onClick={() => openLinkedIn(prospect.linkedinUrl)}
              sx={{ transform: 'translateY(1px)' }}
            />
          )}
        </Flex>

        <Text fontSize="md" fontWeight="bold" pb={4}>
          {connectors['prospect_company_name'] || prospect.companyName}
        </Text>

        {relationship.strength && (
          <>
            <Text fontSize="xs" textColor={'gray.600'}>
              {ratedRelationshipSentence}
            </Text>
            <StrengthDisplay rating={relationship.strength} fontSize="xs" />
          </>
        )}

        {!relationship.strength && (
          <Text fontSize="xs" textColor={'gray.600'}>
            This relationship is unrated.
          </Text>
        )}
      </Box>
      <Box flex="1" textAlign="center" borderLeftWidth="1px" borderStyle="solid" borderColor="gray.100" py={8}>
        {!isConnector && (
          <Box px={4}>
            <Text fontWeight="bold" fontSize="2xs" color="gray.400" textTransform="uppercase" pb={1}>
              {connectorType({ isSuperConnector })}
            </Text>

            <Flex alignItems="baseline" justifyContent="center" w={'100%'}>
              <Text fontSize="xl" mr={2}>
                {connectorName(connector, isConnector, anonymous)}
              </Text>

              {!anonymous && hasLinkedIn(connector.linkedinUrl) && (
                <LinkedInIcon
                  cursor="pointer"
                  onClick={() => openLinkedIn(connector.linkedinUrl)}
                  sx={{ transform: 'translateY(1px)' }}
                />
              )}
            </Flex>

            <Text fontSize="md" fontWeight="bold" pb={4}>
              {(isConnector || !anonymous) && connector.companyName}
            </Text>
            <Text fontSize="xs" textColor={'gray.600'}>
              {sharedRelationshipSentence}
            </Text>
            <Text fontSize="xs" fontWeight="semibold">
              {trusted}
            </Text>
          </Box>
        )}
        {isConnector && (
          <Box px={4}>
            <Text fontWeight="bold" fontSize="2xs" color="gray.400" textTransform="uppercase" pb={1}>
              Requester
            </Text>
            <Flex alignItems="baseline" justifyContent="center" w={'100%'}>
              <Text fontSize="xl" mr={2}>
                {fullname(requester)}
              </Text>
              {hasLinkedIn(requester.linkedinUrl) && (
                <LinkedInIcon
                  cursor="pointer"
                  onClick={() => openLinkedIn(requester.linkedinUrl)}
                  sx={{ transform: 'translateY(1px)' }}
                />
              )}
            </Flex>
            <Text fontSize="md" fontWeight="bold" pb={4}>
              {requester.companyName}
            </Text>
            <Text fontSize="xs" textColor={'gray.600'}>
              {sharedRelationshipSentence}
            </Text>
            <Text fontSize="xs" fontWeight="semibold">
              {trusted}
            </Text>
          </Box>
        )}
      </Box>
    </Flex>
  )
}
