import { useEffect } from 'react'
import useRedirect from '../Login/redirect'

export const Home = () => {
  const redirect = useRedirect()

  useEffect(() => {
    redirect.from('login')
  }, [redirect])

  return <div />
}
