export const useUserRoleIn = (
  introduction: Introduction,
  user: User,
  currentMembership: Membership
): {
  isConnector: boolean
  isRequester: boolean
  isSuperConnector: boolean
  isAnonymous: boolean
} => {
  return {
    isConnector: introduction?.connectorPersonId === user.personId,
    isRequester: introduction?.requesterMembershipId === currentMembership.id,
    isSuperConnector: true,
    isAnonymous: false
  }
}
